/* eslint-disable no-console */
/* eslint-disable camelcase */

import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { errorMessages } from '@constants'
import { buildQueryString } from '../../helpers'
import * as actions from '../actions'
import * as types from '../constants'
import { API } from '../../api'
import { showAlert } from '../actions/alert'
import { getUserRequested } from '../actions'

const barongConfig = {
  apiVersion: 'barong',
}

const peatioConfig = {
  apiVersion: 'peatio',
  withHeaders: true,
}

export function* decryptToken(action) {
  try {
    const gateway_params = { address: '', amount: 0.0, callback_url: '' }
    const decrypted_params = yield call(API.post(barongConfig), '/identity/users/decrypt_token', {
      token: action.payload,
    })
    if (decrypted_params && decrypted_params.currency !== 'bizz') {
      // Calculate bizz equivalent value
      const response = yield call(API.post(peatioConfig), '/public/currency/conversation', {
        currency_to: 'bizz',
        currency_from: decrypted_params.currency,
      })
      if (response && response.data.equivalent_val) {
        gateway_params.amount = parseFloat(
          (parseFloat(decrypted_params.amount) * parseFloat(response.data.equivalent_val)).toFixed(
            8,
          ),
        )
        gateway_params.address = decrypted_params.address
        gateway_params.callback_url = decrypted_params.callback_url
        gateway_params.created_at = decrypted_params.created_at
      }
    } else {
      gateway_params.amount = parseFloat(parseFloat(decrypted_params.amount))
      gateway_params.address = decrypted_params.address
      gateway_params.callback_url = decrypted_params.callback_url
      gateway_params.created_at = decrypted_params.created_at
    }
    yield put(actions.saveAddress(gateway_params))
  } catch (e) {
    yield put(actions.failDecryption())
  }
}

export function* saveDecryptToken() {
  yield takeEvery(types.DECRYPT_TOKEN, decryptToken)
}

export function* makePaymentRequest(action) {
  try {
    const { amount } = action.payload
    const { currency } = action.payload
    const makePaymentResponse = yield call(API.post(peatioConfig), '/account/payment/checkout', {
      amount,
      currency,
    })
    if (makePaymentResponse.data.result.code == '000.200.100') {
      yield put(actions.savePaymentDetail(makePaymentResponse.data.id))
    } else {
      yield put(
        showAlert({
          message: [makePaymentResponse.data.result.description],
          type: 'error',
        }),
      )
    }
  } catch (e) {
    yield put(
      showAlert({
        message: ['Error - could not initiate payment'],
        type: 'error',
      }),
    )
  }
}

export function* makePaymentRequestSaga() {
  yield takeEvery(types.MAKE_PAYMENT_REQUEST, makePaymentRequest)
}

export function* paymentStatus(action) {
  try {
    const id = action.payload

    const paymentStatusResponse = yield call(
      API.get(peatioConfig),
      `/account/axcess/payment_status?id=${id}`,
    )

    yield put(actions.paymentStatusResult(paymentStatusResponse.data))
  } catch (e) {
    yield put(
      showAlert({
        message: [errorMessages.other.something_went_wrong],
        type: 'error',
      }),
    )
  }
}

export function* paymentStatusSaga() {
  yield takeEvery(types.PAYMENT_STATUS, paymentStatus)
}

export function* convertToAnotherCurrency(action) {
  try {
    const currency_to = action.payload.currency_to.toLowerCase()
    const currency_from = action.payload.currency_from.toLowerCase()

    const target_currency = yield call(API.post(peatioConfig), '/public/currency/conversation', {
      currency_to,
      currency_from,
    })

    yield put(
      actions.convertToAnotherCurrencyOutput({
        currency_to,
        currency_from,
        data: target_currency.data,
      }),
    )
  } catch (e) {
    console.log(e)
  }
}

export function* convertToAnotherCurrencySaga() {
  yield takeEvery(types.CONVERT_TO_ANOTHER_CURRENCY, convertToAnotherCurrency)
}

export function* fetchQuote(action) {
  yield put(getUserRequested(true))
  try {
    const target_currency = yield call(
      API.post(peatioConfig),
      '/account/create_quote',
      action.payload,
    )
    yield put(actions.successQuotes(target_currency))
    action.callback(target_currency)
  } catch (e) {
    e.type = 'error'
    action.callback(e)
    yield put(showAlert(e))
  }
  yield put(getUserRequested(false))
}

export function* fetchQuotesSaga() {
  yield takeLatest(types.QUOTE, fetchQuote)
}

export function* getQuoteTransaction(action) {
  yield put(getUserRequested(true))
  try {
    const target_currency = yield call(
      API.get(peatioConfig),
      `/account/quotes?${buildQueryString(action?.paylaod)}`,
    )
    action.callback(target_currency)
  } catch (e) {
    action.callback(e)
  }
  yield put(getUserRequested(false))
}

export function* getQuoteTransactionSaga() {
  yield takeLatest(types.FETCH_QUOTE_TRANSACTION, getQuoteTransaction)
}

export function* modifyQuote(action) {
  yield put(getUserRequested(true))
  try {
    const target_currency = yield call(
      API.put(peatioConfig),
      '/account/update_quote',
      action.paylaod,
    )
    if (action.callback) {
      action.callback(target_currency)
    }
  } catch (e) {
    e.type = 'error'
    if (action.callback) {
      action.callback(e)
    }
    yield put(showAlert(e))
  }
  yield put(getUserRequested(false))
}

export function* modifyQuotesSaga() {
  yield takeLatest(types.UPDATE_QUOTES, modifyQuote)
}
