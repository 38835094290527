/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
import { DEPTH_DATA, DEPTH_ERROR, DEPTH_FETCH } from '../constants'

export const initialDepth = {
  asks: [],
  bids: [],
  loading: false,
}

// eslint-disable-next-line default-param-last
export const depthReducer = (state = initialDepth, action) => {
  switch (action.type) {
    case DEPTH_FETCH:
      return {
        ...state,
        loading: true,
        error: undefined,
      }

    case DEPTH_DATA:
      // eslint-disable-next-line no-case-declarations
      let { asks } = state
      const asks_data = action.payload.asks
      if (asks_data) {
        asks = Object.assign([], replaceOrRemove(asks, asks_data, 'asks').reverse())
      }

      let { bids } = state
      const bid_data = action.payload.bids
      if (bid_data) {
        bids = Object.assign([], replaceOrRemove(bids, bid_data, 'bids'))
      }

      return {
        ...state,
        asks,
        bids,
        loading: false,
        error: undefined,
      }

    case DEPTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}

function replaceOrRemove(arr, elem, side) {
  if (arr.length === 0) {
    if (side === 'asks') {
      return sort_array(elem)
    }
    return elem
  }

  const el = arr.filter((e) => e[0] === elem[0])[0]
  if (el) {
    if (elem[1] === '' || elem[1] === undefined) {
      // eslint-disable-next-line no-param-reassign
      arr = arr.filter((e) => e[0] !== elem[0])
    } else {
      const index = arr.indexOf(el)
      arr.splice(index, 1, elem)
    }
  } else if (elem[1] && elem[0]) {
    arr.push(elem)
  }
  const sorted_arr = elem.sort((a, b) => {
    if (a[0] === b[0]) {
      return a[1] - b[1]
    }
    return b[0] - a[0]
  })

  return sorted_arr
}

function sort_array(elem) {
  const s_array = elem.sort((a, b) => b[0] - a[0])
  return s_array
}
