import {
  FETCH_CRYPTO_SETTING,
  SUCCESS_CRYPTO_SETTING,
  FAIL_CRYPTO_SETTING,
  FETCH_USD_PRICE,
  SUCCESS_FETCH_USD_PRICE,
  FAIL_FETCH_USD_PRICE,
  SET_AMOUNT,
  MODAL_STATUS,
} from '../constants'

export const fetchTuxcCryptoSetting = () => ({ type: FETCH_CRYPTO_SETTING })
export const successTuxcCryptoSetting = (payload) => ({ type: SUCCESS_CRYPTO_SETTING, payload })

export const failTuxcCryptoSetting = (payload) => ({ type: FAIL_CRYPTO_SETTING, payload })
export const fetchUsdPrice = (payload) => ({ type: FETCH_USD_PRICE, payload })
export const successUsdPrice = (payload) => ({ type: SUCCESS_FETCH_USD_PRICE, payload })

export const failUsdPrice = (payload) => ({ type: FAIL_FETCH_USD_PRICE, payload })

export const setLatestAmount = (payload) => ({ type: SET_AMOUNT, payload })

export const modalStatus = (payload, key) => ({ type: MODAL_STATUS, payload, key })
