import { useEffect, useMemo, useState } from 'react'
import { useSearchParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Table from '@components/UiKit/Table'
import useQuery from '@hooks/useQuery'
import { getCurrencies } from '@core/icx/redux/actions/currency'
import Pagination from '@components/Pagination'
import { API_URLS } from '@core/api/apiUrls'
import routes from '@routing/path'
import { configSelectors } from '@core/selectors'

import { getNFETransactionColumns } from '../main/Transactions.settings'
import styles from './style.module.scss'

const ITEMS_COUNT_PER_PAGE = 10

function Transactions() {
  const currencies = useSelector(configSelectors.currencies)
  const [page, setPage] = useState(0)
  const dispatch = useDispatch()
  const [search] = useSearchParams()
  const code = search.get('code')
  const { t } = useTranslation('nsUserProfile')

  const columnsNames = t('columnsNames', { returnObjects: true })

  useEffect(() => {
    dispatch(getCurrencies())
  }, [])

  const currencyPrecision = useMemo(
    () => currencies?.reduce((acc, item) => ({ ...acc, [item.id]: item.precision }), {}),
    [currencies],
  )

  const { data, loading, headers } = useQuery({
    url: API_URLS.token.purchase,
    params: {
      token: code,
      state: 'collected',
      page: page + 1,
      limit: ITEMS_COUNT_PER_PAGE,
    },
  })

  const handlePageChange = (currentPage) => {
    setPage(currentPage.selected)
  }

  const total = headers?.total || 0

  const columns = getNFETransactionColumns(currencyPrecision, currencies, columnsNames)

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Detailed Transactions</h2>

      <div className={styles.wrap_backBtn}>
        <Link to={routes.withAuth.profile.transactions}>Go to all transactions</Link>
      </div>

      <Table
        displayedMobileCards
        columns={columns}
        data={data}
        loading={loading}
        tableLayout="fixed"
        emptyText="You don't have any transactions yet"
      />

      <Pagination
        itemsPerPage={ITEMS_COUNT_PER_PAGE}
        total={Number(total)}
        totalItemsCount={total}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </div>
  )
}

export default Transactions
