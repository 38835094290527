import types from './types'

export const fetchWonkaTokensRequest = (payload) => ({
  type: types.FETCH_WONKA_TOKENS_REQUEST,
  payload,
})

export const fetchWonkaTokensSuccess = (payload) => ({
  type: types.FETCH_WONKA_TOKENS_SUCCESS,
  payload,
})

export const fetchWonkaTokensFailed = (payload) => ({
  type: types.FETCH_WONKA_TOKENS_FAILED,
  payload,
})

export const revealWonkaRequest = (payload) => ({
  type: types.REVEAL_WONKA_REQUEST,
  payload,
})

export const revealWonkaSuccess = (payload) => ({
  type: types.REVEAL_WONKA_SUCCESS,
  payload,
})

export const revealWonkaFailed = (payload) => ({
  type: types.REVEAL_WONKA_FAILED,
  payload,
})

export const revealAllWonkaRequest = (payload) => ({
  type: types.REVEAL_ALL_WONKA_REQUEST,
  payload,
})

export const revealAllWonkaImmediatelySuccess = (payload) => ({
  type: types.REVEAL_ALL_WONKA_IMMEDIATELY_SUCCESS,
  payload,
})

export const revealAllWonkaFailed = (payload) => ({
  type: types.REVEAL_ALL_WONKA_FAILED,
  payload,
})

export const setWonkaPurchaseLoading = (payload) => ({
  type: types.SET_WONKA_PURCHASE_LOADING,
  payload,
})

export const setWonkaRevealAllLoading = (payload) => ({
  type: types.SET_WONKA_REVEAL_ALL_LOADING,
  payload,
})

export const setWonkaRevealExplosion = (payload) => ({
  type: types.SET_WONKA_REVEAL_EXPLOSION,
  payload,
})

export const setWonkaTokensPopupOpen = (payload) => ({
  type: types.SET_WONKA_TOKENS_POPUP,
  payload,
})
