import {
  SUBMIT_BUYTUXC_ORDER,
  SUCCESS_BUYTUXC_ORDER,
  FAIL_BUYTUXC_ORDER,
  FETCH_BUYTUXC,
  SUCCESS_FETCH_BUYTUXC,
  FAIL_FETCH_BUYTUXC,
  FETCH_ALL_BUYTUXC,
  SUCCESS_ALL_FETCH_BUYTUXC,
  FAIL_ALL_FETCH_BUYTUXC,
  SUCCESS_RESPONSE_TUXC,
  SET_SWAP_CURRENCY,
  FETCH_CURRENT_SWAP_PRICE,
  SUCCESS_CURRENT_SWAP_PRICE,
  FAIL_CURRENT_SWAP_PRICE,
  UPDATE_REFRESH_VALUE,
  PURCHASE_FUND,
  NEW_AGREEMENT,
  FETCH_BONUS_DETAIL,
  SET_BONUS_DETAIL,
  FETCH_BONUS_DETAIL_TRANSACTION,
  SET_BONUS_DETAIL_TRANSACTION,
  FETCH_MEMBER_AGREEMENTS,
  SUCCESS_MEMBER_AGREEMENTS,
  FAIL_MEMBER_AGREEMENTS,
} from '../constants'

export const fetchBuyTuxc = (payload) => ({ type: FETCH_BUYTUXC, payload })

export const successFetchBuyTuxc = (payload) => ({ type: SUCCESS_FETCH_BUYTUXC, payload })

export const failFetchBuyTuxc = (payload) => ({ type: FAIL_FETCH_BUYTUXC, payload })

export const fetchtAllBuyTuxc = (payload) => ({ type: FETCH_ALL_BUYTUXC, payload })

export const successAllFetchBuyTuxc = (payload) => ({ type: SUCCESS_ALL_FETCH_BUYTUXC, payload })

export const failAllFetchBuyTuxc = (payload) => ({ type: FAIL_ALL_FETCH_BUYTUXC, payload })

export const submitBuyTuxcOrder = (payload, callback) => ({
  type: SUBMIT_BUYTUXC_ORDER,
  payload,
  callback,
})

export const successBuyTuxcOrder = (payload) => ({ type: SUCCESS_BUYTUXC_ORDER, payload })

export const failBuyTuxcOrder = (payload) => ({ type: FAIL_BUYTUXC_ORDER, payload })

export const successResponseTuxc = (payload) => ({ type: SUCCESS_RESPONSE_TUXC, payload })

export const swapCurrencySet = (payload) => ({ type: SET_SWAP_CURRENCY, payload })

export const getCurrentSwapPrice = (payload) => ({ type: FETCH_CURRENT_SWAP_PRICE, payload })

export const purchaseFund = (payload) => ({ type: PURCHASE_FUND, payload })

export const updateRefreshValue = (payload) => ({ type: UPDATE_REFRESH_VALUE, payload })

export const successCurrentSwapPrice = (payload) => ({ type: SUCCESS_CURRENT_SWAP_PRICE, payload })

export const failCurrentSwapPrice = (payload) => ({ type: FAIL_CURRENT_SWAP_PRICE, payload })

export const newAgreement = (payload, callback) => ({ type: NEW_AGREEMENT, payload, callback })

export const fetchBonusDetail = (payload) => ({ type: FETCH_BONUS_DETAIL, payload })

export const setBonusDetail = (payload) => ({ type: SET_BONUS_DETAIL, payload })

export const fetchBonusDetailTransaction = (payload) => ({
  type: FETCH_BONUS_DETAIL_TRANSACTION,
  payload,
})

export const setBonusDetailTransaction = (payload) => ({
  type: SET_BONUS_DETAIL_TRANSACTION,
  payload,
})
export const fetchMemberAgreements = (payload) => ({ type: FETCH_MEMBER_AGREEMENTS, payload })

export const successMemberAgreements = (payload) => ({ type: SUCCESS_MEMBER_AGREEMENTS, payload })

export const failMemberAgreements = (payload) => ({ type: FAIL_MEMBER_AGREEMENTS, payload })
