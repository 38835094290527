import { call, put, takeEvery } from 'redux-saga/effects'
import { API } from '../../api'
import { successGetStatements, failGetStatements } from '../actions'
import { GET_STATEMENTS } from '../constants'
import { buildQueryString } from '../../helpers'

const statementOptions = {
  apiVersion: 'peatio',
  withHeaders: true,
}

export function* statements(action) {
  try {
    const { data, headers } = yield call(
      API.get(statementOptions),
      `/market/statements?${buildQueryString(action.payload)}`,
    )
    yield put(successGetStatements({ list: data, total: headers.total }))
  } catch (error) {
    yield put(failGetStatements(error))
  }
}

export function* statementSaga() {
  yield takeEvery(GET_STATEMENTS, statements)
}
