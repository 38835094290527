import React from 'react'

import styles from './full_screen_popup.module.scss'

interface FullScreenPopupProps {
  setIsOpen?: (isOpen: boolean) => void
  title: string | React.JSX.Element
  onCloseCb?: VoidFunction
}

function FullScreenPopup({
  setIsOpen,
  title,
  onCloseCb,
  children,
}: React.PropsWithChildren<FullScreenPopupProps>) {
  const onWrapperClick = (e) => {
    if (e.target.className.includes('wrapper')) {
      if (onCloseCb) onCloseCb()
      if (setIsOpen) setIsOpen(false)
    }
  }

  return (
    <div className={styles.wrapper} onClick={onWrapperClick}>
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        {children}
      </div>
    </div>
  )
}

export default FullScreenPopup
