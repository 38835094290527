import types from './types'

export const fetchGetWithdrawLimits = () => ({ type: types.FETCH_GET_WITHDRAW_LIMITS })
export const fetchGetWithdrawLimitsSuccess = (payload) => ({
  type: types.FETCH_GET_WITHDRAW_LIMITS_SUCCESS,
  payload,
})
export const fetchGetWithdrawLimitsFailure = () => ({
  type: types.FETCH_GET_WITHDRAW_LIMITS_FAILURE,
})

export const fetchCurrencies = () => ({ type: types.FETCH_CURRENCIES_REQUEST })
export const fetchCurrenciesSuccess = (payload) => ({
  type: types.FETCH_CURRENCIES_SUCCESS,
  payload,
})
export const fetchCurrenciesFailure = () => ({ type: types.FETCH_CURRENCIES_FAILURE })

export const fetchFiatBanxaCurrencies = () => ({ type: types.FETCH_FIAT_BANXA_CURRENCIES_REQUEST })
export const fetchFiatBanxaCurrenciesSuccess = (payload) => ({
  type: types.FETCH_FIAT_BANXA_CURRENCIES_SUCCESS,
  payload,
})
export const fetchFiatBanxaCurrenciesFailure = () => ({
  type: types.FETCH_FIAT_BANXA_CURRENCIES_FAILURE,
})

export const updateCurrenciesPrice = (payload) => ({
  type: types.FETCH_CURRENCIES_PRICE_UPDATE,
  payload,
})

export const fetchPaymentBanxaMethods = (payload) => ({
  type: types.FETCH_PAYMENT_BANXA_METHODS_REQUEST,
  payload,
})
export const fetchPaymentBanxaMethodsSuccess = (payload) => ({
  type: types.FETCH_PAYMENT_BANXA_METHODS_SUCCESS,
  payload,
})
export const fetchPaymentBanxaMethodsFailure = () => ({
  type: types.FETCH_PAYMENT_BANXA_METHODS_FAILURE,
})

export const fetchBalance = (payload) => ({ type: types.FETCH_BALANCE_REQUEST, payload })

export const fetchBalanceSuccess = (payload) => ({ type: types.FETCH_BALANCE_SUCCESS, payload })

export const fetchBalanceFailure = () => ({ type: types.FETCH_BALANCE_FAILURE })

export const fetchPriceDefaultCoin = () => ({
  type: types.FETCH_PRICE_DEFAULT_COIN_REQUEST,
})

export const fetchPriceDefaultCoinSuccess = (payload) => ({
  type: types.FETCH_PRICE_DEFAULT_COIN_SUCCESS,
  payload,
})

export const fetchPriceDefaultCoinFailure = () => ({
  type: types.FETCH_PRICE_DEFAULT_COIN_FAILURE,
})

export const newAgreement = (payload, callback) => ({
  type: types.NEW_AGREEMENT,
  payload,
  callback,
})

export const getPresale = () => ({ type: types.GET_PRESALE })

export const successPresale = (payload) => ({ type: types.GET_PRESALE_SUCCESS, payload })

export const failedPresale = () => ({ type: types.GET_PRESALE_FAILURE })

export const addProcessingTransaction = (payload) => ({
  type: types.ADD_PROCESSING_TRANSACTION,
  payload,
})
export const endProcessingTransaction = (payload) => ({
  type: types.END_PROCESSING_TRANSACTION,
  payload,
})
