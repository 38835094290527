import React, { memo, useMemo } from 'react'
import T from 'prop-types'
import isEqual from 'react-fast-compare'
import { uid } from 'react-uid'
import { DefaultRecordType as IDefaultRecordType } from 'rc-table/lib/interface'

import styles from './style.module.scss'

interface IProps {
  columns: any
  data: IDefaultRecordType[]
}

function MobileTableCards({ columns, data }: IProps) {
  const mappedData = useMemo(
    () =>
      data?.map((item) =>
        columns?.map(({ dataIndex, render, title, mobileWidth, key }) => {
          let content = dataIndex ? item[dataIndex] : item

          if (render) {
            content = render(content)
          }

          return {
            title,
            width: mobileWidth,
            key: key || uid(item),
            content,
          }
        }),
      ) || [],
    [data, columns],
  )

  return (
    <div>
      {mappedData?.map((item) => (
        <div key={item.key} className={styles.card}>
          {item.map(({ title, content }) => (
            <div className={styles.card__item}>
              <div className={styles.card__item_title}>{title}</div>
              <div className={styles.card__item_content}>{content}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

MobileTableCards.propTypes = {
  columns: T.object,
  data: T.array,
}

export default memo(MobileTableCards, isEqual)
