const types = {
  FETCH_WONKA_TOKENS_REQUEST: 'FETCH_WONKA_TOKENS_REQUEST',
  FETCH_WONKA_TOKENS_SUCCESS: 'FETCH_WONKA_TOKENS_SUCCESS',
  FETCH_WONKA_TOKENS_FAILED: 'FETCH_WONKA_TOKENS_FAILED',

  REVEAL_WONKA_REQUEST: 'REVEAL_WONKA_REQUEST',
  REVEAL_WONKA_SUCCESS: 'REVEAL_WONKA_SUCCESS',
  REVEAL_WONKA_FAILED: 'REVEAL_WONKA_FAILED',

  SET_WONKA_PURCHASE_LOADING: 'SET_WONKA_PURCHASE_LOADING',
  SET_WONKA_REVEAL_ALL_LOADING: 'SET_WONKA_REVEAL_ALL_LOADING',
  SET_WONKA_REVEAL_EXPLOSION: 'SET_WONKA_REVEAL_EXPLOSION',

  REVEAL_ALL_WONKA_REQUEST: 'REVEAL_ALL_WONKA_REQUEST',
  REVEAL_ALL_WONKA_FAILED: 'REVEAL_ALL_WONKA_FAILED',

  REVEAL_ALL_WONKA_IMMEDIATELY_SUCCESS: 'REVEAL_ALL_WONKA_IMMEDIATELY_SUCCESS',

  SET_WONKA_TOKENS_POPUP: 'SET_WONKA_TOKENS_POPUP',
}

export default types
