import React from 'react'
import popupImage from '@assets/images/successPopup.svg'
import styles from './popup.module.scss'
import Button from '../ButtonNew/Button'
import { TSize } from '../types'
import Typography from '../Typography'
import { TPopupProps } from './types'

function Popup({ text, buttonText, action }: TPopupProps) {
  return (
    <div className={styles.gradientBorderWrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={popupImage} alt="popupImage" />
          <Typography.Paragraph className={styles.text}>{text}</Typography.Paragraph>
        </div>
        <Button onClick={action} fullWidth size={TSize.compact}>
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default Popup
