import { isDevOrDevEnvironment } from '@constants/index'
import { setWalletRequest } from '@core/profile/actions'
import { configSelectors } from '@core/selectors'
import { Web3WalletEvents, eventTrack } from '@utils/analyticsTracker'
import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalError,
  useWeb3ModalEvents,
  useWeb3ModalProvider,
  // eslint-disable-next-line import/no-unresolved
} from '@web3modal/ethers/react'
import { BrowserProvider, Eip1193Provider } from 'ethers'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useObserveNewWalletConnection() {
  const profile = useSelector(configSelectors.profile)
  const { isAuth } = useSelector(configSelectors.isAuthenticated)

  const { walletProvider } = useWeb3ModalProvider()
  const { address = '', isConnected } = useWeb3ModalAccount()
  const events = useWeb3ModalEvents()
  const { disconnect } = useDisconnect()
  const dispatch = useDispatch()
  const { error } = useWeb3ModalError()
  const { close } = useWeb3Modal()

  useEffect(() => {
    console.error('web3modal ERROR:', error)
    close()
  }, [error])

  useEffect(() => {
    switch (events.data.event) {
      case 'CONNECT_SUCCESS':
        eventTrack(Web3WalletEvents.addressConnected({ address }))
        break
      case 'MODAL_CLOSE':
        if (!events.data.properties.connected)
          eventTrack(Web3WalletEvents.addressDisconnected({ address: profile?.wallet_address }))
        break
      default:
        break
    }
  }, [events.timestamp])

  async function onSignMessage() {
    const provider = new BrowserProvider(walletProvider as Eip1193Provider)

    try {
      const signer = await provider.getSigner()
      const signature = await signer?.signMessage(JSON.stringify({ address })).catch(() => {
        eventTrack(Web3WalletEvents.addressDisconnected({ address }))
        disconnect()
      })

      if (signature)
        // passing disconnect function is temporary solution, will be changed to listenerMiddleware in WEB-1056
        dispatch(
          setWalletRequest({ wallet_details: { address }, signature, disconnectFn: disconnect }),
        )
    } catch (err) {
      if (isDevOrDevEnvironment) {
        console.log(err)
      }
    }

    return false
  }

  useEffect(() => {
    if (isConnected && address && profile && isAuth && address !== profile?.wallet_address) {
      onSignMessage()
    }
  }, [isConnected, address, profile?.wallet_address])
}
