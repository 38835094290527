export const environments = {
  development: 'development',
  stage: 'stage',
  uat: 'uat',
  production: 'production',
}

export const { REACT_APP_STAGE } = process.env
export const isDev = process.env.NODE_ENV === 'development'
export const isDevEnvironment = REACT_APP_STAGE === environments.development
export const isStageEnvironment = REACT_APP_STAGE === environments.stage
export const isUatEnvironment = REACT_APP_STAGE === environments.uat
export const isProductionEnvironment = REACT_APP_STAGE === environments.production
export const isDevOrDevEnvironment = isDev || isDevEnvironment
export const notIsProductionEnvironment = !isProductionEnvironment
export const showCoinPrice = window?.env?.showCoinPrice ?? false
export const enableMocks = false
