export default {
  SET_WALLET_REQUEST: 'SET_WALLET_REQUEST',

  FETCH_PROFILE_REQUEST: 'FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE: 'FETCH_PROFILE_FAILURE',

  FETCH_PROFILE_FEE_REQUEST: 'FETCH_PROFILE_FEE_REQUEST',
  FETCH_PROFILE_FEE_SUCCESS: 'FETCH_PROFILE_FEE_SUCCESS',
  FETCH_PROFILE_FEE_FAILURE: 'FETCH_PROFILE_FEE_FAILURE',

  FETCH_UPLOAD_PROFILE_PICTURE_REQUEST: 'FETCH_UPLOAD_PROFILE_PICTURE_REQUEST',
  FETCH_DELETE_PROFILE_PICTURE_REQUEST: 'FETCH_DELETE_PROFILE_PICTURE_REQUEST',

  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
  UPDATE_PROFILE_EMAIL: 'UPDATE_PROFILE_EMAIL',
  UPDATE_PROFILE_PHONE_NUMBER: 'UPDATE_PROFILE_PHONE_NUMBER',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  POST_COUNTRY: 'POST_COUNTRY',
  SET_COUNTRY: 'SET_COUNTRY',
  GET_USA_DISCLAIMER: 'GET_USA_DISCLAIMER',
  SUCCESS_USER: 'SUCCESS_USER',

  SET_PROFILE_EMOJI_PICTURE: 'SET_PROFILE_EMOJI_PICTURE',
  SET_PROFILE_WONKA_PICTURE: 'SET_PROFILE_WONKA_PICTURE',
  SET_PROFILE_EXTERNAL_NFT__PICTURE: 'SET_PROFILE_EXTERNAL_NFT__PICTURE',

  FETCH_SEND_OTP_REQUEST: 'FETCH_SEND_OTP_REQUEST',
  FETCH_SEND_OTP_SUCCESS: 'FETCH_SEND_OTP_SUCCESS',
  FETCH_SEND_OTP_FAILURE: 'FETCH_SEND_OTP_FAILURE',

  FETCH_GET_NOTIFICATIONS_REQUEST: 'FETCH_GET_NOTIFICATIONS_REQUEST',
  FETCH_GET_NOTIFICATIONS_SUCCESS: 'FETCH_GET_NOTIFICATIONS_SUCCESS',
  FETCH_GET_NOTIFICATIONS_FAILURE: 'FETCH_GET_NOTIFICATIONS_FAILURE',

  FETCH_SET_NOTIFICATIONS_REQUEST: 'FETCH_SET_NOTIFICATIONS_REQUEST',
  FETCH_SET_NOTIFICATIONS_SUCCESS: 'FETCH_SET_NOTIFICATIONS_SUCCESS',
  FETCH_SET_NOTIFICATIONS_FAILURE: 'FETCH_SET_NOTIFICATIONS_FAILURE',

  FETCH_DELETE_ACCOUNT_REQUEST: 'FETCH_DELETE_ACCOUNT_REQUEST',
  FETCH_DELETE_ACCOUNT_SUCCESS: 'FETCH_DELETE_ACCOUNT_SUCCESS',
  FETCH_DELETE_ACCOUNT_FAILURE: 'FETCH_DELETE_ACCOUNT_FAILURE',

  FETCH_UPDATE_PROFILE_EMAIL_REQUEST: 'FETCH_UPDATE_PROFILE_EMAIL_REQUEST',
  FETCH_UPDATE_PROFILE_EMAIL_SUCCESS: 'FETCH_UPDATE_PROFILE_EMAIL_REQUEST',
  FETCH_UPDATE_PROFILE_EMAIL_FAILURE: 'FETCH_UPDATE_PROFILE_EMAIL_FAILURE',

  FETCH_VERIFY_PROFILE_EMAIL_REQUEST: 'FETCH_VERIFY_PROFILE_EMAIL_REQUEST',
  FETCH_VERIFY_PROFILE_EMAIL_SUCCESS: 'FETCH_VERIFY_PROFILE_EMAIL_SUCCESS',
  FETCH_VERIFY_PROFILE_EMAIL_FAILURE: 'FETCH_VERIFY_PROFILE_EMAIL_FAILURE',

  FILL_NEXT_MISSED_FIELD: 'FILL_NEXT_MISSED_FIELD',

  FETCH_UPDATE_PROFILE_PHONE_NUMBER_REQUEST: 'FETCH_UPDATE_PROFILE_PHONE_NUMBER_REQUEST',
  FETCH_UPDATE_PROFILE_PHONE_NUMBER_SUCCESS: 'FETCH_UPDATE_PROFILE_PHONE_NUMBER_SUCCESS',
  FETCH_UPDATE_PROFILE_PHONE_NUMBER_FAILURE: 'FETCH_UPDATE_PROFILE_PHONE_NUMBER_FAILURE',

  FETCH_VERIFY_PROFILE_PHONE_NUMBER_REQUEST: 'FETCH_VERIFY_PROFILE_PHONE_NUMBER_REQUEST',
  FETCH_VERIFY_PROFILE_PHONE_NUMBER_SUCCESS: 'FETCH_VERIFY_PROFILE_PHONE_NUMBER_SUCCESS',
  FETCH_VERIFY_PROFILE_PHONE_NUMBER_FAILURE: 'FETCH_VERIFY_PROFILE_PHONE_NUMBER_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',

  SET_UPLOAD_LOADER_FLAG: 'SET_UPLOAD_LOADER_FLAG',

  FETCH_EXTERNAL_NFT_LIST: 'FETCH_EXTERNAL_NFT_LIST',
  FETCH_EXTERNAL_NFT_LIST_SUCCESS: 'FETCH_EXTERNAL_NFT_LIST_SUCCESS',
  FETCH_EXTERNAL_NFT_LIST_FAILURE: 'FETCH_EXTERNAL_NFT_LIST_FAILURE',
  RESET_EXTERNAL_NFT_LIST: 'RESET_EXTERNAL_NFT_LIST',

  SET_PROFILE_LOADING: 'SET_PROFILE_LOADING',
}
