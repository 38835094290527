import {
  CREATE_SWAP,
  FAIL_QUOTE,
  FETCH_FIAT_QUOTE,
  FETCH_QUOTE,
  FETCH_SWAP_TRANSACTION,
  GET_IP,
  SUCCESS_FIAT_QUOTE,
  SUCCESS_GET_IP,
  SUCCESS_QUOTE,
  SUCCESS_SIMPLEX_SWAP,
  SUCCESS_SWAP_TRANSACTION,
  UNSET_SIMPLEX_MODAL,
  UNSET_SUCCESS_SIMPLEX,
  UPDATE_SWAP_TRANSACTION,
  FAIL_SIMPLEX_SWAP,
  ERROR_QUOTE,
  PAYMENT_METHOD_REQUEST,
} from '../constants/buySell'

export const fetchQuote = (payload, location) => ({ type: FETCH_QUOTE, payload, location })

export const getIp = (payload) => ({ type: GET_IP, payload })

export const successGetIp = (payload) => ({ type: SUCCESS_GET_IP, payload })

export const sendSimplexSwap = (payload, callback) => ({ type: CREATE_SWAP, payload, callback })

export const successSimplexSwap = (payload) => ({ type: SUCCESS_SIMPLEX_SWAP, payload })

export const failSimplexSwap = (payload) => ({ type: FAIL_SIMPLEX_SWAP, payload })

export const unsetSuccessSimplex = () => ({ type: UNSET_SUCCESS_SIMPLEX })

export const unsetSuccessModal = () => ({ type: UNSET_SIMPLEX_MODAL })

export const fetchSwapTransaction = (payload) => ({ type: FETCH_SWAP_TRANSACTION, payload })

export const updateSwapTransaction = (payload) => ({ type: UPDATE_SWAP_TRANSACTION, payload })

export const successSwapTransaction = (payload) => ({ type: SUCCESS_SWAP_TRANSACTION, payload })

export const fetchFaitQuote = () => ({ type: FETCH_FIAT_QUOTE })

export const payemntMethodRequest = (payload, callback) => ({
  type: PAYMENT_METHOD_REQUEST,
  payload,
  callback,
})

export const successFaitQuote = (payload) => ({ type: SUCCESS_FIAT_QUOTE, payload })

export const failQuote = (payload) => ({ type: FAIL_QUOTE, payload })

export const successQuote = (payload) => ({ type: SUCCESS_QUOTE, payload })

export const errorQuote = (payload) => ({ type: ERROR_QUOTE, payload })
