/* eslint-disable no-console */

import { call, put, takeEvery } from 'redux-saga/effects'
import { useNavigate } from 'react-router'
import routes from '@routing/path'
import { buildQueryString } from '../../helpers'
import * as actions from '../actions'
import * as types from '../constants'

import { fetchUser } from './user'

import { API } from '../../api'
import { showAlert } from '../actions/alert'

const profileConfig = {
  apiVersion: 'barong',
}
const preSaleConfig = {
  apiVersion: 'peatio',
}

export function* saveProfile({ payload, navigate }) {
  yield put(actions.getUserRequested(true))

  try {
    yield call(API.post(profileConfig), '/resource/profiles', payload)
    yield call(fetchUser)
    navigate(routes.withAuth.profile.kyc.id)
    yield put(showAlert({ message: ['Profile Updated Successfully'], type: 'success' }))
    yield put(actions.getUserRequested(false))
  } catch (e) {
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    yield put(actions.failProfile(e))
    yield put(actions.getUserRequested(false))
  }
}

export function* saveProfileSaga() {
  yield takeEvery(types.SAVE_PROFILE, saveProfile)
}

export function* updateProfile(action) {
  const navigate = useNavigate()

  yield put(actions.getUserRequested(true))

  try {
    yield call(API.put(profileConfig), '/resource/profiles', action.payload)
    yield call(fetchUser)
    navigate(routes.withAuth.profile.kyc.id)
    yield put(showAlert({ message: ['Profile Updated Successfully'], type: 'success' }))
    yield put(actions.getUserRequested(false))
  } catch (e) {
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    yield put(actions.failProfile(e))
    yield put(actions.getUserRequested(false))
  }
}

export function* updateProfileSaga() {
  yield takeEvery(types.UPDATE_PROFILE, updateProfile)
}

export function* getChartdata(action) {
  yield put(actions.getUserRequested(true))
  try {
    const data = yield call(
      API.get(preSaleConfig),
      `public/graph/data?${buildQueryString(action.payload)}`,
    )
    yield put(actions.setChartData(data))
  } catch (e) {
    console.log(e)
  }
  yield put(actions.getUserRequested(false))
}

export function* getChartDataSaga() {
  yield takeEvery(types.FETCH_CHART_DATA, getChartdata)
}

export function* getGoogleData(action) {
  yield put(actions.getUserRequested(true))
  try {
    const data = yield call(
      API.get(profileConfig),
      `public/graph/data?${buildQueryString(action.payload)}`,
    )
    yield put(actions.setGoogleData(data))
  } catch (e) {
    console.log(e)
  }
  yield put(actions.getUserRequested(false))
}

export function* getGoogleDataSaga() {
  yield takeEvery(types.FETCH_GOOGLE_DATA, getGoogleData)
}

export function* getKycAid(action) {
  try {
    const res = yield call(API.get(profileConfig), '/resource/users/kycaid/form_url')
    yield put(actions.setKycAid(res))
    action.callback(res)
  } catch (e) {
    action.callback(e)
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
  }
}

export function* getKycAidSaga() {
  yield takeEvery(types.KYC_AID, getKycAid)
}

export function* saveNetVerify() {
  yield put(actions.getUserRequested(true))
  try {
    const response = yield call(API.get(profileConfig), 'resource/profiles/net_verify/initiate')
    yield put(actions.saveNetVerifyUrl(response.redirectUrl))
    yield put(actions.getUserRequested(false))
  } catch (e) {
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    yield put(actions.failProfile(e))
    yield put(actions.getUserRequested(false))
  }
}

export function* saveNetVerifySaga() {
  yield takeEvery(types.INITIATE_NET_VERIFY, saveNetVerify)
}

export function* saveTransactionToken(action) {
  yield put(actions.getUserRequested(true))
  try {
    const response = yield call(API.post(profileConfig), '/resource/profiles/net_verify/success', {
      scan_ref: action.token,
    })
    if (response) {
      yield call(API.post(profileConfig), '/resource/profiles/net_verify/kyc', {
        scan_ref: action.token,
      })
      yield put(actions.successTransactionReference(action.status))
    }
    yield call(fetchUser)
    yield put(actions.getUserRequested(false))
  } catch (e) {
    yield put(actions.getUserRequested(false))
  }
}

export function* saveTransactionReference() {
  yield takeEvery(types.SAVE_TRANSACTION_REFERENCE, saveTransactionToken)
}

export function* fetchComponentHideOrNot(action) {
  yield put(actions.getUserRequested(true))
  try {
    const response = yield call(
      API.get(preSaleConfig),
      `/account/agreement?${buildQueryString(action.payload)}`,
    )
    yield put(actions.setComponentHideOrNot(response.agreement_state))
  } catch (e) {
    console.log(e)
  }
  yield put(actions.getUserRequested(false))
}

export function* fetchComponentHideOrNotSaga() {
  yield takeEvery(types.GET_COMPONENT_HIDE_OR_NOT, fetchComponentHideOrNot)
}
