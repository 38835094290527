/* eslint-disable no-restricted-syntax */
import { END, eventChannel } from 'redux-saga'
import { all, call, cancel, fork, put, select, take, takeEvery } from 'redux-saga/effects'

import { store } from '@core/store'
import { updateCurrenciesPrice } from '@core/common/actions'

import { revealWonkaSuccess, setWonkaTokensPopupOpen } from '@core/wonkas/actions'
import { rangerUrl } from '../../api'
import { selectCurrentMarket } from '../selectors'
import {
  RANGER_CONNECT_FETCH,
  RANGER_DISCONNECT_FETCH,
  RANGER_USER_ORDER_UPDATE,
} from '../constants'

import {
  rangerDisconnectData,
  rangerUserOrderUpdate,
  subscriptionsUpdate,
  pushMyRecentTrades,
  recentTradesPush,
  depthData,
  klinePush,
  updateOpenOrders,
  updateOrderHistory,
  successTuxcCryptoSetting,
  successCurrentSwapPrice,
  successUpdatedCurrency,
} from '../actions'
import { generateSocketURI, streamsBuilder } from '../helpers'

const subscriptions_cb = {}

const initRanger = ({ withAuth }) => {
  const baseUrl = `${rangerUrl()}/${withAuth ? 'private' : 'public'}`

  const streams = streamsBuilder(withAuth)

  const ws = new WebSocket(generateSocketURI(baseUrl, streams))

  const channel = eventChannel((emitter) => {
    ws.onerror = (error) => {
      window.console.dir(error)
      emitter(END)
    }

    ws.onclose = () => {
      emitter(END)
    }

    ws.onmessage = ({ data }) => {
      let payload = {}

      try {
        payload = JSON.parse(data)
      } catch (e) {
        console.err(e)
      }

      for (const routingKey in payload) {
        if (routingKey in payload) {
          const event = payload[routingKey]
          const state = store.getState()
          const currentMarket = selectCurrentMarket(state)

          const orderBookMatch = routingKey.match(/([^.]*)\.ob-inc/)

          // public
          if (orderBookMatch) {
            if (currentMarket && orderBookMatch[1] === currentMarket.id) {
              emitter(depthData(event))
            }

            return
          }

          // public
          const klineMatch = String(routingKey).match(/([^.]*)\.kline-(.+)/)
          if (klineMatch) {
            emitter(
              klinePush({
                marketId: klineMatch[1],
                kline: event,
                period: klineMatch[2],
              }),
            )

            return
          }

          // public
          const tradesMatch = String(routingKey).match(/([^.]*)\.trades/)
          if (tradesMatch) {
            emitter(
              recentTradesPush({
                trades: event.trades,
                market: tradesMatch[1],
              }),
            )

            return
          }

          const func = subscriptions_cb[routingKey]
          if (func) {
            func(event)
          }

          switch (routingKey) {
            // public
            case 'global.crypto_setting':
              emitter(successTuxcCryptoSetting(event))
              break
            case 'global.updated_price':
              emitter(successCurrentSwapPrice(event))
              emitter(successUpdatedCurrency(event))
              emitter(updateCurrenciesPrice(event))
              break
            case 'success':
              if (event.message) {
                emitter(subscriptionsUpdate({ subscriptions: event.streams }))
                return
              }

              return

            // private
            case 'order':
              emitter(rangerUserOrderUpdate(event))
              return

            // private
            case 'wonka_tokens':
              emitter(setWonkaTokensPopupOpen({ isOpen: true, tid: event.tid }))
              break

            // private
            case 'wonka_media':
              emitter(revealWonkaSuccess(event))
              break

            // private
            case 'trade':
              emitter(pushMyRecentTrades(event))
              return

            default:
          }
        }
      }
    }

    // unsubscribe function
    return () => {
      ws?.close()
      emitter(rangerDisconnectData())
    }
  })

  return [channel, ws]
}

export const addSubscriptions = (key, cb) => {
  subscriptions_cb[key] = cb
}

export const removeSubscriptions = (key) => {
  delete subscriptions_cb[key]
}

function* reader(channel) {
  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function* watchDisconnect(socket) {
  yield take(RANGER_DISCONNECT_FETCH)
  socket?.close()
  yield put(rangerDisconnectData())
}

function* bindSocket(channel, socket) {
  return yield all([call(reader, channel), call(watchDisconnect, socket, channel)])
}

function* dispatchCurrentMarketOrderUpdates(action) {
  let market

  try {
    market = yield select(selectCurrentMarket)
  } catch (error) {
    market = undefined
  }

  if (market && action.payload.market === market.id) {
    yield put(updateOpenOrders(action.payload))
    yield put(updateOrderHistory(action.payload))
  }
}

export function* rangerSagas() {
  let activeSagas

  yield takeEvery(RANGER_USER_ORDER_UPDATE, dispatchCurrentMarketOrderUpdates)

  while (true) {
    const connectFetch = yield take(RANGER_CONNECT_FETCH)

    const [channel, socket] = yield call(initRanger, connectFetch.payload)

    if (activeSagas) {
      yield cancel(activeSagas)
    }

    activeSagas = yield fork(bindSocket, channel, socket)
  }
}
