import React, { HTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'
import { ContractType } from '@core/emojis/types'
import useCurrentWidth from '@hooks/useCurrentWidth'
import Typography from '../Typography'
import styles from './styles.module.scss'
import { CardNameSize } from './utils'

const { Paragraph } = Typography

interface ICardProps extends HTMLAttributes<HTMLDivElement> {
  type?: 'moji' | 'wonka'
  name?: string
  src: string
  price?: string | number
  currency?: string
  size?: 'big' | 'small'
  isReveal?: boolean
  isActive?: boolean
  noHover?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  actions?: ReactNode
  className?: string
  style?: React.CSSProperties
  quantity?: number
  contractType?: ContractType
  needBorder?: boolean
  isSelected?: boolean
  tokenId?: string
}

function Card({
  type = 'moji',
  name = '',
  src = 'http://swap-assests.pstuff.net/tokens/219/medium_nfe_poop_03.png',
  price,
  currency,
  quantity,
  size,
  isReveal = false,
  isActive = false,
  noHover = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  actions,
  style,
  className,
  contractType,
  needBorder,
  isSelected,
  tokenId,
}: Readonly<ICardProps>) {
  const { size: currentSize } = useCurrentWidth()
  return (
    <div
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(styles.card, className, styles[size || ''], {
        [styles.isReveal]: isReveal,
        [styles.active]: isActive,
        [styles.noHover]: noHover,
        [styles.border]: needBorder,
        [styles.selected]: isSelected,
      })}
    >
      <div className={styles.minted_label}>
        <span className={styles.tag}>VINE NETWORK</span>
        {tokenId && <span className={styles.token_id_text}>ID: {tokenId}</span>}
      </div>

      <div className={styles.card_wrap_image}>
        <img className={styles.card_image} src={src} alt={name} />
      </div>

      {actions && <div className={styles.actions}>{actions}</div>}
      <div className={cn(styles.details, { [styles.hide]: !!actions })}>
        {name && (
          <Paragraph size={CardNameSize[currentSize]} font="Gilroy" className={styles.name}>
            {name}
          </Paragraph>
        )}

        {type === 'moji' && (
          <div className={styles.price_type_details}>
            <div>
              {price && currency && (
                <Paragraph size={4} font="Gilroy" className={styles.price}>
                  {currency}
                  {price}
                </Paragraph>
              )}
              {quantity && (
                <Paragraph size={4} font="Gilroy" className={styles.price}>
                  QTY: {quantity}
                </Paragraph>
              )}
            </div>
            {contractType && (
              <Paragraph size={4} font="Gilroy" className={styles.price}>
                {contractType}
              </Paragraph>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(Card)
