import React from 'react'

import Size from '@components/UiKit/Size'

export default function TestSize() {
  const onChange = (_value) => {
    console.log(_value)
  }

  return (
    <div>
      <Size onChange={onChange} total={1120} />
    </div>
  )
}
