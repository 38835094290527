import {
  CHANGE_PASSWORD,
  SUCCESS_CHANGE_PASSWORD,
  FAIL_CHANGE_PASSWORD,
  GENERATE_CODE,
  SUCCESS_GENERATE_CODE,
  FAIL_GENERATE_CODE,
  VERIFY_QR_CODE,
  SUCCESS_VERIFY_QR_CODE,
  FAIL_VERIFY_QR_CODE,
  DISABLE_GOOGLE_AUTH,
  SUCCESS_DISABLE_GOOGLE_AUTH,
  FAIL_DISABLE_GOOGLE_AUTH,
  FETCH_LAST_LOGIN,
  SUCCESS_LAST_LOGIN,
  FETCH_VI_ACCOUNT_CONFIRMATION,
  SUCCESS_VI_ACCOUNT_CONFIRMATION,
  FAIL_VI_ACCOUNT_CONFIRMATION,
  ADD_VI_USER_ID,
  SUCCESS_ADD_VI_USER_ID,
  FAIL_ADD_VI_USER_ID,
  VERIFY_INVESTOR_REQUEST,
  SUCCESS_VERIFY_INVESTOR_REQUEST,
  FAIL_VERIFY_INVESTOR_REQUEST,
  UPDATE_VERIFICATION_STATUS,
  SUCCESS_UPDATE_VERIFICATION_STATUS,
  FAIL_UPDATE_VERIFICATION_STATUS,
  FETCH_ADVERTISEMENTS,
  SUCCESS_FETCH_ADVERTISEMENTS,
  FAIL_FETCH_ADVERTISEMENTS,
} from '../constants'

const initState = {
  error: null,
  success: null,
  statusCode: null,
  qrCodeData: null,
  verifyAction: null,
  lastLogin: null,
  accountStatus: null,
  verifyInvestorRequestResponse: null,
  advertisements: null,
}

// eslint-disable-next-line consistent-return, default-param-last
export const settingsReducer = (state = initState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD: {
      return { ...state }
    }
    case SUCCESS_CHANGE_PASSWORD: {
      return {
        ...state,
        error: null,
        success: action.payload.success,
        statusCode: action.payload.statusCode,
      }
    }
    case FAIL_CHANGE_PASSWORD: {
      return {
        ...state,
        error: action.payload.error,
        success: null,
        statusCode: action.payload.statusCode,
      }
    }
    case GENERATE_CODE: {
      return { ...state }
    }
    case SUCCESS_GENERATE_CODE: {
      return {
        ...state,
        error: null,
        success: action.payload.success,
        qrCodeData: action.payload.qrCodeData,
        verifyAction: action.payload.verifyAction,
      }
    }
    case FAIL_GENERATE_CODE: {
      return {
        ...state,
        error: action.payload.error,
        success: null,
        statusCode: action.payload.statusCode,
        verifyAction: action.payload.verifyAction,
      }
    }
    case VERIFY_QR_CODE: {
      return { ...state }
    }
    case SUCCESS_VERIFY_QR_CODE: {
      return {
        ...state,
        error: null,
        success: action.payload.success,
        verifyAction: action.payload.verifyAction,
      }
    }
    case FAIL_VERIFY_QR_CODE: {
      return {
        ...state,
        error: action.payload.error,
        success: null,
        statusCode: action.payload.statusCode,
      }
    }
    case DISABLE_GOOGLE_AUTH: {
      return { ...state }
    }
    case SUCCESS_DISABLE_GOOGLE_AUTH: {
      return {
        ...state,
        error: null,
        success: action.payload.success,
        verifyAction: action.payload.verifyAction,
      }
    }
    case FAIL_DISABLE_GOOGLE_AUTH: {
      return {
        ...state,
        error: action.payload.error,
        success: null,
        statusCode: action.payload.statusCode,
      }
    }
    case FETCH_LAST_LOGIN: {
      return { ...state }
    }

    case SUCCESS_LAST_LOGIN: {
      return { ...state, lastLogin: action.payload }
    }
    case FETCH_ADVERTISEMENTS: {
      return { ...state }
    }

    case SUCCESS_FETCH_ADVERTISEMENTS: {
      return { ...state, advertisements: action.payload }
    }
    case FAIL_FETCH_ADVERTISEMENTS: {
      return { ...state, error: action.payload, loading: false }
    }
    case FETCH_VI_ACCOUNT_CONFIRMATION: {
      return { ...state }
    }
    case SUCCESS_VI_ACCOUNT_CONFIRMATION: {
      if (!action.payload) {
        return {
          ...state,
          accountStatus: {
            verificationStep: 'create_account',
            verification_desc: 'Verify Your Accredited Status.',
          },
        }
      }
      switch (action.payload.status) {
        case 'verification_pending':
        case 'accepted_by_investor':
        case 'in_processing':
        case 'declined_expire':
        case 'self_not_accredited':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'verify_account',
              status: action.payload.status,
              verification_desc: 'Verify Your Accredited Status.',
            },
          }
        case 'accredited_expired':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'verify_account',
              status: action.payload.status,
              verification_desc: 'Your Verification is expired. Please renew your verification.',
            },
          }
        case 'not_accredited':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'verify_account',
              status: action.payload.status,
              verification_desc: 'Your Accredited verification is Rejected.',
            },
          }
        case 'declined_by_investor':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'verify_account',
              verification_desc: 'You declined the verification request.',
            },
          }
        case 'accepted_expire':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'verify_account',
              verification_desc: 'Your Accredited verification request is Expired.',
            },
          }
        case 'waiting_for_information_from_investor':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'waiting_for_information',
              status: 'Waiting for more information',
              verification_desc:
                'You need to provide more information' +
                ' to complete your Accredited verification.',
            },
          }
        case 'accredited':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'disabled',
              status: 'Accredited',
              verification_desc: 'Verified Accredited Investor.',
            },
          }
        case 'waiting_for_review':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'disabled',
              status: 'Waiting for review',
              verification_desc: 'Your Accredited verification is under Review',
            },
          }
        case 'in_review':
          return {
            ...state,
            accountStatus: {
              verificationStep: 'disabled',
              status: 'In review',
              verification_desc: 'Your Accredited verification is under Review',
            },
          }
        case 'waiting_for_investor_acceptance':
          if (action.payload.status === 'waiting_for_investor_acceptance') {
            return {
              ...state,
              accountStatus: {
                verificationStep: 'verify_account',
                status: 'Waiting for your acceptance',
                verification_desc: 'Verify Your Accredited Status.',
              },
            }
          }
          break
        default:
          return state
      }

      break
    }
    case FAIL_VI_ACCOUNT_CONFIRMATION: {
      return {
        ...state,
        error: action.payload.error,
        success: null,
        statusCode: action.payload.statusCode,
      }
    }
    case ADD_VI_USER_ID: {
      return { ...state }
    }
    case SUCCESS_ADD_VI_USER_ID: {
      return { ...state }
    }
    case FAIL_ADD_VI_USER_ID: {
      return { ...state, error: action.payload }
    }
    case VERIFY_INVESTOR_REQUEST: {
      return { ...state }
    }
    case SUCCESS_VERIFY_INVESTOR_REQUEST: {
      return { ...state, verifyInvestorRequestResponse: action.payload }
    }
    case FAIL_VERIFY_INVESTOR_REQUEST: {
      return { ...state, error: action.payload }
    }
    case UPDATE_VERIFICATION_STATUS: {
      return { ...state }
    }
    case SUCCESS_UPDATE_VERIFICATION_STATUS: {
      return { ...state }
    }
    case FAIL_UPDATE_VERIFICATION_STATUS: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}
