import React, { useState } from 'react'
import IconButton from '@components/UiKit/ButtonNew/IconButton'
import { TSize } from '@components/UiKit/types'

function TestIconButtons() {
  const [value, setValue] = useState(null)

  const buttonHandler = (data) => {
    setValue(data)
  }

  return (
    <div>
      <div>
        <IconButton icon="Atom" size={TSize.spacious} variant="primary" />
      </div>
      <br />
      <div>
        <IconButton icon="Atom" size={TSize.spacious} variant="secondary" />
      </div>
      <br />
      <div>
        <IconButton icon="Atom" size={TSize.spacious} variant="tertiary" />
      </div>
      <br />
      <div>
        <IconButton icon="Atom" size={TSize.spacious} variant="danger" />
      </div>
      <br />
      <div>
        <IconButton icon="Atom" size={TSize.spacious} variant="blue" />
      </div>
      <br />
      <div>
        <IconButton icon="Atom" size={TSize.spacious} variant="warning" />
      </div>
      <br />
      <div>
        <IconButton icon="Atom" disabled size={TSize.spacious} variant="warning" />
      </div>
      <br />
      <div>
        <IconButton icon="Atom" size={TSize.compact} variant="warning" />
      </div>
      <br />
      <div>
        <IconButton icon="Atom" size={TSize.small} variant="danger" />
      </div>
      <br />
      <div>
        <IconButton
          icon="Atom"
          size={TSize.spacious}
          variant="primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            buttonHandler(event)
          }}
        />
      </div>
    </div>
  )
}

export default TestIconButtons
