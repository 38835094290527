import React, { useState } from 'react'
import ProfileDropdown from '@components/UiKit/ProfileDropdown'

export default function TestProfileDropdown() {
  const [isProfileDropdown, setIsProfileDropdown] = useState<boolean>(true)
  return (
    <div>
      <ProfileDropdown setIsProfileDropdown={setIsProfileDropdown} userName="Alex Kruger" />
    </div>
  )
}
