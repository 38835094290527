import moment from 'moment'

import types from '../types'

const calculateDate = (start_date) => {
  const date1 = new Date(start_date)
  const utc = new Date()
  const diffTime = Math.abs(utc - date1)
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))
  const lastDate = moment(date1).add(diffDays + 1, 'days')
  return { date: lastDate, time: lastDate.utc().format('hh:mm a') }
}

const initialState = {
  price: {
    loading: false,
    data: null,
  },
  currency: {
    loading: false,
    data: null,
  },
  fiatBanxaCurrency: {
    loading: false,
    data: null,
  },
  paymentBanxaMethods: {
    loading: false,
    data: null,
  },
  balance: {
    loading: false,
    data: null,
  },
  defaultCoin: {
    loading: false,
    data: null,
  },
  withdrawLimit: {
    loading: false,
    data: null,
  },
  processingTransactionsAddresses: {},
}

const commonReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.FETCH_GET_WITHDRAW_LIMITS:
      return {
        ...state,
        withdrawLimit: {
          ...state.withdrawLimit,
          loading: true,
        },
      }
    case types.FETCH_CURRENCIES_REQUEST:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: true,
        },
      }
    case types.FETCH_BALANCE_REQUEST:
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: true,
        },
      }
    case types.FETCH_PRICE_DEFAULT_COIN_REQUEST:
      return {
        ...state,
        defaultCoin: {
          ...state.defaultCoin,
          loading: true,
        },
      }
    case types.FETCH_FIAT_BANXA_CURRENCIES_REQUEST:
      return {
        ...state,
        fiatBanxaCurrency: {
          ...state.fiatBanxaCurrency,
          loading: true,
        },
      }
    case types.FETCH_PAYMENT_BANXA_METHODS_REQUEST:
      return {
        ...state,
        paymentBanxaMethods: {
          ...state.paymentBanxaMethods,
          loading: true,
        },
      }

    case types.FETCH_GET_WITHDRAW_LIMITS_SUCCESS:
      return {
        ...state,
        withdrawLimit: {
          ...state.withdrawLimit,
          loading: false,
          data: payload,
        },
      }
    case types.FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: false,
          data: payload,
        },
      }
    case types.FETCH_BALANCE_SUCCESS:
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: false,
          data: payload,
        },
      }

    case types.FETCH_PRICE_DEFAULT_COIN_SUCCESS:
      return {
        ...state,
        defaultCoin: {
          ...state.defaultCoin,
          loading: false,
          data: payload,
        },
      }
    case types.FETCH_FIAT_BANXA_CURRENCIES_SUCCESS:
      return {
        ...state,
        fiatBanxaCurrency: {
          ...state.fiatBanxaCurrency,
          loading: false,
          data: payload,
        },
      }
    case types.FETCH_PAYMENT_BANXA_METHODS_SUCCESS:
      return {
        ...state,
        paymentBanxaMethods: {
          ...state.paymentBanxaMethods,
          loading: false,
          data: payload,
        },
      }
    case types.FETCH_CURRENCIES_PRICE_UPDATE:
      return {
        ...state,
        price: {
          ...state.price,
          loading: false,
          data: payload,
        },
      }

    case types.FETCH_GET_WITHDRAW_LIMITS_FAILURE:
      return {
        ...state,
        withdrawLimit: {
          ...state.withdrawLimit,
          loading: false,
        },
      }
    case types.FETCH_CURRENCIES_FAILURE:
      return {
        ...state,
        currency: {
          ...state.currency,
          loading: false,
        },
      }
    case types.FETCH_BALANCE_FAILURE:
      return {
        ...state,
        balance: {
          ...state.balance,
          loading: false,
        },
      }
    case types.FETCH_PRICE_DEFAULT_COIN_FAILURE:
      return {
        ...state,
        defaultCoin: {
          ...state.defaultCoin,
          loading: false,
        },
      }
    case types.FETCH_FIAT_BANXA_CURRENCIES_FAILURE:
      return {
        ...state,
        fiatBanxaCurrency: {
          ...state.fiatBanxaCurrency,
          loading: false,
        },
      }
    case types.FETCH_PAYMENT_BANXA_METHODS_FAILURE:
      return {
        ...state,
        paymentBanxaMethods: {
          ...state.paymentBanxaMethods,
          loading: false,
        },
      }

    case types.GET_PRESALE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          preSale: {
            activePresale: payload[0],
            date_time: payload[0] ? calculateDate(payload[0].start_date) : {},
          },
        },
      }
    }

    case types.GET_PRESALE_FAILURE: {
      return {
        ...state,
        data: {
          ...state.data,
          preSale: {
            error: true,
          },
        },
      }
    }

    case types.ADD_PROCESSING_TRANSACTION:
      return {
        ...state,
        processingTransactionsAddresses: {
          ...state.processingTransactionsAddresses,
          [payload.address]: payload,
        },
      }

    case types.END_PROCESSING_TRANSACTION:
      const { [payload.address]: transactionToRemove, ...pendingTransactions } =
        state.processingTransactionsAddresses
      return {
        ...state,
        processingTransactionsAddresses: pendingTransactions,
      }

    default:
      return state
  }
}

export default commonReducer
