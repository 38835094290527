import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'

import { Web3Provider } from '@context/web3Provider'
import { getSentryKey } from '@constants'
import rootSaga from '@core/rootSagaWatcher'
import { rangerSagas } from '@core/icx/redux/sagas/ranger'
import mintBg from '@assets/images/mint_horizontal.png'
import landingBg from '@assets/images/landing/citybg.webp'
import flame from '@assets/images/landing/largeflame.png'
import Logo from '@assets/images/logo/checkout_logo.svg'
import CookiesDisclaimer from '@components/CookiesDisclaimer'
import { enableMocks, isDev } from '@constants/environments'
import { persistor, rangerMiddleware, sagaMiddleware, store } from '@core/store'
import AuthApiGate from '@routing/AuthApiGate'
import { LocalizationProvider } from '@context/LocalizationProvider'
import { OtpTimerProvider } from '@context/otpTimer'
import { CaptchaProvider } from '@context/CaptchaProvider'

import App from './App'

import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'

import './index.scss'

sagaMiddleware.run(rootSaga)
rangerMiddleware.run(rangerSagas)

if (isDev && enableMocks) {
  require('./mocks')
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const dsn = getSentryKey()

Sentry.init({
  dsn,
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3Provider>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AuthApiGate>
              <LocalizationProvider>
                <OtpTimerProvider>
                  <CaptchaProvider>
                    <App />

                    {/* content to preload */}
                    <div style={{ display: 'none' }}>
                      <img src={mintBg} alt="" />
                      <img src={landingBg} alt="" />
                      <img src={flame} alt="" />
                      <img src={Logo} alt="" />
                    </div>
                  </CaptchaProvider>
                </OtpTimerProvider>
              </LocalizationProvider>
            </AuthApiGate>
          </BrowserRouter>
        </PersistGate>
        <ToastContainer />
        <CookiesDisclaimer />
      </Web3Provider>
    </Provider>
  </React.StrictMode>,
)
