import React from 'react'
import Input from '@components/UiKit/Form/Input'
import IconButton from '@components/UiKit/ButtonNew/IconButton'
import Button from '@components/UiKit/ButtonNew/Button'
import { PROFILE_POPUPS } from '@constants/profile'
import { TSize } from '@components/UiKit/types'
import Typography from '@components/UiKit/Typography'
import styles from './withdraw.module.scss'

const { Heading, Paragraph } = Typography

const balanceData = [
  {
    id: 1,
    label: 'Available Balance',
    price: '0.00 PEER',
  },
  {
    id: 2,
    label: 'Locked Balance Info',
    price: '0.00 PEER',
  },
  {
    id: 3,
    label: 'Locked Balance Info',
    price: '0.00 PEER',
  },
]

const descriptionData = [
  {
    id: 1,
    label: 'Min withdraw value 1.0 PEER',
  },
  {
    id: 2,
    label: 'Withdrawals are processed immediately.',
  },
  {
    id: 3,
    label:
      'For Security purposes, withdrawals are not permitted for 24 hours after the password reset.',
  },
]

interface IWithdrawProps {
  label?: string
  profileTypeHandler: (value: string) => void
  setIsProfileDropdown: (value: boolean) => void
}

function Withdraw({
  profileTypeHandler,
  label = 'Withdraw',
  setIsProfileDropdown,
}: IWithdrawProps) {
  return (
    <>
      <div className={styles.header}>
        <IconButton
          icon="CustomIconArrowLeft"
          size={TSize.compact}
          variant="secondary"
          className={styles.iconBg}
          onClick={() => profileTypeHandler(PROFILE_POPUPS.main_profile)}
        />
        <Heading size={2}>{label}</Heading>
      </div>
      <Input
        label="I want to withdraw"
        placeholder="Amount"
        value=""
        fullWidth
        className={styles.input}
      />
      <ul className={styles.balance}>
        {balanceData.map(({ id, label, price }) => (
          <li key={id} className={styles.balance__item}>
            <Paragraph size={2}>{label}</Paragraph>
            <span className={styles.balance__price}>{price}</span>
          </li>
        ))}
      </ul>
      <Button variant="primary">Enable Google Authentication</Button>
      <ul className={styles.description}>
        {descriptionData.map(({ id, label }) => (
          <li key={id} className={styles.description__list}>
            {label}
          </li>
        ))}
      </ul>
    </>
  )
}

export default Withdraw
