import React from 'react'
import ActionButton from '@components/UiKit/ButtonNew/ActionButton'

function TestActionButton() {
  return (
    <div>
      <div>
        <ActionButton variant="atom">refresh</ActionButton>
      </div>
      <br />
      <div>
        <ActionButton variant="swap">refresh</ActionButton>
      </div>
    </div>
  )
}

export default TestActionButton
