import React from 'react'
import Modal, { IModalType } from '@components/UiKit/Modal'
import Typography from '@components/UiKit/Typography'
import Button from '@components/UiKit/ButtonNew/Button'
import styles from './modal.module.scss'

const { Heading, Paragraph } = Typography

function UpdateCover({ open, onClose }: IModalType) {
  return (
    <Modal open={open} onClose={onClose} className={styles.updateCoverWrapper}>
      <Heading size={3} className={styles.title}>
        Update cover
      </Heading>

      <Paragraph font="Gilroy" size={2} className={styles.modal_paragraph}>
        Upload new cover. We recommend to upload images in 1860x332 resolution. Max 15MB in JPEG or
        PNG format.
      </Paragraph>

      <div className={styles.footer}>
        <Button type="submit" variant="primary" onClick={onClose}>
          Upload Image
        </Button>

        <Button
          type="submit"
          variant="secondary"
          className={styles.cancel_button}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default React.memo(UpdateCover)
