import React, { ReactNode, useEffect, useState } from 'react'
import cn from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'
import Typography from '../Typography'
import styles from './styles.module.scss'

const { Label } = Typography

export interface Tab {
  key: string
  children: ReactNode
  to?: string
  href?: string
}

interface TabsProps {
  items: Tab[]
  onChange?: (t: Tab['key']) => void
  defaultActiveKey?: string
  wrapperClassName?: string
  itemClassName?: string
}

function Tabs({
  items,
  onChange = (t) => t,
  defaultActiveKey = 'all',
  wrapperClassName,
  itemClassName,
}: TabsProps) {
  const location = useLocation()
  const [activeItem, setActiveItem] = useState<Tab['key']>(defaultActiveKey)

  const onSelectItem = (item: Tab): void => {
    setActiveItem(item.key)
  }

  useEffect(() => {
    onChange(activeItem)
  }, [activeItem])

  return (
    <div className={cn(styles.tabs, wrapperClassName)}>
      {items.map((item: Tab) =>
        item.href ? (
          <a
            href={item.href}
            className={cn(styles.tabs_item, itemClassName, {
              [styles.active]: location.pathname === item.href,
            })}
            key={item.key}
            onClick={() => onSelectItem(item)}
          >
            <Label size={4} className={styles.tabs_item__content}>
              {item.children}
            </Label>
          </a>
        ) : item.to ? (
          <NavLink
            className={cn(styles.tabs_item, itemClassName, {
              [styles.active]: location.pathname === item.to,
            })}
            key={item.key}
            to={item.to}
            onClick={() => onSelectItem(item)}
          >
            <Label size={4} className={styles.tabs_item__content}>
              {item.children}
            </Label>
          </NavLink>
        ) : (
          <div
            className={cn(styles.tabs_item, itemClassName, {
              [styles.active]: activeItem === item.key,
            })}
            key={item.key}
            onClick={() => onSelectItem(item)}
          >
            <Label size={4} className={styles.tabs_item__content}>
              {item.children}
            </Label>
          </div>
        ),
      )}
    </div>
  )
}

export default React.memo<TabsProps>(Tabs)
