/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import {
  GET_MARKET,
  SUCCESS_GET_MARKET,
  FAIL_GET_MARKET,
  MARKET_GET_TICKER,
  MARKET_GET_TICKER_SUCCESS,
  MARKET_GET_TICKER_FAILURE,
  MARKET_SET_CURRENT_MARKET,
  MARKETS_SET_CURRENT_MARKET_IFUNSET,
  SEARCH_MARKET,
  SET_UNSET_FAVOURITE,
  CHECK_MARKET_LIMIT,
  SUCCESS_CHECK_MARKET_LIMIT,
  FAIL_CHECK_MARKET_LIMIT,
} from '../constants'

const initState = {
  list: [],
  currentMarket: undefined,
  tickers: {},
  tickerLoading: false,
  loading: false,
  actualList: [],
  favMarkets: [],
  actualFavMarkets: [],
  marketList: [],
  marketLimit: {
    loading: false,
    success: false,
    error: false,
    exceeded: null,
  },
}

// eslint-disable-next-line default-param-last
export const marketsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_MARKET: {
      return { ...state, loading: true }
    }

    case SUCCESS_GET_MARKET: {
      const favMarkets = []
      const favMarketsId = JSON.parse(localStorage.getItem('favMarketsId'))
      action.payload.forEach((market, index) => {
        if (favMarketsId != null && favMarketsId.length > 0 && favMarketsId.includes(market.id)) {
          action.payload[index].isFav = 1
          favMarkets.push(action.payload[index])
        } else {
          action.payload[index].isFav = 0
        }
      })
      return {
        ...state,
        list: action.payload,
        marketList: action.payload,
        loading: false,
        favMarkets,
      }
    }

    case FAIL_GET_MARKET: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    }
    case SEARCH_MARKET: {
      if (state.actualList.length === 0 && state.list.length > 0) {
        state.actualList = state.list
      } else if (state.list.length === 0 && state.actualList.length > 0) {
        state.list = state.actualList
      }

      if (state.actualFavMarkets.length === 0 && state.favMarkets.length > 0) {
        state.actualFavMarkets = state.favMarkets
      } else if (state.favMarkets.length === 0 && state.actualFavMarkets.length > 0) {
        state.favMarkets = state.actualFavMarkets
      }
      if (action.payload !== '') {
        var data = state.actualList.filter((obj) =>
          Object.keys(obj).some(
            // eslint-disable-next-line no-unused-vars
            (key) =>
              typeof obj.base_unit == 'string' &&
              obj.base_unit.includes(action.payload.toLowerCase()),
          ),
        )

        var favData = state.actualFavMarkets.filter((obj) =>
          Object.keys(obj).some(
            () =>
              typeof obj.base_unit == 'string' &&
              obj.base_unit.includes(action.payload.toLowerCase()),
          ),
        )
      } else {
        data = state.actualList
        favData = state.actualFavMarkets
      }

      return {
        ...state,
        list: data,
        favMarkets: favData,
      }
    }

    case MARKET_GET_TICKER: {
      return { ...state, tickerLoading: true }
    }

    case MARKET_GET_TICKER_SUCCESS: {
      return {
        ...state,
        tickerLoading: false,
        tickers: action.payload,
      }
    }

    case MARKET_GET_TICKER_FAILURE: {
      return { ...state, tickerLoading: false, error: true }
    }

    case MARKET_SET_CURRENT_MARKET: {
      return { ...state, currentMarket: action.payload }
    }

    case MARKETS_SET_CURRENT_MARKET_IFUNSET:
      if (state.currentMarket) {
        return state
      }
      return {
        ...state,
        currentMarket: action.payload,
      }

    case SET_UNSET_FAVOURITE:
      var setList = Object.assign([], state.list)
      var favMarketList = Object.assign([], state.favMarkets)
      setList.forEach((market, index) => {
        if (market.id === action.payload.id && action.payload.type === 'set') {
          setList[index].isFav = 1
          favMarketList.push(setList[index])
        }
        if (market.id === action.payload.id && action.payload.type === 'unset') {
          setList[index].isFav = 0
          const filterFavMarketList = favMarketList.filter(
            (favmarket) => favmarket.id !== action.payload.id,
          )
          favMarketList = filterFavMarketList
        }
      })
      return {
        ...state,
        list: setList,
        loading: false,
        favMarkets: favMarketList,
      }

    case CHECK_MARKET_LIMIT: {
      return {
        ...state,
        marketLimit: {
          loading: true,
          success: false,
          error: false,
          exceeded: null,
        },
      }
    }

    case SUCCESS_CHECK_MARKET_LIMIT: {
      return {
        ...state,
        marketLimit: {
          loading: false,
          success: true,
          error: false,
          exceeded: action.payload,
        },
      }
    }

    case FAIL_CHECK_MARKET_LIMIT: {
      return {
        ...state,
        marketLimit: {
          loading: false,
          success: false,
          error: true,
          exceeded: null,
        },
      }
    }

    default:
      return state
  }
}
