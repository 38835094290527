import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
  SET_SELECTED_CURRENCY,
  SET_SEARCHED_CURRENCY,
} from '../constants'
import { UPDATED_CURRENCIES_SUCCESS } from '../constants/actions'

const initState = {
  list: [],
  updatedPrices: {},
  loading: true,
  error: false,
  selectedCurrency: '',
  searchedCurrency: '',
}

// eslint-disable-next-line default-param-last
export const currenciesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CURRENCIES: {
      return { ...state, loading: true }
    }
    case GET_CURRENCIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: action.payload instanceof Array ? action.payload : [action.payload],
      }
    }
    case UPDATED_CURRENCIES_SUCCESS: {
      return {
        ...state,
        updatedPrices: action.payload,
      }
    }
    case GET_CURRENCIES_FAILURE: {
      return { ...state, loading: false, error: true }
    }

    case SET_SELECTED_CURRENCY: {
      return { ...state, selectedCurrency: action.payload }
    }

    case SET_SEARCHED_CURRENCY: {
      return { ...state, searchedCurrency: action.payload }
    }
    default:
      return state
  }
}
