import classNames, { ArgumentArray } from 'classnames'

export const fixedArgClassNames =
  (fixedArg: ArgumentArray[0]) =>
  (...restArgs: ArgumentArray) =>
    classNames(fixedArg, restArgs)

export const camelCaseToUpperCaseConverter = (input: string) =>
  input.replace(/[A-Z]/g, (match) => ` ${match}`).toUpperCase()

export const cssVar = (key: string, fallback?: string) =>
  getComputedStyle(document.querySelector(':root') as Element).getPropertyValue(key) || fallback
