import React from 'react'
import routes from '@routing/path'
import Tabs from '@components/UiKit/Tabs'

function TestTabs() {
  return (
    <div>
      <Tabs
        onChange={(t) => console.log(t)}
        items={[
          { key: 'profile', children: 'Profile' },
          { key: 'account', children: 'Account', to: routes.test },
          { key: 'wallets', children: 'Wallets', href: '/' },
        ]}
      />
    </div>
  )
}

export default TestTabs
