import { StylesConfig } from 'react-select'
import { cssVar } from '@utils/utils'
import { TSize } from '@components/UiKit/types'
import { ThorizontalMenuPlacement } from './types'

export const getCustomStyles: (
  size: TSize,
  horizontalMenuPlacement?: ThorizontalMenuPlacement,
) => StylesConfig = (size, horizontalMenuPlacement) =>
  ({
    container: (provided) => ({
      ...provided,
      width: '100%',
      zIndex: 10,
    }),
    menu: (provided) => ({
      ...provided,
      color: cssVar('--primary-white'),
      backgroundColor: cssVar('--neutrals-gray-600'),
      borderRadius: cssVar('--spacing-4'),
      padding: cssVar('--spacing-3'),
      cursor: 'pointer',
      width: 'max-content',
      left: !horizontalMenuPlacement || horizontalMenuPlacement === 'left' ? 0 : 'unset',
      right: horizontalMenuPlacement === 'right' ? 0 : 'unset',
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: cssVar('--spacing-2'),
      backgroundColor: state.isFocused
        ? cssVar('--neutrals-gray-500')
        : cssVar('--neutrals-gray-600'),
      padding:
        size === 'spacious'
          ? `${cssVar('--spacing-3')} ${cssVar('--spacing-2')}`
          : `10px ${cssVar('--spacing-2')} 10px ${cssVar('--spacing-2')}`,

      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: cssVar('--spacing-3', '12px'),
      backgroundColor: state.hasValue
        ? cssVar('--neutrals-gray-500')
        : cssVar('--neutrals-gray-700'),
      border: 'none',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
      padding: size === 'spacious' ? `18px 16px 18px 24px` : `12px 16px 12px 24px`,
      cursor: 'pointer',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: cssVar('--primary-white'),
    }),
    multiValue: (provided) => ({
      ...provided,
      color: cssVar('--primary-white'),
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: 0,
      backgroundColor: cssVar('--neutrals-gray-700'),
      fontFamily: 'IBM Plex Mono, monospace',
      fontSize: '14px',
      textTransform: 'uppercase',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '20px',
    }),
  } as StylesConfig)
