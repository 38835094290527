import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'
import { environments } from '@constants/environments'
import { useDispatch } from 'react-redux'
import { setCaptcha, setResetCaptchaCb } from '@core/auth/actions'
import { useLocation, useSearchParams } from 'react-router-dom'

interface ICaptchaContext {
  captchaKey: string
  onSuccess: (value: string) => void
  ref: React.RefObject<TurnstileInstance | undefined>
  onExpire: () => void
  onError: () => void
  captchaValue: string
  reset: () => void
}

const initialValue = {
  captchaKey: '',
  onSuccess: () => {},
  ref: React.createRef<TurnstileInstance | undefined>(),
  onExpire: () => {},
  onError: () => {},
  reset: () => {},
  captchaValue: '',
}

const CaptchaContext = createContext<ICaptchaContext>(initialValue)

export function CaptchaProvider({ children }) {
  const [captchaValue, setCaptchaValue] = useState<string>('')
  const ref = useRef<TurnstileInstance | undefined>(null)
  const dispatch = useDispatch()
  const [search] = useSearchParams()
  const path = search?.get('page')
  const location = useLocation()

  const reset = () => {
    ref?.current?.reset()
    setCaptchaValue('')
  }
  const onExpire = reset
  const onError = reset

  const onSuccess = (value: string) => {
    setCaptchaValue(value)
    dispatch(setCaptcha(value))
  }

  useEffect(() => {
    // NOTE: to get access to reset captcha from redux saga
    dispatch(setResetCaptchaCb(reset))
  }, [])

  useEffect(reset, [path, location.pathname])

  let captchaKey: string

  switch (process.env?.REACT_APP_STAGE) {
    case environments.production:
      captchaKey = window.env?.captcha?.recaptchaKeyProd
      break
    case environments.uat:
      captchaKey = window.env?.captcha?.recaptchaKeyUat
      break
    case environments.development:
      captchaKey = window.env?.captcha?.recaptchaKeyDev
      break
    default:
      captchaKey = window.env?.captcha?.recaptchaKeyDev
  }

  const value = useMemo(
    () => ({
      captchaKey,
      onSuccess,
      ref,
      onExpire,
      onError,
      captchaValue,
      reset,
    }),
    [captchaKey, ref, onExpire, onError, captchaValue, reset, onSuccess],
  )

  return (
    <CaptchaContext.Provider value={value}>
      {children}
      <Turnstile
        siteKey={captchaKey}
        onSuccess={onSuccess}
        ref={ref}
        onExpire={onExpire}
        onError={onError}
        options={{
          size: 'invisible',
        }}
      />
    </CaptchaContext.Provider>
  )
}

export const useCaptcha = () => useContext(CaptchaContext)
