import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { getUserRequest, getUserRequested } from '../actions'
import * as actions from '../actions/buySell'
import * as types from '../constants'
import { showAlert } from '../actions/alert'
import { API } from '../../api'
import { buildQueryString } from '../../helpers'

const peatioConfig = {
  apiVersion: 'peatio',
  withHeaders: true,
}
const barongConfig = {
  apiVersion: 'barong',
}

export function* fetchQuote(action) {
  if (action.location && action.location.state) {
    yield put(getUserRequest(true))
  }
  try {
    const url = `/account/banxa/get-price?${buildQueryString(action.payload)}`
    const quote = yield call(API.get(peatioConfig), url)
    yield put(actions.successQuote(quote.data))
    yield put(getUserRequested(false))
  } catch (e) {
    const str = e.message[0]
    const str1 = str.slice(str.indexOf('('))
    yield put(actions.errorQuote(str.replace(str1, '')))
    yield put(actions.failQuote(e))
    yield put(getUserRequested(false))
  }
  yield put(getUserRequest(false))
}

export function* fetchUserIp(action) {
  yield put(getUserRequested(true))

  try {
    const url = `/resource/users/user_activity?${buildQueryString(action.payload)}`
    const quote = yield call(API.get(barongConfig), url)
    yield put(actions.successGetIp(quote?.user_ip))
    yield put(getUserRequested(false))
  } catch (e) {
    if (e.message[0] !== 'account.withdraw.not_permitted') {
      yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    }

    yield put(actions.failQuote(e))
    yield put(getUserRequested(false))
  }
}

export function* sendSwap(action) {
  yield put(getUserRequested(true))
  try {
    const url = '/account/banxa/create-order'
    const adjustment = yield call(API.post(peatioConfig), url, action.payload)
    yield put(actions.successSimplexSwap({ data: adjustment.data }))
  } catch (e) {
    yield put(
      actions.failSimplexSwap({
        message: e.message,
        code: e.code,
        type: 'error',
      }),
    )
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
  }
  yield put(getUserRequested(false))
  action.callback(true)
}

export function* fetchSimplex(action) {
  yield put(getUserRequested(true))

  try {
    const { data, headers } = yield call(
      API.get(peatioConfig),
      `/account/swaps?${buildQueryString(action.payload)}`,
    )
    yield put(actions.successSwapTransaction({ data, total: headers.total }))
    yield put(getUserRequested(false))
  } catch (e) {
    yield put(showAlert({ message: [e.message], code: e.code, type: 'error' }))
    yield put(getUserRequested(false))
  }
}

export function* updateSwap(action) {
  yield put(getUserRequested(true))

  try {
    const url = '/account/swaps'
    const data = yield call(API.put(peatioConfig), url, action.payload)
    yield put(actions.successSwapTransaction({ data }))
    yield put(getUserRequested(false))
  } catch (e) {
    yield put(showAlert({ message: [e.message], code: e.code, type: 'error' }))
    yield put(getUserRequested(false))
  }
}

export function* fetchFiat() {
  yield put(getUserRequested(true))

  try {
    const url = '/account/banxa/fiat_currencies'
    const data = yield call(API.get(peatioConfig), url)
    yield put(actions.successFaitQuote({ data }))
    yield put(getUserRequested(false))
  } catch (e) {
    yield put(showAlert({ code: e.code, type: 'error' }))
    yield put(getUserRequested(false))
  }
}

export function* getPaymentRequest(action) {
  yield put(getUserRequested(true))

  try {
    const url = '/account/banxa/payment-methods'
    const data = yield call(API.get(peatioConfig), `${url}?${buildQueryString(action.payload)}`)
    action.callback(data.data)
    yield put(getUserRequested(false))
  } catch (e) {
    yield put(getUserRequested(false))
  }
}

export function* fetchQuoteSaga() {
  yield takeLatest(types.FETCH_QUOTE, fetchQuote)
}
export function* fetchActivitySaga() {
  yield takeEvery(types.GET_IP, fetchUserIp)
}

export function* sendSimplexSwapSaga() {
  yield takeEvery(types.CREATE_SWAP, sendSwap)
}
export function* fetchSwapRecordSaga() {
  yield takeEvery(types.FETCH_SWAP_TRANSACTION, fetchSimplex)
}
export function* updateSwapRecordSaga() {
  yield takeEvery(types.UPDATE_SWAP_TRANSACTION, updateSwap)
}
export function* fetchFiatQuoteSaga() {
  yield takeEvery(types.FETCH_FIAT_QUOTE, fetchFiat)
}

export function* getPaymentRequestSagas() {
  yield takeEvery(types.PAYMENT_METHOD_REQUEST, getPaymentRequest)
}
