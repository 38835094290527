import { TParams } from '@core/type'
import { ITransactionTypes } from '@components/Profile/Transactions/main/types'

import types from './types'
import { IReducerPurchaseTokens } from './type'

export const fetchPurchasedTokens = (params: TParams) => ({
  type: types.GET_PURCHASED_TOKENS_REQUEST,
  params,
})

export const fetchPurchasedTokensSuccess = (payload: IReducerPurchaseTokens) => ({
  type: types.GET_PURCHASED_TOKENS_SUCCESS,
  payload,
})

export const fetchPurchasedTokensFailure = (error: Error) => ({
  type: types.GET_PURCHASED_TOKENS_FAILURE,
  payload: error,
})

export const fetchTransactions = (transactionType: ITransactionTypes, params: TParams) => ({
  type: types.GET_TRANSACTIONS_REQUEST,
  payload: {
    params,
    transactionType,
  },
})

export const fetchTransactionsSuccess = (transactionType: ITransactionTypes, payload: any) => ({
  type: types.GET_TRANSACTIONS_SUCCESS,
  payload: {
    payload,
    transactionType,
  },
})

export const fetchTransactionsFailure = (transactionType: ITransactionTypes, error: Error) => ({
  type: types.GET_TRANSACTIONS_FAILURE,
  payload: {
    error,
    transactionType,
  },
})
