export const ANALYTICS_CATEGORY = {
  payment: 'Payment',
  emoji: 'Emoji',
  checkout: 'Checkout',
  wonka: 'Wonka',
  search: 'Search',
  cart: 'Cart',
  purchase: 'Purchase',
  stripe: 'Stripe',
  auth: 'Auth',
  web3Wallet: 'Web3Wallet',
}
