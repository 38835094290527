import React, { useState } from 'react'
import Input from '@components/UiKit/Form/Input'

function TestInputs() {
  const [value, setValue] = useState('Value')

  return (
    <div>
      <div>
        <Input
          noGutters
          label="Label"
          fullWidth
          placeholder="Placeholder"
          error
          helperText="Error"
        />
      </div>

      <div>
        <Input label="Label" placeholder="Placeholder" error disabled helperText="Error" />
      </div>

      <div>
        <Input label="Label" placeholder="Placeholder" value="" />
      </div>

      <div>
        <Input
          label="Label"
          placeholder="Placeholder"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value)
          }}
          value={value}
        />
      </div>

      <div>
        <Input
          multiline
          noGutters
          label="Label"
          fullWidth
          placeholder="Placeholder"
          error
          helperText="Error"
        />
      </div>
      <div>
        <Input
          multiline
          label="Label"
          placeholder="Placeholder"
          error
          disabled
          helperText="Error"
        />
      </div>
      <div>
        <Input multiline label="Label" placeholder="Placeholder" value="" />
      </div>
      <div>
        <Input
          multiline
          label="Label"
          placeholder="Placeholder"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value)
          }}
          value={value}
        />
      </div>

      <div>
        <Input
          label="Label"
          placeholder="Placeholder"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value)
          }}
          value={value}
          counter
          maxLength={200}
        />
      </div>
    </div>
  )
}

export default TestInputs
