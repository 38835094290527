import routes from '../routing/path'

export enum PAY_METHODS {
  checkout_from_account = 'checkout_from_account',
  external_wallets = 'external_wallets',
  choose_method = 'choose_method',
  pay_instantly = 'pay_instantly',
  pay_crypto = 'pay_crypto',
  crypto_address = 'crypto_address',
  crypto_address_mint = 'crypto_address_mint',
  bank_stripe = 'bank_stripe',
  bank_banxa = 'bank_banxa',
  credit_card = 'visa/mastercard',
  bank_transfer = 'wiretransfer',
  transfer_status = 'transfer_status',
  wonka_initial = 'wonka_initial',
  choose_card_method = 'choose_card_method',
  wonka_wait_confirmation = 'wonka_wait_confirmation',
}

export const CUT_WONKA_CHECKOUT = routes.withAuth.mint.mintPay.slice(1)

export const TRANSFER_STATUS = {
  deposit_initiated: 'PENDING',
  collected: 'SUCCESS',
  accepted: 'SUCCESS',
  errored: 'SUCCESS',
  declined: 'FAILED',
}
