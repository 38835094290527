import React from 'react'
import Typography from '@components/UiKit/Typography'

const { Display, Heading, Paragraph, Label } = Typography

function TestTypography() {
  return (
    <div>
      <Display>
        Settings
        <br />
        Second
      </Display>
      <br />
      <Display size={2}>
        Settings
        <br />
        Second
      </Display>
      <br />
      <Display size={3}>
        Settings
        <br />
        Second
      </Display>
      <br />
      <Display size={4}>
        Settings
        <br />
        Second
      </Display>
      <br />
      <Display size={5}>
        Settings
        <br />
        Second
      </Display>
      <br />
      <Heading>Tony Tran</Heading>
      <br />
      <Heading size={2}>
        you don’t own
        <br />
        any NFTs yet!
      </Heading>
      <br />
      <Heading size={3}>
        you don’t own
        <br />
        any NFTs yet!
      </Heading>
      <br />
      <Heading size={4}>
        you don’t own
        <br />
        any NFTs yet!
      </Heading>
      <br />
      <Heading size={5}>
        you don’t own
        <br />
        any NFTs yet!
      </Heading>
      <br />
      <Paragraph>
        peer market is your digital outfitter.
        <br />
        here, you can browse and purchase
        <br />
        digital goods, services
      </Paragraph>
      <br />
      <Paragraph size={2}>
        Once you delete your account,
        <br />
        there is no going back.
      </Paragraph>
      <br />
      <Paragraph font="IBMPlexMono">
        peer market is your digital outfitter.
        <br />
        here, you can browse and purchase
        <br />
        digital goods, services
      </Paragraph>
      <br />
      <Paragraph size={3}>
        peer market is your digital outfitter.
        <br />
        here, you can browse and purchase
        <br />
        digital goods, services
      </Paragraph>
      <br />
      <Paragraph font="IBMPlexMono" size={2}>
        peer market is your digital outfitter.
        <br />
        here, you can browse and purchase
        <br />
        digital goods, services
      </Paragraph>
      <br />
      <Paragraph size={4}>
        peer market is your digital outfitter.
        <br />
        here, you can browse and purchase
        <br />
        digital goods, services
      </Paragraph>
      <br />
      <Paragraph font="IBMPlexMono" size={3}>
        peer market is your digital outfitter.
        <br />
        here, you can browse and purchase
        <br />
        digital goods, services
      </Paragraph>
      <br />
      <Label>0xa8c3e49273b0a471e...bce8</Label>
      <br />
      <Label size={2}>Shop now</Label>
      <br />
      <Label size={3}>Shop now</Label>
      <br />
      <Label size={4}>Shop now</Label>
      <br />
      <Label size={5}>Shop now</Label>
      <br />
      <Label size={6}>Shop now</Label>
      <br />
      <Label size={7}>Shop now</Label>
    </div>
  )
}

export default TestTypography
