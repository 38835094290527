import { AVAILABLE_FUNCTIONALITY } from '@constants/settings'
import routes from '@routing/path'

export function createPagesList(t) {
  return [
    {
      children: t('items_wallet'),
      key: 'items_wallet',
      href: routes.withAuth.profile.walletNFTAssets,
    },
    {
      children: t('peerNfts'),
      key: 'Items',
      href: routes.withAuth.profile.purchased,
    },
    {
      isActive: AVAILABLE_FUNCTIONALITY.profileWonka,
      children: t('minted'),
      key: 'minted',
      href: routes.withAuth.profile.minted,
    },
    {
      children: t('activity'),
      key: 'activity',
      href: routes.withAuth.profile.transactions,
    },
  ]
}
