import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import Button from '@components/UiKit/ButtonNew/Button'
import { fixedArgClassNames } from '@utils/utils'
import useCurrentWidth from '@hooks/useCurrentWidth'

import MojiCard, { IMojiCardProps } from './index'
import styles from './styles.module.scss'
import { ArrowDownIcon } from './icon'

interface IMojiCardSftPfpProps extends IMojiCardProps {
  onSet?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  code?: string
}

function MojiCardSftPfp({
  contractAddress,
  appForbidden,
  className,
  details,
  classNameDetails,
  id,
  code,
  name,
  contractType,
  onSet,
  quantity,
  ...props
}: Readonly<IMojiCardSftPfpProps>) {
  const { size: autoSize } = useCurrentWidth()
  const { t } = useTranslation('nsUserProfile')

  const partionedClassNames = fixedArgClassNames(styles[autoSize])

  return (
    <MojiCard
      {...props}
      className={cn(styles.cardSft, className)}
      additionContent={
        <div className={styles.cardSft__details}>
          <div className={cn(styles.details, classNameDetails)}>
            <div className={styles.details__header}>
              {id && (
                <div className={styles.details__arrow}>
                  <div className={partionedClassNames(styles.details__arrow_icon)}>
                    <ArrowDownIcon />
                  </div>
                </div>
              )}
              {id && <div className={styles.id}>ID: {id}</div>}
            </div>

            <div className={styles.details__content}>
              <div className={styles.details__content_inner}>
                <p className={partionedClassNames(styles.details__name)}>
                  {name} {code ? `#${code}` : ''}
                </p>
                {contractType && (
                  <div className={partionedClassNames(styles.details__price_type)}>
                    {contractType}
                  </div>
                )}
              </div>
              {(quantity && (
                <div className={partionedClassNames(styles.details__price_value)}>
                  QTY:{quantity}
                </div>
              )) ||
                ''}
            </div>
          </div>
          {(contractAddress || appForbidden) && (
            <div className={styles.cardSft__btn}>
              <Button onClick={onSet} variant="secondary">
                {t('setAsPfp')}
              </Button>
            </div>
          )}
        </div>
      }
      details={details ?? false}
    />
  )
}

export default React.memo(MojiCardSftPfp)
