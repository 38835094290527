import { call, put, takeEvery } from 'redux-saga/effects'
import { errorsHandler } from '@core/helper'
import * as actions from '../actions'
import * as types from '../constants'
import { API } from '../../api'
import { showAlert } from '../actions/alert'

const peatioConfig = {
  apiVersion: 'peatio',
}

export function* getMarkets() {
  try {
    const list = yield call(API.get(peatioConfig), '/public/markets')
    yield put(actions.successGetMarkets(list))
    yield put(actions.setCurrentMarketIfUnset(list[0]))
  } catch (err) {
    errorsHandler(err)
    yield put(actions.failGetMarkets({ error: true, statusCode: 400 }))
  }
}

export function* getMarketsSaga() {
  yield takeEvery(types.GET_MARKET, getMarkets)
}

export function* marketFetchTickers() {
  try {
    const tickers = yield call(API.get(peatioConfig), '/public/markets/tickers')

    if (tickers) {
      const pairs = Object.keys(tickers)
      const convertedTickers = pairs.reduce((result, pair) => {
        result[pair] = tickers[pair].ticker
        return result
      }, {})
      yield put(actions.marketSuccessTicker(convertedTickers))
    }
  } catch (err) {
    errorsHandler(err)
    yield put(actions.marketFailedTicker())
  }
}

export function* rootMarketFetchTickers() {
  yield takeEvery(types.MARKET_GET_TICKER, marketFetchTickers)
}

export function* checkMarketLimit(action) {
  try {
    const data = yield call(API.get(peatioConfig), `/market/check_limit/${action.payload.id}`)

    yield put(actions.successCheckMarketLimit(data.limit_exceeded))
  } catch (e) {
    if (e.message[0] !== 'market.trade.not_permitted') {
      yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    }

    yield put(actions.failCheckMarketLimit())
  }
}

export function* checkMarketLimitSaga() {
  yield takeEvery(types.CHECK_MARKET_LIMIT, checkMarketLimit)
}
