export const GET_MARKET = 'markets/GET_MARKET'
export const SUCCESS_GET_MARKET = 'markets/SUCCESS_GET_MARKET'
export const FAIL_GET_MARKET = 'markets/FAIL_GET_MARKET'
export const MARKET_GET_TICKER = 'markets/MARKET_GET_TICKER'
export const MARKET_GET_TICKER_SUCCESS = 'markets/MARKET_GET_TICKER_SUCCESS'
export const MARKET_GET_TICKER_FAILURE = 'markets/MARKET_GET_TICKER_FAILURE'
export const MARKET_SET_CURRENT_MARKET = 'markets/MARKET_SET_CURRENT_MARKET'
export const MARKETS_SET_CURRENT_MARKET_IFUNSET = 'markets/MARKETS_SET_CURRENT_MARKET_IFUNSET'

export const SEARCH_MARKET = 'markets/SEARCH_MARKET'
export const SET_UNSET_FAVOURITE = 'markets/SET_UNSET_FAVOURITE'

export const CHECK_MARKET_LIMIT = 'markets/CHECK_MARKET_LIMIT'
export const SUCCESS_CHECK_MARKET_LIMIT = 'markets/SUCCESS_CHECK_MARKET_LIMIT'
export const FAIL_CHECK_MARKET_LIMIT = 'markets/FAIL_CHECK_MARKET_LIMIT'
