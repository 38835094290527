import {
  GET_PRESALE,
  GET_PRESALE_SUCCESS,
  GET_PRESALE_FAILURE,
  SAVE_PUBLIC_ICX_PROGRESS,
  SET_CHART_DATA,
  SET_GOOGLE_DATA,
  SET_COMPONENT_HIDE_OR_NOT,
} from '../constants'
import { calculateDate } from '../../utils/formatter'

const initState = {
  activePresale: undefined,
  date_time: {},
  loading: true,
  error: false,
  publicIcxProgress: null,
  total_raised: null,
  chartData: [],
  googleData: [],
  agreement: {},
}

// eslint-disable-next-line default-param-last
export const presaleReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PRESALE: {
      return { ...state, loading: true }
    }
    case GET_PRESALE_SUCCESS: {
      return {
        ...state,
        loading: false,
        activePresale: action.payload[0],
        date_time: action.payload[0] ? calculateDate(action.payload[0].start_date) : {},
      }
    }
    case SET_CHART_DATA: {
      return {
        ...state,
        chartData: action.payload,
      }
    }
    case SET_GOOGLE_DATA: {
      return {
        ...state,
        googleData: action.payload,
      }
    }
    case SET_COMPONENT_HIDE_OR_NOT: {
      return {
        ...state,
        agreement: action.payload,
      }
    }
    case GET_PRESALE_FAILURE: {
      return { ...state, loading: false, error: true }
    }
    case SAVE_PUBLIC_ICX_PROGRESS: {
      return {
        ...state,
        publicIcxProgress: action?.payload?.icx_progess,
        total_raised: action?.payload?.total_raised,
      }
    }
    default:
      return state
  }
}
