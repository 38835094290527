import React, { useEffect, useState, ReactNode, useRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import cn from 'classnames'
import styles from './modal.module.scss'

export interface IModalType {
  open: boolean
  onClose: (...args: any[]) => void
}

interface IModalProps extends IModalType {
  children: ReactNode
  className?: string
}

const popupElement = document.getElementById('popup')

function Modal({ children, className, open = false, onClose }: IModalProps): JSX.Element | null {
  const modalRef = useRef<HTMLDivElement>(null)
  const refOnClose = useRef(onClose)
  const [visible, setVisible] = useState<boolean>(false)

  refOnClose.current = onClose

  const handleOutsideClick: EventListener = useCallback((e: Event) => {
    if (modalRef.current && !modalRef.current?.contains(e.target as Node)) {
      refOnClose.current()
    }
  }, [])

  useEffect(() => {
    document.documentElement.style.overflow = visible ? 'hidden' : 'auto'
  }, [visible])

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  useEffect(() => {
    setVisible(open)
  }, [open])

  return visible && popupElement
    ? ReactDOM.createPortal(
        <div className={styles.modalOverlay}>
          <div
            ref={modalRef}
            className={cn(styles.modalContent, className, { [styles.active]: visible })}
          >
            {children}
          </div>
        </div>,
        popupElement,
      )
    : null
}

export default Modal
