import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import cn from 'classnames'
import queryString from 'query-string'
import { useTranslation } from 'react-i18next'

import PlayaNFTs from '@containers/Settings/Modals/PlayaNFTs'
import EmptyNFTsData from '@containers/Settings/Modals/EmptyNFTsData'
import { TSize } from '@components/UiKit/types'
import { API } from '@core/api'
import { IEmojiType, IHeadersType, IWonkaType } from '@containers/Settings/Modals/PlayaNFTs/types'
import { ApiVersion } from '@core/api/types'
import { IconPhoto, IconProfile } from '../Icons'
import CameraButton from '../../ButtonNew/CameraButton'
import { IPlayaTypes } from './types'
import { IMAGE_URLS_BY_TYPE } from './PlayaSettings'
import styles from './popup.module.scss'

export interface ITPlayaItem {
  children: string
  key: IPlayaTypes
}

export interface IPopupType {
  size?: TSize
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const config = {
  apiVersion: ApiVersion.peatio,
  withHeaders: true,
}

const ITEMS_COUNT_PER_PAGE = 30

const defaultStatePages = Object.fromEntries(Object.values(IPlayaTypes).map((type) => [type, 1]))

function Popup({ size = TSize.small, onSelectFile }: Readonly<IPopupType>) {
  const randomId = useMemo(() => crypto.randomUUID(), [])
  const { t } = useTranslation('nsUserProfile')

  const [isPopupVisible, setPopupVisible] = useState(false)
  const [showAvatarModal, setShowAvatarModal] = useState(false)
  const [keyId, setKeyId] = useState(randomId)

  const [headers, setHeaders] = useState<IHeadersType>({
    [IPlayaTypes.emoji]: { total: '' },
    [IPlayaTypes.wonka]: { total: '' },
  })

  const [page, setPage] = useState(defaultStatePages)
  const [items, setItems] = useState<
    | Record<IPlayaTypes.emoji, IEmojiType[] | undefined>
    | Record<IPlayaTypes.wonka, IWonkaType[] | undefined>
  >({
    [IPlayaTypes.emoji]: undefined,
    [IPlayaTypes.wonka]: undefined,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchData = useCallback(
    async (type: IPlayaTypes): Promise<void> => {
      if (!type) return

      const url = queryString.stringifyUrl({
        url: IMAGE_URLS_BY_TYPE[type],
        query: {
          limit: ITEMS_COUNT_PER_PAGE,
          page: page[type],
          app_forbidden: false,
        },
      })

      setIsLoading(true)
      setError(null)

      try {
        const res = await API.get(config)(url)

        const { data, headers } = res as {
          headers: IHeadersType
          data: IEmojiType[] | IWonkaType[]
        }

        setHeaders((preItems) => ({
          ...preItems,
          [type]: headers,
        }))

        setItems((prevItems) => ({
          ...prevItems,
          [type]: [...(prevItems[type] ?? []), ...data],
        }))

        setPage((prevPage) => ({
          ...prevPage,
          [type]: prevPage[type] + 1,
        }))
      } catch (error) {
        setError(error)
      } finally {
        setIsLoading(false)
      }
    },
    [page, items],
  )

  const isLoadingImages = isLoading

  const { isEmojiOrWonkaDataWhileFetching, isEmojiOrWonkaData } = useMemo(
    () => ({
      isEmojiOrWonkaDataWhileFetching: Object.values(items).some((item) => item === undefined),
      isEmojiOrWonkaData: Object.values(items).some((item) => item?.length > 0),
    }),
    [items],
  )

  const popupRef = useRef<HTMLDivElement>(null)

  const onOpenPopup = () => {
    if (!isEmojiOrWonkaData) {
      Object.values(IPlayaTypes).forEach((type) => fetchData(type))
    }

    setPopupVisible(true)
  }

  const handleShowModal = () => {
    setShowAvatarModal(true)
  }

  const handleCancelModal = useCallback(() => {
    setShowAvatarModal(false)
  }, [])

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectFile(e)
    setPopupVisible(false)
    setKeyId(randomId)
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setPopupVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className={cn(styles.loadBtn, styles.wrapper)}>
        <CameraButton size={size} onClick={onOpenPopup} />
        <div
          ref={popupRef}
          className={cn(styles.popup, {
            [styles.popup__visible]: isPopupVisible,
          })}
        >
          <div className={styles.popup__item} onClick={handleShowModal}>
            <IconPhoto />
            <p>{t('peerNfts')}</p>
          </div>
          <div className={styles.popup__item}>
            <label htmlFor="avatar-input">
              <IconProfile />
              {t('uploadPhoto')}
              <input
                key={keyId}
                type="file"
                id="avatar-input"
                onChange={handleSelectFile}
                accept="image/png, image/gif, image/jpeg"
              />
            </label>
          </div>
        </div>
      </div>
      {!isEmojiOrWonkaDataWhileFetching && isEmojiOrWonkaData ? (
        <PlayaNFTs
          data={items}
          isLoadingImages={isLoadingImages}
          open={showAvatarModal}
          onClose={handleCancelModal}
          fetchData={fetchData}
          headers={headers}
        />
      ) : (
        !isEmojiOrWonkaDataWhileFetching && (
          <EmptyNFTsData open={showAvatarModal} onClose={handleCancelModal} />
        )
      )}
    </>
  )
}

export default React.memo(Popup)
