import React, { memo } from 'react'
import isEqual from 'react-fast-compare'
import cn from 'classnames'
import Dropdown from '@components/UiKit/Form/Dropdown'
import { settings } from '@constants/settings'

import styles from './style.module.scss'

type IOption = { label: string | number; value: string | number }
interface IProps {
  className?: string
  onChange: (value: IOption) => void
  value?: IOption
  total?: number | string
}

const preparedOptions = settings.size.list.map((item) => ({
  value: item,
  label: item,
}))

function Size({ className, onChange, value, total = 0 }: Readonly<IProps>) {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.text}>showing</div>
      <Dropdown
        options={preparedOptions}
        classNameWrapper={styles.select}
        defaultValue={preparedOptions[0]}
        classNames={{
          option: () => styles.option,
        }}
        value={value}
        onChange={onChange}
      />
      <div className={styles.total}>of {total}</div>
    </div>
  )
}

export default memo(Size, isEqual)
