import {
  RANGER_CONNECT_DATA,
  RANGER_CONNECT_ERROR,
  RANGER_CONNECT_FETCH,
  RANGER_DISCONNECT_DATA,
  RANGER_SUBSCRIPTIONS_DATA,
} from '../constants'

const initialRangerState = {
  withAuth: false,
  connected: false,
  connecting: false,
  subscriptions: [],
}

export const rangerReducer = (state = initialRangerState, { type, payload } = {}) => {
  switch (type) {
    case RANGER_CONNECT_FETCH:
      return {
        ...state,
        connecting: true,
        withAuth: payload.withAuth,
      }

    case RANGER_SUBSCRIPTIONS_DATA:
      return {
        ...state,
        connecting: false,
        connected: true,
        subscriptions: [...payload.subscriptions],
      }

    case RANGER_CONNECT_DATA:
      return {
        ...state,
        connecting: false,
        connected: true,
      }

    case RANGER_CONNECT_ERROR:
      return {
        ...state,
        connecting: false,
        connected: false,
      }

    case RANGER_DISCONNECT_DATA:
      return {
        ...state,
        connecting: false,
        connected: false,
        subscriptions: [],
      }

    default:
      return state
  }
}
