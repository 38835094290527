/* eslint-disable no-console */
import { toast } from 'react-toastify'

import { errorMessages, isDev } from '@constants'

// eslint-disable-next-line import/no-cycle
import { store } from './store'

export const getErrorText = (path, obj = errorMessages) => {
  if (!path) {
    return null
  }
  const paths = path.split('.').filter((item) => !!item)
  let current = obj
  let i = 0

  for (i = 0; i < paths.length; i += 1) {
    if (current[paths[i]] == undefined) {
      return obj.default
    }
    current = current[paths[i]]
  }

  return current
}

export const getErrorTextHandler = (err) => {
  const { message } = err
  const isStringMessage = typeof message === 'string'
  const messageText = getErrorText(isStringMessage ? message : message?.[0]) || null
  return messageText
}

export const errorsHandler = (error, { errorCallback = null, showToastErrors = false } = {}) => {
  if (typeof error === 'string') {
    if (errorCallback) {
      store.dispatch(errorCallback(error))
    }
    return toast.error(error)
  }

  // eslint-disable-next-line prefer-const
  let { message = null, errors = null, code = null } = error

  if (Array.isArray(error)) {
    errors = error
  }

  const isStringMessage = typeof message === 'string'

  if (!isStringMessage && message?.length > 1) {
    errors = message
    message = null
  }

  if (isStringMessage && code && typeof code === 'string') {
    message = code
  }

  const messageText = getErrorText(isStringMessage ? message : message?.[0]) || null
  const errorsText = errors?.map((e) => getErrorText(e))

  if (isDev) {
    console.error(error)
  }

  if (errorCallback) {
    store.dispatch(errorCallback(messageText || errorsText))

    if (!showToastErrors) {
      return false
    }
  }

  if (messageText) {
    return toast.error(messageText)
  }

  return errorsText?.forEach((e) => toast.error(e))
}

/**
 * @param {Object} stateLoading loading state.
 * @param {String} key key of loading.
 * @param {Boolean} value value.
 */

export const setLoading = (stateLoading, key, value = true) => ({ ...stateLoading, [key]: value })

export default {
  errorsHandler,
}
