import React from 'react'
import OTPInput from '@components/UiKit/OTPInput'

function TestOTPInput() {
  return (
    <div>
      <OTPInput onComplete={(value) => console.log(value)} length={4} />
    </div>
  )
}

export default TestOTPInput
