import { defaultStorageLimit } from '../../api/config'

import {
  GET_MY_RECENT_TRADES,
  SUCCESS_MY_RECENT_TRADES,
  FAIL_MY_RECENT_TRADES,
  PUSH_MY_RECENT_TRADES,
} from '../constants'

const initState = {
  loading: false,
  list: [],
}

// eslint-disable-next-line default-param-last
export const myRecentTradesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_MY_RECENT_TRADES: {
      return { ...state, loading: true }
    }

    case SUCCESS_MY_RECENT_TRADES: {
      return {
        ...state,
        list: action.payload.slice(0, defaultStorageLimit()),
        loading: false,
      }
    }

    case FAIL_MY_RECENT_TRADES: {
      return {
        ...state,
        loading: false,
      }
    }

    case PUSH_MY_RECENT_TRADES: {
      return {
        ...state,
        list: [action.payload, ...state.list].slice(0, defaultStorageLimit()),
        loading: false,
      }
    }

    default:
      return state
  }
}
