export default {
  FETCH_GET_WITHDRAW_LIMITS: 'FETCH_GET_WITHDRAW_LIMITS',
  FETCH_GET_WITHDRAW_LIMITS_SUCCESS: 'FETCH_GET_WITHDRAW_LIMITS_SUCCESS',
  FETCH_GET_WITHDRAW_LIMITS_FAILURE: 'FETCH_GET_WITHDRAW_LIMITS_FAILURE',

  FETCH_CURRENCIES_REQUEST: 'FETCH_CURRENCIES_REQUEST',
  FETCH_CURRENCIES_SUCCESS: 'FETCH_CURRENCIES_SUCCESS',
  FETCH_CURRENCIES_FAILURE: 'FETCH_CURRENCIES_FAILURE',

  FETCH_CURRENCIES_PRICE_UPDATE: 'FETCH_CURRENCIES_PRICE_UPDATE',

  FETCH_FIAT_BANXA_CURRENCIES_REQUEST: 'FETCH_FIAT_BANXA_CURRENCIES_REQUEST',
  FETCH_FIAT_BANXA_CURRENCIES_SUCCESS: 'FETCH_FIAT_BANXA_CURRENCIES_SUCCESS',
  FETCH_FIAT_BANXA_CURRENCIES_FAILURE: 'FETCH_FIAT_BANXA_CURRENCIES_FAILURE',

  FETCH_PAYMENT_BANXA_METHODS_REQUEST: 'FETCH_PAYMENT_BANXA_METHODS_REQUEST',
  FETCH_PAYMENT_BANXA_METHODS_SUCCESS: 'FETCH_PAYMENT_BANXA_METHODS_SUCCESS',
  FETCH_PAYMENT_BANXA_METHODS_FAILURE: 'FETCH_PAYMENT_BANXA_METHODS_FAILURE',

  FETCH_BALANCE_REQUEST: 'FETCH_BALANCE_REQUEST',
  FETCH_BALANCE_SUCCESS: 'FETCH_BALANCE_SUCCESS',
  FETCH_BALANCE_FAILURE: 'FETCH_BALANCE_FAILURE',

  FETCH_PRICE_DEFAULT_COIN_REQUEST: 'FETCH_PRICE_DEFAULT_COIN_REQUEST',
  FETCH_PRICE_DEFAULT_COIN_SUCCESS: 'FETCH_PRICE_DEFAULT_COIN_SUCCESS',
  FETCH_PRICE_DEFAULT_COIN_FAILURE: 'FETCH_PRICE_DEFAULT_COIN_FAILURE',

  GET_PRESALE: 'GET_PRESALE',
  GET_PRESALE_SUCCESS: 'GET_PRESALE_SUCCESS',
  GET_PRESALE_FAILURE: 'GET_PRESALE_FAILURE',
  NEW_AGREEMENT: 'NEW_AGREEMENT',

  ADD_PROCESSING_TRANSACTION: 'ADD_PROCESSING_TRANSACTION',
  END_PROCESSING_TRANSACTION: 'END_PROCESSING_TRANSACTION',
}
