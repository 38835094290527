import React from 'react'
import Input from '@components/UiKit/Form/Input'
import IconButton from '@components/UiKit/ButtonNew/IconButton'
import { PROFILE_POPUPS } from '@constants/profile'
import { TSize } from '@components/UiKit/types'
import Typography from '@components/UiKit/Typography'
import styles from './deposit.module.scss'

const { Heading, Paragraph } = Typography

interface IDepositProps {
  label?: string
  profileTypeHandler: (value: string) => void
  setIsProfileDropdown: (value: boolean) => void
}

function Deposit({ profileTypeHandler, label = 'Deposit', setIsProfileDropdown }: IDepositProps) {
  return (
    <>
      <div className={styles.header}>
        <IconButton
          icon="CustomIconArrowLeft"
          size={TSize.compact}
          variant="secondary"
          className={styles.iconBg}
          onClick={() => profileTypeHandler(PROFILE_POPUPS.main_profile)}
        />
        <Heading size={2}>{label}</Heading>
      </div>
      <div className={styles.address}>
        <Input
          rows={3}
          className={styles.input}
          multiline
          label="Deposite address"
          placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit."
          value=""
          fullWidth
        />
      </div>
      <Paragraph size={4} className={styles.description}>
        At least 0.5000000000000001 PEER is required to deposit. Send only PEER to this address.
      </Paragraph>
    </>
  )
}

export default Deposit
