/* eslint-disable max-len */
export const stripeErrorMessages = {
  url_invalid: 'The URL is invalid',
  resource_missing: 'The ID is not valid',
  expired_card: 'The card has expired',
  api_key_expired: 'API key has expired',
  invalid_source_usage: 'The source cannot be used',
  not_allowed_on_standard_account: 'Transfers are not allowed',
  bank_account_unusable: 'The account cannot be used',
  state_unsupported: 'The state is not supported',
  invalid_number: 'The card number is invalid',
  card_declined: 'The card has been declined',
  shipping_calculation_failed: 'Shipping calculation failed',
  terminal_reader_offline: 'Terminal offline',
  tax_id_invalid: 'The tax ID number is invalid',
  email_invalid: 'Invalid Email',
  country_code_invalid: 'Invalid country code',
  incorrect_number: 'The card number is incorrect',
  postal_code_invalid: 'The postal code was incorrect',
  invoice_no_payment_method_types: 'Invoice cannot be generated',
  bank_account_exists: 'The bank account already exists',
  token_already_used: 'The token has already been used',
  invalid_charge_amount: 'The specified amount is invalid',
  incorrect_address: "The card's address is incorrect",
  charge_disputed: 'Charge has been charged back',
  invalid_tax_location: 'The specified location is invalid',
  clearing_code_unsupported: 'Invalid clearing code',
  routing_number_invalid: 'Invalid bank routing number',
  bank_account_verification_failed: 'The bank account cannot be verified',
  invoice_not_editable: 'Invoice can no longer be edited',
  coupon_expired: 'Expired coupon',
  invalid_cvc: 'The card’s security code is invalid',
  incorrect_zip: 'The card’s postal code is incorrect',
  balance_invalid_parameter: 'Balance contains invalid parameters',
  refund_disputed_payment: 'Cannot refund a disputed payment',
  charge_already_refunded: 'Charge has already been refunded',
  charge_already_captured: 'Charge has already been captured',
  taxes_calculation_failed: 'Tax calculation for the order failed',
  terminal_reader_hardware_fault: 'Terminal error',
  invalid_card_type: 'The card is not supported for payouts',
  no_account: 'The bank account could not be located',
  forwarding_api_invalid_parameter: 'Invalid parameters',
  invalid_expiry_year: 'The card’s expiration year is incorrect',
  parameter_missing: 'Some required values are missing',
  lock_timeout: 'Transaction failed, try again',
  transfers_not_allowed: 'The requested transfer cannot be created',
  payment_method_customer_decline: 'The customer did not approve the payment',
  invalid_expiry_month: 'The card’s expiration month is incorrect',
  intent_verification_method_missing: 'Transaction failed, try again',
  rate_limit: 'Too many requests hit the API',
  out_of_inventory: 'One or more line item(s) are out of stock',
  invoice_on_behalf_of_not_editable: 'Transaction failed, try again',
  card_decline_rate_limit_exceeded: 'This card has been declined too many times',
  payment_method_provider_timeout: 'The payment failed due to a timeout',
  token_card_network_invalid: 'Invalid transaction',
  billing_policy_remote_function_timeout: 'Billing policy error',
  sepa_unsupported_account: 'Account does not support SEPA payments',
  payment_method_bank_account_already_verified: 'This bank account has already been verified',
  account_closed: 'This account has been closed',
  parameter_invalid_string_blan: 'Some required values are missing',
  parameter_invalid_string_empty: 'Some required values are missing',
  charge_not_refundable: 'Unsuccessful refund charge',
  invoice_payment_intent_requires_action: 'Additional actions required',
  status_transition_invalid: 'Invalid status transition',
  payment_intent_authentication_failure: 'Failed authentication',
  setup_intent_authentication_failure: 'The payment method has failed authentication',
  balance_insufficient: 'No available balance',
  processing_error: 'An error while processing the card',
  payouts_limit_exceeded: 'You have reached your daily limits',
  instant_payouts_unsupported: 'This card is not eligible for Instant Payouts',
  missing: 'Transaction failed, try again',
  authentication_required: 'Authentication required to proceed',
  bank_account_bad_routing_numbers: 'This currency is not supported',
  parameter_invalid_empty: 'Some required values are missing',
  financial_connections_account_inactive: 'Data cannot be refreshed on inactive accounts',
  billing_policy_remote_function_unexpected_status_code: 'Billing policy error',
  payment_method_microdeposit_verification_amounts_invalid: 'Two microdeposits required',
  bitcoin_upgrade_required: 'Unsupported payment method',
  platform_api_key_expired: 'Transaction failed, try again',
  forwarding_api_upstream_connection_timeout: 'The request to the destination endpoint timed out',
  stripe_tax_inactive: 'Stripe Tax has not been activated on your account',
  parameter_invalid_integer: 'Some required values are missing',
  payment_intent_incompatible_payment_method: 'Invalid payment method',
  billing_policy_remote_function_unreachable: 'Billing policy error',
  bank_account_declined: 'The account is not verified or it is not supported',
  bank_account_restricted: 'The account cannot be used with the payment method',
  payment_method_invalid_parameter: 'Invalid parameter was in the payment',
  resource_already_exists: 'Invalid transaction',
  invalid_characters: 'The value contains unsupported characters',
  forwarding_api_upstream_connection_error: 'Stripe did not receive a response',
  payout_reconciliation_not_ready: 'Reconciliation is still in progress',
  terminal_reader_busy: 'Terminal busy',
  payouts_not_allowed: 'Payouts disabled on the connected account',
  token_in_use: 'Invalid transaction',
  refer_to_customer: 'Customer has stopped the payment with their bank',
  account_information_mismatch: 'Account details mismatch',
  customer_max_payment_methods: 'Maximum number of payment methods reached',
  account_country_invalid_address: "Country doesn't match your profile data",
  invoice_upcoming_none: 'No upcoming invoice recorded',
  parameter_unknown: 'Invalid parameters',
  payment_method_unsupported_type: 'Unsupported payment method',
  terminal_location_country_unsupported: 'Terminal unavailable',
  charge_invalid_parameter: 'Invalid parameters',
  account_error_country_change_requires_additional_steps:
    'Account already onboarded as a Connect platform',
  billing_policy_remote_function_response_invalid: 'Billing policy error',
  parameters_exclusive: 'Invalid parameters',
  payment_intent_action_require: 'Customer actions required',
  setup_attempt_failed: 'Transaction failed, try again',
  livemode_mismatch: 'Invalid parameters',
  sku_inactive: 'Transaction failed, try again later',
  setup_intent_setup_attempt_expired: 'Transaction failed, try again',
  capture_unauthorized_payment: 'Charge was not authorized',
  return_intent_already_processed: 'Refund has already been processed',
  account_number_invalid: 'Bank account number invalid',
  invoice_no_customer_line_items: 'Invoice cannot be generated',
  payment_intent_payment_attempt_failed: 'Transaction failed, try again',
  payment_method_microdeposit_failed: 'Microdeposit failed',
  payment_method_configuration_failures: 'Attempt to create payment was unsuccessful',
  instant_payouts_config_disabled: 'This account is not eligible for Instant Payouts',
  payment_method_provider_decline: 'The payment attempt was declined',
  platform_account_required: 'Stripe Connect required',
  ownership_declaration_not_allowed: 'Declaration only allowed during account updates',
  transfer_source_balance_parameters_mismatch: 'Invalid transaction',
  payment_intent_payment_attempt_expired: 'Transaction failed, try again',
  invoice_no_subscription_line_items: 'Invoice cannot be generated',
  terminal_reader_timeout: 'Terminal timeout',
  forwarding_api_inactive: 'Transaction failed, try again',
  setup_intent_unexpected_state: 'Transaction failed, try again',
  customer_tax_location_invalid: 'Location invalid to determine tax rates',
  payment_method_billing_details_address_missing: 'Address details is missing',
  payment_method_microdeposit_verification_attempts_exceeded:
    'The number of verification attempts are exceeded',
  instant_payouts_limit_exceeded: 'Daily limit for Instant Payouts was reached',
  amount_too_small: 'The amount is less than allowed',
  payment_intent_unexpected_state: 'Transaction failed, try again',
  payment_intent_invalid_parameter: 'Transaction failed, try again',
  payment_intent_konbini_rejected_confirmation_number: 'The confirmation number was rejected',
  capture_charge_authorization_expired: 'Charge authorization expired',
  progressive_onboarding_limit_exceeded: 'Transaction failed, try again',
  payment_method_not_available: 'The payment is temporarily unavailable',
  idempotency_key_in_use: 'Transaction failed, try again',
  charge_expired_for_capture: 'Authorization expired',
  secret_key_required: 'Transaction failed, try again',
  amount_too_large: 'The amount is more than allowed',
  setup_intent_mandate_invalid: 'Transaction failed, try again',
  instant_payouts_currency_disabled: 'This account is not eligible in this currency',
  payment_intent_mandate_invalid: 'Transaction failed, try again',
  alipay_upgrade_required: 'Unsupported payment method',
  customer_max_subscriptions: 'Maximum number of subscriptions reached',
  testmode_charges_only: 'Account has not been activated',
  account_invalid: 'Account is invalid',
  payment_method_microdeposit_verification_descriptor_code_mismatch:
    'Verification code does not match',
  payment_method_bank_account_blocked: 'The account has failed verification in the past',
  bank_account_unverified: 'Unverified account',
  product_inactive: 'Product no longer available',
  insufficient_funds: 'Account funds are not enough',
  intent_invalid_state: 'Transaction failed, try again',
  shipping_address_invalid: 'Shipping address invalid to determine tax rates',
  payment_method_invalid_parameter_testmode: 'Invalid payment method in testmode',
  tls_version_unsupported: 'Transaction failed, try again',
  payment_method_microdeposit_verification_amounts_mismatch: 'Verification amount does not match',
  payment_method_currency_mismatch: 'Currency mismatch',
  acss_debit_session_incomplete: 'Transaction failed, try again',
  debit_not_authorized: 'Payment not authorized',
  charge_exceeds_source_limit: 'Invalid charge amount',
  billing_invalid_mandate: 'Transaction failed, try again',
  country_unsupported: 'Unsupported country',
  setup_intent_invalid_parameter: 'Transaction failed, try again',
  cardholder_phone_number_required: 'Phone number is required',
  application_fees_not_allowed: 'The transaction can not be completed',
  payment_method_microdeposit_verification_timeout: 'Microdeposit timed out',
  payment_method_unexpected_state: 'Unsupported payment method',
  payment_method_unactivated: 'Unsupported payment method',
  financial_connections_no_successful_transaction_refresh: 'No transactions available',
}
