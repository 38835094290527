import BigNumber from 'bignumber.js'

export const truncateData = (num, fixed) => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`)
  let number
  if (num === 0) {
    number = num.toFixed(fixed)
  } else {
    number = new BigNumber(num || 0).toFixed()
  }
  if (number == Infinity || number == null) {
    return 0
  }
  return number?.toString()?.match(re)[0]
}

export const convertPmc = (pmc, usd, amount) => {
  const usdToCoinPrice = 1 / pmc
  const latestPrice = usd * usdToCoinPrice // 0.088
  const pmcAmount = latestPrice * amount
  return pmcAmount || 0
}

export const convertUsd = (usd, amount) => amount * (1 / usd)
