import React, { useState } from 'react'
import Button from '@components/UiKit/ButtonNew/Button'
import { TSize } from '@components/UiKit/types'

function TestButtons() {
  const [value, setValue] = useState(null)

  const buttonHandler = (data) => {
    setValue(data)
  }

  return (
    <div>
      <div>
        <Button type="submit" size={TSize.spacious} variant="primary">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" size={TSize.spacious} variant="secondary">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="reset" size={TSize.spacious} variant="tertiary">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" size={TSize.spacious} variant="danger">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" size={TSize.spacious} variant="warning">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" size={TSize.spacious} variant="blue">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" disabled size={TSize.spacious} variant="warning">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" size={TSize.compact} variant="warning">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" size={TSize.small} variant="danger">
          button
        </Button>
      </div>
      <br />
      <div>
        <Button
          type="submit"
          size={TSize.spacious}
          variant="primary"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            buttonHandler(event)
          }}
        >
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" size={TSize.spacious} variant="secondary" isBorderSquare>
          button
        </Button>
      </div>
      <br />
      <div>
        <Button type="button" size={TSize.spacious} variant="danger" fullWidth>
          button
        </Button>
      </div>
    </div>
  )
}

export default TestButtons
