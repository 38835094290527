import React, { useState } from 'react'
import PageHeader from '@components/PageHeader'
import Dropdown from '@components/UiKit/Form/Dropdown'
import SegmentedControl from '@components/UiKit/SegmentedControl'
import SearchSort from '@components/SearchSort'
import Search from '@components/UiKit/Search'
import { TSize } from '@components/UiKit/types'

function TestCardHeader() {
  const [compactValue, setCompactValue] = useState('')
  const options = [
    {
      label: 'red',
      value: 'red',
    },
    {
      label: 'green',
      value: 'green',
    },
    {
      label: 'blue',
      value: 'blue',
    },
    {
      label: 'orange',
      value: 'orange',
    },
    {
      label: 'yellow',
      value: 'yellow',
    },
    {
      label: 'violet',
      value: 'violet',
    },
    {
      label: 'azure',
      value: 'azure',
    },
  ]

  return (
    <div>
      <div>
        <PageHeader userName="ttran" fullName="Tony Tran" />
      </div>
      <div style={{ maxWidth: '1000px' }}>
        <PageHeader userName="ttran" size={TSize.compact} isEditable fullName="Tony Tran" />
      </div>
      <div style={{ maxWidth: '400px' }}>
        <PageHeader userName="ttran" size={TSize.small} isPZoneDisabled fullName="Tony Tran" />
      </div>
      <div>
        <PageHeader
          userName="ttran"
          size={TSize.spacious}
          isEditable
          isUserImageOnCenter
          isPZone={false}
          fullName="Tony Tran"
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '36px',
          maxWidth: '980px',
          padding: '20px 0',
        }}
      >
        <Search onSearch={(value) => console.log(value)} size={TSize.spacious} />
        <Search onSearch={(value) => console.log(value)} size={TSize.compact} />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '36px',
          maxWidth: '980px',
          padding: '20px 0',
        }}
      >
        <Search onSearch={(value) => console.log(value)} size={TSize.spacious} />
        <Dropdown value={compactValue} options={options} onChange={setCompactValue} />
      </div>

      <div
        style={{
          display: 'flex',
          gap: '36px',
          maxWidth: '980px',
          padding: '20px 0',
        }}
      >
        <SegmentedControl action={(value) => console.log(value)} />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '36px',
          maxWidth: '980px',
          padding: '20px 0',
        }}
      >
        <SearchSort
          action={(value) => console.log(value)}
          value={compactValue}
          options={options}
          onChange={setCompactValue}
          onSearch={(value) => console.log(value)}
          isDropdownDisabled
        />
      </div>
    </div>
  )
}

export default TestCardHeader
