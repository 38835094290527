import { FormatOptionLabelMeta } from 'react-select'
import React, { ReactElement } from 'react'
import Check from '@assets/check.svg'
import { Option } from '@components/UiKit/Form/Dropdown/Dropdown.types'

import styles from './style.module.scss'

export function CustomOption(
  minimizeOnMobile: boolean,
  item: Option,
  meta: FormatOptionLabelMeta<Option>,
) {
  const isMobile = window.innerWidth < 560

  const { iconWidth, label: itemLabel } = item
  let { icon } = item
  const { selectValue, context } = meta

  let checked: null | ReactElement = null

  if (typeof icon === 'string') {
    icon = <img width={iconWidth || 25} src={icon} alt="" />
  }

  if (selectValue.some((selected) => selected.label === itemLabel) && context === 'menu') {
    checked = <img width={iconWidth || 16} src={Check} alt="" />
  }

  return (
    <span className={styles.optionsValue}>
      {!(minimizeOnMobile && isMobile && context === 'value') && (
        <div className={styles.optionsValueInner}>
          <div>
            {icon}
            {itemLabel}
          </div>
          <div className={styles.checked}>{checked}</div>
        </div>
      )}
    </span>
  )
}
