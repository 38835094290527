/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line import/no-cycle

export const generateSocketURI = (baseUrl, s) => `${baseUrl}/?stream=${s.sort().join('&stream=')}`

export const formatTicker = (events) => {
  const tickers = {}

  for (const market in events) {
    if (Object.prototype.hasOwnProperty.call(events, market)) {
      const event = events[market]
      const {
        open,
        low,
        high,
        last,
        volume,
        sell,
        buy,
        avg_price,
        price_change_percent,
        total_volume,
        usd,
      } = event

      tickers[market] = {
        open,
        low,
        high,
        last,
        sell,
        buy,
        vol: volume,
        total_volume,
        avg_price,
        price_change_percent,
        usd,
      }
    }
  }

  return tickers
}

export const streamsBuilder = (withAuth) => {
  let streams = ['global.crypto_setting', 'global.updated_price']

  if (withAuth) {
    streams = [
      ...streams,
      'order',
      'trade',
      'deposit',
      'wonka_tokens',
      'wonka_media',
      'banxa',
      'stripe',
    ]
  }

  return streams
}

export const periodsMapNumber = {
  '1m': 1,
  '5m': 5,
  '15m': 15,
  '30m': 30,
  '1h': 60,
  '2h': 120,
  '4h': 240,
  '6h': 360,
  '12h': 720,
  '1d': 1440,
  '3d': 4320,
  '1w': 10080,
}

export const periodsMapString = {
  1: '1m',
  5: '5m',
  15: '15m',
  30: '30m',
  60: '1h',
  120: '2h',
  240: '4h',
  360: '6h',
  720: '12h',
  1440: '1d',
  4320: '3d',
  10080: '1w',
}

export const periodStringToMinutes = (period) => periodsMapNumber[period]

export const periodMinutesToString = (period) => periodsMapString[period]

export const marketKlineStreams = (marketId, periodString) => ({
  channels: [`${marketId}.kline-${periodString}`],
})
