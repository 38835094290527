import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Table from '@components/UiKit/Table'
import { getCurrencies } from '@core/icx/redux/actions/currency'
import Pagination from '@components/UiKit/Pagination'

import { configSelectors } from '@core/selectors'
import Size from '@components/UiKit/Size'
import { settings } from '@constants/settings'
import { fetchTransactions } from '@core/purchasedTokens/actions'

import TransactionsHeader from './Header'
import { ITransactionTypes, ITtransactionItem } from './types'
import { getMappedColumns } from './Transaction.utils'
import styles from './style.module.scss'

const { defaultValue: defaultSize } = settings.size

function Transactions() {
  const currencies = useSelector(configSelectors.currencies)
  const transactions = useSelector(configSelectors.transactions)
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(defaultSize)
  const [transactionType, setTransactionType] = useState<ITransactionTypes>(
    ITransactionTypes.recentActivities,
  )

  const { items, total, loading } = useMemo(() => {
    const currentTransaction = transactions?.[transactionType]
    const items = currentTransaction?.data?.items ?? null
    const total = currentTransaction?.data?.total ?? 0
    const loading = !items || currentTransaction?.loading

    return {
      items,
      total,
      loading,
    }
  }, [transactions, transactionType])

  const dispatch = useDispatch()

  const { t } = useTranslation('nsUserProfile')

  const columnsNames = t('columnsNames', { returnObjects: true })

  useEffect(() => {
    dispatch(getCurrencies())
  }, [])

  const getTransaction = useCallback(() => {
    dispatch(
      fetchTransactions(transactionType, {
        page,
        limit,
      }),
    )
  }, [page, limit, transactionType])

  useEffect(() => {
    getTransaction()
  }, [getTransaction])

  const currencyPrecision = useMemo(
    () => currencies?.reduce((acc, item) => ({ ...acc, [item.id]: item.precision }), {}),
    [currencies],
  )

  useEffect(() => {
    if (items) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [items])

  const handlePageChange = (currentPage) => {
    setPage(currentPage.selected)
  }

  const handleSizeChange = (size) => {
    setLimit(Number(size.value))
    setPage(0)
  }

  const mappedColumns = useMemo(
    () =>
      getMappedColumns({
        currencyPrecision,
        currencies,
        columnsNames,
      }),
    [currencies, currencyPrecision, columnsNames],
  )

  const onChangeTab = useCallback((item: ITtransactionItem) => {
    setTransactionType(item.type)
    setPage(0)
  }, [])

  return (
    <div className={styles.container}>
      <TransactionsHeader
        transactionType={transactionType}
        onChangeTab={onChangeTab}
        reload={getTransaction}
      />
      <Table
        displayedMobileCards
        columns={mappedColumns[transactionType]}
        data={items}
        loading={loading}
        emptyText={t('noTransactions')}
      />

      {total > defaultSize && (
        <div className={styles.footer}>
          <Pagination
            renderOnOnePageCount={limit !== defaultSize}
            itemsPerPage={limit}
            total={total}
            onPageChange={handlePageChange}
            forcePage={page}
            className={styles.pagination}
          />
          <Size total={total} onChange={handleSizeChange} />
        </div>
      )}
    </div>
  )
}

export default memo(Transactions)
