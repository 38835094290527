/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
// eslint-disable-next-line import/no-cycle
import { selectCurrencies } from './currencies'
import { uppercase } from '../../helpers'

const selectMarketsState = (state) => state.markets

export const selectMarkets = (state) => selectMarketsState(state).list

export const selectMarketsLoading = (state) => selectMarketsState(state).loading

export const selectCurrentMarket = (state) => selectMarketsState(state).currentMarket

export const selectMarketTickers = (state) => selectMarketsState(state).tickers
export const selectCurrentMarketTrades = (markets, currentCurrency) => {
  let trades = []
  if (markets !== undefined) {
    trades = markets.filter((market) => market.base_unit === currentCurrency)
  }
  return trades
}

export const getQuoteUnit = (markets, market_id) => {
  const market = markets.find((item) => item.id === market_id)
  if (market !== undefined) {
    return uppercase(market.quote_unit)
  }
  return ''
}

export const getMarketName = (markets, market_id) => {
  const market = markets.find((item) => item.id === market_id)
  if (market !== undefined) {
    return market.name
  }
  return ''
}

export const totalVolume = (state) => {
  const currencies = selectCurrencies(state)
  const markets = selectMarkets(state)
  const tickers = selectMarketTickers(state)

  if (tickers !== undefined && currencies !== undefined && markets !== undefined) {
    const data = []
    currencies.map((value) => {
      const markets_list = markets.filter((item) => item.quote_unit === value.id)
      if (markets_list !== undefined && markets_list !== []) {
        let volumeSum = 0.0
        markets_list.map((market) => {
          const ticker = tickers[market.id]
          if (ticker !== undefined) {
            volumeSum += parseFloat(ticker.total_volume)
          }
        })
        data.push({
          name: value.id,
          symbol: value.symbol,
          volume: volumeSum,
        })
      }
    })
    return data
  }
}
