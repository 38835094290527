/* eslint-disable import/no-unresolved */
import React, { memo, useCallback, useEffect } from 'react'
import isEqual from 'react-fast-compare'
import Loader from '@components/Loader'
import useEffectOnce from '@hooks/useEffectOnce'
import MojiCard from '@components/MojiCard/CardSftPfp'

import { useDispatch, useSelector } from 'react-redux'

import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { BrowserProvider } from 'ethers'
import { fetchExternalNFTList, setProfileExternalNFTPicture } from '@core/profile/actions'
import { isDevOrDevEnvironment } from '@constants/environments'
import { configSelectors } from '@core/selectors'
import { CONTRACT_INTERFACES } from '@constants/web3'
import { TEmojiMedia } from '@core/emojis/types'
import ProfilePurchasedNoData from '../NoDataPurchased'
import styles from '../Purchased/style.module.scss'

export type TNFTTokenData = {
  contract_type: CONTRACT_INTERFACES
  name: string
  symbol: string
  imageUrl: string
  contract_address: string
  tokenId: string
  volume: number
  token_id: string
  address: string
  media: TEmojiMedia
}

function ProfilePurchased() {
  const dispatch = useDispatch()
  const profile = useSelector(configSelectors.profile, isEqual)
  const loading = useSelector(configSelectors.externalNFTLoader)
  const tokenList = useSelector(configSelectors.externalNFTList)
  const { address } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  useEffect(() => {
    if (address === profile?.wallet_address && !loading) dispatch(fetchExternalNFTList())
  }, [profile?.wallet_address, address])

  useEffectOnce(() => {
    if (tokenList?.length) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [tokenList])

  const onSetHandler = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, token: TNFTTokenData) => {
      if (!walletProvider) return

      const provider = new BrowserProvider(walletProvider)

      event.stopPropagation()
      try {
        const signer = await provider.getSigner()
        const payload = {
          address,
          contract_address: token.contract_address,
          contract_type: token.contract_type,
          token_id: token.token_id,
        }
        const signature = await signer?.signMessage(JSON.stringify(payload))
        dispatch(setProfileExternalNFTPicture({ nft_details: payload, signature }))
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
        }, 100)
      } catch (err) {
        if (isDevOrDevEnvironment) {
          console.log(err)
        }
      }
    },
    [walletProvider],
  )

  const noData = !(loading || tokenList?.length) || !address

  return (
    <Loader isMountVisibleContent center loading={loading} height="200px">
      <div className={styles.emojiContainer}>
        {address &&
          address === profile?.wallet_address &&
          tokenList?.map((item) =>
            item.address === address ? (
              <MojiCard
                className={styles.card}
                key={`${item.name}${item.token_id}${item.address}`}
                name={item.name}
                src={item?.media?.url}
                contractType={item.contract_type}
                onSet={(e) => onSetHandler(e, item)}
                contractAddress={item?.contract_address}
              />
            ) : null,
          )}
      </div>

      {noData && <ProfilePurchasedNoData />}
    </Loader>
  )
}

export default memo(ProfilePurchased, isEqual)
