import React, { ReactNode, CSSProperties } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

interface ITypographyProps extends Partial<Omit<HTMLElement, 'children' | 'className' | 'style'>> {
  children: ReactNode
  style?: CSSProperties
  className?: string
}

interface IDisplayProps extends ITypographyProps {
  size?: 1 | 2 | 3 | 4 | 5 | 6
}

function Display({ size = 1, className, style, children }: IDisplayProps) {
  return (
    <h1 className={classNames(styles.display, className, styles[`size_${size}`])} style={style}>
      {children}
    </h1>
  )
}

interface IHeadingProps extends IDisplayProps {
  size?: 1 | 2 | 3 | 4 | 5
}

function Heading({ size = 1, className, style, children }: IHeadingProps) {
  return (
    <h1 className={classNames(styles.heading, className, styles[`size_${size}`])} style={style}>
      {children}
    </h1>
  )
}

interface IParagraphProps extends ITypographyProps {
  size?: 1 | 2 | 3 | 4
  font?: 'Gilroy' | 'IBMPlexMono'
}

function Paragraph({ size = 1, className, style, font = 'Gilroy', children }: IParagraphProps) {
  return (
    <p
      className={classNames(styles.paragraph, className, styles[`size_${size}`], styles[font])}
      style={style}
    >
      {children}
    </p>
  )
}

interface ILabelProps extends ITypographyProps {
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7
}

function Label({ size = 1, className, style, children }: ILabelProps) {
  return (
    <p className={classNames(styles.label, className, styles[`size_${size}`])} style={style}>
      {children}
    </p>
  )
}

const Typography = {
  Display,
  Heading,
  Paragraph,
  Label,
}

export default Typography
