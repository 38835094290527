/* eslint-disable react/button-has-type */
import React from 'react'
import cn from 'classnames'
import LoaderIcon from '@components/Icons/Loader'
import { TSize } from '@components/UiKit/types'
import useCurrentWidth from '@hooks/useCurrentWidth'
import { TVariant, TType } from '../constants'

import styles from './button.module.scss'

interface IButtonProps {
  type?: TType
  size?: TSize
  variant?: TVariant
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  children?: React.ReactNode
  loading?: boolean
  loadingIconSize?: number
  isBorderSquare?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

function Button({
  className,
  fullWidth,
  disabled = false,
  size,
  variant = 'primary',
  type = 'button',
  children,
  loading,
  loadingIconSize = 30,
  isBorderSquare,
  onClick,
}: IButtonProps) {
  const { size: autoSize } = useCurrentWidth()
  const currentSize = size || autoSize
  return (
    <button
      onClick={onClick}
      type={type}
      className={cn(styles.button, className, styles[variant], styles[currentSize], {
        [styles.full]: fullWidth,
        [styles.disabled]: disabled,
        [styles.border]: isBorderSquare,
      })}
    >
      {loading && (
        <span className={styles.buttonLoader}>
          <LoaderIcon size={loadingIconSize} />
        </span>
      )}
      <div className={cn(styles.buttonInner, { [styles.hide]: loading })}>{children}</div>
    </button>
  )
}

export default Button
