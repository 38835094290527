import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { logout } from '@core/profile/actions'
import { PROFILE_POPUPS } from '@constants/profile'
import routes from '@routing/path'
import UserIcon from '@components/UiKit/Avatar/UserIcon'
import Button from '@components/UiKit/ButtonNew/Button'
import Typography from '@components/UiKit/Typography'
import MenuList from './MenuList'
import styles from './mainProfile.module.scss'

const { Heading, Paragraph } = Typography

const DISPLAY_HEADER_WALLET = false

interface IMainProfileProps {
  name: string
  profileTypeHandler: (value: string) => void
  setIsProfileDropdown: (value: boolean) => void
}

function MainProfile({
  name,
  profileTypeHandler,
  setIsProfileDropdown,
}: Readonly<IMainProfileProps>) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('nsCommon')

  const closeDropdown = () => {
    setIsProfileDropdown(false)
  }

  const onLogout = useCallback(async () => {
    dispatch(logout())

    closeDropdown()

    navigate({
      pathname: routes.withAuth.home,
    })
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header__img}>
          <UserIcon onClick={() => {}} isInteractive={false} isSmall />
        </div>
        <Heading size={5} className={styles.userName}>
          {name}
        </Heading>
      </div>
      {DISPLAY_HEADER_WALLET && (
        <div className={styles.wallet}>
          <div className={styles.wallet__balance}>
            <Paragraph size={3} className={styles.balanceInfo}>
              {t('availableBalance')}
            </Paragraph>
            <Paragraph size={3} className={styles.balanceInfo}>
              0 peer
            </Paragraph>
          </div>
          <div className={styles.wallet__actions}>
            <Button
              variant="secondary"
              className={styles.button}
              isBorderSquare
              onClick={() => profileTypeHandler?.(PROFILE_POPUPS.deposit)}
            >
              {t('deposit')}
            </Button>
            <Button
              variant="secondary"
              className={styles.button}
              isBorderSquare
              onClick={() => profileTypeHandler?.(PROFILE_POPUPS.withdraw)}
            >
              {t('withdraw')}
            </Button>
          </div>
        </div>
      )}
      <MenuList setIsProfileDropdown={setIsProfileDropdown} />
      <button type="button" className={styles.logout} onClick={onLogout}>
        {t('logOut')}
      </button>
    </div>
  )
}

export default MainProfile
