export default {
  selectEmoji: {
    event: 'select_item',
    label: 'Click on the emoji card',
  },

  viewEmoji: {
    event: 'view_item',
    label: 'Click on the purchased emoji card',
  },

  addEmojiToCart: {
    event: 'add_to_cart',
    label: 'Adding emoji to cart',
  },

  removeEmojiFromCart: {
    event: 'remove_from_cart',
    label: 'Removing emoji from cart',
  },

  instantBuy: {
    event: 'instant_buy',
    label: 'Selected instant buy',
  },
}
