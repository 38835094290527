/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M153.387 148L130.832 177.496C128.148 181.008 128.816 186.028 132.328 188.711L151.391 203.289C154.902 205.973 159.922 205.305 162.605 201.793L206.789 144.016C207.586 142.903 209.082 140.528 209.949 138.344C211.27 135.157 212 131.664 212 128C212 124.336 211.273 120.844 209.953 117.66C209.082 115.473 207.586 113.098 206.789 111.985L162.605 54.2073C159.922 50.6956 154.902 50.0276 151.391 52.7112L132.328 67.2893C128.816 69.9729 128.148 74.9964 130.832 78.5042L153.387 108L53.8594 108C49.4414 108 45.8594 111.582 45.8594 116L45.8594 140C45.8594 144.418 49.4414 148 53.8594 148L153.387 148Z"
    />,
  ],
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M153.387 148L130.832 177.496C128.148 181.008 128.816 186.028 132.328 188.711L151.391 203.289C154.902 205.973 159.922 205.305 162.605 201.793L206.789 144.016C207.586 142.903 209.082 140.528 209.949 138.344C211.27 135.157 212 131.664 212 128C212 124.336 211.273 120.844 209.953 117.66C209.082 115.473 207.586 113.098 206.789 111.985L162.605 54.2073C159.922 50.6956 154.902 50.0276 151.391 52.7112L132.328 67.2893C128.816 69.9729 128.148 74.9964 130.832 78.5042L153.387 108L53.8594 108C49.4414 108 45.8594 111.582 45.8594 116L45.8594 140C45.8594 144.418 49.4414 148 53.8594 148L153.387 148Z"
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M153.387 148L130.832 177.496C128.148 181.008 128.816 186.028 132.328 188.711L151.391 203.289C154.902 205.973 159.922 205.305 162.605 201.793L206.789 144.016C207.586 142.903 209.082 140.528 209.949 138.344C211.27 135.157 212 131.664 212 128C212 124.336 211.273 120.844 209.953 117.66C209.082 115.473 207.586 113.098 206.789 111.985L162.605 54.2073C159.922 50.6956 154.902 50.0276 151.391 52.7112L132.328 67.2893C128.816 69.9729 128.148 74.9964 130.832 78.5042L153.387 108L53.8594 108C49.4414 108 45.8594 111.582 45.8594 116L45.8594 140C45.8594 144.418 49.4414 148 53.8594 148L153.387 148Z"
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M153.387 148L130.832 177.496C128.148 181.008 128.816 186.028 132.328 188.711L151.391 203.289C154.902 205.973 159.922 205.305 162.605 201.793L206.789 144.016C207.586 142.903 209.082 140.528 209.949 138.344C211.27 135.157 212 131.664 212 128C212 124.336 211.273 120.844 209.953 117.66C209.082 115.473 207.586 113.098 206.789 111.985L162.605 54.2073C159.922 50.6956 154.902 50.0276 151.391 52.7112L132.328 67.2893C128.816 69.9729 128.148 74.9964 130.832 78.5042L153.387 108L53.8594 108C49.4414 108 45.8594 111.582 45.8594 116L45.8594 140C45.8594 144.418 49.4414 148 53.8594 148L153.387 148Z"
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M153.387 148L130.832 177.496C128.148 181.008 128.816 186.028 132.328 188.711L151.391 203.289C154.902 205.973 159.922 205.305 162.605 201.793L206.789 144.016C207.586 142.903 209.082 140.528 209.949 138.344C211.27 135.157 212 131.664 212 128C212 124.336 211.273 120.844 209.953 117.66C209.082 115.473 207.586 113.098 206.789 111.985L162.605 54.2073C159.922 50.6956 154.902 50.0276 151.391 52.7112L132.328 67.2893C128.816 69.9729 128.148 74.9964 130.832 78.5042L153.387 108L53.8594 108C49.4414 108 45.8594 111.582 45.8594 116L45.8594 140C45.8594 144.418 49.4414 148 53.8594 148L153.387 148Z"
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M153.387 148L130.832 177.496C128.148 181.008 128.816 186.028 132.328 188.711L151.391 203.289C154.902 205.973 159.922 205.305 162.605 201.793L206.789 144.016C207.586 142.903 209.082 140.528 209.949 138.344C211.27 135.157 212 131.664 212 128C212 124.336 211.273 120.844 209.953 117.66C209.082 115.473 207.586 113.098 206.789 111.985L162.605 54.2073C159.922 50.6956 154.902 50.0276 151.391 52.7112L132.328 67.2893C128.816 69.9729 128.148 74.9964 130.832 78.5042L153.387 108L53.8594 108C49.4414 108 45.8594 111.582 45.8594 116L45.8594 140C45.8594 144.418 49.4414 148 53.8594 148L153.387 148Z"
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M153.387 148L130.832 177.496C128.148 181.008 128.816 186.028 132.328 188.711L151.391 203.289C154.902 205.973 159.922 205.305 162.605 201.793L206.789 144.016C207.586 142.903 209.082 140.528 209.949 138.344C211.27 135.157 212 131.664 212 128C212 124.336 211.273 120.844 209.953 117.66C209.082 115.473 207.586 113.098 206.789 111.985L162.605 54.2073C159.922 50.6956 154.902 50.0276 151.391 52.7112L132.328 67.2893C128.816 69.9729 128.148 74.9964 130.832 78.5042L153.387 108L53.8594 108C49.4414 108 45.8594 111.582 45.8594 116L45.8594 140C45.8594 144.418 49.4414 148 53.8594 148L153.387 148Z"
    />,
  ],
])

const CustomIconArrowRight: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconArrowRight.displayName = 'CustomIconArrowRight'

export default CustomIconArrowRight
