import React, { memo, useState } from 'react'
import isEqual from 'react-fast-compare'
import cn from 'classnames'
import { useNavigate } from 'react-router'
import useQuery from '@hooks/useQuery'
import Loader from '@components/Loader'
import { API_URLS } from '@core/api/apiUrls'
import Pagination from '@components/UiKit/Pagination'
import useEffectOnce from '@hooks/useEffectOnce'
import Card from '@components/UiKit/Card'
import { TSize } from '@components/UiKit/types'
import { TEmojiItem } from '@core/emojis/types'
import Button from '@components/UiKit/ButtonNew/Button'
import Robot from '@assets/images/landingNav/Robot.png'
import routes from '@routing/path'
import SearchSort from '@components/SearchSort'
import { AVAILABLE_FUNCTIONALITY } from '@constants/settings'

import styles from './style.module.scss'

import ProfileMintedNoData from '../NoDataPurchased'
import { useLimitItem } from './Purchased.utils'

const DEFAULT_ITEMS_COUNT_PER_PAGE = 50

function ProfileMinted() {
  const navigate = useNavigate()
  const [isLargeCard, setIsLargeCard] = useState(false)
  const [page, setPage] = useState(0)

  const limit = useLimitItem(isLargeCard)

  const { data: unrevealedWonkasCount = 0, loading: loadingUnrevealed = false } = useQuery({
    url: API_URLS.wonka.listTokensCount,
    params: {
      revealed: false,
    },
  })

  const itemsCountPerPage = limit || DEFAULT_ITEMS_COUNT_PER_PAGE

  const { data, loading, headers } = useQuery<TEmojiItem[]>({
    url: API_URLS.wonka.listTokens,
    params: {
      limit: unrevealedWonkasCount ? itemsCountPerPage - 1 : itemsCountPerPage,
      page: page + 1,
      revealed: true,
    },
    trigger: !loadingUnrevealed,
  })

  const total = headers?.total || 0

  useEffectOnce(() => {
    if (data?.length) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [data])

  const handlePageChange = (currentPage) => {
    setPage(currentPage.selected)
  }

  const noData = !loadingUnrevealed && !loading && !data?.length && !unrevealedWonkasCount

  const goToReveal = () => {
    navigate(routes.withAuth.paymentReveal)
  }

  return (
    <Loader isMountVisibleContent center loading={loadingUnrevealed || loading} height="200px">
      {AVAILABLE_FUNCTIONALITY.profileControlsPanel && (
        <div className={styles.header}>
          <SearchSort isDropdownDisabled disabled onSearch={() => {}} action={setIsLargeCard} />
        </div>
      )}
      <div className={styles.emojiContainer}>
        {!!(unrevealedWonkasCount && data) && (
          // TODO: Remove or replace on a new MojiCard
          <Card
            name="Wonka type"
            className={cn(styles.card, { [styles['card--large']]: isLargeCard })}
            src={Robot}
            type="wonka"
            actions={
              <Button type="button" onClick={goToReveal} size={TSize.spacious} variant="blue">
                Reveal
              </Button>
            }
          />
        )}

        {data?.map((item) => {
          const name = item?.name
          const imgSrc = item?.media?.medium?.url || item?.media?.large?.url
          const code = item?.code
          // TODO: Remove or replace on a new MojiCard
          return (
            <Card
              className={cn(styles.card, { [styles['card--large']]: isLargeCard })}
              key={code}
              name={name}
              src={imgSrc}
            />
          )
        })}
      </div>
      {noData && <ProfileMintedNoData />}
      <Pagination
        itemsPerPage={itemsCountPerPage || DEFAULT_ITEMS_COUNT_PER_PAGE}
        total={Number(total)}
        onPageChange={handlePageChange}
        forcePage={page}
      />
    </Loader>
  )
}

export default memo(ProfileMinted, isEqual)
