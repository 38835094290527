import { GET_STATEMENTS, SUCCESS_GET_STATEMENTS, FAIL_GET_STATEMENTS } from '../constants'

const initState = {
  loading: false,
  list: [],
  total: 0,
}

// eslint-disable-next-line default-param-last
export const statementReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_STATEMENTS: {
      return { ...state, loading: true }
    }

    case SUCCESS_GET_STATEMENTS: {
      return {
        ...state,
        list: action.payload.list,
        total: action.payload.total,
        loading: false,
      }
    }

    case FAIL_GET_STATEMENTS: {
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}
