export const SUBMIT_BUYTUXC_ORDER = 'SUBMIT_BUYTUXC_ORDER'
export const SUCCESS_BUYTUXC_ORDER = 'SUCCESS_BUYTUXC_ORDER'
export const FAIL_BUYTUXC_ORDER = 'FAIL_BUYTUXC_ORDER'

export const FETCH_BUYTUXC = 'FETCH_BUYTUXC'
export const SUCCESS_FETCH_BUYTUXC = 'SUCCESS_FETCH_BUYTUXC'
export const FAIL_FETCH_BUYTUXC = 'FAIL_FETCH_BUYTUXC'
export const FETCH_BONUS_DETAIL = 'FETCH_BONUS_DETAIL'
export const SET_BONUS_DETAIL = 'SET_BONUS_DETAIL'
export const FETCH_BONUS_DETAIL_TRANSACTION = 'FETCH_BONUS_DETAIL_TRANSACTION'
export const SET_BONUS_DETAIL_TRANSACTION = 'SET_BONUS_DETAIL_TRANSACTION'

export const FETCH_ALL_BUYTUXC = 'FETCH_ALL_BUYTUXC'
export const SUCCESS_ALL_FETCH_BUYTUXC = 'SUCCESS_ALL_FETCH_BUYTUXC'
export const FAIL_ALL_FETCH_BUYTUXC = 'FAIL_ALL_FETCH_BUYTUXC'

export const SUCCESS_RESPONSE_TUXC = 'SUCCESS_RESPONSE_TUXC'
export const SET_SWAP_CURRENCY = 'SET_SWAP_CURRENCY'
export const FETCH_CURRENT_SWAP_PRICE = 'FETCH_CURRENT_SWAP_PRICE'
export const PURCHASE_FUND = 'PURCHASE_FUND'
export const SUCCESS_CURRENT_SWAP_PRICE = 'SUCCESS_CURRENT_SWAP_PRICE'
export const FAIL_CURRENT_SWAP_PRICE = 'FAIL_CURRENT_SWAP_PRICE'
export const UPDATE_REFRESH_VALUE = 'UPDATE_REFRESH_VALUE'
export const NEW_AGREEMENT = 'NEW_AGREEMENT'

export const FETCH_MEMBER_AGREEMENTS = 'FETCH_MEMBER_AGREEMENTS'
export const SUCCESS_MEMBER_AGREEMENTS = 'SUCCESS_MEMBER_AGREEMENTS'
export const FAIL_MEMBER_AGREEMENTS = 'FAIL_MEMBER_AGREEMENTS'
