/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 61.460938 72.40625 C 56.699219 77.40625 56.699219 85.511719 61.460938 90.507812 L 95.941406 126.714844 L 61.460938 162.917969 C 56.699219 167.914062 56.699219 176.019531 61.460938 181.019531 L 78.699219 199.121094 C 83.460938 204.121094 91.179688 204.121094 95.941406 199.121094 L 130.417969 162.917969 L 164.898438 199.121094 C 169.660156 204.121094 177.378906 204.121094 182.140625 199.121094 L 199.378906 181.019531 C 204.140625 176.019531 204.140625 167.914062 199.378906 162.917969 L 164.898438 126.714844 L 199.378906 90.507812 C 204.140625 85.511719 204.140625 77.40625 199.378906 72.40625 L 182.140625 54.304688 C 177.378906 49.308594 169.660156 49.308594 164.898438 54.304688 L 130.417969 90.507812 L 95.941406 54.304688 C 91.179688 49.308594 83.460938 49.308594 78.699219 54.304688 Z M 61.460938 72.40625 "
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 61.460938 72.40625 C 56.699219 77.40625 56.699219 85.511719 61.460938 90.507812 L 95.941406 126.714844 L 61.460938 162.917969 C 56.699219 167.914062 56.699219 176.019531 61.460938 181.019531 L 78.699219 199.121094 C 83.460938 204.121094 91.179688 204.121094 95.941406 199.121094 L 130.417969 162.917969 L 164.898438 199.121094 C 169.660156 204.121094 177.378906 204.121094 182.140625 199.121094 L 199.378906 181.019531 C 204.140625 176.019531 204.140625 167.914062 199.378906 162.917969 L 164.898438 126.714844 L 199.378906 90.507812 C 204.140625 85.511719 204.140625 77.40625 199.378906 72.40625 L 182.140625 54.304688 C 177.378906 49.308594 169.660156 49.308594 164.898438 54.304688 L 130.417969 90.507812 L 95.941406 54.304688 C 91.179688 49.308594 83.460938 49.308594 78.699219 54.304688 Z M 61.460938 72.40625 "
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 61.460938 72.40625 C 56.699219 77.40625 56.699219 85.511719 61.460938 90.507812 L 95.941406 126.714844 L 61.460938 162.917969 C 56.699219 167.914062 56.699219 176.019531 61.460938 181.019531 L 78.699219 199.121094 C 83.460938 204.121094 91.179688 204.121094 95.941406 199.121094 L 130.417969 162.917969 L 164.898438 199.121094 C 169.660156 204.121094 177.378906 204.121094 182.140625 199.121094 L 199.378906 181.019531 C 204.140625 176.019531 204.140625 167.914062 199.378906 162.917969 L 164.898438 126.714844 L 199.378906 90.507812 C 204.140625 85.511719 204.140625 77.40625 199.378906 72.40625 L 182.140625 54.304688 C 177.378906 49.308594 169.660156 49.308594 164.898438 54.304688 L 130.417969 90.507812 L 95.941406 54.304688 C 91.179688 49.308594 83.460938 49.308594 78.699219 54.304688 Z M 61.460938 72.40625 "
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 61.460938 72.40625 C 56.699219 77.40625 56.699219 85.511719 61.460938 90.507812 L 95.941406 126.714844 L 61.460938 162.917969 C 56.699219 167.914062 56.699219 176.019531 61.460938 181.019531 L 78.699219 199.121094 C 83.460938 204.121094 91.179688 204.121094 95.941406 199.121094 L 130.417969 162.917969 L 164.898438 199.121094 C 169.660156 204.121094 177.378906 204.121094 182.140625 199.121094 L 199.378906 181.019531 C 204.140625 176.019531 204.140625 167.914062 199.378906 162.917969 L 164.898438 126.714844 L 199.378906 90.507812 C 204.140625 85.511719 204.140625 77.40625 199.378906 72.40625 L 182.140625 54.304688 C 177.378906 49.308594 169.660156 49.308594 164.898438 54.304688 L 130.417969 90.507812 L 95.941406 54.304688 C 91.179688 49.308594 83.460938 49.308594 78.699219 54.304688 Z M 61.460938 72.40625 "
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 61.460938 72.40625 C 56.699219 77.40625 56.699219 85.511719 61.460938 90.507812 L 95.941406 126.714844 L 61.460938 162.917969 C 56.699219 167.914062 56.699219 176.019531 61.460938 181.019531 L 78.699219 199.121094 C 83.460938 204.121094 91.179688 204.121094 95.941406 199.121094 L 130.417969 162.917969 L 164.898438 199.121094 C 169.660156 204.121094 177.378906 204.121094 182.140625 199.121094 L 199.378906 181.019531 C 204.140625 176.019531 204.140625 167.914062 199.378906 162.917969 L 164.898438 126.714844 L 199.378906 90.507812 C 204.140625 85.511719 204.140625 77.40625 199.378906 72.40625 L 182.140625 54.304688 C 177.378906 49.308594 169.660156 49.308594 164.898438 54.304688 L 130.417969 90.507812 L 95.941406 54.304688 C 91.179688 49.308594 83.460938 49.308594 78.699219 54.304688 Z M 61.460938 72.40625 "
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 61.460938 72.40625 C 56.699219 77.40625 56.699219 85.511719 61.460938 90.507812 L 95.941406 126.714844 L 61.460938 162.917969 C 56.699219 167.914062 56.699219 176.019531 61.460938 181.019531 L 78.699219 199.121094 C 83.460938 204.121094 91.179688 204.121094 95.941406 199.121094 L 130.417969 162.917969 L 164.898438 199.121094 C 169.660156 204.121094 177.378906 204.121094 182.140625 199.121094 L 199.378906 181.019531 C 204.140625 176.019531 204.140625 167.914062 199.378906 162.917969 L 164.898438 126.714844 L 199.378906 90.507812 C 204.140625 85.511719 204.140625 77.40625 199.378906 72.40625 L 182.140625 54.304688 C 177.378906 49.308594 169.660156 49.308594 164.898438 54.304688 L 130.417969 90.507812 L 95.941406 54.304688 C 91.179688 49.308594 83.460938 49.308594 78.699219 54.304688 Z M 61.460938 72.40625 "
    />,
  ],
])

const CustomIconClose: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconClose.displayName = 'CustomIconClose'

export default CustomIconClose
