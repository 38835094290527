import React, { useCallback, useState } from 'react'
import Button from '@components/UiKit/ButtonNew/Button'
import UpdateCover from '@containers/Settings/Modals/UpdateCover'
import PlayaNFTs from '@containers/Settings/Modals/PlayaNFTs'
import ConnectWallet from '@containers/Settings/Modals/ConnectWallet'

const allModals = {
  updateCover: false,
  playaNFTs: false,
  connectWallet: false,
}

function TestModal() {
  const [modals, setModals] = useState({ ...allModals })

  const showModal = (key) => {
    setModals((prevModals) => ({
      ...prevModals,
      [key]: true,
    }))
  }

  const handleCancel = useCallback(() => {
    setModals({ ...allModals })
  }, [])

  const data = {
    emoji: [],
    wonka: [],
  }

  const fetchData = useCallback(() => {
    console.log('fetchData')
  }, [])

  return (
    <div>
      <Button onClick={() => showModal('updateCover')}>Open Modal Update cover</Button>
      <br />
      <Button onClick={() => showModal('playaNFTs')}>Open Modal Playa NFTs</Button>
      <br />
      <Button onClick={() => showModal('connectWallet')}>Open Modal Connect wallet</Button>
      <br />

      <UpdateCover open={modals.updateCover} onClose={handleCancel} />
      <PlayaNFTs
        open={modals.playaNFTs}
        onClose={handleCancel}
        isLoadingImages
        data={data}
        fetchData={fetchData}
      />
      <ConnectWallet open={modals.connectWallet} onClose={handleCancel} />
    </div>
  )
}

export default TestModal
