import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { isDevEnvironment } from '@constants/index'

import { deepCloneWithKeys } from '@utils/deepClone'

import nsMoji from './locales/en/nsMoji.json'
import nsAuth from './locales/en/nsAuth.json'
import nsSettings from './locales/en/nsSettings.json'
import nsUserProfile from './locales/en/nsUserProfile.json'
import nsPopupTitles from './locales/en/nsPopupTitles.json'
import nsCommon from './locales/en/nsCommon.json'
import nsMessages from './locales/en/nsMessages.json'

const nsMojiKeys = deepCloneWithKeys<typeof nsMoji>(nsMoji, 'nsMoji')
const nsAuthKeys = deepCloneWithKeys<typeof nsAuth>(nsAuth, 'nsAuth')
const nsSettingsKeys = deepCloneWithKeys<typeof nsSettings>(nsSettings, 'nsSettings')
const nsUserProfileKeys = deepCloneWithKeys<typeof nsUserProfile>(nsUserProfile, 'nsUserProfile')
const nsPopupTitlesKeys = deepCloneWithKeys<typeof nsPopupTitles>(nsPopupTitles, 'nsPopupTitles')
const nsCommonKeys = deepCloneWithKeys<typeof nsCommon>(nsCommon, 'nsCommon')
const nsMessagesKeys = deepCloneWithKeys<typeof nsMessages>(nsMessages, 'nsMessages')

const getResources = (isWithKey?: boolean) => ({
  en: {
    nsMoji: isWithKey ? nsMojiKeys : nsMoji,
    nsAuth: isWithKey ? nsAuthKeys : nsAuth,
    nsSettings: isWithKey ? nsSettingsKeys : nsSettings,
    nsUserProfile: isWithKey ? nsUserProfileKeys : nsUserProfile,
    nsPopupTitles: isWithKey ? nsPopupTitlesKeys : nsPopupTitles,
    nsCommon: isWithKey ? nsCommonKeys : nsCommon,
    nsMessages: isWithKey ? nsMessagesKeys : nsMessages,
  },
})

export const resources = getResources()

export const i18nextInit = (isWithKey) => {
  const resource = getResources(isWithKey)

  i18next.use(initReactI18next).init({
    lng: 'en',
    debug: isDevEnvironment,
    resources: resource,
  })
}

i18nextInit(false)
