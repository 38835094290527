/* eslint-disable import/no-cycle */
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'

import { isDevOrDevEnvironment } from '@constants'
import { sentryErrorHandler } from '@utils/errors'
import rootReducer from './rootReducer'

export const sagaMiddleware = createSagaMiddleware({
  onError: sentryErrorHandler,
})
export const rangerMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, rangerMiddleware, thunk]

const enhancer = isDevOrDevEnvironment
  ? window.REDUX_DEVTOOLS_EXTENSION || window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? compose(
        applyMiddleware(...middlewares),
        (window.REDUX_DEVTOOLS_EXTENSION && window.REDUX_DEVTOOLS_EXTENSION()) ||
          (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()),
      )
    : compose(applyMiddleware(...middlewares))
  : compose(applyMiddleware(...middlewares))

export const store = createStore(rootReducer, enhancer)
export const persistor = persistStore(store)
