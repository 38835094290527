import { TFunction } from 'i18next'
import path from '@routing/path'
import { URLS } from '@constants/urls'
import { AVAILABLE_FUNCTIONALITY } from '@constants/settings'

const getMenuItems = (t: TFunction<'nsCommon'>) => [
  {
    id: 1,
    label: t('headerMenu.userProfile'),
    status: 'default',
    to: path.withAuth.profile.purchased,
  },
  {
    id: 2,
    label: t('headerMenu.settings'),
    status: 'default',
    to: path.withAuth.profile.settings,
  },
  {
    id: 3,
    label: t('headerMenu.help'),
    status: 'default',
    to: URLS.HELP,
    isRouter: false,
  },
]

export default getMenuItems
