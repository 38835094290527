import React, { Suspense, useEffect, useLayoutEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './i18n/config'

import PrivateRoute from '@routing/PrivateRoute'
import { isDevOrDevEnvironment } from '@constants'
import { configSelectors, selectRanger } from '@core/selectors'
import { fetchProfile, fetchProfileFee } from '@core/profile/actions'
import useEffectOnce from '@hooks/useEffectOnce'
import { rangerConnectFetch, rangerDisconnectFetch } from '@core/icx/redux/actions/ranger'
import { getCurrencies } from '@core/icx/redux/actions/currency'
import { fetchCurrencies } from '@core/common/actions'
import TestScreen from '@containers/Test'
import Header from '@components/UiKit/Header'
import Footer from '@components/UiKit/Footer'
import AuthPopup from '@containers/AuthPopup'
import { useObserveNewWalletConnection } from '@hooks/useObserveNewWalletConnection'
import { AVAILABLE_FUNCTIONALITY } from '@constants/settings'
import WalletAssets from '@components/Profile/WalletAssets'
import ProfileLayoutV2 from '@components/Profile/Layout'
import TransactionsV2 from '@containers/Profile/Transactions'
import PurchasedV2 from '@containers/Profile/Purchased'
import MintedV2 from '@containers/Profile/Minted'
// eslint-disable-next-line import/no-unresolved
import { useDisconnect } from '@web3modal/ethers/react'
import routes from './routing/path'

import styles from './app.module.scss'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const buffer = require('buffer').Buffer

window.Buffer = window.Buffer || buffer

const SettingsLayout = React.lazy(() => import('./containers/Settings'))
const Placeholder = React.lazy(() => import('./containers/Placeholder'))
const ResetPassword = React.lazy(() => import('./containers/ResetPassword'))
const Auth = React.lazy(() => import('./containers/Auth'))

function App() {
  console.log('v1.014')
  const { isAuth, authLoaded } = useSelector(configSelectors.isAuthenticated)
  const { disconnect } = useDisconnect()

  useObserveNewWalletConnection()

  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const rangerState = useSelector((state) => selectRanger(state))

  useEffectOnce(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useLayoutEffect(() => {
    dispatch(fetchProfile())
    dispatch(getCurrencies())
    dispatch(fetchCurrencies())
  }, [])

  useEffect(() => {
    // NOTE: unsubscribe from old channels and subscribe to new ones
    if (rangerState?.subscriptions.length) {
      dispatch(rangerDisconnectFetch())
    }

    dispatch(rangerConnectFetch({ withAuth: isAuth }))

    if (!isAuth) {
      disconnect()
    }

    return () => {
      dispatch(rangerDisconnectFetch())
    }
  }, [isAuth])

  useEffect(() => {
    if (isAuth && authLoaded) {
      dispatch(fetchProfileFee())
    }
  }, [isAuth, authLoaded])

  if (!authLoaded) {
    return null
  }

  return (
    <div className={styles.main_container}>
      <main id="main" className={styles.main}>
        <Header />

        <div className={styles.page_container}>
          <Suspense fallback="">
            <Routes>
              <Route
                path={routes.withAuth.home}
                element={<Navigate to={routes.withAuth.profile.purchased} replace />}
              />
              <Route path={routes.withoutAuth.reset_password} element={<ResetPassword />} />
              <Route path={routes.withoutAuth.auth} element={<Auth />} />
              <Route path={routes.withoutAuth.authPopup} element={<AuthPopup />} />

              <Route
                element={
                  <PrivateRoute>
                    <ProfileLayoutV2 />
                  </PrivateRoute>
                }
              >
                <Route path={routes.withAuth.profile.transactions} element={<TransactionsV2 />} />
                <Route path={routes.withAuth.profile.purchased} element={<PurchasedV2 />} />
                <Route path={routes.withAuth.profile.walletNFTAssets} element={<WalletAssets />} />

                {AVAILABLE_FUNCTIONALITY.profileWonka && (
                  <Route path={routes.withAuth.profile.minted} element={<MintedV2 />} />
                )}
              </Route>

              <Route
                path={routes.withAuth.profile.settings}
                element={
                  <PrivateRoute>
                    <SettingsLayout />
                  </PrivateRoute>
                }
              />

              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path={routes.withoutAuth.about} element={<Placeholder />} />
              <Route path={routes.withoutAuth.help} element={<Placeholder />} />
              <Route path={routes.withoutAuth.account} element={<Placeholder />} />
              <Route path={routes.withoutAuth.terms} element={<Placeholder />} />
              <Route path={routes.withoutAuth.privacy} element={<Placeholder />} />
              <Route path={routes.withoutAuth.blue} element={<Placeholder />} />
              <Route path={routes.withoutAuth.search} element={<Placeholder />} />
              <Route path={routes.withoutAuth.comingq4} element={<Placeholder />} />

              {isDevOrDevEnvironment && <Route path={routes.test} element={<TestScreen />} />}
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </main>
    </div>
  )
}

export default App
