import {
  getBonusTransactionColumns,
  getCryptoTXTransactionColumns,
  getNFETransactionColumns,
  getPurchaseTransactionColumns,
  getQuoteTransactionColumns,
  getRecentTransactionColumns,
  getWithdrawsTransactionColumns,
  getWonkabotsTransactionColumns,
} from './Transactions.settings'
import { ITransactionTypes } from './types'

export const getMappedColumns = ({ currencyPrecision, currencies, columnsNames }) => ({
  [ITransactionTypes.recentActivities]: getRecentTransactionColumns(
    currencyPrecision,
    currencies,
    columnsNames,
  ),
  [ITransactionTypes.cryptoTX]: getCryptoTXTransactionColumns(currencyPrecision, columnsNames),
  [ITransactionTypes.quote]: getQuoteTransactionColumns(currencyPrecision, columnsNames),
  [ITransactionTypes.withdraw]: getWithdrawsTransactionColumns(
    currencyPrecision,
    currencies,
    columnsNames,
  ),
  [ITransactionTypes.purchase]: getPurchaseTransactionColumns(columnsNames),
  [ITransactionTypes.bonus]: getBonusTransactionColumns(columnsNames),
  [ITransactionTypes.nfe]: getNFETransactionColumns(currencyPrecision, currencies, columnsNames),
  [ITransactionTypes.wonkabots]: getWonkabotsTransactionColumns(currencies, columnsNames),
})
