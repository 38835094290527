/* eslint-disable */
import {
  FETCH_USER,
  SUCCESS_USER,
  FAIL_USER,
  RESET_USER,
  VERIFY_USER,
  FETCH_ICX_PROGRESS,
  SAVE_ICX_PROGRESS,
  GET_CIRCULATING_SUPPLY,
  SAVE_CIRCULATING_SUPPLY,
  GET_USA_DISCLAIMER,
  SUCCESS_USA_DISCLAIMER,
  FAIL_USA_DISCLAIMER,
  POST_COUNTRY,
  SET_COUNTRY,
  FETCH_USER_FEE,
  SET_USER_FEE,
  SEND_PASSWORD_OTP,
  SUCCESS_SEND_PASSWORD_OTP,
  FAIL_SEND_PASSWORD_OTP,
  SET_NEW_PASSWORD,
  SUCCESS_SET_NEW_PASSWORD,
  FAIL_SET_NEW_PASSWORD
} from '../constants';

export const fetchUser = () => {
  return { type: FETCH_USER };
};

export const fetchUserFee = () => {
  return { type: FETCH_USER_FEE };
};

export const setUserFee = payload => {
  return { type: SET_USER_FEE, payload };
};

export const successUser = payload => {
  return { type: SUCCESS_USER, payload };
};

export const failUser = () => {
  return { type: FAIL_USER };
};

export const resetUser = () => {
  return { type: RESET_USER };
};

export const verifyUser = payload => {
  return { type: VERIFY_USER, payload };
};

export const getIcxProgress = () => {
  return { type: FETCH_ICX_PROGRESS };
};

export const saveIcxProgress = payload => {
  return { type: SAVE_ICX_PROGRESS, payload };
};

export const getCirculatingSupply = () => {
  return { type: GET_CIRCULATING_SUPPLY };
};

export const saveCirculatingSupply = payload => {
  return { type: SAVE_CIRCULATING_SUPPLY, payload };
};

export const getUsaDisclaimer = () => {
  return { type: GET_USA_DISCLAIMER };
};
export const postCountry = payload => {
  return { type: POST_COUNTRY, payload };
};
export const setCountry = payload => {
  return { type: SET_COUNTRY, payload };
};

export const successUsaDisclaimer = payload => {
  return { type: SUCCESS_USA_DISCLAIMER, payload };
};

export const failedUsaDisclaimer = () => {
  return { type: FAIL_USA_DISCLAIMER };
};

export const getUserRequested = payload => {
  return {
    type: 'GET_USER_REQUESTED',
    payload
  };
};

export const getUserRequest = payload => {
  return {
    type: 'GET_USER_REQUEST',
    payload
  };
};

export const sendPasswordOtp = (payload, callback) => {
  return { type: SEND_PASSWORD_OTP, payload, callback };
};

export const successSendPasswordOtp = payload => {
  return { type: SUCCESS_SEND_PASSWORD_OTP, payload };
};

export const failSendPasswordOtp = () => {
  return { type: FAIL_SEND_PASSWORD_OTP };
};

export const successSetNewPassword = payload => {
  return { type: SUCCESS_SET_NEW_PASSWORD, payload };
};

export const failSetNewPassword = () => {
  return { type: FAIL_SET_NEW_PASSWORD };
};
