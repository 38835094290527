import { call, put, delay, takeLatest } from 'redux-saga/effects'
import * as actions from '../actions'
import * as types from '../constants'
import { API } from '../../api'
import { showAlert } from '../actions/alert'

const addressDepositConfig = {
  apiVersion: 'peatio',
}

export function* fetchAddress(coin) {
  yield put(actions.getUserRequested(true))
  try {
    // keep looking for address untill found for atleast 10 times
    let i
    let address

    for (i = 1; i <= 10; i += 1) {
      address = yield call(
        API.get(addressDepositConfig),
        `/account/deposit_address/${coin.payload}`,
      )
      if (address !== undefined && address.address !== undefined && address.address !== null) {
        break
      } else {
        yield delay(2000)
      }
    }

    yield put(actions.getUserRequested(false))
    yield put(actions.successAddress(address))
  } catch (e) {
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    yield put(actions.getUserRequested(false))
    yield put(actions.failedAddress())
  }
}

export function* fetchAddressSaga() {
  yield takeLatest(types.GET_ADDRESS, fetchAddress)
}
