import { useCallback, useRef, useState } from 'react'
import queryString from 'query-string'
import { API } from '@core/api'
import { TNullable } from 'types/utilityTypes'

import { errorsHandler } from '@core/helper'
import { ApiVersion, IApiConfig } from '@core/api/types'
import useEffectOnce from './useEffectOnce'

type TMethods = keyof typeof API

const cache = {
  get: {},
  post: {},
}

const defaultConfig: IApiConfig = {
  apiVersion: ApiVersion.peatio,
  withHeaders: true,
}

const setInCache = (url: string, config: IApiConfig, method: TMethods, data: unknown) => {
  const key = cache[method][config.apiVersion]
  if (!key) {
    cache[method][config.apiVersion] = {}
  }

  cache[method][config.apiVersion][url] = data
}

const getFromCache = (url: string, config: IApiConfig, method: TMethods) =>
  cache[method]?.[config.apiVersion]?.[url]

interface IProps {
  url: string
  config?: IApiConfig
  method?: TMethods
  params?: Record<string, unknown>
  body?: unknown
  trigger?: boolean
  deps?: unknown[]
  polling?: number
  cached?: boolean
  toastError?: boolean
}

interface ReturnType<T> {
  loading: boolean
  error: string
  headers: Record<string, string>
  data?: T
  reload: () => Promise<void>
}

const useQuery = <T = any>({
  url,
  config = defaultConfig,
  method = 'get',
  params = {},
  body = {},
  trigger = true,
  deps = [],
  polling = 0,
  cached = false,
  toastError = false,
}: IProps): ReturnType<T> => {
  const cacheData = cached && getFromCache(url, config, method)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [data, setData] = useState<T | undefined>(cached ? cacheData?.data : undefined)
  const [headers, setHeaders] = useState(cached ? cacheData?.headers : undefined)

  const pollingInterval = useRef<TNullable<NodeJS.Timeout | number>>(null)
  const prevUrl = useRef<string>('')

  const resetData = prevUrl.current !== undefined ? prevUrl.current !== url : !(cached && cacheData)

  const onGetDat = useCallback(async () => {
    if (!trigger) return
    let newUrl = url

    if (prevUrl.current !== undefined ? prevUrl.current !== newUrl : !(cached && cacheData)) {
      setData(undefined)
      setHeaders(undefined)
    }

    if (Object.values(params).length > 0) {
      newUrl = `${newUrl}?${queryString.stringify(params)}`
    }

    setLoading(true)
    setTimeout(async () => {
      try {
        const res = await API[method]<ReturnType<T>>(config)(newUrl, body)
        const resData = res.data
        setHeaders(res.headers)
        setData((resData ?? res) as T | undefined)

        if (cached) {
          setInCache(url, config, method, {
            data: resData || res,
            headers: res.headers,
          })
        }
      } catch (err) {
        if (err?.message && toastError) {
          errorsHandler(err?.message)
        }

        setError(err)
      } finally {
        setLoading(false)
      }
      prevUrl.current = url
    })
  }, [url, toastError, trigger, ...Object.values(params), ...deps])

  useEffectOnce(() => {
    if (!polling) {
      onGetDat()
    } else {
      onGetDat()
      pollingInterval.current = setInterval(() => onGetDat(), polling)
      onGetDat()
    }
    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current)
      }
    }
  }, [onGetDat])

  return {
    loading: resetData ? true : loading,
    error,
    headers,
    data: resetData ? undefined : data,
    reload: onGetDat,
  }
}

export default useQuery
