import React, { useState } from 'react'
import copy from 'copy-to-clipboard'
import IconButton from '@components/UiKit/ButtonNew/IconButton'
import { TSize } from '@components/UiKit/types'
import Typography from '@components/UiKit/Typography'
import styles from './share_links.module.scss'

const { Paragraph } = Typography

function ShareLinks() {
  const [copiedStatus, setCopiedStatus] = useState(false)
  const currentLocation = encodeURIComponent(window.location.href)

  const links = {
    x: `https://twitter.com/intent/tweet?url=${currentLocation}`,
    telegram: `https://t.me/share/url?url=${currentLocation}`,
    email: `mailto:?subject=Subject&body=${currentLocation}`,
  }

  const copyText = () => {
    copy(window.location.href)
    setCopiedStatus(true)
    setTimeout(() => setCopiedStatus(false), 1500)
  }

  return (
    <div className={styles.shareLinks}>
      <Paragraph size={2}>Share link to this page</Paragraph>

      <div className={styles.content}>
        <div className={styles.link}>
          <a href={links.x} target="_blank" rel="noreferrer" className={styles.navigate}>
            <IconButton
              className={styles.icon}
              icon="CustomIconShareX"
              size={TSize.spacious}
              variant="secondary"
            />
          </a>

          <Paragraph font="IBMPlexMono" size={3} className={styles.name}>
            X
          </Paragraph>
        </div>

        <div className={styles.link}>
          <a href={links.telegram} target="_blank" rel="noreferrer" className={styles.navigate}>
            <IconButton
              className={styles.icon}
              icon="CustomIconShareTG"
              size={TSize.spacious}
              variant="secondary"
            />
          </a>

          <Paragraph font="IBMPlexMono" size={3} className={styles.name}>
            Telegram
          </Paragraph>
        </div>

        <div className={styles.link}>
          <a href={links.email} target="_blank" rel="noreferrer" className={styles.navigate}>
            <IconButton
              className={styles.icon}
              icon="CustomIconShareEmail"
              size={TSize.spacious}
              variant="secondary"
            />
          </a>

          <Paragraph font="IBMPlexMono" size={3} className={styles.name}>
            E-mail
          </Paragraph>
        </div>

        <div className={styles.link}>
          <IconButton
            className={styles.icon}
            icon={copiedStatus ? 'CustomIconShareCopied' : 'CustomIconShareCopy'}
            size={TSize.spacious}
            variant="secondary"
            onClick={copiedStatus ? undefined : copyText}
          />

          <Paragraph font="IBMPlexMono" size={3} className={styles.name}>
            {copiedStatus ? 'Copied' : 'Copy'}
          </Paragraph>
        </div>
      </div>
    </div>
  )
}

export default ShareLinks
