import { all, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { successMessages } from '@constants'
import { API } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'
import { errorsHandler } from '@core/helper'
import { ApiVersion } from '@core/api/types'
import { eventTrack, Web3WalletEvents } from '@utils/analyticsTracker'
import queryString from 'query-string'
import i18next from 'i18next'
import types from '../types'
import {
  fetchDeleteAccountFailure,
  fetchDeleteAccountSuccess,
  fetchExternalNFTListSuccess,
  fetchGetNotificationsFailure,
  fetchGetNotificationsSuccess,
  fetchProfile,
  fetchProfileFailure,
  fetchProfileFeeFailure,
  fetchProfileFeeSuccess,
  fetchProfileSuccess,
  fetchSendOTPFailure,
  fetchSendOTPSuccess,
  fetchSetNotificationsFailure,
  fetchSetNotificationsSuccess,
  modifyProfileFailure,
  modifyProfileSuccess,
  setCountry,
  setProfileLoading,
  setUploadLoader,
  successUser,
} from './actions'

function* setProfileDataWorker({ payload }) {
  yield put({ type: types.SET_PROFILE, payload })
}

const userConfig = {
  apiVersion: ApiVersion.barong,
}

const peatioConfig = {
  apiVersion: ApiVersion.peatio,
}

function* fetchSendWalletSaga({ payload }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.wallet, payload)
    yield call(
      eventTrack,
      Web3WalletEvents.addressVerified({ address: payload.wallet_details.address }),
    )
    yield call([toast, 'success'], i18next.t('walletConnected', { ns: 'nsMessages' }))
    yield put(fetchProfile())
  } catch (err) {
    if (err?.message?.includes('resource.wallet.already_exist')) {
      errorsHandler(err)
      payload.disconnectFn()
    }
  }
}

function* fetchProfileSaga() {
  try {
    const user = yield call(
      API.get(userConfig),
      `${API_URLS.resource.users.me}?date=${new Date().getTime()}`,
    )
    yield put(fetchProfileSuccess(user))
  } catch (err) {
    yield put(fetchProfileFailure())
  }
}

function* fetchProfileFeeSaga() {
  try {
    const res = yield call(API.get(peatioConfig), API_URLS.account.members.me)

    yield put(fetchProfileFeeSuccess(res))
  } catch (err) {
    yield put(fetchProfileFeeFailure())
  }
}

function* profilePhotoUploadSaga({ payload, cb }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.media, payload)
    yield put(fetchProfile())
    toast.success('Photo uploaded successfully.')
  } catch (err) {
    errorsHandler(err)
  } finally {
    cb && cb()
  }
}

function* profileDeletePhotoSaga({ cb }) {
  try {
    yield call(API.delete(userConfig), API_URLS.resource.users.removeMedia)
    yield put(fetchProfile())
    yield put(setUploadLoader(false))

    toast.success('Photo removed successfully.')
  } catch (err) {
    errorsHandler(err)
  } finally {
    cb && cb()
  }
}

function* modifyUser({ payload, cb }) {
  try {
    yield put(setProfileLoading(true))
    const updatedData = yield call(API.post(userConfig), 'resource/users/update', payload)
    yield put(fetchProfileSuccess(updatedData))
    yield put(modifyProfileSuccess())
    toast.success(successMessages.other.profile_updated)

    cb?.onSuccess()
  } catch (err) {
    yield put(modifyProfileFailure())
    errorsHandler(err)
  } finally {
    yield put(setProfileLoading(false))
  }
}

export function* sendCountry(action) {
  try {
    const usaDisclaimer = yield call(API.post(userConfig), 'resource/users/country', action.payload)
    yield put(setCountry(usaDisclaimer))
    yield put(fetchProfile())
  } catch (err) {
    errorsHandler(err)
  }
}

export function* fetchUsaDisclaimer(action) {
  try {
    const usaDisclaimer = yield call(
      API.post(userConfig),
      'resource/users/agreement',
      action.payload,
    )
    toast.success('Agreement accepted')
    yield put(successUser(usaDisclaimer))
    yield put(fetchProfile())
  } catch (err) {
    errorsHandler(err)
  }
}

function* logoutSaga() {
  try {
    yield call(API.delete(userConfig), API_URLS.identity.sessions.index)
    yield put(fetchProfile())
  } catch (err) {
    errorsHandler(err)
  }
}

function* profileSetEmojiAsPhotoSaga({ payload, cb }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.mediaEmoji, payload)
    yield put(fetchProfile())

    toast.success('Emoji uploaded successfully.')
    cb?.()
  } catch (err) {
    errorsHandler(err)
  }
}

function* profileSetExternalNFTAsPhotoSaga({ payload }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.mediaExternalNft, payload)
    yield put(fetchProfile())

    toast.success('Token media uploaded successfully.')
  } catch (err) {
    errorsHandler(err)
  }
}

function* profileSetWonkaAsPhotoSaga({ payload, cb }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.mediaWonka, payload)
    yield put(fetchProfile())
    yield put(setUploadLoader(false))

    toast.success('Wonka uploaded successfully.')
    cb?.()
  } catch (err) {
    errorsHandler(err)
  }
}

function* profileUpdateEmailSaga({ payload, cb }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.updateEmail, payload)
    yield put(fetchProfile())
    cb?.onSuccess?.()
    toast.success(successMessages.resource.otp.sent)
  } catch (err) {
    errorsHandler(err)
  }
}

function* profileVerifyEmailSaga({ payload, cb }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.verifyEmail, payload)
    yield put(fetchProfile())
    cb?.onSuccess?.()
    toast.success(successMessages.other.email_updated)
  } catch (err) {
    errorsHandler(err)
  }
}

function* profileUpdatePhoneNumberSaga({ payload, cb }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.updatePhoneNumber, payload)
    yield put(fetchProfile())
    cb?.onSuccess?.()
  } catch (err) {
    errorsHandler(err)
  }
}

function* profileVerifyPhoneNumberSaga({ payload, cb }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.users.verifyPhoneNumber, payload)
    yield put(fetchProfile())
    toast.success(successMessages.other.phone_updated)
    cb?.onSuccess?.()
  } catch (err) {
    errorsHandler(err)
  }
}

function* sendOTP(action) {
  try {
    yield call(API.post(userConfig), 'resource/user/send-otp')
    yield put(fetchSendOTPSuccess())
    action?.cb?.onSuccess()
    toast.success(successMessages.resource.otp.sent)
  } catch (err) {
    yield put(fetchSendOTPFailure())
    errorsHandler(err)
  }
}

function* deleteAccount(action) {
  try {
    yield call(API.delete(userConfig), 'resource/user/delete-account', action.payload)
    yield put(fetchDeleteAccountSuccess())
    action?.cb?.onSuccess?.()
  } catch (err) {
    yield put(fetchDeleteAccountFailure())
    if (err?.message?.[0] === 'resource.user.cant_be_deleted') {
      action?.cb?.onError?.()
    } else {
      errorsHandler(err)
    }
  }
}

function* getNotifications() {
  try {
    const data = yield call(API.get(userConfig), API_URLS.resource.email.notifications)
    yield put(fetchGetNotificationsSuccess(data))
  } catch (err) {
    yield put(fetchGetNotificationsFailure())
  }
}

function* setNotifications({ payload }) {
  try {
    yield call(API.post(userConfig), API_URLS.resource.email.notifications, payload)
    yield put(fetchSetNotificationsSuccess(payload))
    toast.success(successMessages.other.notifications_updated)
  } catch (err) {
    yield put(fetchSetNotificationsFailure())
  }
}

function* changePassword({ payload, cb }) {
  try {
    yield call(API.put(userConfig), API_URLS.resource.users.password, payload)
    cb?.onSuccess()
    toast.success(successMessages.other.password_updated)
  } catch (err) {
    errorsHandler(err)
  }
}

function* fetchExternalNFTs({ payload = {} }) {
  try {
    const url = queryString.stringifyUrl({
      url: API_URLS.resource.users.nftDetails,
      query: payload,
    })
    const data = yield call(API.get(userConfig), url)
    yield put(fetchExternalNFTListSuccess(data))
  } catch (err) {
    errorsHandler(err)
  }
}

export function* rootSagaProfile() {
  yield all([takeLatest(types.SET_WALLET_REQUEST, fetchSendWalletSaga)])
  yield all([takeLatest(types.FETCH_PROFILE_REQUEST, fetchProfileSaga)])
  yield all([takeLatest(types.FETCH_PROFILE_FEE_REQUEST, fetchProfileFeeSaga)])
  yield all([takeLatest(types.ADD_PROFILE, setProfileDataWorker)])
  yield all([takeLatest(types.UPDATE_PROFILE, modifyUser)])
  yield all([takeLatest(types.FETCH_UPDATE_PROFILE_EMAIL_REQUEST, profileUpdateEmailSaga)])
  yield all([takeLatest(types.FETCH_VERIFY_PROFILE_EMAIL_REQUEST, profileVerifyEmailSaga)])
  yield all([
    takeLatest(types.FETCH_UPDATE_PROFILE_PHONE_NUMBER_REQUEST, profileUpdatePhoneNumberSaga),
  ])
  yield all([
    takeLatest(types.FETCH_VERIFY_PROFILE_PHONE_NUMBER_REQUEST, profileVerifyPhoneNumberSaga),
  ])
  yield all([takeLatest(types.POST_COUNTRY, sendCountry)])
  yield all([takeLatest(types.GET_USA_DISCLAIMER, fetchUsaDisclaimer)])
  yield all([takeLatest(types.FETCH_UPLOAD_PROFILE_PICTURE_REQUEST, profilePhotoUploadSaga)])
  yield all([takeLatest(types.FETCH_DELETE_PROFILE_PICTURE_REQUEST, profileDeletePhotoSaga)])
  yield all([takeLatest(types.SET_PROFILE_EMOJI_PICTURE, profileSetEmojiAsPhotoSaga)])
  yield all([takeLatest(types.SET_PROFILE_WONKA_PICTURE, profileSetWonkaAsPhotoSaga)])
  yield all([takeLatest(types.SET_PROFILE_EXTERNAL_NFT__PICTURE, profileSetExternalNFTAsPhotoSaga)])
  yield all([takeLatest(types.LOGOUT_REQUEST, logoutSaga)])
  yield all([takeLatest(types.FETCH_SEND_OTP_REQUEST, sendOTP)])
  yield all([takeLatest(types.FETCH_DELETE_ACCOUNT_REQUEST, deleteAccount)])
  yield all([takeLatest(types.FETCH_GET_NOTIFICATIONS_REQUEST, getNotifications)])
  yield all([takeLatest(types.FETCH_SET_NOTIFICATIONS_REQUEST, setNotifications)])
  yield all([takeLatest(types.CHANGE_PASSWORD_REQUEST, changePassword)])

  yield all([takeLatest(types.FETCH_EXTERNAL_NFT_LIST, fetchExternalNFTs)])
}
