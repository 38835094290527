import { Method } from 'axios/index'

export enum ApiVersion {
  barong = 'barong',
  applogic = 'applogic',
  peatio = 'peatio',
  alias = 'alias',
}

export interface IRequestConfig {
  body?: unknown
  method?: Method | string
  url?: string
}

export interface IApiConfig {
  apiVersion: ApiVersion
  withHeaders?: boolean
}

export interface FormattedError {
  code: number
  message: string[]
}

export enum Platform {
  zen = 'zen',
  app = 'app',
}
