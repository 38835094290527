import {
  GET_MY_RECENT_TRADES,
  SUCCESS_MY_RECENT_TRADES,
  FAIL_MY_RECENT_TRADES,
  PUSH_MY_RECENT_TRADES,
} from '../constants'

export const getMyRecentTrades = (payload) => ({ type: GET_MY_RECENT_TRADES, payload })

export const successMyRecentTrades = (payload) => ({ type: SUCCESS_MY_RECENT_TRADES, payload })

export const failMyRecentTrades = (payload) => ({ type: FAIL_MY_RECENT_TRADES, payload })

export const pushMyRecentTrades = (payload) => ({ type: PUSH_MY_RECENT_TRADES, payload })
