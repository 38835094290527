export const defaultConfig = {
  baseUrl: '',
  api: {
    authUrl: '',
    tradeUrl: '',
    applogicUrl: '',
    rangerUrl: '',
    aliasUrl: 'https://alias.peerclub.com/api',
  },

  minutesUntilAutoLogout: '5',
  rangerReconnectPeriod: '1',
  withCredentials: true,
  tablePageLimit: 50,
  captcha: {
    captchaType: 'none',
    siteKey: '',
  },

  refid: '',
  defaultStorageLimit: 50,
  gaTrackerKey: '',
  infuraUrl: 'https://rpc.testnet.moonbeam.network',
  tuxUsername: 'demo@mailinator.com',
  tuxPassword: 'Test@260!123',
  removePeer: true,
  chainid: 5,
  vi_public_key: '',
  defaultCurrency: 'vne',
  defaultCurrencyName: 'Vine',
}

export const Frontend = {
  config: defaultConfig,
}

const isProduction =
  process.env?.REACT_APP_STAGE === 'production' || process.env?.REACT_APP_STAGE === 'uat'

window.env = window.env || defaultConfig
Frontend.config = { ...window.env }

Frontend.config.captcha = Frontend.config.captcha || defaultConfig.captcha

export const baseUrl = () => Frontend.config.baseUrl
export const authUrl = () =>
  isProduction ? Frontend.config.prodApi.authUrl : Frontend.config.api.authUrl
export const tradeUrl = () =>
  isProduction ? Frontend.config.prodApi.tradeUrl : Frontend.config.api.tradeUrl
export const applogicUrl = () =>
  isProduction ? Frontend.config.prodApi.applogicUrl : Frontend.config.api.applogicUrl
export const rangerUrl = () =>
  isProduction ? Frontend.config.prodApi.rangerUrl : Frontend.config.api.rangerUrl
export const aliasUrl = () => Frontend.config.api.aliasUrl
export const minutesUntilAutoLogout = () => Frontend.config.minutesUntilAutoLogout || '5'
export const withCredentials = () => Frontend.config.withCredentials
export const siteKey = () => Frontend.config.captcha.siteKey
export const captchaType = () => Frontend.config.captcha.captchaType
export const refid = () => Frontend.config.refid
export const tablePageLimit = () => Frontend.config.tablePageLimit
export const defaultStorageLimit = () => Frontend.config.defaultStorageLimit
export const title = () => Frontend.config.title
export const defaultMarket = () => Frontend.config.defaultMarket
export const adminDepositAddress = () => Frontend.config.adminDepositAddress
export const gaTrackerKey = () => Frontend.config.gaTrackerKey
export const infuraUrl = () => Frontend.config.infuraUrl
export const tuxUsername = () => Frontend.config.tuxUsername
export const tuxPassword = () => Frontend.config.tuxPassword
export const removePeer = () => Frontend.config.removePeer
export const chainid = () => Frontend.config.chainid
export const verifyInvestorRedirectUrl = () => Frontend.config.verify_investor_redirect_url
export const verifyInvestorPublicKey = () => Frontend.config.vi_public_key
export const defaultCurrency =
  Frontend.config.defaultCurrencyName || Frontend.config.defaultCurrency.toUpperCase()
