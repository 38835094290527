import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import Modal from '@components/UiKit/Modal'
import Typography from '@components/UiKit/Typography'
import IconButton from '@components/UiKit/ButtonNew/IconButton'
import styles from './modal.module.scss'

const { Heading } = Typography

export interface IEmptyNFTsDataTypeProps {
  open: boolean
  onClose: () => void
}

function EmptyNFTsData({ open, onClose }: Readonly<IEmptyNFTsDataTypeProps>) {
  const { t } = useTranslation('nsUserProfile')

  return (
    <Modal onClose={onClose} open={open} className={cn(styles.wrapper)}>
      <div className={styles.header}>
        <IconButton
          onClick={onClose}
          icon="CustomIconClose"
          className={styles.close_btn}
          variant="secondary"
        />
      </div>

      <div className={styles.content}>
        <Heading size={3} className={styles.title}>
          {t('notHaveAnyNfts')}
        </Heading>
      </div>
    </Modal>
  )
}

export default React.memo(EmptyNFTsData)
