import React from 'react'
import IconButton from '@components/UiKit/ButtonNew/IconButton'
import { TSize } from '@components/UiKit/types'

function TestCustomIconButtons() {
  return (
    <div>
      <div>
        <IconButton icon="CustomIconArrowLeft" size={TSize.small} variant="secondary" />
      </div>
      <br />
      <div>
        <IconButton icon="CustomIconClose" size={TSize.spacious} variant="secondary" />
      </div>
      <br />
    </div>
  )
}

export default TestCustomIconButtons
