import React, { forwardRef, memo, useState } from 'react'
import cn from 'classnames'
import isEqual from 'react-fast-compare'
import Eye from '@components/Icons/Eye'
import EyeClosed from '@components/Icons/EyeClosed'
import styles from './style.module.scss'

import { FormControl, FormError, FormHelperText, FormLabel } from '../FormComponents'

interface IProps {
  error?: boolean
  helperText?: string
  disabled?: boolean
  multiline?: boolean
  value?: string | number
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  placeholder?: string
  label?: string
  type?: string
  name?: string
  className?: string
  classNameLabel?: string
  classNameInput?: string
  fullWidth?: boolean
  startAdornment?: string | JSX.Element
  endAdornment?: string | JSX.Element
  additionContent?: string | JSX.Element
  showPasswordToggle?: boolean
  refElement?: React.Ref<HTMLDivElement>
  noGutters?: boolean
  rows?: number
  resize?: boolean
  counter?: boolean
  maxLength?: number
  autoFocus?: boolean
}

const Input = forwardRef(
  (
    {
      error,
      helperText,
      disabled,
      multiline,
      value,
      onChange,
      onBlur,
      onFocus,
      label,
      type = 'text',
      name = '',
      className,
      classNameLabel,
      classNameInput,
      fullWidth,
      startAdornment,
      endAdornment,
      additionContent,
      showPasswordToggle,
      refElement,
      placeholder,
      noGutters,
      counter,
      maxLength,
      rows = 4,
      resize = true,
      autoFocus,
    }: IProps,
    refInput: any,
  ) => {
    const [focused, setFocused] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword)
    }

    const handleMouseDown = () => {
      if (type === 'password') {
        setShowPassword(true)
      }
    }

    const handleMouseUp = () => {
      if (type === 'password') {
        setShowPassword(false)
      }
    }

    const inputProps = {
      name,
      ref: refInput,
      disabled,
      autoFocus,
      placeholder,
      className: cn(classNameInput, styles.input, {
        [styles.input_textarea]: multiline,
        [styles.input__disabled]: disabled,
        [styles.input__resize]: multiline && resize,
      }),
      onChange,
      onBlur: (e) => {
        setFocused(false)
        onBlur?.(e)
      },
      onFocus: (e) => {
        setFocused(true)
        onFocus?.(e)
      },
      maxLength,
    }

    return (
      <FormControl
        noGutters={noGutters}
        ref={refElement}
        fullWidth={fullWidth}
        className={className}
      >
        {label && (
          <FormLabel disabled={disabled} className={classNameLabel}>
            {label}
          </FormLabel>
        )}
        <div
          className={cn(styles.wrapInput, {
            [styles.wrapInput__focused]: focused,
            [styles.wrapInput__error]: error,
            [styles.wrapInput__disabled]: disabled,
          })}
        >
          {startAdornment}
          {(multiline && (
            <textarea rows={rows} {...inputProps}>
              {value}
            </textarea>
          )) || (
            <input
              type={type === 'password' && showPassword ? 'text' : type}
              value={value}
              {...inputProps}
            />
          )}

          {endAdornment}

          {showPasswordToggle && type === 'password' && (
            <div
              className={styles.togglePassword}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
              onMouseUp={handleMouseUp}
              onTouchEnd={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              {showPassword ? <Eye /> : <EyeClosed />}
            </div>
          )}
        </div>
        <FormError disabled={disabled} error={error}>
          {helperText}
        </FormError>

        {counter && (
          <FormHelperText show={counter && !error}>
            {`${String(value).length}${maxLength ? `/${maxLength}` : ''}`}
          </FormHelperText>
        )}

        {additionContent}
      </FormControl>
    )
  },
)

export default memo(Input, isEqual)
