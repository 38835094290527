/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M183.211 31H212.652L148.332 104.705L224 205H164.753L118.348 144.171L65.2505 205H35.7914L104.589 126.165L32 31H92.7515L134.697 86.5997L183.211 31ZM172.878 187.332H189.192L83.8871 47.7397H66.3808L172.878 187.332Z"
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M183.211 31H212.652L148.332 104.705L224 205H164.753L118.348 144.171L65.2505 205H35.7914L104.589 126.165L32 31H92.7515L134.697 86.5997L183.211 31ZM172.878 187.332H189.192L83.8871 47.7397H66.3808L172.878 187.332Z"
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M183.211 31H212.652L148.332 104.705L224 205H164.753L118.348 144.171L65.2505 205H35.7914L104.589 126.165L32 31H92.7515L134.697 86.5997L183.211 31ZM172.878 187.332H189.192L83.8871 47.7397H66.3808L172.878 187.332Z"
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M183.211 31H212.652L148.332 104.705L224 205H164.753L118.348 144.171L65.2505 205H35.7914L104.589 126.165L32 31H92.7515L134.697 86.5997L183.211 31ZM172.878 187.332H189.192L83.8871 47.7397H66.3808L172.878 187.332Z"
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M183.211 31H212.652L148.332 104.705L224 205H164.753L118.348 144.171L65.2505 205H35.7914L104.589 126.165L32 31H92.7515L134.697 86.5997L183.211 31ZM172.878 187.332H189.192L83.8871 47.7397H66.3808L172.878 187.332Z"
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M183.211 31H212.652L148.332 104.705L224 205H164.753L118.348 144.171L65.2505 205H35.7914L104.589 126.165L32 31H92.7515L134.697 86.5997L183.211 31ZM172.878 187.332H189.192L83.8871 47.7397H66.3808L172.878 187.332Z"
    />,
  ],
])

const CustomIconShareX: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconShareX.displayName = 'CustomIconShareX'

export default CustomIconShareX
