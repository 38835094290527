import storage from 'redux-persist/lib/storage'
import encryptor from '@core/encryptor'
import { persistReducer } from 'redux-persist'

import types from '../types'

const initialState = {
  loading: false,
  userFields: {
    email: '',
  },
  userName: {
    loading: false,
    isAvailable: false,
  },
  routeBeforeAuth: null,
  captcha: '',
  resetCaptcha: () => {},
}

const authReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.FETCH_CHECK_USERNAME_REQUSET:
      return {
        ...state,
        userName: {
          ...initialState.userName,
          loading: true,
        },
      }

    case types.FETCH_CHECK_USERNAME_FAILURE:
      return {
        ...state,
        userName: {
          ...initialState.userName,
        },
      }

    case types.FETCH_CHECK_USERNAME_SUCCESS:
      return {
        ...state,
        userName: {
          isAvailable: true,
          loading: false,
        },
      }

    case types.SET_AUTH_LOADING:
      return {
        ...state,
        loading: payload,
      }

    case types.SET_AUTH_FIELDS:
      return {
        ...state,
        userFields: {
          ...state.userFields,
          ...payload,
        },
      }

    case types.SET_ROUTE_BEFORE_AUTH:
      return {
        ...state,
        routeBeforeAuth: payload,
      }

    case types.SET_CAPTCHA:
      return {
        ...state,
        captcha: payload,
      }

    case types.SET_RESET_CAPTCHA_CB:
      return {
        ...state,
        resetCaptcha: payload,
      }

    default:
      return state
  }
}

const authConfig = {
  key: 'peer_auth',
  storage,
  transforms: [encryptor],
  blacklist: ['loading'],
}

export default persistReducer(authConfig, authReducer)
