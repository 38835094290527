import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import cn from 'classnames'
import OtpInput, { InputProps } from 'react-otp-input'
import styles from './otp.module.scss'

export interface IOtpRef {
  setOtp: Dispatch<SetStateAction<string>>
}

interface IOTPInputProps {
  length: number
  onChange?: (otp: string) => void
  onComplete?: (otp: string) => void
  classNameWrapper?: string
  classNameInput?: string
  otpRef?: MutableRefObject<IOtpRef | null>
  shouldAutoFocus?: boolean
}

function OTPInput({
  length,
  onChange,
  onComplete,
  classNameWrapper,
  classNameInput,
  otpRef,
  shouldAutoFocus,
}: Readonly<IOTPInputProps>): React.ReactElement {
  const [otp, setOtp] = useState<string>('')

  useEffect(() => {
    if (otpRef) {
      otpRef.current = { setOtp }
    }
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange(otp)
    }

    if (otp.length === length && onComplete) {
      onComplete(otp)
    }
  }, [otp, onChange])

  const handleChange = useCallback(
    (newOtp: string) => {
      // NOTE: to prevent changing last number when otp is filled
      setOtp((oldOtp) => (oldOtp.length === length && newOtp.length === length ? oldOtp : newOtp))
    },
    [setOtp],
  )

  const handlePaste: React.ClipboardEventHandler = (event) => {
    const data = event.clipboardData
      .getData('text')
      ?.replace(/\s/g, '')
      ?.replace(/\D/g, '')
      ?.slice(0, length)
    setOtp(data ?? '')
  }

  const handleChangeInput =
    (onChangeInput: InputProps['onChange']) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      if (!Number.isNaN(Number(value))) {
        onChangeInput(e)
      }
    }

  return (
    <OtpInput
      shouldAutoFocus={shouldAutoFocus}
      value={otp}
      onChange={handleChange}
      onPaste={handlePaste}
      numInputs={length}
      containerStyle={cn(styles.otpInput, classNameWrapper)}
      renderInput={(props: InputProps) => (
        <input
          {...props}
          onChange={handleChangeInput(props.onChange)}
          className={cn(styles.otpInput__field, classNameInput)}
        />
      )}
    />
  )
}

export default React.memo(OTPInput)
