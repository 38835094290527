import axios from 'axios'

import { sentryErrorHandler } from '@utils/errors'

import { getCsrfToken } from '../helpers'

import { applogicUrl, authUrl, tradeUrl, withCredentials, aliasUrl } from './config'

const getAPI = () => ({
  barong: authUrl(),
  applogic: `${applogicUrl()}`,
  peatio: `${tradeUrl()}`,
  alias: `${aliasUrl()}`,
})

const buildRequest = (request, configData) => {
  const { body, method, url } = request
  const { apiVersion } = configData
  const api = getAPI()
  const contentType = body instanceof FormData ? 'multipart/form-data' : 'application/json'

  const defaultHeaders = {
    'content-type': contentType,
    'X-CSRF-Token': getCsrfToken(),
  }
  const apiUrl = api[apiVersion]

  const requestConfig = {
    baseURL: apiUrl,
    data: body,
    headers: defaultHeaders,
    method,
    url,
    withCredentials: withCredentials(),
  }

  return requestConfig
}

export const defaultResponse = {
  status: 500,
  data: {
    error: 'Server error',
  },
}

export const formatError = (responseError) => {
  const response = responseError?.response || defaultResponse
  const errors = response.data && (response.data.errors || [response.data.error])

  sentryErrorHandler(responseError)

  return {
    code: response.status,
    message: errors,
  }
}

export const makeRequest = async (request, configData) => {
  const requestConfig = buildRequest(request, configData)

  return new Promise((resolve, reject) => {
    const axiosRequest = axios(requestConfig)
    axiosRequest
      .then((response) => {
        if (configData.withHeaders) {
          resolve(response)
        } else {
          resolve(response.data)
        }
      })
      .catch((error) => {
        reject(formatError(error))
      })
  })
}
