/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 104.472656 108 L 127.027344 78.503906 C 129.710938 74.992188 129.042969 69.972656 125.53125 67.289062 L 106.46875 52.710938 C 102.957031 50.027344 97.9375 50.695312 95.253906 54.207031 L 51.070312 111.984375 C 50.273438 113.097656 48.777344 115.472656 47.910156 117.65625 C 46.589844 120.84375 45.859375 124.335938 45.859375 128 C 45.859375 131.664062 46.585938 135.15625 47.90625 138.339844 C 48.777344 140.527344 50.273438 142.902344 51.070312 144.015625 L 95.253906 201.792969 C 97.9375 205.304688 102.957031 205.972656 106.46875 203.289062 L 125.53125 188.710938 C 129.042969 186.027344 129.710938 181.003906 127.027344 177.496094 L 104.472656 148 L 204 148 C 208.417969 148 212 144.417969 212 140 L 212 116 C 212 111.582031 208.417969 108 204 108 Z M 104.472656 108 "
    />,
  ],
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 104.472656 108 L 127.027344 78.503906 C 129.710938 74.992188 129.042969 69.972656 125.53125 67.289062 L 106.46875 52.710938 C 102.957031 50.027344 97.9375 50.695312 95.253906 54.207031 L 51.070312 111.984375 C 50.273438 113.097656 48.777344 115.472656 47.910156 117.65625 C 46.589844 120.84375 45.859375 124.335938 45.859375 128 C 45.859375 131.664062 46.585938 135.15625 47.90625 138.339844 C 48.777344 140.527344 50.273438 142.902344 51.070312 144.015625 L 95.253906 201.792969 C 97.9375 205.304688 102.957031 205.972656 106.46875 203.289062 L 125.53125 188.710938 C 129.042969 186.027344 129.710938 181.003906 127.027344 177.496094 L 104.472656 148 L 204 148 C 208.417969 148 212 144.417969 212 140 L 212 116 C 212 111.582031 208.417969 108 204 108 Z M 104.472656 108 "
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 104.472656 108 L 127.027344 78.503906 C 129.710938 74.992188 129.042969 69.972656 125.53125 67.289062 L 106.46875 52.710938 C 102.957031 50.027344 97.9375 50.695312 95.253906 54.207031 L 51.070312 111.984375 C 50.273438 113.097656 48.777344 115.472656 47.910156 117.65625 C 46.589844 120.84375 45.859375 124.335938 45.859375 128 C 45.859375 131.664062 46.585938 135.15625 47.90625 138.339844 C 48.777344 140.527344 50.273438 142.902344 51.070312 144.015625 L 95.253906 201.792969 C 97.9375 205.304688 102.957031 205.972656 106.46875 203.289062 L 125.53125 188.710938 C 129.042969 186.027344 129.710938 181.003906 127.027344 177.496094 L 104.472656 148 L 204 148 C 208.417969 148 212 144.417969 212 140 L 212 116 C 212 111.582031 208.417969 108 204 108 Z M 104.472656 108 "
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 104.472656 108 L 127.027344 78.503906 C 129.710938 74.992188 129.042969 69.972656 125.53125 67.289062 L 106.46875 52.710938 C 102.957031 50.027344 97.9375 50.695312 95.253906 54.207031 L 51.070312 111.984375 C 50.273438 113.097656 48.777344 115.472656 47.910156 117.65625 C 46.589844 120.84375 45.859375 124.335938 45.859375 128 C 45.859375 131.664062 46.585938 135.15625 47.90625 138.339844 C 48.777344 140.527344 50.273438 142.902344 51.070312 144.015625 L 95.253906 201.792969 C 97.9375 205.304688 102.957031 205.972656 106.46875 203.289062 L 125.53125 188.710938 C 129.042969 186.027344 129.710938 181.003906 127.027344 177.496094 L 104.472656 148 L 204 148 C 208.417969 148 212 144.417969 212 140 L 212 116 C 212 111.582031 208.417969 108 204 108 Z M 104.472656 108 "
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 104.472656 108 L 127.027344 78.503906 C 129.710938 74.992188 129.042969 69.972656 125.53125 67.289062 L 106.46875 52.710938 C 102.957031 50.027344 97.9375 50.695312 95.253906 54.207031 L 51.070312 111.984375 C 50.273438 113.097656 48.777344 115.472656 47.910156 117.65625 C 46.589844 120.84375 45.859375 124.335938 45.859375 128 C 45.859375 131.664062 46.585938 135.15625 47.90625 138.339844 C 48.777344 140.527344 50.273438 142.902344 51.070312 144.015625 L 95.253906 201.792969 C 97.9375 205.304688 102.957031 205.972656 106.46875 203.289062 L 125.53125 188.710938 C 129.042969 186.027344 129.710938 181.003906 127.027344 177.496094 L 104.472656 148 L 204 148 C 208.417969 148 212 144.417969 212 140 L 212 116 C 212 111.582031 208.417969 108 204 108 Z M 104.472656 108 "
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 104.472656 108 L 127.027344 78.503906 C 129.710938 74.992188 129.042969 69.972656 125.53125 67.289062 L 106.46875 52.710938 C 102.957031 50.027344 97.9375 50.695312 95.253906 54.207031 L 51.070312 111.984375 C 50.273438 113.097656 48.777344 115.472656 47.910156 117.65625 C 46.589844 120.84375 45.859375 124.335938 45.859375 128 C 45.859375 131.664062 46.585938 135.15625 47.90625 138.339844 C 48.777344 140.527344 50.273438 142.902344 51.070312 144.015625 L 95.253906 201.792969 C 97.9375 205.304688 102.957031 205.972656 106.46875 203.289062 L 125.53125 188.710938 C 129.042969 186.027344 129.710938 181.003906 127.027344 177.496094 L 104.472656 148 L 204 148 C 208.417969 148 212 144.417969 212 140 L 212 116 C 212 111.582031 208.417969 108 204 108 Z M 104.472656 108 "
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M 104.472656 108 L 127.027344 78.503906 C 129.710938 74.992188 129.042969 69.972656 125.53125 67.289062 L 106.46875 52.710938 C 102.957031 50.027344 97.9375 50.695312 95.253906 54.207031 L 51.070312 111.984375 C 50.273438 113.097656 48.777344 115.472656 47.910156 117.65625 C 46.589844 120.84375 45.859375 124.335938 45.859375 128 C 45.859375 131.664062 46.585938 135.15625 47.90625 138.339844 C 48.777344 140.527344 50.273438 142.902344 51.070312 144.015625 L 95.253906 201.792969 C 97.9375 205.304688 102.957031 205.972656 106.46875 203.289062 L 125.53125 188.710938 C 129.042969 186.027344 129.710938 181.003906 127.027344 177.496094 L 104.472656 148 L 204 148 C 208.417969 148 212 144.417969 212 140 L 212 116 C 212 111.582031 208.417969 108 204 108 Z M 104.472656 108 "
    />,
  ],
])

const CustomIconArrowLeft: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconArrowLeft.displayName = 'CustomIconArrowLeft'

export default CustomIconArrowLeft
