import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import queryString from 'query-string'
import { useLocation } from 'react-router'

import { isDev } from '@constants/environments'
import { configSelectors } from '@core/selectors'
import routes from '@routing/path'
import { AuthPages } from '@containers/Auth/types'
import { fetchSendSessionOTPRequest, setRouteBeforeAuth } from '@core/auth/actions'
import { useCaptcha } from '@context/CaptchaProvider'
import { useOtpTimer } from '@context/otpTimer'

const showPrivateRouteWhenDev = false

export default function PrivateRoute({ children }: React.PropsWithChildren) {
  const { isAuth } = useSelector(configSelectors.isAuthenticated)
  const additionalUserDataCompleted = useSelector(configSelectors.additionalUserDataCompleted)
  const isPhoneVerified = useSelector(configSelectors.isPhoneVerified)
  const isPhoneExist = useSelector(configSelectors.isPhoneExist)

  const { phone } = useSelector(configSelectors.authUserFields)
  const { captchaValue, reset } = useCaptcha()
  const { startTimer } = useOtpTimer()
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()

  if (!isAuth && !(showPrivateRouteWhenDev && isDev)) {
    dispatch(
      setRouteBeforeAuth(
        queryString.stringifyUrl({
          url: pathname,
          query: queryString.parse(search),
        }),
      ),
    )
    return <Navigate to={routes.withoutAuth.auth} />
  }

  if (!isPhoneExist) {
    return (
      <Navigate
        to={queryString.stringifyUrl({
          url: routes.withoutAuth.auth,
          query: { page: AuthPages.updatePhone },
        })}
      />
    )
  }

  if (!isPhoneVerified && captchaValue) {
    dispatch(
      fetchSendSessionOTPRequest(
        { identity: phone, captcha_response: captchaValue },
        {
          onFinally() {
            reset()
            startTimer()
          },
        },
      ),
    )

    return (
      <Navigate
        to={queryString.stringifyUrl({
          url: routes.withoutAuth.auth,
          query: {
            page: AuthPages.phoneOtp,
          },
        })}
      />
    )
  }

  if (!additionalUserDataCompleted) {
    return (
      <Navigate
        to={queryString.stringifyUrl({
          url: routes.withoutAuth.auth,
          query: {
            page: AuthPages.userData,
          },
        })}
      />
    )
  }

  return children
}
