import React, { useState, useEffect, Ref } from 'react'
import cn from 'classnames'
import { MagnifyingGlass, X } from '@phosphor-icons/react'
import useCurrentWidth from '@hooks/useCurrentWidth'
import { SearchEvents, eventTrack } from '@utils/analyticsTracker'
import styles from './styles.module.scss'
import { TSize } from '../types'

export interface ISearchProps {
  defaultValue?: string
  placeholder?: string
  className?: string
  onSearch: (value: string) => void
  focusCallback?: () => void
  disabled?: boolean
  size?: TSize
}

function Search({
  defaultValue,
  placeholder = 'search mojis',
  onSearch,
  focusCallback,
  className,
  disabled,
  size,
}: Readonly<ISearchProps>) {
  const [isHovered, setIsHovered] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [searchValue, setSearchValue] = useState(defaultValue || '')

  const { size: currentSize } = useCurrentWidth()
  const displaySize = size || currentSize

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearch(searchValue)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [searchValue])

  const handleSearch = (e) => {
    setSearchValue(e.currentTarget.value)
    eventTrack(SearchEvents.searchBegin({ searchingItem: e.currentTarget.value }))
  }

  const focusHandler = () => {
    setIsFocused(true)
    if (focusCallback) focusCallback()
  }

  return (
    <div className={cn(styles.container, className)} key="searchForm">
      <div
        className={cn(styles.wrapper, {
          [styles.hovered]: isHovered,
          [styles.focused]: isFocused,
        })}
      >
        <MagnifyingGlass
          className={cn(styles.search_icon, styles.icon, { [styles.active]: isFocused })}
          weight="bold"
          size={32}
        />
        <input
          disabled={disabled}
          className={cn(styles.field, {
            [styles.hovered]: isHovered,
            [styles.focused]: isFocused,
            [styles.compact]: displaySize !== TSize.spacious,
          })}
          value={searchValue}
          onChange={handleSearch}
          onFocus={focusHandler}
          onBlur={() => setIsFocused(false)}
          onMouseLeave={() => setIsHovered(false)}
          onMouseEnter={() => setIsHovered(true)}
          placeholder={placeholder}
          type="text"
        />

        {(isFocused || searchValue) && (
          <button type="button" onClick={() => setSearchValue('')} className={styles.clear_btn}>
            {searchValue && (
              <span className={cn(styles.clear_btn_text, { [styles.active]: isFocused })}>
                clear
              </span>
            )}
            <X
              className={cn(styles.icon, { [styles.active]: isFocused })}
              weight="bold"
              size={19}
            />
          </button>
        )}
      </div>
    </div>
  )
}

export default React.memo(Search)
