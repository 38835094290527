import React from 'react'
import ShareLinks from '@components/ShareLinks'

function TestShareLinks() {
  return (
    <div>
      <ShareLinks />
    </div>
  )
}

export default TestShareLinks
