import {
  DECRYPT_TOKEN,
  FAIL_DECRYPT,
  STORE_ADDRESS,
  STORE_PAYMENT_STATUS,
  MAKE_PAYMENT_REQUEST,
  SAVE_PAYMENT_DETAIL,
  PAYMENT_STATUS,
  PAYMENT_VERIFIED,
  CONVERT_TO_ANOTHER_CURRENCY,
  CONVERT_TO_ANOTHER_CURRENCY_OUTPUT,
  QUOTE,
  SUCCESS_QUOTES,
  UPDATE_QUOTES,
  FETCH_QUOTE_TRANSACTION,
  SET_QUOTE_TRANSACTIONS,
} from '../constants'

export const decryptToken = (payload) => ({ type: DECRYPT_TOKEN, payload })

export const saveAddress = (payload) => ({ type: STORE_ADDRESS, payload })

export const failDecryption = () => ({ type: FAIL_DECRYPT })

export const saveBizzPayment = (payload) => ({ type: STORE_PAYMENT_STATUS, payload })

export const makePaymentRequest = (payload) => ({ type: MAKE_PAYMENT_REQUEST, payload })
export const savePaymentDetail = (payload) => ({ type: SAVE_PAYMENT_DETAIL, payload })

export const paymentStatus = (payload) => ({ type: PAYMENT_STATUS, payload })

export const paymentStatusResult = (payload) => ({ type: PAYMENT_VERIFIED, payload })

export const convertToAnotherCurrency = (payload) => ({
  type: CONVERT_TO_ANOTHER_CURRENCY,
  payload,
})

export const convertToAnotherCurrencyOutput = (payload) => ({
  type: CONVERT_TO_ANOTHER_CURRENCY_OUTPUT,
  payload,
})

export const quote = (payload, callback) => ({ type: QUOTE, payload, callback })

export const successQuotes = (payload) => ({ type: SUCCESS_QUOTES, payload })

export const updateQuote = (paylaod, callback) => ({ type: UPDATE_QUOTES, paylaod, callback })

export const fetchQuoteTransaction = (paylaod, callback) => ({
  type: FETCH_QUOTE_TRANSACTION,
  paylaod,
  callback,
})

export const setQuoteTransactions = (payload) => ({ type: SET_QUOTE_TRANSACTIONS, payload })
