/* eslint-disable no-console */
import { call, put, takeEvery } from 'redux-saga/effects'
import { errorsHandler } from '@core/helper'
import * as actions from '../actions'
import * as types from '../constants'
import { API, defaultCurrency } from '../../api'

import { showAlert } from '../actions'

const peatioConfig = {
  apiVersion: 'peatio',
}

const userConfig = {
  apiVersion: 'barong',
}

export function* fetchUser() {
  try {
    const user = yield call(API.get(userConfig), '/resource/users/me')
    yield put(actions.successUser(user))
  } catch (e) {
    yield put(actions.showAlert(e))
    yield put(actions.failUser())
  }
}

export function* fetchUserFee() {
  try {
    const user = yield call(API.get(peatioConfig), '/account/members/me')
    yield put(actions.setUserFee(user))
  } catch (e) {
    yield put(actions.showAlert(e))
    yield put(actions.failUser())
  }
}

export function* fetchIcxProgress() {
  try {
    const icxProgress = yield call(API.get(peatioConfig), '/account/adjustments/icx_progess')
    yield put(actions.saveIcxProgress(icxProgress))
  } catch (e) {
    console.error(e)
  }
}

export function* fetchCirculatingSupply() {
  yield put(actions.getUserRequested(true))
  try {
    const circulationSupply = yield call(
      API.get(peatioConfig),
      `/public/currencies/${defaultCurrency()}/circulation`,
    )
    yield put(actions.saveCirculatingSupply(circulationSupply))
  } catch (err) {
    errorsHandler(err)
  }
  yield put(actions.getUserRequested(false))
}

export function* fetchUsaDisclaimer(action) {
  yield put(actions.getUserRequested(true))

  try {
    const usaDisclaimer = yield call(
      API.post(userConfig),
      'resource/users/agreement',
      action.payload,
    )
    yield put(
      actions.showAlert({
        message: ['Agreement accepted'],
        type: 'success',
      }),
    )
    yield put(actions.successUser(usaDisclaimer))
    yield put(actions.fetchUser())
  } catch (e) {
    yield put(actions.showAlert({ message: e.message[0], type: 'error' }))
  }
  yield put(actions.getUserRequested(false))
}

export function* sendCountry(action) {
  yield put(actions.getUserRequested(true))

  try {
    const usaDisclaimer = yield call(API.post(userConfig), 'resource/users/country', action.payload)
    yield put(actions.setCountry(usaDisclaimer))
    yield put(actions.fetchUser())
  } catch (e) {
    console.error(e)
  }
  yield put(actions.getUserRequested(false))
}

export function* sendPasswordOtp(action) {
  yield put(actions.getUserRequested(true))

  try {
    yield call(API.post(userConfig), 'resource/users/send-otp', action?.payload)
    yield put(actions.successSendPasswordOtp())
    yield put(
      showAlert({
        message: ['identity.session.code.sent_via.email'],
        type: 'success',
      }),
    )

    action.callback(true)
  } catch (e) {
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    yield put(actions.failSendPasswordOtp(e))
  }
}

export function* setNewPassword(action) {
  yield put(actions.getUserRequested(true))

  try {
    yield call(API.post(userConfig), 'resource/users/set-password', action?.payload)
    yield call(fetchUser)
    yield put(showAlert({ message: ['password.updated'], type: 'success' }))
  } catch (e) {
    yield put(actions.failSetNewPassword(e))
  }
  yield put(actions.getUserRequested(false))
  action?.callback(true)
}

export function* fetchUserSaga() {
  yield takeEvery(types.FETCH_USER, fetchUser)
  yield takeEvery(types.FETCH_USER_FEE, fetchUserFee)
  yield takeEvery(types.FETCH_ICX_PROGRESS, fetchIcxProgress)
  yield takeEvery(types.GET_CIRCULATING_SUPPLY, fetchCirculatingSupply)
  yield takeEvery(types.GET_USA_DISCLAIMER, fetchUsaDisclaimer)
  yield takeEvery(types.POST_COUNTRY, sendCountry)
  yield takeEvery(types.SEND_PASSWORD_OTP, sendPasswordOtp)
  yield takeEvery(types.SET_NEW_PASSWORD, setNewPassword)
}
