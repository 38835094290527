import React, { memo, useMemo } from 'react'
import cls from 'classnames'
import { InstagramLogo, TelegramLogo } from '@phosphor-icons/react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useScroll, { ScrollDirectionY } from '@hooks/useScroll'
import logo from '@components/Icons/playaLogo.svg'
import routes from '@routing/path'

import XSocialIcon from '@components/Icons/Share/XSocial'
import { URLS } from '@constants/urls'
import useCurrentWidth from '@hooks/useCurrentWidth'
import { Breakpoints } from '@constants/index'
import styles from './footer.module.scss'

const items = [
  {
    icon: <InstagramLogo color="#808080" size={16} />,
    link: 'https://www.instagram.com/peernewworld/',
    type: 'Instagram',
  },
  {
    link: URLS.PEER_NEW_WORLD,
    icon: <XSocialIcon />,
    type: 'X',
  },
  {
    icon: <TelegramLogo color="#808080" size={16} />,
    link: 'https://t.me/peer_inc',
    type: 'Telegram',
  },
]

function Footer() {
  const { scrollDirectionY } = useScroll()
  const { width } = useCurrentWidth()
  const { t } = useTranslation('nsCommon')

  const isTablet = width <= Breakpoints.md

  const isHide = scrollDirectionY === ScrollDirectionY.down

  const links = useMemo(
    () => [
      {
        id: 1,
        title: t('terms')?.toLowerCase(),
        link: URLS.TERMS_OF_SERVICE,
        target: '_blank',
        rel: 'noreferrer',
      },
      {
        id: 2,
        title: t('privacy')?.toLowerCase(),
        link: URLS.PRIVACY_POLICY,
        target: '_blank',
        rel: 'noreferrer',
      },
    ],
    [t],
  )

  return isTablet ? (
    <footer className={styles.mobile_container}>
      <div className={styles.top_links}>
        <NavLink to={routes.withAuth.home}>
          <img src={logo} alt="logo" className={styles.top_links__logo} />
        </NavLink>
        <div className={styles.top_links__wrapper}>
          {links.map(({ link, rel, title, target, id }) => (
            <a key={id} href={link} target={target} rel={rel} className={styles.top_links__item}>
              <span>{title}</span>
            </a>
          ))}
        </div>
      </div>
      <div className={styles.bottom_links}>
        {items.map(({ icon, link, type }) => (
          <a
            className={styles.bottom_links__social}
            href={link}
            target="_blank"
            rel="noreferrer"
            key={type}
          >
            {icon}
          </a>
        ))}
      </div>
    </footer>
  ) : (
    <footer
      className={cls(styles.container, {
        [styles.hidden]: isHide,
      })}
    >
      <div className={styles.main_container}>
        <div className={styles.main_container__inner}>
          <div className={styles.nav}>
            {links.map(({ title, link, target, rel }) => (
              <a key={link} href={link} target={target} rel={rel} className={styles.nav_item}>
                <span>{title}</span>
              </a>
            ))}
          </div>
        </div>

        <NavLink to={routes.withAuth.home} className={styles.logo_wrapper}>
          <img src={logo} alt="logo" className={styles.logo} />
        </NavLink>

        <div className={styles.items_wrapper}>
          {items.map(({ icon, link, type }) => (
            <a className={styles.link} href={link} target="_blank" rel="noreferrer" key={type}>
              {icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default memo(Footer)
