import {
  CREATE_SWAP,
  FAIL_QUOTE,
  FETCH_FIAT_QUOTE,
  FETCH_QUOTE,
  FETCH_SWAP_TRANSACTION,
  SUCCESS_FIAT_QUOTE,
  SUCCESS_GET_IP,
  SUCCESS_QUOTE,
  SUCCESS_SIMPLEX_SWAP,
  SUCCESS_SWAP_TRANSACTION,
  UNSET_SIMPLEX_MODAL,
  UNSET_SUCCESS_SIMPLEX,
  FAIL_SIMPLEX_SWAP,
  ERROR_QUOTE,
} from '../constants'

const initState = {
  list: [],
  allList: [],
  added: null,
  error: null,
  success: false,
  ip_value: null,
  loading: false,
  success_simplex: null,
  simplex_modal: false,
  swap_transaction: [],
  swap_total: 0,
  success_fiat_quote: [],
  swap_amount: 0,
  simplex_error: '',
  swap_amounts: 0,
  quoteError: '',
}
// eslint-disable-next-line default-param-last
export const BuySellReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_QUOTE: {
      return { ...state, loading: true }
    }
    case FAIL_QUOTE: {
      return { ...state, error: action.payload, loading: false }
    }
    case SUCCESS_QUOTE: {
      return {
        ...state,
        swap_amounts: action.payload,
        list: action.payload,
        swap_amount: (action.payload?.amount && action.payload?.amount) || 0,
        loading: false,
      }
    }
    case CREATE_SWAP: {
      return {
        ...state,
        loading: true,
      }
    }
    case SUCCESS_SIMPLEX_SWAP: {
      return {
        ...state,
        success_simplex: action.payload.data.checkout_iframe || '',
        loading: false,
      }
    }

    case ERROR_QUOTE: {
      return {
        ...state,
        quoteError: action.payload,
        loading: false,
      }
    }

    case FAIL_SIMPLEX_SWAP: {
      return {
        ...state,
        loading: false,
        simplex_error: 'If error still persists contact support',
      }
    }
    case SUCCESS_GET_IP: {
      return { ...state, ip_value: action.payload }
    }
    case UNSET_SUCCESS_SIMPLEX: {
      return { ...state, success_simplex: null }
    }
    case UNSET_SIMPLEX_MODAL: {
      return { ...state, simplex_modal: true }
    }
    case SUCCESS_SWAP_TRANSACTION: {
      return {
        ...state,
        swap_transaction: action.payload.data,
        swap_total: action.payload.total,
      }
    }
    case FETCH_SWAP_TRANSACTION: {
      return { ...state }
    }
    case FETCH_FIAT_QUOTE: {
      return { ...state }
    }
    case SUCCESS_FIAT_QUOTE: {
      return { ...state, success_fiat_quote: action.payload.data.data }
    }
    default:
      return state
  }
}
