import React from 'react'
import cn from 'classnames'
import { Icon, IconWeight } from '@phosphor-icons/react'

import { TSize } from '@components/UiKit/types'
import CustomIconClose from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CustomIconClose'
import CustomIconArrowLeft from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CustomIconArrowLeft'
import CustomIconArrowRight from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CustomIconArrowRight'
import CustomIconResend from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CutomIconResend'
import CustomIconShareX from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CustomIconShareX'
import CustomIconShareTG from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CustomIconShareTG'
import CustomIconShareEmail from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CustomIconShareEmail'
import CustomIconShareCopy from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CustomIconShareCopy'
import CustomIconShareCopied from '@components/UiKit/ButtonNew/IconButton/CustomIcon/CustomIconShareCopied'
import useCurrentWidth from '@hooks/useCurrentWidth'
import LoaderIcon from '@components/Icons/Loader'

import Button from '../Button'
import { TVariant } from '../constants'

import styles from './iconButton.module.scss'
import { ConvertedSizeToIconWidth, PhosphorIcons, TIconsKeyNames } from '../types'

const CUSTOM_ICONS = {
  CustomIconClose,
  CustomIconArrowLeft,
  CustomIconArrowRight,
  CustomIconResend,
  CustomIconShareX,
  CustomIconShareTG,
  CustomIconShareEmail,
  CustomIconShareCopy,
  CustomIconShareCopied,
}

interface IconButtonProps {
  size?: TSize
  variant?: TVariant
  className?: string
  disabled?: boolean
  icon: TIconsKeyNames
  weight?: IconWeight
  color?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  loading?: boolean
  loadingIconSize?: number
}
function IconButton({
  variant = 'primary',
  className,
  size,
  disabled = false,
  onClick,
  icon,
  weight = 'bold',
  color = '#fff',
  loading,
  loadingIconSize = 30,
}: Readonly<IconButtonProps>) {
  const { size: autoSize } = useCurrentWidth()
  const currentSize = size ?? autoSize

  const IconComponent = Object.keys(CUSTOM_ICONS).includes(icon)
    ? CUSTOM_ICONS[icon]
    : (PhosphorIcons[icon] as Icon)

  return (
    <Button
      onClick={onClick}
      className={cn(styles.icon, className, styles[variant], styles[currentSize], {
        [styles.disabled]: disabled,
      })}
      variant="text"
    >
      {(loading && <LoaderIcon size={loadingIconSize} />) || (
        <IconComponent size={ConvertedSizeToIconWidth[currentSize]} weight={weight} color={color} />
      )}
    </Button>
  )
}

export default IconButton
