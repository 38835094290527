import { takeEvery, all, call, put } from 'redux-saga/effects'
import queryString from 'query-string'

import { defaultWonkaLimit } from '@constants'
import { API } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'
import types from './types'
import {
  fetchWonkaTokensSuccess,
  fetchWonkaTokensFailed,
  revealWonkaSuccess,
  revealWonkaFailed,
  revealAllWonkaFailed,
  fetchWonkaTokensRequest,
  revealAllWonkaImmediatelySuccess,
} from './actions'

const apiVersion = {
  apiVersion: 'peatio',
  withHeaders: true,
}

function* fetchGetWonkas({ payload }) {
  try {
    const limit = payload.limit ?? defaultWonkaLimit
    const res = yield call(
      API.get(apiVersion),
      queryString.stringifyUrl({
        url: API_URLS.wonka.listTokens,
        query: {
          limit,
          page: payload.page ?? 1,
        },
      }),
    )
    yield put(
      fetchWonkaTokensSuccess({
        list: res.data,
        total: Number(res.headers.total) || 0,
        limit,
        page: payload.page - 1 ?? 0,
      }),
    )
  } catch (err) {
    yield put(fetchWonkaTokensFailed())
  }
}

function* revealWonka({ payload }) {
  try {
    const res = yield call(API.post(apiVersion), API_URLS.wonka.reveal, payload)
    if (res.data.media?.url) yield put(revealWonkaSuccess({ ...res.data, page: payload.page }))
  } catch (err) {
    yield put(revealWonkaFailed())
  }
}

function* revealAllWonkas({ payload }) {
  try {
    const limit = payload.limit ?? defaultWonkaLimit

    yield call(API.post(apiVersion), API_URLS.wonka.revealAll)
    // NOTE: kostyl, sometimes backend already has uploaded wonkas,
    // so it doesn't send message in wonka-media channel
    // that's why we refetch wonka list to be safe in this case
    const res = yield call(
      API.get(apiVersion),
      queryString.stringifyUrl({
        url: API_URLS.wonka.listTokens,
        query: {
          limit,
          page: payload.page ?? 1,
        },
      }),
    )
    if (res.data.every((wonka) => wonka.revealed === true && wonka.status === 'completed')) {
      yield put(
        revealAllWonkaImmediatelySuccess({
          list: res.data,
          limit,
          page: payload.page - 1 ?? 0,
        }),
      )
      return
    }

    yield put(fetchWonkaTokensRequest({ page: payload.page, limit: payload.limit }))
  } catch (err) {
    yield put(revealAllWonkaFailed())
  }
}

export function* rootSagaWonkas() {
  yield all([takeEvery(types.FETCH_WONKA_TOKENS_REQUEST, fetchGetWonkas)])
  yield all([takeEvery(types.REVEAL_WONKA_REQUEST, revealWonka)])
  yield all([takeEvery(types.REVEAL_ALL_WONKA_REQUEST, revealAllWonkas)])
}
