import {
  FETCH_BALANCE,
  SUCCESS_FETCH_BALANCE,
  FAIL_FETCH_BALANCE,
  SEARCH_BALANCE,
} from '../constants'

export const fetchBalance = (payload) => ({ type: FETCH_BALANCE, payload })

export const successFetchBalance = (payload) => ({ type: SUCCESS_FETCH_BALANCE, payload })

export const failFetchBalance = (payload) => ({ type: FAIL_FETCH_BALANCE, payload })

export const searchBalance = (payload) => ({ type: SEARCH_BALANCE, payload })
