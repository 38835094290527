export enum AuthPages {
  email = 'email',
  signupPhone = 'signup_phone',
  signinEmailOtp = 'signin_email_otp',
  signinPhoneOtp = 'signin_phone_otp',
  signupEmailOtp = 'signup_email_otp',
  phoneOtp = 'phone_otp',
  updatePhone = 'update_phone',
  updatePhoneOtp = 'update_phone_otp',
  userData = 'user_data',
  updateEmailOtp = 'update_email_otp',
}
