import React, { memo } from 'react'
import PaginationReact, { ReactPaginateProps } from 'react-paginate'
import isEqual from 'react-fast-compare'
import cn from 'classnames'
import { PartialBy } from 'types/utilityTypes'

import styles from './style.module.scss'

interface IProps extends PartialBy<ReactPaginateProps, 'pageCount'> {
  className?: string
  total?: number
  itemsPerPage?: number
  renderOnOnePageCount?: boolean
}

function Pagination({
  className,
  total = 1,
  itemsPerPage = 1,
  onPageChange,
  nextLabel = '',
  previousLabel = '',
  pageRangeDisplayed = 1,
  forcePage,
  pageCount,
  renderOnZeroPageCount = null,
  renderOnOnePageCount = false,
}: Readonly<IProps>) {
  const pageCounted = pageCount ?? Math.ceil(total / itemsPerPage)

  if (!renderOnOnePageCount && pageCounted < 2) {
    return null
  }

  return (
    <PaginationReact
      className={cn(styles.pagination, className)}
      pageCount={pageCounted}
      forcePage={forcePage}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={onPageChange}
      nextLabel={nextLabel}
      previousLabel={previousLabel}
      renderOnZeroPageCount={renderOnZeroPageCount}
    />
  )
}

export default memo(Pagination, isEqual)
