import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import { TNullable } from 'types/utilityTypes'
import { TEmojiItemPurchased } from '@core/emojis/types'
import { ITransactionTypes } from '@components/Profile/Transactions/main/types'

import types from '../types'
import encryptor from '../encryptor'
import { IReducerPurchaseTokens } from './type'

const initialState: {
  purchase: {
    data: IReducerPurchaseTokens<TNullable<TEmojiItemPurchased[]>>
    loading: boolean
    error: string
  }
  transactions: any
} = {
  purchase: {
    data: {
      items: null,
      total: 0,
      page: 0,
      perPage: 0,
    },
    loading: false,
    error: '',
  },
  transactions: Object.keys(ITransactionTypes).reduce(
    (acc, item) => ({
      ...acc,
      [item]: {
        data: {
          items: null,
          total: 0,
          page: 0,
          perPage: 0,
        },
        loading: false,
        error: '',
      },
    }),
    {},
  ),
}

const profileReducer = (state = initialState, { type, payload }: any = {}) => {
  const itemStateTransactionType = state.transactions[payload?.transactionType]

  switch (type) {
    case types.GET_PURCHASED_TOKENS_REQUEST:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          error: '',
          loading: true,
        },
      }
    case types.GET_PURCHASED_TOKENS_SUCCESS:
      return {
        ...state,
        purchase: {
          data: payload,
          loading: false,
          error: '',
        },
      }

    case types.GET_PURCHASED_TOKENS_FAILURE:
      return {
        ...state,
        purchase: {
          ...initialState.purchase,
          error: payload,
          loading: false,
        },
      }

    case types.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [payload?.transactionType]: {
            ...itemStateTransactionType,
            error: '',
            loading: true,
          },
        },
      }

    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [payload?.transactionType]: {
            data: payload.payload,
            error: '',
            loading: false,
          },
        },
      }

    case types.GET_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [payload?.transactionType]: {
            ...initialState.transactions[payload?.transactionType],
            error: payload.error,
            loading: false,
          },
        },
      }

    default:
      return state
  }
}

const profileConfig = {
  key: 'peer_purchased_tokens',
  storage,
  transforms: [encryptor],
}

export default persistReducer(profileConfig, profileReducer)
