/* eslint-disable */
import {
  FETCH_BALANCE,
  SUCCESS_FETCH_BALANCE,
  FAIL_FETCH_BALANCE,
  SEARCH_BALANCE
} from '../constants';

const initState = {
  list: [],
  actualList: [],
  error: null,
  loading: false,
  hasFund: false
};

export const balanceReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_BALANCE: {
     
      return { ...state, loading: true };
    }
    case SUCCESS_FETCH_BALANCE: {
      let temp = false;
      let funds = action.payload.filter((item)=> item.balance > 0).length;
      if(funds > 0){
        temp = true
      }
      return {
        ...state, 
        list: action.payload, 
        loading: false,
        hasFund: temp
      };
    }
    case FAIL_FETCH_BALANCE: {
      return { ...state, error: action.payload, loading: false };
    }
    case SEARCH_BALANCE: {
      if (state.actualList.length === 0 && state.list.length > 0) {
        state.actualList = state.list;
      } else if (state.list.length === 0 && state.actualList.length > 0) {
        state.list = state.actualList;
      }
      if (action.payload !== '') {
        /* balance search*/
        var data = state.actualList.filter(function(obj) {
          return Object.keys(obj).some(function(key) {
            return (
              typeof obj['currency'] == 'string' &&
              obj['currency'].includes(action.payload)
            );
          });
        });
      } else {
        data = state.actualList;
      }

      return {
        ...state,
        list: data
      };
    }
    default:
      return state;
  }
};
