import {
  GET_PRESALE,
  GET_PRESALE_SUCCESS,
  GET_PRESALE_FAILURE,
  GET_PUBLIC_ICX_PROGRESS,
  SAVE_PUBLIC_ICX_PROGRESS,
  FETCH_CHART_DATA,
  SET_CHART_DATA,
  FETCH_GOOGLE_DATA,
  SET_GOOGLE_DATA,
  SET_COMPONENT_HIDE_OR_NOT,
  GET_COMPONENT_HIDE_OR_NOT,
} from '../constants'

export const getPresale = () => ({ type: GET_PRESALE })

export const successPresale = (payload) => ({ type: GET_PRESALE_SUCCESS, payload })

export const failedPresale = () => ({ type: GET_PRESALE_FAILURE })

export const fetchPublicIcx = () => ({ type: GET_PUBLIC_ICX_PROGRESS })

export const savePublicIcx = (payload) => ({ type: SAVE_PUBLIC_ICX_PROGRESS, payload })

export const fetchChartData = (payload) => ({ type: FETCH_CHART_DATA, payload })

export const fetchGoogleChartData = (payload) => ({ type: FETCH_GOOGLE_DATA, payload })

export const setChartData = (payload) => ({ type: SET_CHART_DATA, payload })

export const setGoogleData = (payload) => ({ type: SET_GOOGLE_DATA, payload })

export const setComponentHideOrNot = (payload) => ({ type: SET_COMPONENT_HIDE_OR_NOT, payload })

export const getComponentHideOrNot = (payload) => ({ type: GET_COMPONENT_HIDE_OR_NOT, payload })
