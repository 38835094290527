import { all } from 'redux-saga/effects'
import { fetchUserSaga } from './user'
// import { fetchLogoutSaga, fetchLoginSaga, fetchOtpVerifySaga, optResendSaga } from './auth'
// import { registerUserSaga } from './register'
// import { sendConfirmationEmailSaga } from './sendConfirationEmail'
import { rootMarketFetchTickers, getMarketsSaga } from './markets'
import {
  saveProfileSaga,
  updateProfileSaga,
  // saveNetVerifySaga,
  // saveTransactionReference,
  getKycAidSaga,
  // getChartDataSaga,
  // getGoogleDataSaga,
  fetchComponentHideOrNotSaga,
} from './profile'
import { savePhoneSaga, verifyPhoneSaga, resendOtpSaga } from './phone'
// import { confirmationEmailSaga } from './confirationEmail'
// import { forgotPasswordSaga } from './forgotPassword'
// import { resetPasswordSaga } from './resetPassword'
// import { saveDocumentSaga, fetchDocumentSaga } from './documents'
// import { videoVerificationSaga } from './video'
// import {
// changePasswordSaga,
// generateCodeSaga,
// verifyQrCodeSaga,
// disableGoogleAuthSaga,
// fetchLastLogin,
// fetchViAccountConfirmation,
// addViUserId,
// createVerifyInvestorRequest,
// updateVerificationStatus,
// createVerifyInvestorAccountSaga,
// fetchAdvertisements,
// profilePhotoUploadSaga,
// } from './settings'
// import { apiTokensSaga, addTokenSaga, changeStateSaga, deleteSaga } from './apiTokens'

import { fetchCurrencySaga } from './currency'
import { fetchAddressSaga } from './address'

import { fetchBalanceSaga } from './balance'
// import { fetchDepositSaga } from './deposits'
// import {
//   fetchWithdrawSaga,
//   withdrawAmountSaga,
//   fetchFiateTransactionSaga,
//   checkLimitSaga,
//   fetchPurchaseTransactionSaga,
//   getBonusTransactionSaga,
// } from './withdraws'

// import { getMyTradesSaga, pushMyTradesSaga } from './myRecentTrades'
// import { submitOrderSaga } from './orders'

import { KlineFetchSaga } from './kline'
import { rootDepthSaga } from './depth'
import { rootRecentTradesSaga } from './recentTrades'
// import { fetchOrderHistorySaga } from './orderHistory'
// import { openOrdersSaga, cancelOrderSaga, cancelAllOrdersSaga } from './openOrders'
// import { tradeHistorySaga } from './tradeHistory'
import { statementSaga } from './statements'
// import { rewardInfoSaga, sendRewardSaga, referralHistorySaga, rewardHistorySaga } from './referral'

// import {
//   addBeneficiarySaga,
//   fetchBeneficiarySaga,
//   deleteBeneficiarySaga,
//   changeStateBeneficiarySaga,
//   fetchResendPin,
// } from './beneficiary'

// import { saveContactSaga } from './contact'
// import {
//   fetchMiningPool,
//   sendMiningPoolRequest,
//   fetchEnrolledProgram,
//   fetchOverallProgress,
//   fetchTodayReward,
//   fetchRewardOrders,
//   fetchMiningTradeRewards,
//   fetchMiningReferralRewards,
//   fetchWithdrawTradeHistory,
//   fetchWithdrawReferralHistory,
//   sendWithdrawTransferRequest,
//   fetchWithdrawBalance,
// } from './rewards'

import {
  saveDecryptToken,
  makePaymentRequestSaga,
  paymentStatusSaga,
  convertToAnotherCurrencySaga,
  fetchQuotesSaga,
  modifyQuotesSaga,
  getQuoteTransactionSaga,
} from './paymentGateway'

// import { fetchTradingFeeSaga } from './tradingFee'

import {
  fetchBuyTuxcHistorySaga,
  fetchAllBuyTuxcHistorySaga,
  submitBuyTuxcOrderSaga,
  fetchSwapCurrencyPriceSaga,
  postNewAgreementSaga,
  fetchMemberAgreements,
} from './buyTuxc'
import { fetchCryptoSetting } from './cryptoSetting'
// import { fetchAliasSaga } from './alias'
import { fetchPresaleSaga } from './presale'
// import { getMyTransactionsSaga, verifyQuoteIdSaga } from './layout'
import {
  fetchActivitySaga,
  fetchFiatQuoteSaga,
  fetchQuoteSaga,
  fetchSwapRecordSaga,
  getPaymentRequestSagas,
  sendSimplexSwapSaga,
  updateSwapRecordSaga,
} from './buySell'
// import { modifyUserSaga } from './verification'
// import { rootSagaProfile } from '../../../profile/sagas'

export const IcxRootSagas = [
  // rootSagaProfile(),
  fetchUserSaga(),
  // fetchLogoutSaga(),
  // fetchLoginSaga(),
  // registerUserSaga(),
  // sendConfirmationEmailSaga(),
  saveProfileSaga(),
  updateProfileSaga(),
  rootMarketFetchTickers(),
  savePhoneSaga(),
  // confirmationEmailSaga(),
  // forgotPasswordSaga(),
  // resetPasswordSaga(),
  verifyPhoneSaga(),
  resendOtpSaga(),
  // saveDocumentSaga(),
  // fetchDocumentSaga(),
  fetchSwapCurrencyPriceSaga(),
  fetchMemberAgreements(),
  // videoVerificationSaga(),
  // changePasswordSaga(),
  // generateCodeSaga(),
  // verifyQrCodeSaga(),
  // disableGoogleAuthSaga(),
  // apiTokensSaga(),
  // addTokenSaga(),
  // changeStateSaga(),
  // deleteSaga(),
  // fetchDepositSaga(),
  // fetchWithdrawSaga(),
  fetchCurrencySaga(),
  fetchAddressSaga(),
  fetchBalanceSaga(),
  // getMyTradesSaga(),
  // openOrdersSaga(),
  // fetchOrderHistorySaga(),
  // cancelOrderSaga(),
  getMarketsSaga(),
  // submitOrderSaga(),
  KlineFetchSaga(),
  rootDepthSaga(),
  rootRecentTradesSaga(),
  // cancelAllOrdersSaga(),
  // tradeHistorySaga(),
  // rewardInfoSaga(),
  // sendRewardSaga(),
  // referralHistorySaga(),
  // rewardHistorySaga(),
  // addBeneficiarySaga(),
  // fetchBeneficiarySaga(),
  // deleteBeneficiarySaga(),
  // changeStateBeneficiarySaga(),
  // withdrawAmountSaga(),
  // fetchFiateTransactionSaga(),
  // saveContactSaga(),
  // fetchLastLogin(),
  // fetchMiningPool(),
  // sendMiningPoolRequest(),
  // fetchEnrolledProgram(),
  // fetchOverallProgress(),
  // fetchTodayReward(),
  // fetchRewardOrders(),
  // fetchMiningTradeRewards(),
  // fetchMiningReferralRewards(),
  // fetchWithdrawTradeHistory(),
  // fetchWithdrawReferralHistory(),
  // sendWithdrawTransferRequest(),
  // fetchWithdrawBalance(),
  // fetchResendPin(),
  saveDecryptToken(),
  // saveNetVerifySaga(),
  // saveTransactionReference(),
  // pushMyTradesSaga(),
  // fetchTradingFeeSaga(),
  makePaymentRequestSaga(),
  paymentStatusSaga(),
  convertToAnotherCurrencySaga(),
  statementSaga(),
  // checkLimitSaga(),
  fetchBuyTuxcHistorySaga(),
  fetchAllBuyTuxcHistorySaga(),
  fetchCryptoSetting(),
  submitBuyTuxcOrderSaga(),
  // fetchAliasSaga(),
  fetchPresaleSaga(),
  // getMyTransactionsSaga(),
  // fetchViAccountConfirmation(),
  // addViUserId(),
  // createVerifyInvestorRequest(),
  // updateVerificationStatus(),
  fetchQuoteSaga(),
  fetchActivitySaga(),
  sendSimplexSwapSaga(),
  fetchSwapRecordSaga(),
  updateSwapRecordSaga(),
  fetchFiatQuoteSaga(),
  // createVerifyInvestorAccountSaga(),
  fetchQuotesSaga(),
  getKycAidSaga(),
  modifyQuotesSaga(),
  // getChartDataSaga(),
  getQuoteTransactionSaga(),
  postNewAgreementSaga(),
  // getGoogleDataSaga(),
  // fetchPurchaseTransactionSaga(),
  // fetchAdvertisements(),
  // getBonusTransactionSaga(),
  fetchComponentHideOrNotSaga(),
  getPaymentRequestSagas(),
  // verifyQuoteIdSaga(),
  // profilePhotoUploadSaga(),
  // fetchOtpVerifySaga(),
  // modifyUserSaga(),
  // optResendSaga(),
]

export default function* rootSaga() {
  yield all(IcxRootSagas)
}
