/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'

import profileReducer from './profile/reducer'
import commonReducer from './common/reducer'
import wonkaReducer from './wonkas/reducer'
import { IcxReducers } from './icx/redux/reducers/index'
import authReducer from './auth/reducer'
import purchasedTokensReducer from './purchasedTokens/reducer'

const rootReducer = combineReducers({
  profile: profileReducer,
  common: commonReducer,
  wonkas: wonkaReducer,
  auth: authReducer,
  purchasedTokens: purchasedTokensReducer,
  ...IcxReducers,
})

export default rootReducer
