import {
  SUBMIT_BUYTUXC_ORDER,
  SUCCESS_BUYTUXC_ORDER,
  FAIL_BUYTUXC_ORDER,
  FETCH_BUYTUXC,
  SUCCESS_FETCH_BUYTUXC,
  FAIL_FETCH_BUYTUXC,
  FETCH_ALL_BUYTUXC,
  SUCCESS_ALL_FETCH_BUYTUXC,
  FAIL_ALL_FETCH_BUYTUXC,
  SUCCESS_RESPONSE_TUXC,
  SET_SWAP_CURRENCY,
  FETCH_CURRENT_SWAP_PRICE,
  SUCCESS_CURRENT_SWAP_PRICE,
  UPDATE_REFRESH_VALUE,
  PURCHASE_FUND,
  FETCH_MEMBER_AGREEMENTS,
  SUCCESS_MEMBER_AGREEMENTS,
  FAIL_MEMBER_AGREEMENTS,
} from '../constants'

const initState = {
  list: [],
  allList: [],
  added: null,
  error: null,
  success: false,
  selectFirst: null,
  selectSecond: null,
  set_swap_price: true,
  success_swap_price: 0.0,
  calculated_price: 0.0,
  refresh: false,
  loading: false,
  purchaseAmount: 0,
  currency: '',
  total: 0,
  agreements: [],
  agreementTotal: 0,
}

// eslint-disable-next-line default-param-last
export const BuyTuxcReducer = (state = initState, action) => {
  switch (action.type) {
    case SUBMIT_BUYTUXC_ORDER: {
      return { ...state, refresh: false }
    }
    case SUCCESS_BUYTUXC_ORDER: {
      return {
        ...state,
        list: [action.payload.data, ...state.list],
        allList: [action.payload.data, ...state.allList],
        success: true,
        refresh: true,
      }
    }
    case FAIL_BUYTUXC_ORDER: {
      return {
        ...state,
        error: action.payload,
        success: false,
        refresh: false,
      }
    }
    case FETCH_BUYTUXC: {
      return { ...state }
    }
    case SUCCESS_FETCH_BUYTUXC: {
      return { ...state, list: action.payload }
    }
    case FAIL_FETCH_BUYTUXC: {
      return { ...state, error: action.payload }
    }
    case FETCH_ALL_BUYTUXC: {
      return { ...state, loading: true }
    }
    case SUCCESS_ALL_FETCH_BUYTUXC: {
      return {
        ...state,
        allList: action.payload.list,
        total: action.payload.total,
        loading: false,
      }
    }

    case FAIL_ALL_FETCH_BUYTUXC: {
      return { ...state, error: action.payload, loading: false }
    }
    case SUCCESS_RESPONSE_TUXC: {
      return { ...state, success: action.payload }
    }
    case SET_SWAP_CURRENCY: {
      return {
        ...state,
        selectFirst: action.payload.selectFirst,
        selectSecond: action.payload.selectSecond,
        success: true,
      }
    }
    case FETCH_CURRENT_SWAP_PRICE: {
      return { ...state, send_swap_price: false, success_swap_price: 0.0 }
    }
    case SUCCESS_CURRENT_SWAP_PRICE: {
      return {
        ...state,
        success_swap_price: action.payload,
        calculated_price:
          action.payload.amount >= 0
            ? action.payload.current_price
            : action.payload[state.currency],
        send_swap_price: false,
      }
    }
    case PURCHASE_FUND: {
      return {
        ...state,
        purchaseAmount: action.payload.price,
        currency: action.payload.currency,
      }
    }
    case FETCH_MEMBER_AGREEMENTS: {
      return { ...state }
    }
    case SUCCESS_MEMBER_AGREEMENTS: {
      return {
        ...state,
        agreements: action.payload.data,
        success: true,
        refresh: true,
        agreementTotal: action.payload.headers.total,
      }
    }
    case FAIL_MEMBER_AGREEMENTS: {
      return {
        ...state,
        error: action.payload,
        success: false,
        refresh: false,
      }
    }
    case UPDATE_REFRESH_VALUE: {
      return { ...state, refresh: false }
    }
    default:
      return state
  }
}
