import React from 'react'
import SegmentedControl, { ISegmentedControlProps } from '@components/UiKit/SegmentedControl'
import Dropdown, { IDropdownProps } from '@components/UiKit/Form/Dropdown'
import Search, { ISearchProps } from '@components/UiKit/Search'
import styles from './search_sort.module.scss'

type TProps = IDropdownProps & ISegmentedControlProps & ISearchProps

function SearchSort({ options, onSearch, action, isDropdownDisabled }: TProps) {
  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <Search disabled onSearch={onSearch} />
      </div>
      <div className={styles.select}>
        <Dropdown options={options} isDropdownDisabled={isDropdownDisabled} />
      </div>
      <div className={styles.control}>
        <SegmentedControl action={action} />
      </div>
    </div>
  )
}

export default React.memo(SearchSort)
