import React from 'react'

import cn from 'classnames'

import styles from './style.module.scss'

interface IFormControlProps {
  fullWidth?: boolean
  className?: string
  noGutters?: boolean
}

type Ref = HTMLDivElement

export const FormControl = React.forwardRef<Ref, React.PropsWithChildren<IFormControlProps>>(
  ({ children, className, fullWidth, noGutters }, ref) => (
    <div
      ref={ref}
      className={cn(styles.formControl, className, {
        [styles.formControl__fullWidth]: fullWidth,
        [styles.formControl__noGutters]: noGutters,
      })}
    >
      {children}
    </div>
  ),
)

interface IFormLabelProps {
  className?: string
  disabled?: boolean
}

export function FormLabel({
  children,
  disabled,
  className,
}: React.PropsWithChildren<IFormLabelProps>) {
  return (
    <div className={cn(styles.label, { [styles.label__disabled]: disabled }, className)}>
      {children}
    </div>
  )
}

interface IFormErrorProps {
  error?: boolean
  disabled?: boolean
}

export function FormError({ children, disabled, error }: React.PropsWithChildren<IFormErrorProps>) {
  return (
    <div
      className={cn(styles.error, {
        [styles.error_show]: error,
        [styles.error__disabled]: disabled,
      })}
    >
      {children}
    </div>
  )
}

interface IFormHelperTextProps {
  disabled?: boolean
  show?: boolean
}

export function FormHelperText({
  children,
  disabled,
  show,
}: React.PropsWithChildren<IFormHelperTextProps>) {
  return (
    <div
      className={cn(styles.helperText, {
        [styles.helperText__show]: show,
        [styles.helperText__disabled]: disabled,
      })}
    >
      {children}
    </div>
  )
}
