import { call, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import * as types from '../constants'
import { showAlert } from '../actions/alert'
import { API } from '../../api'
import { buildQueryString } from '../../helpers'
import { getPresale, getUserRequested } from '../actions'

const peatioConfig = {
  apiVersion: 'peatio',
}

const peatioConfigHeader = {
  apiVersion: 'peatio',
  withHeaders: true,
}

export function* createAdjustmentOrder(action) {
  yield put(actions.getUserRequested(true))
  try {
    const url = '/account/adjustments'
    const adjustment = yield call(API.post(peatioConfig), url, action.payload)
    if (action.callback) {
      action.callback(adjustment)
    }
    yield put(actions.successBuyTuxcOrder({ data: adjustment }))
    yield put(actions.successResponseTuxc(true))
    yield put(actions.fetchBalance())
    yield put(actions.getPresale())
    yield put(
      showAlert({
        message: ['Order Created Successfully'],
        type: 'success',
      }),
    )
    yield put(actions.getUserRequested(false))
  } catch (e) {
    if (action.callback) {
      action.callback(e)
    }
    yield put(getPresale())
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    yield put(actions.failBuyTuxcOrder(e))
    yield put(actions.successResponseTuxc(false))
    yield put(actions.getUserRequested(false))
  }
}

export function* getCurrentSwapPrice(action) {
  try {
    let response = yield call(API.post(peatioConfig), '/public/swap_price', action.payload)
    response = { ...response, ...{ amount: action.payload.amount } }
    yield put(actions.getUserRequested(false))
    yield put(actions.successCurrentSwapPrice(response))
  } catch (e) {
    yield put(showAlert({ message: e.code, code: e.code, type: 'error' }))
    yield put(actions.getUserRequested(false))
    yield put(actions.failCurrentSwapPrice())
  }
}

export function* submitBuyTuxcOrderSaga() {
  yield takeEvery(types.SUBMIT_BUYTUXC_ORDER, createAdjustmentOrder)
}

export function* fetchBuyTuxcHistory(action) {
  yield put(getUserRequested(true))
  try {
    const { data, headers } = yield call(
      API.get(peatioConfigHeader),
      `/account/adjustments?${buildQueryString(action.payload)}`,
    )
    yield put(actions.successAllFetchBuyTuxc({ list: data, total: headers?.total }))
  } catch (e) {
    if (e.message[0] !== 'account.withdraw.not_permitted') {
      yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    }
    yield put(actions.getUserRequested(false))
    yield put(actions.failFetchBuyTuxc(e))
  }
  yield put(getUserRequested(false))
}

export function* postNewAgreement(action) {
  yield put(actions.getUserRequested(true))
  try {
    const url = 'account/swap_agreement'
    const adjustment = yield call(API.post(peatioConfig), url, action.payload)
    if (action.callback) {
      action.callback(adjustment)
    }
    yield put(actions.getUserRequested(false))
  } catch (e) {
    e.type = 'error'
    action.callback(e)
    yield put(showAlert(e))
    if (action.callback) {
      action.callback(e)
    }
    yield put(actions.getUserRequested(false))
  }
}

export function* getMemberAgreements(action) {
  yield put(actions.getUserRequested(true))
  try {
    const agreements = yield call(
      API.get(peatioConfigHeader),
      `/account/swap_agreement?${buildQueryString(action.payload)}`,
    )
    yield put(actions.getUserRequested(false))
    yield put(actions.successMemberAgreements(agreements))
  } catch (e) {
    yield put(actions.failMemberAgreements(e))
    yield put(actions.getUserRequested(false))
  }
}

export function* fetchBuyTuxcHistorySaga() {
  yield takeEvery(types.FETCH_BUYTUXC, fetchBuyTuxcHistory)
}
export function* postNewAgreementSaga() {
  yield takeEvery(types.NEW_AGREEMENT, postNewAgreement)
}
export function* fetchAllBuyTuxcHistorySaga() {
  yield takeEvery(types.FETCH_ALL_BUYTUXC, fetchBuyTuxcHistory)
}

export function* fetchSwapCurrencyPriceSaga() {
  yield takeEvery(types.FETCH_CURRENT_SWAP_PRICE, getCurrentSwapPrice)
}
export function* fetchMemberAgreements() {
  yield takeEvery(types.FETCH_MEMBER_AGREEMENTS, getMemberAgreements)
}
