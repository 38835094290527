import ProfileTypes from './profile/types'
import CommonTypes from './common/types'
import AuthTypes from './auth/types'
import PurchasedTokensTypes from './purchasedTokens/types'

const types = {
  ADD_PROFILE: 'ADD_PROFILE',
  SET_PROFILE: 'SET_PROFILE',
  SET_COUNTRY: 'SET_COUNTRY',
  SUCCESS_USER: 'SUCCESS_USER',

  GET_PRESALE: 'GET_PRESALE',
  GET_PRESALE_SUCCESS: 'GET_PRESALE_SUCCESS',
  GET_PRESALE_FAILURE: 'GET_PRESALE_FAILURE',
  ...ProfileTypes,
  ...CommonTypes,
  ...AuthTypes,
  ...PurchasedTokensTypes,
}

export default types
