import { ApiVersion, IApiConfig } from '@core/api/types'

export const defaultConfig = {
  title: 'Playa',
  baseUrl: '',
  api: {
    authUrl: '',
    tradeUrl: '',
    applogicUrl: '',
    rangerUrl: '',
    aliasUrl: 'https://alias.peerclub.com/api',
    dev: 'https://swap.peerclub.com/api/v2',
    prod: 'https://api.peerclub.com/api/v2',
    url: '',
  },

  prodApi: {
    authUrl: '',
    tradeUrl: '',
    applogicUrl: '',
    rangerUrl: '',
    aliasUrl: '',
    dev: '',
    prod: '',
    url: '',
  },

  uatApi: {
    authUrl: '',
    tradeUrl: '',
    applogicUrl: '',
    rangerUrl: '',
    aliasUrl: '',
    dev: '',
    prod: '',
    url: '',
  },

  peerclubUrl: {
    development: '',
    stage: '',
    production: '',
  },

  minutesUntilAutoLogout: '60',
  withCredentials: true,
  captcha: {
    captchaType: 'none',
    siteKey: '',
    recaptchaKeyDev: '',
    recaptchaKeyUat: '',
    recaptchaKeyProd: '',
  },

  stripe: {
    testKey: '',
    liveKey: '',
  },

  defaultStorageLimit: 50,
  chainid: 1,
  chainidTestnet: 11155111,
  defaultCurrency: 'vne',
  defaultCurrencyName: 'Vine',
  sentry: {
    development: 'https://94f423f0e25641048367a92fd80959ed@sentry.peer.inc/7',
    stage: 'https://777a8b75ba32423aa85aa894817082e2@sentry.peer.inc/8',
    uat: 'https://a859f6aa29e74372890215ba1941eab7@sentry.peer.inc/9',
    production: 'https://bbed85fb6a8e4b7595ce07e863194bbb@sentry.peer.inc/6',
  },
  googleKey: 'G-45SD36KSJX',

  termsOfUseVersion: '',
  walletConnectProjectID: 'ea4fe94a9e89ff0df5ca5bc4eb5486ad',
}

export const Frontend = {
  config: defaultConfig,
}
const isProductionApi = process.env?.REACT_APP_STAGE === 'production'
const isUatApi = process.env?.REACT_APP_STAGE === 'uat'

window.env = window.env || defaultConfig
Frontend.config = { ...window.env }

Frontend.config.captcha = Frontend.config.captcha || defaultConfig.captcha

export const baseUrl = () => Frontend.config.baseUrl

export const authUrl = () =>
  isProductionApi
    ? Frontend.config.prodApi.authUrl
    : isUatApi
    ? Frontend.config.uatApi.authUrl
    : Frontend.config.api.authUrl

export const tradeUrl = () =>
  isProductionApi
    ? Frontend.config.prodApi.tradeUrl
    : isUatApi
    ? Frontend.config.uatApi.tradeUrl
    : Frontend.config.api.tradeUrl

export const applogicUrl = () =>
  isProductionApi
    ? Frontend.config.prodApi.applogicUrl
    : isUatApi
    ? Frontend.config.uatApi.applogicUrl
    : Frontend.config.api.applogicUrl

export const rangerUrl = () =>
  isProductionApi
    ? Frontend.config.prodApi.rangerUrl
    : isUatApi
    ? Frontend.config.prodApi.rangerUrl
    : Frontend.config.api.rangerUrl

export const aliasUrl = () => Frontend.config.api.aliasUrl
export const minutesUntilAutoLogout = () => Frontend.config.minutesUntilAutoLogout || '5'
export const withCredentials = () => Frontend.config.withCredentials
export const siteKey = () => Frontend.config.captcha.siteKey
export const captchaType = () => Frontend.config.captcha.captchaType
export const defaultStorageLimit = () => Frontend.config.defaultStorageLimit
export const title = () => Frontend.config.title
export const chainid = () =>
  isProductionApi || isUatApi ? Frontend.config.chainid : Frontend.config.chainidTestnet

export const defaultCurrencyId = Frontend.config.defaultCurrency.toLowerCase()
export const defaultCurrency =
  Frontend.config.defaultCurrencyName || Frontend.config.defaultCurrency.toUpperCase()

export const prodApiUrl = Frontend.config.prodApi.url
export const devApiUrl = Frontend.config.api.url
export const testStripeKey = Frontend.config.stripe.testKey
export const liveStripeKey = Frontend.config.stripe.liveKey
export const TermsOfUseVersion = Frontend.config.termsOfUseVersion

export const BarongConfig: IApiConfig = {
  apiVersion: ApiVersion.barong,
}
