export enum CardNameSize {
  spacious = 3,
  compact = 3,
  small = 4,
}

export enum CardPriceSize {
  spacious = 3,
  compact = 3,
  small = 4,
}
