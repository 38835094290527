import { environments, REACT_APP_STAGE } from './environments'

const sentryEnvKeys = {
  [environments.development]: window.env.sentry.development,
  [environments.stage]: window.env.sentry.stage,
  [environments.uat]: window.env.sentry.uat,
  [environments.production]: window.env.sentry.production,
}

export const getSentryKey = () => {
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    return null
  }

  return sentryEnvKeys[REACT_APP_STAGE] || sentryEnvKeys.development
}
