import {
  SAVE_PROFILE,
  UPDATE_PROFILE,
  FAIL_PROFILE,
  INITIATE_NET_VERIFY,
  SAVE_NET_VERIFY,
  SAVE_TRANSACTION_REFERENCE,
  SUCCESS_TRANSACTION_REFERENCE,
  KYC_AID,
  SET_KYC_AID,
} from '../constants'

export const saveProfile = (payload, navigate) => ({ type: SAVE_PROFILE, payload, navigate })

export const updateProfile = (payload, navigate) => ({ type: UPDATE_PROFILE, payload, navigate })

export const setKycAid = (payload) => ({ type: SET_KYC_AID, payload })

export const kycAid = (callback) => ({ type: KYC_AID, callback })

export const failProfile = (message) => ({ type: FAIL_PROFILE, payload: { message } })

export const initiateNetVerify = () => ({ type: INITIATE_NET_VERIFY })

export const saveNetVerifyUrl = (payload) => ({ type: SAVE_NET_VERIFY, payload })

export const saveTransactionReference = (payload) => ({
  type: SAVE_TRANSACTION_REFERENCE,
  status: payload.status,
  token: payload.token,
})

export const successTransactionReference = (payload) => ({
  type: SUCCESS_TRANSACTION_REFERENCE,
  payload,
})
