import { call, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import * as types from '../constants'
import { API } from '../../api'

const peatioConfig = {
  apiVersion: 'peatio',
}

export function* fetchBalance(action) {
  yield put(actions.getUserRequested(true))

  try {
    let url = '/account/balances'
    if (typeof action.payload != 'undefined') {
      url = `/account/balances/${action.payload}`
    }
    const balances = yield call(API.get(peatioConfig), url)
    let arr = []

    if (!Array.isArray(balances)) {
      arr.push(balances)
    } else {
      arr = balances
    }
    yield put(actions.successFetchBalance(arr))
    yield put(actions.getUserRequested(false))
  } catch (e) {
    yield put(actions.failFetchBalance(e))
    yield put(actions.getUserRequested(false))
  }
}
export function* fetchBalanceSaga() {
  yield takeEvery(types.FETCH_BALANCE, fetchBalance)
}
