import React, { createContext, useState, useMemo } from 'react'

import { i18nextInit } from '../i18n/config'

interface LocalizationContextType {
  isWithKey: boolean
  toggleIsWithKey: () => void
}

export const LocalizationContext = createContext<LocalizationContextType | undefined>(undefined)

export function LocalizationProvider({ children }) {
  const [isWithKey, setIsWithKey] = useState(false)

  const toggleIsWithKey = () => {
    setIsWithKey((prevState) => !prevState)
    i18nextInit(isWithKey)
  }

  const contextValue = useMemo(() => ({ isWithKey, toggleIsWithKey }), [isWithKey, toggleIsWithKey])

  return (
    <LocalizationContext.Provider value={contextValue}>{children}</LocalizationContext.Provider>
  )
}
