/* eslint-disable max-len */
import React from 'react'

export function IconLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="24"
      viewBox="0 0 110 24"
      fill="none"
    >
      <path
        d="M88.6785 23.2008L88.2305 22.7528V4.00078L91.4305 0.800781H106.118L109.318 4.00078V22.7528L108.87 23.2008H104.326L103.878 22.7528V15.2648H93.6705V22.7528L93.2225 23.2008H88.6785ZM93.6705 10.4648H103.878V6.65678L102.822 5.60078H94.7585L93.6705 6.65678V10.4648Z"
        fill="white"
      />
      <path
        d="M65.5332 7.90478V1.24878L65.9812 0.800781H70.5252L70.9732 1.24878V6.68878L76.0932 13.0888L81.1812 6.68878V1.24878L81.6292 0.800781H86.1732L86.6212 1.24878V7.90478L86.3972 8.48078L78.8132 17.3768V22.7528L78.3652 23.2008H73.8212L73.3732 22.7528V17.3768L65.7572 8.48078L65.5332 7.90478Z"
        fill="white"
      />
      <path
        d="M43.2976 23.2008L42.8496 22.7528V4.00078L46.0496 0.800781H60.7376L63.9376 4.00078V22.7528L63.4896 23.2008H58.9456L58.4976 22.7528V15.2648H48.2896V22.7528L47.8416 23.2008H43.2976ZM48.2896 10.4648H58.4976V6.65678L57.4416 5.60078H49.3776L48.2896 6.65678V10.4648Z"
        fill="white"
      />
      <path
        d="M23.9363 0.800781H28.4803L28.9283 1.24878V18.4008H40.7683L41.2483 18.8488L40.6723 22.7528L40.1923 23.2008H23.9363L23.4883 22.7528V1.24878L23.9363 0.800781Z"
        fill="white"
      />
      <path
        d="M0 22.7528V1.24878L0.448 0.800781H17.888L21.088 4.00078V14.6568L17.888 17.8568H5.44V22.7528L4.992 23.2008H0.448L0 22.7528ZM5.44 13.0568H14.56L15.648 11.9688V6.68878L14.56 5.60078H5.44V13.0568Z"
        fill="white"
      />
    </svg>
  )
}

export function IconLogoMobile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="94" height="20" viewBox="0 0 94 20" fill="none">
      <path
        d="M76.2524 19.6321L75.8672 19.2469V3.12267L78.6188 0.371094H91.2485L94.0001 3.12267V19.2469L93.6149 19.6321H89.7076L89.3224 19.2469V12.8082H80.5449V19.2469L80.1596 19.6321H76.2524ZM80.5449 8.68085H89.3224V5.40648L88.4144 4.49846H81.4804L80.5449 5.40648V8.68085Z"
        fill="white"
      />
      <path
        d="M56.3496 6.47959V0.756314L56.7348 0.371094H60.6421L61.0273 0.756314V5.43399L65.4298 10.9371L69.8048 5.43399V0.756314L70.19 0.371094H74.0973L74.4825 0.756314V6.47959L74.2899 6.97487L67.7687 14.6243V19.2469L67.3834 19.6321H63.4762L63.091 19.2469V14.6243L56.5422 6.97487L56.3496 6.47959Z"
        fill="white"
      />
      <path
        d="M37.2299 19.6321L36.8447 19.2469V3.12267L39.5963 0.371094H52.226L54.9776 3.12267V19.2469L54.5924 19.6321H50.6852L50.2999 19.2469V12.8082H41.5224V19.2469L41.1372 19.6321H37.2299ZM41.5224 8.68085H50.2999V5.40648L49.3919 4.49846H42.4579L41.5224 5.40648V8.68085Z"
        fill="white"
      />
      <path
        d="M20.5815 0.371094H24.4887L24.874 0.756314V15.5048H35.0548L35.4675 15.89L34.9722 19.2469L34.5595 19.6321H20.5815L20.1963 19.2469V0.756314L20.5815 0.371094Z"
        fill="white"
      />
      <path
        d="M0 19.2469V0.756314L0.38522 0.371094H15.3813L18.1329 3.12267V12.2854L15.3813 15.037H4.67768V19.2469L4.29246 19.6321H0.38522L0 19.2469ZM4.67768 10.9096H12.5197L13.4552 9.97409V5.43399L12.5197 4.49846H4.67768V10.9096Z"
        fill="white"
      />
    </svg>
  )
}
