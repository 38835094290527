import React, { memo } from 'react'
import cn from 'classnames'
import useScroll, { ScrollDirectionY } from '@hooks/useScroll'
import useCurrentWidth from '@hooks/useCurrentWidth'

import { Breakpoints } from '@constants/settings'
import LeftSection from './components/LeftSection'
import RightSection from './components/RightSection'
import styles from './header.module.scss'

function Header() {
  const { scrollDirectionY, scrollY } = useScroll()

  const { width } = useCurrentWidth()
  const isTablet = width <= Breakpoints.lg

  return (
    <header
      className={cn(
        styles.container,
        {
          [styles.hidden]: scrollDirectionY === ScrollDirectionY.down,
        },
        {
          [styles.bg]:
            (scrollDirectionY === ScrollDirectionY.up ||
              scrollDirectionY === ScrollDirectionY.bottom) &&
            scrollY > 10,
        },
      )}
    >
      <div className={cn(styles.main_container)}>
        <div className={styles.leftGroupMobile}>
          <LeftSection isMobile={isTablet} />
        </div>
        <div className={styles.rightGroupMobile}>
          <RightSection />
        </div>
      </div>
    </header>
  )
}

export default memo(Header)
