/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M45.1447 106.735C96.6781 83.9873 130.99 68.8744 148.201 61.5374C197.235 40.7445 207.54 37.1456 214.162 37C215.637 37 218.886 37.2997 221.084 39.0938C222.859 40.5925 223.3 42.5343 223.601 44.033C223.876 45.5274 224.174 48.6681 223.876 51.0617C221.242 79.4978 209.756 148.485 203.848 180.206C201.357 193.674 196.501 198.164 191.794 198.614C181.489 199.508 173.684 191.735 163.82 185.149C148.201 174.821 139.53 168.383 124.36 158.21C106.84 146.539 118.186 140.101 128.184 129.628C130.841 126.933 176.043 85.0385 176.926 81.294C177.066 80.8487 177.066 79.0482 176.043 78.1532C175.008 77.254 173.535 77.5538 172.351 77.8535C170.728 78.1532 146.002 94.9126 97.8683 127.984C90.7963 132.923 84.4558 135.316 78.7169 135.167C72.3937 135.017 60.3143 131.572 51.1844 128.583C40.148 124.989 31.3167 123.049 32.0417 116.759C32.4918 113.464 36.9063 110.175 45.1447 106.735Z"
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M45.1447 106.735C96.6781 83.9873 130.99 68.8744 148.201 61.5374C197.235 40.7445 207.54 37.1456 214.162 37C215.637 37 218.886 37.2997 221.084 39.0938C222.859 40.5925 223.3 42.5343 223.601 44.033C223.876 45.5274 224.174 48.6681 223.876 51.0617C221.242 79.4978 209.756 148.485 203.848 180.206C201.357 193.674 196.501 198.164 191.794 198.614C181.489 199.508 173.684 191.735 163.82 185.149C148.201 174.821 139.53 168.383 124.36 158.21C106.84 146.539 118.186 140.101 128.184 129.628C130.841 126.933 176.043 85.0385 176.926 81.294C177.066 80.8487 177.066 79.0482 176.043 78.1532C175.008 77.254 173.535 77.5538 172.351 77.8535C170.728 78.1532 146.002 94.9126 97.8683 127.984C90.7963 132.923 84.4558 135.316 78.7169 135.167C72.3937 135.017 60.3143 131.572 51.1844 128.583C40.148 124.989 31.3167 123.049 32.0417 116.759C32.4918 113.464 36.9063 110.175 45.1447 106.735Z"
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M45.1447 106.735C96.6781 83.9873 130.99 68.8744 148.201 61.5374C197.235 40.7445 207.54 37.1456 214.162 37C215.637 37 218.886 37.2997 221.084 39.0938C222.859 40.5925 223.3 42.5343 223.601 44.033C223.876 45.5274 224.174 48.6681 223.876 51.0617C221.242 79.4978 209.756 148.485 203.848 180.206C201.357 193.674 196.501 198.164 191.794 198.614C181.489 199.508 173.684 191.735 163.82 185.149C148.201 174.821 139.53 168.383 124.36 158.21C106.84 146.539 118.186 140.101 128.184 129.628C130.841 126.933 176.043 85.0385 176.926 81.294C177.066 80.8487 177.066 79.0482 176.043 78.1532C175.008 77.254 173.535 77.5538 172.351 77.8535C170.728 78.1532 146.002 94.9126 97.8683 127.984C90.7963 132.923 84.4558 135.316 78.7169 135.167C72.3937 135.017 60.3143 131.572 51.1844 128.583C40.148 124.989 31.3167 123.049 32.0417 116.759C32.4918 113.464 36.9063 110.175 45.1447 106.735Z"
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M45.1447 106.735C96.6781 83.9873 130.99 68.8744 148.201 61.5374C197.235 40.7445 207.54 37.1456 214.162 37C215.637 37 218.886 37.2997 221.084 39.0938C222.859 40.5925 223.3 42.5343 223.601 44.033C223.876 45.5274 224.174 48.6681 223.876 51.0617C221.242 79.4978 209.756 148.485 203.848 180.206C201.357 193.674 196.501 198.164 191.794 198.614C181.489 199.508 173.684 191.735 163.82 185.149C148.201 174.821 139.53 168.383 124.36 158.21C106.84 146.539 118.186 140.101 128.184 129.628C130.841 126.933 176.043 85.0385 176.926 81.294C177.066 80.8487 177.066 79.0482 176.043 78.1532C175.008 77.254 173.535 77.5538 172.351 77.8535C170.728 78.1532 146.002 94.9126 97.8683 127.984C90.7963 132.923 84.4558 135.316 78.7169 135.167C72.3937 135.017 60.3143 131.572 51.1844 128.583C40.148 124.989 31.3167 123.049 32.0417 116.759C32.4918 113.464 36.9063 110.175 45.1447 106.735Z"
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M45.1447 106.735C96.6781 83.9873 130.99 68.8744 148.201 61.5374C197.235 40.7445 207.54 37.1456 214.162 37C215.637 37 218.886 37.2997 221.084 39.0938C222.859 40.5925 223.3 42.5343 223.601 44.033C223.876 45.5274 224.174 48.6681 223.876 51.0617C221.242 79.4978 209.756 148.485 203.848 180.206C201.357 193.674 196.501 198.164 191.794 198.614C181.489 199.508 173.684 191.735 163.82 185.149C148.201 174.821 139.53 168.383 124.36 158.21C106.84 146.539 118.186 140.101 128.184 129.628C130.841 126.933 176.043 85.0385 176.926 81.294C177.066 80.8487 177.066 79.0482 176.043 78.1532C175.008 77.254 173.535 77.5538 172.351 77.8535C170.728 78.1532 146.002 94.9126 97.8683 127.984C90.7963 132.923 84.4558 135.316 78.7169 135.167C72.3937 135.017 60.3143 131.572 51.1844 128.583C40.148 124.989 31.3167 123.049 32.0417 116.759C32.4918 113.464 36.9063 110.175 45.1447 106.735Z"
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M45.1447 106.735C96.6781 83.9873 130.99 68.8744 148.201 61.5374C197.235 40.7445 207.54 37.1456 214.162 37C215.637 37 218.886 37.2997 221.084 39.0938C222.859 40.5925 223.3 42.5343 223.601 44.033C223.876 45.5274 224.174 48.6681 223.876 51.0617C221.242 79.4978 209.756 148.485 203.848 180.206C201.357 193.674 196.501 198.164 191.794 198.614C181.489 199.508 173.684 191.735 163.82 185.149C148.201 174.821 139.53 168.383 124.36 158.21C106.84 146.539 118.186 140.101 128.184 129.628C130.841 126.933 176.043 85.0385 176.926 81.294C177.066 80.8487 177.066 79.0482 176.043 78.1532C175.008 77.254 173.535 77.5538 172.351 77.8535C170.728 78.1532 146.002 94.9126 97.8683 127.984C90.7963 132.923 84.4558 135.316 78.7169 135.167C72.3937 135.017 60.3143 131.572 51.1844 128.583C40.148 124.989 31.3167 123.049 32.0417 116.759C32.4918 113.464 36.9063 110.175 45.1447 106.735Z"
    />,
  ],
])

const CustomIconShareTG: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconShareTG.displayName = 'CustomIconShareTG'

export default CustomIconShareTG
