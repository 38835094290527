import {
  FETCH_ORDER_HISTORY,
  SUCCESS_ORDER_HISTORY,
  FAIL_ORDER_HISTORY,
  UPDATE_ORDER_HISTORY,
} from '../constants'

export const fetchOrderHistory = (payload) => ({ type: FETCH_ORDER_HISTORY, payload })

export const successOrderHistory = (payload) => ({ type: SUCCESS_ORDER_HISTORY, payload })

export const failOrderHistory = (payload) => ({ type: FAIL_ORDER_HISTORY, payload })

export const updateOrderHistory = (payload) => ({ type: UPDATE_ORDER_HISTORY, payload })
