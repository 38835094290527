import { createSelector } from 'reselect'
import { defaultCurrencyId } from '@core/api/config'
import { shouldUpdateFields, shouldUpdatePhone, shouldVerifyPhone } from '@utils/helpers'

export * from './icx/redux/selectors'

const selectCurrencies = (state) => state.common.currency.data

export const defaultCurrency = createSelector([selectCurrencies], (currencies) =>
  currencies?.find((item) => item.id === defaultCurrencyId),
)

export const priceDefaultCurrency = createSelector(
  (state) => state.common,
  ({ currency, price }) =>
    Number(
      price.data?.[defaultCurrencyId] ||
        currency.data?.find((c) => c.id === defaultCurrencyId)?.price,
    ),
)

const profile = (state) => state.profile.data
const profileLoading = (state) => state.profile.loading

const isAuthenticated = createSelector([profile, profileLoading], (profileData, authLoading) => ({
  authLoading,
  isAuth: profileData?.state === 'active',
  authLoaded: profileData !== null,
  isEmailVerified: profileData?.labels ? profileData?.labels[1]?.value === 'verified' : false,
  isPhoneVerified: profileData?.labels ? profileData?.labels[0]?.value === 'verified' : false,
}))

const additionalUserDataCompleted = createSelector(
  [profile],
  (data) => data !== null && !shouldUpdateFields(data),
)

const isPhoneExist = createSelector([profile], (data) => data !== null && !shouldUpdatePhone(data))

const isPhoneVerified = createSelector(
  [profile],
  (data) => data !== null && !shouldVerifyPhone(data),
)

export const configSelectors = {
  user: (state) => state.user,
  userData: (state) => state.user.data,
  kycAid: (state) => state.user.kycAid,
  profiles: (state) => state?.user?.data?.profiles,

  profile,
  additionalUserDataCompleted,
  isPhoneVerified,
  isPhoneExist,

  modifyUserLoading: (state) => state.profile.modifyUserLoading,
  profileLoading,
  profileUploadPFPLoading: (state) => state.profile.uploadLoading,
  externalNFTLoader: (state) => state.profile.externalNFTLoader,

  profileNotifications: (state) => state.profile.notifications,
  profileWallet: (state) => state.profile.wallet,
  externalNFTList: (state) => state.profile.externalNFTList,

  otpPopupLoading: (state) => state.profile.otpPopup.loading,
  deleteAccountPopupLoading: (state) => state.profile.deleteAccountPopup.loading,

  profileEmojiCollected: (state) => state.profile.emojiCollected,
  profileWonkaCollected: (state) => state.profile.wonkaCollected,

  purchasedTokens: (state) => state.purchasedTokens.purchase,
  transactions: (state) => state.purchasedTokens.transactions,

  lastCountry: (state) => state.profile.data?.last_country,

  currencies: selectCurrencies,

  withdrawLimits: (state) => state.common.withdrawLimit.data,
  fiatBanxaCurrencies: (state) => state.common.fiatBanxaCurrency.data,
  paymentBanxaMethods: (state) => state.common.paymentBanxaMethods.data,
  currenciesPrice: (state) => state.common.price.data,
  defaultCurrency,
  priceDefaultCurrency,
  priceDefaultCoin: (state) => state.common.defaultCoin.data,
  balance: (state) => state.common.balance.data,
  processingTransactions: (state) => state.common.processingTransactionsAddresses,

  preSale: (state) => state,
  preSaleDate: (state) => state?.date?.preSale,
  swap: (state) => state.swap,
  isAuthenticated,
  wonkas: (state) => state.wonkas.paginated,
  wonkaFetchLoading: (state) => state.wonkas.loading.list,
  wonkaPurchaseLoading: (state) => state.wonkas.loading.purchase,
  wonkaRevealAllLoading: (state) => state.wonkas.loading.revealAll,
  wonkasTotal: (state) => state.wonkas.total,
  wonkaTokensPopup: (state) => state.wonkas.wonkaTokensPopup,

  authLoading: (state) => state.auth.loading,
  authUserFields: (state) => state.auth.userFields,
  authEmail: (state) => state.auth.userFields?.email,
  authUsername: (state) => state.auth.userName,
  routeBeforeAuth: (state) => state.auth.routeBeforeAuth,
}
