import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import routes from '@routing/path'

import { AuthEvents, eventTrack } from '@utils/analyticsTracker'
import styles from './login_buttons.module.scss'

function LoginButtons() {
  const { t } = useTranslation('nsAuth')

  const onSigninClick = () => {
    eventTrack(AuthEvents.authBegin())
  }

  return (
    <div className={styles.container}>
      <Link to={routes.withoutAuth.auth} className={styles.signin_link} onClick={onSigninClick}>
        <div className={styles.button}>{t('loginBtn')}</div>
      </Link>
    </div>
  )
}

export default LoginButtons
