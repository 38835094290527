import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setProfileEmojiPicture, setProfileWonkaPicture } from '@core/profile/actions'
import Modal from '@components/UiKit/Modal'
import Typography from '@components/UiKit/Typography'
import Button from '@components/UiKit/ButtonNew/Button'
import MojiCard from '@components/MojiCard'
import NavBar from '@components/UiKit/NavBar'
import Loader from '@components/Loader'
import useDisableScroll from '@hooks/useDisableScroll'
import IconButton from '@components/UiKit/ButtonNew/IconButton'
import { IPlayaTypes, IDataType, IHeadersType } from '@containers/Settings/Modals/PlayaNFTs/types'
import NoDataPurchased from '@components/Profile/NoDataPurchased'
import { TNullable } from 'types/utilityTypes'
import { getPageList } from './PlayaSettings'
import styles from './modal.module.scss'

const { Heading } = Typography

export interface IPlayaTypeProps {
  open: boolean
  onClose: () => void
  isLoadingImages: boolean
  data: IDataType
  fetchData: (type) => void
  headers?: IHeadersType
}

function PlayaNFTs({
  open,
  onClose,
  isLoadingImages,
  data,
  fetchData,
  headers,
}: Readonly<IPlayaTypeProps>) {
  const [imageType, setImageType] = useState<IPlayaTypes>(IPlayaTypes.emoji)
  const [activeCode, setActiveCode] = useState('')
  const [modalScrollBlock, setModalScrollBlock] = useState<TNullable<HTMLDivElement>>(null)
  const { t } = useTranslation('nsPopupTitles')
  const { t: tSettings } = useTranslation('nsSettings')
  const { t: tCommon } = useTranslation('nsCommon')

  Object.keys(data).forEach((key) => {
    if (key === 'wonka' && data[key] !== undefined) {
      data[key] = data[key]?.filter((item) => item.revealed === true)
    }
  })

  const refIsGetAllData = useRef(false)

  refIsGetAllData.current = (data[imageType]?.length ?? 0) >= Number(headers?.[imageType]?.total)

  const dispatch = useDispatch()
  useDisableScroll(open)

  const onClickCard = useCallback((code) => {
    setActiveCode(code)
  }, [])

  const handleSelect = () => {
    const formData = new FormData()

    if (imageType === IPlayaTypes.wonka) {
      formData.append('wonka', activeCode)
      dispatch(setProfileWonkaPicture(formData, () => {}))
    } else if (imageType === IPlayaTypes.emoji) {
      formData.append('token', activeCode)
      dispatch(setProfileEmojiPicture(formData, () => {}))
    }

    onClose()
    setActiveCode('')
    setImageType(IPlayaTypes.emoji)
  }

  const handleClose = useCallback(() => {
    onClose()
    setActiveCode('')
    setTimeout(() => setImageType(IPlayaTypes.emoji), 1000)
  }, [])

  const onChangeTab = useCallback(
    (type: IPlayaTypes) => {
      setActiveCode('')
      setImageType(type)
      modalScrollBlock?.scrollTo?.(0, 0)
    },
    [modalScrollBlock],
  )

  useEffect(() => {
    let prevScrollTop = 0
    const handleScroll = () => {
      if (modalScrollBlock) {
        const { scrollTop, clientHeight, scrollHeight } = modalScrollBlock

        if (prevScrollTop > scrollTop || refIsGetAllData.current) return

        if (clientHeight + scrollTop + 500 >= scrollHeight) {
          prevScrollTop = scrollHeight
          fetchData(imageType)
          return
        }

        prevScrollTop = scrollTop
      }
    }

    modalScrollBlock?.addEventListener('scroll', handleScroll)
    return () => {
      modalScrollBlock?.removeEventListener('scroll', handleScroll)
    }
  }, [modalScrollBlock, imageType, fetchData])

  const currentData = data[imageType]
  const currentDataLength = currentData?.length

  const navBarTabs = useMemo(() => {
    const pageList = getPageList({ mojisTitle: tSettings('nfts'), wonkaTilte: tSettings('wonka') })

    if (data[IPlayaTypes.wonka]?.length !== 0) {
      return pageList
    }

    return pageList.filter((item) => item.key !== IPlayaTypes.wonka)
  }, [data])

  return (
    <Modal onClose={onClose} open={open} className={cn(styles.playaNFTsWrapper)}>
      <div className={styles.header}>
        <Heading size={3} className={styles.title}>
          {t('playaNfts')}
        </Heading>
        <IconButton
          onClick={handleClose}
          icon="CustomIconClose"
          className={styles.closeBtn}
          variant="secondary"
        />
      </div>

      <NavBar
        wrapperClassName={styles.navBar}
        fullWidth
        align="middle"
        size="medium"
        items={navBarTabs}
        onSelectTab={onChangeTab}
      />

      <Loader className={styles.loader} absoluteCenter loading={isLoadingImages} />

      <div className={styles.list} ref={(e) => setModalScrollBlock(e)}>
        {(currentDataLength &&
          data[imageType]?.map((item, i) => {
            const { token, name, code } = item
            const itemName = token?.name || name
            const imgSrc = token?.media?.medium?.url || token?.media?.large?.url
            const imgWonkaSrc = item?.media?.medium?.url || item?.media?.large?.url
            const itemCode = token?.code || code
            const order = i + 1
            const balance = Number(item?.balance)

            return (
              <MojiCard
                style={{
                  order,
                }}
                className={styles.card}
                key={itemCode}
                name={itemName}
                src={imgSrc || imgWonkaSrc}
                onClick={() => onClickCard(itemCode)}
                isActive={itemCode === activeCode}
                quantity={balance > 1 ? balance : undefined}
              />
            )
          })) || <NoDataPurchased />}
      </div>

      <div className={styles.footer}>
        <Button
          type="submit"
          variant="secondary"
          className={styles.cancel_button}
          onClick={handleClose}
        >
          {tCommon('cancel')}
        </Button>

        <Button type="submit" variant="primary" onClick={handleSelect} disabled={!activeCode}>
          {tCommon('confirmSelected')}
        </Button>
      </div>
    </Modal>
  )
}

export default React.memo(PlayaNFTs)
