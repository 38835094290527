import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TNullable } from 'types/utilityTypes'
import MenuItem from './MenuItem'
import getMenuItems from './items'
import styles from './menuList.module.scss'

interface IMenuListProps {
  setIsProfileDropdown: (value: boolean) => void
}

function MenuList({ setIsProfileDropdown }: Readonly<IMenuListProps>) {
  const [hoveredItem, setHoverItem] = useState<TNullable<number>>(null)
  const { t } = useTranslation('nsCommon')

  const handleItemHover = (itemId: number): void => {
    setHoverItem(itemId)
  }

  const menuItems = useMemo(() => getMenuItems(t), [t])

  return (
    <ul className={styles.list}>
      {menuItems.map(({ label, id, status, to, isRouter }) => (
        <MenuItem
          status={status}
          label={label}
          key={id}
          id={id}
          onHover={handleItemHover}
          isHovered={hoveredItem === id}
          to={to}
          setIsProfileDropdown={setIsProfileDropdown}
          isRouter={isRouter}
        />
      ))}
    </ul>
  )
}

export default MenuList
