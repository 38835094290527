// eslint-disable-next-line import/no-unresolved
import { IndicatorsContainerProps } from 'react-select/dist/declarations/src/components/containers'
import cn from 'classnames'
import React from 'react'
import { FunnelSimple } from '@phosphor-icons/react'
import Chevron from '@assets/icons/chevron_down_bold.svg'

import { Option } from '@components/UiKit/Form/Dropdown/Dropdown.types'
import styles from './style.module.scss'

export function CustomIndicator(
  minimizeOnMobile: boolean,
  { selectProps }: IndicatorsContainerProps<Option>,
) {
  const isMobile = window.innerWidth < 560
  if (minimizeOnMobile && isMobile) {
    return <FunnelSimple size={24} color="#fff" className={styles.mobileIndicator} />
  }
  return (
    <img
      width={24}
      src={Chevron}
      alt="checked"
      className={cn(styles.indicator, selectProps.menuIsOpen && styles.indicator__open)}
    />
  )
}
