export const DECRYPT_TOKEN = 'payment-gateway/DECRYPT_TOKEN'
export const FAIL_DECRYPT = 'payment-gateway/FAIL_DECRYPTION'
export const STORE_ADDRESS = 'payment-gateway/STORE_ADDRESS'
export const STORE_PAYMENT_STATUS = 'payment-gateway/STORE_PAYMENT_STATUS'
export const MAKE_PAYMENT_REQUEST = 'payment-gateway/MAKE_PAYMENT_REQUEST'
export const SAVE_PAYMENT_DETAIL = 'payment-gateway/SAVE_PAYMENT_DETAIL'
export const PAYMENT_STATUS = 'payment-gateway/PAYMENT_STATUS'
export const PAYMENT_VERIFIED = 'payment-gateway/PAYMENT_VERIFIED'
export const CONVERT_TO_ANOTHER_CURRENCY = 'payment-gateway/CONVERT_TO_ANOTHER_CURRENCY'

export const CONVERT_TO_ANOTHER_CURRENCY_OUTPUT =
  'payment-gateway/CONVERT_TO_ANOTHER_CURRENCY_OUTPUT'
export const QUOTE = 'payment-gateway/QUOTE'
export const SUCCESS_QUOTES = 'payment-gateway/SUCCESS_QUOTES'
export const UPDATE_QUOTES = 'payment-gateway/UPDATE_QUOTES'
export const SET_SIMPLEX_ID = 'payment-gateway/SET_SIMPLEX_ID'
export const FETCH_QUOTE_TRANSACTION = 'payment-gateway/FETCH_QUOTE_TRANSACTION'
export const SET_QUOTE_TRANSACTIONS = 'payment-gateway/SET_QUOTE_TRANSACTIONS'
