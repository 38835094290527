/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M94.0016 35.0693C110.966 28.6789 129.33 27.302 147.015 31.0946C164.7 34.8872 180.999 43.6976 194.066 56.5279C207.132 69.359 216.445 85.6965 220.948 103.69C225.45 121.684 224.963 140.615 219.543 158.34C214.121 176.064 203.983 191.872 190.275 203.975C176.567 216.077 159.838 223.989 141.983 226.815C133.645 228.134 125.847 222.253 124.565 213.679C123.281 205.103 129 197.083 137.336 195.764C149.554 193.83 160.999 188.417 170.379 180.136C179.758 171.856 186.695 161.039 190.404 148.912C194.113 136.785 194.446 123.832 191.365 111.521C188.285 99.2091 181.913 88.0307 172.973 79.2515C164.033 70.473 152.881 64.4447 140.78 61.8497C128.679 59.2548 116.114 60.197 104.508 64.5692C92.9015 68.9414 82.7174 76.5693 75.1095 86.5888C67.5008 96.6083 62.7736 108.619 61.4612 121.26C60.5653 129.887 53.0406 136.133 44.6531 135.212C36.2656 134.291 30.1922 126.551 31.0876 117.925C33.0053 99.4498 39.9153 81.8962 51.0349 67.2521C62.1537 52.6081 77.0385 41.4596 94.0016 35.0693Z"
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M94.0016 35.0693C110.966 28.6789 129.33 27.302 147.015 31.0946C164.7 34.8872 180.999 43.6976 194.066 56.5279C207.132 69.359 216.445 85.6965 220.948 103.69C225.45 121.684 224.963 140.615 219.543 158.34C214.121 176.064 203.983 191.872 190.275 203.975C176.567 216.077 159.838 223.989 141.983 226.815C133.645 228.134 125.847 222.253 124.565 213.679C123.281 205.103 129 197.083 137.336 195.764C149.554 193.83 160.999 188.417 170.379 180.136C179.758 171.856 186.695 161.039 190.404 148.912C194.113 136.785 194.446 123.832 191.365 111.521C188.285 99.2091 181.913 88.0307 172.973 79.2515C164.033 70.473 152.881 64.4447 140.78 61.8497C128.679 59.2548 116.114 60.197 104.508 64.5692C92.9015 68.9414 82.7174 76.5693 75.1095 86.5888C67.5008 96.6083 62.7736 108.619 61.4612 121.26C60.5653 129.887 53.0406 136.133 44.6531 135.212C36.2656 134.291 30.1922 126.551 31.0876 117.925C33.0053 99.4498 39.9153 81.8962 51.0349 67.2521C62.1537 52.6081 77.0385 41.4596 94.0016 35.0693Z"
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M94.0016 35.0693C110.966 28.6789 129.33 27.302 147.015 31.0946C164.7 34.8872 180.999 43.6976 194.066 56.5279C207.132 69.359 216.445 85.6965 220.948 103.69C225.45 121.684 224.963 140.615 219.543 158.34C214.121 176.064 203.983 191.872 190.275 203.975C176.567 216.077 159.838 223.989 141.983 226.815C133.645 228.134 125.847 222.253 124.565 213.679C123.281 205.103 129 197.083 137.336 195.764C149.554 193.83 160.999 188.417 170.379 180.136C179.758 171.856 186.695 161.039 190.404 148.912C194.113 136.785 194.446 123.832 191.365 111.521C188.285 99.2091 181.913 88.0307 172.973 79.2515C164.033 70.473 152.881 64.4447 140.78 61.8497C128.679 59.2548 116.114 60.197 104.508 64.5692C92.9015 68.9414 82.7174 76.5693 75.1095 86.5888C67.5008 96.6083 62.7736 108.619 61.4612 121.26C60.5653 129.887 53.0406 136.133 44.6531 135.212C36.2656 134.291 30.1922 126.551 31.0876 117.925C33.0053 99.4498 39.9153 81.8962 51.0349 67.2521C62.1537 52.6081 77.0385 41.4596 94.0016 35.0693Z"
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M94.0016 35.0693C110.966 28.6789 129.33 27.302 147.015 31.0946C164.7 34.8872 180.999 43.6976 194.066 56.5279C207.132 69.359 216.445 85.6965 220.948 103.69C225.45 121.684 224.963 140.615 219.543 158.34C214.121 176.064 203.983 191.872 190.275 203.975C176.567 216.077 159.838 223.989 141.983 226.815C133.645 228.134 125.847 222.253 124.565 213.679C123.281 205.103 129 197.083 137.336 195.764C149.554 193.83 160.999 188.417 170.379 180.136C179.758 171.856 186.695 161.039 190.404 148.912C194.113 136.785 194.446 123.832 191.365 111.521C188.285 99.2091 181.913 88.0307 172.973 79.2515C164.033 70.473 152.881 64.4447 140.78 61.8497C128.679 59.2548 116.114 60.197 104.508 64.5692C92.9015 68.9414 82.7174 76.5693 75.1095 86.5888C67.5008 96.6083 62.7736 108.619 61.4612 121.26C60.5653 129.887 53.0406 136.133 44.6531 135.212C36.2656 134.291 30.1922 126.551 31.0876 117.925C33.0053 99.4498 39.9153 81.8962 51.0349 67.2521C62.1537 52.6081 77.0385 41.4596 94.0016 35.0693Z"
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M94.0016 35.0693C110.966 28.6789 129.33 27.302 147.015 31.0946C164.7 34.8872 180.999 43.6976 194.066 56.5279C207.132 69.359 216.445 85.6965 220.948 103.69C225.45 121.684 224.963 140.615 219.543 158.34C214.121 176.064 203.983 191.872 190.275 203.975C176.567 216.077 159.838 223.989 141.983 226.815C133.645 228.134 125.847 222.253 124.565 213.679C123.281 205.103 129 197.083 137.336 195.764C149.554 193.83 160.999 188.417 170.379 180.136C179.758 171.856 186.695 161.039 190.404 148.912C194.113 136.785 194.446 123.832 191.365 111.521C188.285 99.2091 181.913 88.0307 172.973 79.2515C164.033 70.473 152.881 64.4447 140.78 61.8497C128.679 59.2548 116.114 60.197 104.508 64.5692C92.9015 68.9414 82.7174 76.5693 75.1095 86.5888C67.5008 96.6083 62.7736 108.619 61.4612 121.26C60.5653 129.887 53.0406 136.133 44.6531 135.212C36.2656 134.291 30.1922 126.551 31.0876 117.925C33.0053 99.4498 39.9153 81.8962 51.0349 67.2521C62.1537 52.6081 77.0385 41.4596 94.0016 35.0693Z"
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(0%,0%,0%)', fillOpacity: 1 }}
      d="M94.0016 35.0693C110.966 28.6789 129.33 27.302 147.015 31.0946C164.7 34.8872 180.999 43.6976 194.066 56.5279C207.132 69.359 216.445 85.6965 220.948 103.69C225.45 121.684 224.963 140.615 219.543 158.34C214.121 176.064 203.983 191.872 190.275 203.975C176.567 216.077 159.838 223.989 141.983 226.815C133.645 228.134 125.847 222.253 124.565 213.679C123.281 205.103 129 197.083 137.336 195.764C149.554 193.83 160.999 188.417 170.379 180.136C179.758 171.856 186.695 161.039 190.404 148.912C194.113 136.785 194.446 123.832 191.365 111.521C188.285 99.2091 181.913 88.0307 172.973 79.2515C164.033 70.473 152.881 64.4447 140.78 61.8497C128.679 59.2548 116.114 60.197 104.508 64.5692C92.9015 68.9414 82.7174 76.5693 75.1095 86.5888C67.5008 96.6083 62.7736 108.619 61.4612 121.26C60.5653 129.887 53.0406 136.133 44.6531 135.212C36.2656 134.291 30.1922 126.551 31.0876 117.925C33.0053 99.4498 39.9153 81.8962 51.0349 67.2521C62.1537 52.6081 77.0385 41.4596 94.0016 35.0693Z"
    />,
  ],
])

const CustomIconResend: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconResend.displayName = 'CustomIconResend'

export default CustomIconResend
