import React from 'react'
import routes from '@routing/path'
import NavBar from '@components/UiKit/NavBar'

function TestNavBar() {
  return (
    <div>
      <NavBar
        align="middle"
        size="large"
        items={[
          { children: 'Profile', key: 'profile', href: routes.withAuth.profile.settings },
          { children: 'Account', key: 'account', href: routes.withAuth.profile.wallet.main },
          { children: 'Wallets', key: 'wallets', href: routes.withAuth.profile.wallet.main },
        ]}
      />
      <br />
      <NavBar
        align="left"
        size="medium"
        items={[
          { children: 'Profile', key: 'profile', href: routes.withAuth.profile.settings },
          { children: 'Account', key: 'account', href: routes.withAuth.profile.wallet.main },
          { children: 'Wallets', key: 'wallets', href: routes.withAuth.profile.wallet.main },
        ]}
      />
      <br />
      <NavBar
        align="middle"
        size="medium"
        items={[
          { children: 'Profile', key: 'profile', href: routes.withAuth.profile.settings },
          { children: 'Account', key: 'account', href: routes.withAuth.profile.wallet.main },
          { children: 'Wallets', key: 'wallets', href: routes.withAuth.profile.wallet.main },
        ]}
      />
      <br />
      <NavBar
        align="right"
        size="medium"
        items={[
          { children: 'Profile', key: 'profile', href: routes.withAuth.profile.settings },
          { children: 'Account', key: 'account', href: routes.withAuth.profile.wallet.main },
          { children: 'Wallets', key: 'wallets', href: routes.withAuth.profile.wallet.main },
        ]}
      />
    </div>
  )
}

export default TestNavBar
