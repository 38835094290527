import { put, select } from 'redux-saga/effects'
import queryString from 'query-string'

import { configSelectors } from '@core/selectors'
import { fetchSendSessionOTPRequest, setAuthFields, setRouteBeforeAuth } from '@core/auth/actions'
import routes from '@routing/path'
import { AuthPages } from '@containers/Auth/types'
import {
  shouldUpdateFields,
  shouldUpdatePhone,
  shouldVerifyEmail,
  shouldVerifyPhone,
} from '@utils/helpers'
import { AuthEvents, eventTrack } from '@utils/analyticsTracker'

export function* successSigninRedirect(navigate) {
  const routeBeforeAuth = yield select(configSelectors.routeBeforeAuth)
  yield put(setRouteBeforeAuth(null))

  // todo: check if routeBeforeAuth still needed
  // return navigate(routeBeforeAuth ?? routes.withAuth.home)
  return navigate(routes.withAuth.profile.purchased)
}

export function* navigateAfterSigninEmailOtp(navigate, userData, payload) {
  if (shouldUpdatePhone(userData)) {
    return navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: { page: AuthPages.updatePhone },
      }),
    )
  }

  if (shouldVerifyPhone(userData)) {
    const validPhone = `+${userData.phone_number}`

    yield put(
      fetchSendSessionOTPRequest({ identity: validPhone, captcha_response: payload.captchaValue }),
    )

    yield put(setAuthFields({ phone: validPhone }))

    return navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: { page: AuthPages.phoneOtp },
      }),
    )
  }

  if (shouldUpdateFields(userData)) {
    return navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: {
          page: AuthPages.userData,
        },
      }),
    )
  }

  eventTrack(AuthEvents.authCompleted())

  return yield successSigninRedirect(navigate)
}

export function* navigateAfterSignupEmailOtp(navigate, userData) {
  if (shouldUpdateFields(userData)) {
    return navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: {
          page: AuthPages.userData,
        },
      }),
    )
  }

  eventTrack(AuthEvents.authCompleted())

  return yield successSigninRedirect(navigate)
}

export function* navigateAfterPhoneOtp(navigate, userData) {
  if (shouldVerifyEmail(userData)) {
    return navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: { page: AuthPages.signupEmailOtp },
      }),
    )
  }

  if (shouldUpdateFields(userData)) {
    return navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: {
          page: AuthPages.userData,
        },
      }),
    )
  }

  eventTrack(AuthEvents.authCompleted())

  return yield successSigninRedirect(navigate)
}
