import { SHOW_ALERT, RESET_ALERT } from '../constants'

const initState = {
  message: [],
  code: null,
  type: null,
}

// eslint-disable-next-line default-param-last
export const alertReducer = (state = initState, action) => {
  switch (action?.type) {
    case SHOW_ALERT: {
      return {
        ...state,
        message: action.payload?.message,
        code: action.payload?.code,
        type: action.payload?.type,
      }
    }

    case RESET_ALERT: {
      return {
        ...state,
        message: null,
        code: null,
        type: null,
      }
    }

    default:
      return state
  }
}
