import { useState, useEffect } from 'react'
import { TSize } from '@components/UiKit/types'
import { BreakpointFlags, SizeByBreakpointFlag } from '@constants/index'
import { TNullable } from 'types/utilityTypes'

const getSize = () => ({
  width: document.documentElement.getBoundingClientRect().width || document.body.clientWidth,
  height: document.documentElement.getBoundingClientRect().height || document.body.clientHeight,
})

export function useCurrentHeight(mobileBreakpoint) {
  const [height, setHeight] = useState(getSize().height)

  useEffect(() => {
    let timeoutId = {} as NodeJS.Timeout
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setHeight(getSize().height), 150)
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  if (mobileBreakpoint) {
    return { height, isMobile: height <= mobileBreakpoint }
  }

  return height
}

export default function useCurrentWidth() {
  const [width, setWidth] = useState(getSize().width)

  useEffect(() => {
    let timeoutId: TNullable<NodeJS.Timeout> = null
    const resizeListener = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => setWidth(getSize().width), 150)
    }
    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  const result = {
    width,
    isMobile: width <= BreakpointFlags.isMobile,
    isTablet: width <= BreakpointFlags.isTablet && width > BreakpointFlags.isMobile,
    isLaptop: width <= BreakpointFlags.isLaptop && width > BreakpointFlags.isTablet,
    isDesktop: width <= BreakpointFlags.isDesktop && width > BreakpointFlags.isLaptop,
    isFullHd: width > BreakpointFlags.isDesktop,
    size: TSize.compact,
  }

  const currentFlagIndex = Object.values(result).findIndex(
    (value) => typeof value === 'boolean' && value === true,
  )
  const currentFlagName = Object.keys(result)[currentFlagIndex]
  result.size = SizeByBreakpointFlag[currentFlagName] || TSize.compact

  return result
}
