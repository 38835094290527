/* eslint-disable no-bitwise */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import BigNumber from 'bignumber.js'
import * as moment from 'moment-timezone'
import ReactGA from 'react-ga4'
import { differenceInYears, parse } from 'date-fns'
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import * as emailValidator from 'email-validator'

import { environments, isProductionEnvironment, REACT_APP_STAGE } from '@constants/environments'

const eventTracker = ({ category, action, label, value, nonInteraction = false }) => {
  let options = { category, action, nonInteraction }

  if (label) options = { ...options, label }
  if (value) options = { ...options, value }

  if (isProductionEnvironment && category && action) {
    ReactGA.event(options)
  }

  return null
}

function searchInString(str, searchValue) {
  return str.toLowerCase().includes(searchValue.toLowerCase())
}

function getSlugByText(text) {
  if (!text) return text
  return text.replaceAll(' ', '').toLowerCase()
}

function getTotalPriceWithFees(currency, cartList) {
  return cartList
    .map((i) => {
      const sum = i?.token
        ? (i?.volume || 0) * Number(i?.token?.[currency] || i?.[currency] || 0)
        : Number(i?.token?.[currency] || i?.[currency] || 0)

      if (i.fee) {
        return Number(i.fee) + sum
      }

      const calculatedFee =
        i?.token?.fees_type === 'percentage' || i?.fees_type === 'percentage'
          ? Number(i?.token?.fees || i?.fees || 0) * (sum / 100)
          : Number(i?.token?.fees || i?.fees || 0)
      return sum + calculatedFee
    })
    .reduce((partialSum, a) => partialSum + a, 0)
    .toFixed(2)
}

function getFees(amount, cartList) {
  return cartList
    ?.map((i) => {
      if (i.fee) {
        return Number(i.fee)
      }
      const calculatedFee =
        i?.token?.fees_type === 'percentage' || i?.fees_type === 'percentage'
          ? Number(i?.token?.fees || i?.fees || 0) * (amount / 100)
          : Number(i?.token?.fees || i?.fees || 0)
      return calculatedFee
    })
    .reduce((partialSum, a) => partialSum + a, 0)
}

function getFee({ feeType, fee, amount }) {
  if (!amount || !fee) return 0
  if (feeType === 'percentage') {
    return Number(fee || 0) * amount
  }

  return Number(fee || 0)
}

const calculateDefaultCoinPrice = (nftItem, currencyData) => {
  const discount =
    (Number(nftItem?.price_usd || nftItem?.token?.price_usd || 0) / 100) *
    Number(nftItem?.price_peer || nftItem?.token?.price_peer || 0)

  return (Number(nftItem?.price_usd || nftItem?.token?.price_usd) - discount) / (currencyData || 0)
}

function getTotalPrice(currency, cartList, currencyData = {}) {
  if (!cartList) return '0.00'
  if (currency === 'price_peer') {
    return cartList
      .reduce((partialSum, a) => partialSum + 1 * calculateDefaultCoinPrice(a, currencyData), 0)
      .toFixed(2)
  }
  return cartList
    .reduce(
      // 1 as a volume is temporary till purchase volume will be implemented
      (partialSum, a) => partialSum + (1 * Number(a?.token?.[currency]) || 0),
      0,
    )
    .toFixed(2)
}

const convertDate = (utcDate) => {
  const date = new Date(utcDate)
  return date.toLocaleString()
}

const getTransactionId = (id) => {
  if (!id) return ''
  return `${`${id.slice(0, 6)}---${id.slice(-6)}`}`
}

const capitalize = (word) => (word ? word[0].toUpperCase() + word.slice(1) : word)

const toFixed = (num, tofixed = 2) => new BigNumber(num).toFixed(tofixed)

const truncateData = (num, fixed) => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`)
  let number
  if (num === 0) {
    number = num.toFixed(fixed)
  } else {
    number = new BigNumber(num || 0).toFixed()
  }
  if (number == Infinity || number == null) {
    return 0
  }
  return number?.toString()?.match(re)[0]
}

const convertPmc = (pmc, usd, amount) => {
  const usdToCoinPrice = 1 / pmc
  const latestPrice = usd * usdToCoinPrice
  const pmcAmount = latestPrice * amount
  return pmcAmount || 0
}

const checkTruncateLength = (value) => {
  if (value) {
    const price = truncateData(value || 0, 2)
    const prices = price.slice(price.indexOf('.') + 1).length
    return price.includes('.') ? (prices == 1 ? `${price}0` : price) : `${price}.00`
  }
  return 0
}

const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

let timezone = ''

const getTimezone = () => (timezone.length > 0 ? timezone : moment.tz.guess())

const setTimezone = (tz) => {
  timezone = tz
  return null
}

const localeDateTime = (date, timeZone = getTimezone(), format = 'YYYY-MM-DD HH:mm:ss') => {
  const isUnix = typeof date === 'number'

  const momentObj = isUnix ? moment.unix(date) : moment(date)

  return momentObj.tz(timeZone).format(format)
}

const getDeepValues = (obj) => {
  const arr = []

  for (const key in obj) {
    const item = obj[key]
    if (item instanceof Object) {
      arr.push(getDeepValues(item))
    } else if (item) {
      arr.push(item)
    }
  }

  return arr.flatMap((item) => item)
}

const clearBrowserHistory = () => {
  window?.history?.deleteAll()
}

const removeQueryParams = (searchParams, queryKey) => {
  const param = searchParams.get(queryKey)
  if (param) {
    // delete each query param
    searchParams.delete(queryKey)
    // update state after
    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}${
        searchParams.toString().length ? `?${searchParams.toString()}` : ''
      }`,
    )
  }
}

const removeEmptyValues = (obj) => {
  const copiedObj = { ...obj }
  Object.entries(copiedObj).forEach(([key, val]) => {
    if (val === undefined) {
      delete copiedObj[key]
    }
  })

  return copiedObj
}

const getOriginZoneByUserName = (username) => {
  if (!username) return ''
  switch (REACT_APP_STAGE) {
    case environments.production:
    case environments.uat:
      return `p.zone/${username}`
    case environments.development:
      return `d.p.zone/${username}`
    case environments.stage:
      return `s.p.zone/${username}`
    default:
      return `p.zone/${username}`
  }
}

const getPositionElement = (element) => {
  const c = element.getBoundingClientRect()
  const y = window.scrollY + c.top
  const x = window.scrollX + c.left
  return {
    x,
    y,
  }
}

export const getMissedFields = (profileData) => {
  const result = []

  if (!profileData) return result

  if (!profileData.first_name || !profileData.last_name) {
    result.push('name')
  }

  if (!profileData.dob && !profileData?.profiles?.[0]?.dob) {
    result.push('dob')
  }

  if (!profileData.email) {
    result.push('email')
  }

  if (!profileData.username) {
    result.push('username')
  }

  if (!profileData.phone_number && !profileData?.phones?.[0]?.number) {
    result.push('phone')
  }

  return result
}

export const isPhoneOrEmail = (value) => {
  const phone = isValidPhoneNumber(value) ? parsePhoneNumber(value)?.format('E.164') : null
  const email = emailValidator.validate(value) ? value : null
  const isEmail = !!email
  const isPhone = !!phone

  return {
    isEmail,
    isPhone,
    email,
    phone,
  }
}

const getAge = (date) => {
  if (date) {
    const parsedDate = parse(date, 'yyyy-MM-dd', new Date())
    return differenceInYears(new Date(), parsedDate)
  }

  return null
}

export const shouldUpdatePhone = ({ phone_number }) => !phone_number

export const shouldUpdateFields = ({ full_name, username, dob }) => !full_name || !username || !dob

const LOGIN_PHONE_KEY = 'login_phone'
const LOGIN_EMAIL_KEY = 'login_email'
const PENDING = 'pending'

export const shouldVerifyPhone = (userData) =>
  userData?.labels?.some(({ key, value }) => key === LOGIN_PHONE_KEY && value === PENDING)

export const shouldVerifyEmail = (userData) =>
  userData?.labels?.some(({ key, value }) => key === LOGIN_EMAIL_KEY && value === PENDING)

const helpers = {
  capitalize,
  calculateDefaultCoinPrice,
  checkTruncateLength,
  clearBrowserHistory,
  convertDate,
  convertPmc,
  eventTracker,
  getOriginZoneByUserName,
  getSlugByText,
  getTotalPrice,
  getTotalPriceWithFees,
  getTransactionId,
  getFees,
  getTimezone,
  setTimezone,
  getDeepValues,
  getFee,
  localeDateTime,
  numberWithCommas,
  searchInString,
  toFixed,
  truncateData,
  removeQueryParams,
  removeEmptyValues,
  getPositionElement,
  isPhoneOrEmail,
  getAge,
  shouldUpdateFields,
}

export default helpers
