import { call, put, takeEvery } from 'redux-saga/effects'
import * as actions from '../actions'
import * as types from '../constants'
import { API } from '../../api'
import { showAlert } from '../actions/alert'
import { getUserRequested } from '../actions'

const currencyConfig = {
  apiVersion: 'peatio',
}

export function* fetchCurrency(action) {
  yield put(getUserRequested(true))

  try {
    let url = '/public/currencies'
    if (typeof action.payload != 'undefined') {
      url = `/public/currencies/${action.payload}`
    }
    const currencies = yield call(API.get(currencyConfig), url)
    yield put(actions.successCurrency(currencies))
  } catch (e) {
    yield put(actions.failedCurrency())
    yield put(actions.getUserRequested(false))
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
  }
  yield put(getUserRequested(false))
}

export function* fetchCurrencySaga() {
  yield takeEvery(types.GET_CURRENCIES, fetchCurrency)
}
