import {
  DECRYPT_TOKEN,
  FAIL_DECRYPT,
  STORE_ADDRESS,
  STORE_PAYMENT_STATUS,
  SAVE_PAYMENT_DETAIL,
  PAYMENT_VERIFIED,
  CONVERT_TO_ANOTHER_CURRENCY_OUTPUT,
  SUCCESS_QUOTES,
  SET_QUOTE_TRANSACTIONS,
} from '../constants'

const initState = {
  address: '',
  isLoading: false,
  amount: 0,
  callback_url: '',
  created_at: null,
  payment: {},
  checkoutId: {},
  paymentStatus: {},
  currencyConversion: {},
  decryptError: false,
  swap_quote: '',
  quote_id: '',
  transaction: [],
  total: 0,
}

// eslint-disable-next-line default-param-last
export const paymentGatewayReducer = (state = initState, action) => {
  switch (action.type) {
    case DECRYPT_TOKEN: {
      return { ...state, isLoading: true }
    }
    case STORE_ADDRESS: {
      return {
        ...state,
        isLoading: false,
        address: action.payload.address,
        amount: action.payload.amount,
        created_at: action.payload.created_at,
        callback_url: action.payload.callback_url,
      }
    }
    case FAIL_DECRYPT: {
      return { ...state, decryptError: true }
    }

    case SUCCESS_QUOTES: {
      return { ...state, quote_id: action.payload.data }
    }

    case STORE_PAYMENT_STATUS: {
      return { ...state, payment: action.payload }
    }

    case SAVE_PAYMENT_DETAIL: {
      return { ...state, checkoutId: action.payload }
    }

    case PAYMENT_VERIFIED: {
      return { ...state, paymentStatus: action.payload }
    }

    case CONVERT_TO_ANOTHER_CURRENCY_OUTPUT: {
      return { ...state, currencyConversion: action.payload }
    }
    case SET_QUOTE_TRANSACTIONS: {
      return {
        ...state,
        transaction: action.payload.data,
        total: action.payload.headers.total,
      }
    }
    default:
      return state
  }
}
