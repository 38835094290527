import React, { useState } from 'react'
import Dropdown from '@components/UiKit/Form/Dropdown'
import { TSize } from '@components/UiKit/types'

function TestDropdown() {
  const [spaciousValue, setSpaciousValue] = useState('')
  const [compactValue, setCompactValue] = useState('')
  const options = [
    {
      label: 'red',
      value: 'red',
    },
    {
      label: 'green',
      value: 'green',
    },
    {
      label: 'blue',
      value: 'blue',
    },
    {
      label: 'orange',
      value: 'orange',
    },
    {
      label: 'yellow',
      value: 'yellow',
    },
    {
      label: 'violet',
      value: 'violet',
    },
    {
      label: 'azure',
      value: 'azure',
    },
  ]

  return (
    <div>
      <Dropdown
        value={spaciousValue}
        options={options}
        onChange={setSpaciousValue}
        size={TSize.spacious}
      />
      <Dropdown
        value={compactValue}
        options={options}
        onChange={setCompactValue}
        size={TSize.compact}
      />
    </div>
  )
}

export default TestDropdown
