/* eslint-disable max-len */
export default function BigSegmentsIcon({ size, active }) {
  const fill = active ? 'url(#paint0_linear_4134_32391)' : '#646464'

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          d="M0 2.5C0 1.11929 1.11929 0 2.5 0H6.73077C8.11148 0 9.23077 1.11929 9.23077 2.5V6.73077C9.23077 8.11148 8.11148 9.23077 6.73077 9.23077H2.5C1.11929 9.23077 0 8.11148 0 6.73077V2.5Z"
          fill={fill}
        />
        <path
          d="M10.7692 2.5C10.7692 1.11929 11.8885 0 13.2692 0H17.5C18.8807 0 20 1.11929 20 2.5V6.73077C20 8.11148 18.8807 9.23077 17.5 9.23077H13.2692C11.8885 9.23077 10.7692 8.11148 10.7692 6.73077V2.5Z"
          fill={fill}
        />
        <path
          d="M10.7692 13.2692C10.7692 11.8885 11.8885 10.7692 13.2692 10.7692H17.5C18.8807 10.7692 20 11.8885 20 13.2692V17.5C20 18.8807 18.8807 20 17.5 20H13.2692C11.8885 20 10.7692 18.8807 10.7692 17.5V13.2692Z"
          fill={fill}
        />
        <path
          d="M0 13.2692C0 11.8885 1.11929 10.7692 2.5 10.7692H6.73077C8.11148 10.7692 9.23077 11.8885 9.23077 13.2692V17.5C9.23077 18.8807 8.11148 20 6.73077 20H2.5C1.11929 20 0 18.8807 0 17.5V13.2692Z"
          fill={fill}
        />
        <defs>
          <linearGradient
            id="paint0_linear_4134_32391"
            x1="17.9306"
            y1="-0.022021"
            x2="0.17886"
            y2="2.57183"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFBAF3" />
            <stop offset="0.494792" stopColor="#FFF1D4" />
            <stop offset="1" stopColor="#8BF3FC" />
          </linearGradient>
        </defs>
      </g>
    </svg>
  )
}
