import React, { useState, useMemo } from 'react'
import { PROFILE_POPUPS } from '@constants/profile'
import MainProfile from './MainProfile'
import Withdraw from './Withdraw'
import Deposit from './Deposit'
import styles from './profile.module.scss'

interface IProps {
  userName: string
  setIsProfileDropdown: (value: boolean) => void
}

function ProfileDropdown({ setIsProfileDropdown, userName }: IProps) {
  const [profileType, setProfileType] = useState<string>(PROFILE_POPUPS.main_profile)

  const typeHandler = (value: string): void => {
    setProfileType(value)
  }

  const DATA = useMemo(
    () => ({
      [PROFILE_POPUPS.main_profile]: (
        <MainProfile
          profileTypeHandler={typeHandler}
          setIsProfileDropdown={setIsProfileDropdown}
          name={userName}
        />
      ),
      [PROFILE_POPUPS.withdraw]: (
        <Withdraw profileTypeHandler={typeHandler} setIsProfileDropdown={setIsProfileDropdown} />
      ),
      [PROFILE_POPUPS.deposit]: (
        <Deposit profileTypeHandler={typeHandler} setIsProfileDropdown={setIsProfileDropdown} />
      ),
    }),
    [],
  )

  return <div className={styles.container}>{DATA[profileType]}</div>
}

export default ProfileDropdown
