import React from 'react'
import cn from 'classnames'
import Typography from '@components/UiKit/Typography'
import { TDescriptionItemProps } from '../types'
import styles from './description_item.module.scss'

function DescriptionItem({ title, value, size, isLast }: Readonly<TDescriptionItemProps>) {
  return (
    <div className={cn(styles.container, size, { [styles.last]: isLast })}>
      <Typography.Label size={6} className={cn(styles[size], styles.label)}>
        {title}
      </Typography.Label>

      <Typography.Label size={6} className={cn(styles[size], styles.display)}>
        {value}
      </Typography.Label>
    </div>
  )
}

export default DescriptionItem
