/* eslint-disable import/no-cycle */
import {
  OPEN_ORDERS,
  SUCCESS_OPEN_ORDERS,
  FAIL_OPEN_ORDERS,
  UPDATE_OPEN_ORDERS,
  CANCEL_ORDER,
  SUCCESS_CANCEL_ORDER,
  FAIL_CANCEL_ORDER,
  CANCEL_ALL_ORDERS,
  SUCCESS_CANCEL_ALL_ORDERS,
  FAIL_CANCEL_ALL_ORDERS,
} from '../constants'

import { insertOrUpdate } from '../helpers'

const initState = {
  loading: false,
  list: [],
  total: 0,
  cancelLoading: false,
  cancelAllLoading: false,
}

// eslint-disable-next-line default-param-last
export const openOrderReducer = (state = initState, action) => {
  switch (action.type) {
    case OPEN_ORDERS: {
      return { ...state, loading: true }
    }

    case SUCCESS_OPEN_ORDERS: {
      return {
        ...state,
        list: action.payload.list,
        total: action.payload.total,
        loading: false,
      }
    }

    case FAIL_OPEN_ORDERS: {
      return {
        ...state,
        loading: false,
      }
    }

    case UPDATE_OPEN_ORDERS: {
      return {
        ...state,
        list: insertOrUpdate(state.list, action.payload),
      }
    }

    case CANCEL_ORDER: {
      return { ...state, cancelLoading: true }
    }

    case SUCCESS_CANCEL_ORDER: {
      return {
        ...state,
        cancelLoading: false,
        list: state.list,
      }
    }

    case FAIL_CANCEL_ORDER: {
      return {
        ...state,
        cancelLoading: false,
      }
    }

    case CANCEL_ALL_ORDERS: {
      return {
        ...state,
        cancelAllLoading: true,
      }
    }

    case SUCCESS_CANCEL_ALL_ORDERS: {
      return {
        ...state,
        cancelAllLoading: false,
        list: [],
      }
    }

    case FAIL_CANCEL_ALL_ORDERS: {
      return {
        ...state,
        cancelAllLoading: true,
      }
    }

    default:
      return state
  }
}
