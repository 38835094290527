import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import ReactCrop, { Crop } from 'react-image-crop'
import { useTranslation } from 'react-i18next'
import 'react-image-crop/dist/ReactCrop.css'
import { deleteProfilePicture, setUploadLoader, uploadProfilePicture } from '@core/profile/actions'
import { errorsHandler } from '@core/helper'
import { errorMessages } from '@constants/messages'
import { asyncConfirm } from '@components/PopUp/ConfirmPopUp'
import { configSelectors } from '@core/selectors'
import { TSize } from '@components/UiKit/types'
import Close from '@components/Icons/Close'
import Button from '@components/UiKit/ButtonNew/Button'
import LoaderIcon from '@components/Icons/Loader'
import Popup from './Popup'
import { getCropImageURl } from './Avatar.utils'
import { IconTrashCan } from './icon'
import styles from './avatar.module.scss'
import Hexagon from './Hexagon'

interface IAvatarProps {
  src?: string
  size?: TSize
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isEditable?: boolean
  withBorder?: boolean
}

function Avatar({
  src,
  size = TSize.small,
  onClick,
  isEditable,
  withBorder,
}: Readonly<IAvatarProps>) {
  const imageRef = useRef<HTMLImageElement | null>(null)
  const [editedImage, setEditedImage] = useState<string | Blob>('')
  const [imageSrc, setImageSrc] = useState<string | null>(null)

  const [showModal, setShowModal] = useState(false)
  const [imageSizeError, setImageSizeError] = useState(false)
  const { t } = useTranslation('nsUserProfile')
  const { t: tCommon } = useTranslation('nsCommon')
  const { first_name, username, email } = useSelector(configSelectors.profile)
  const uploadLoading = useSelector(configSelectors.profileUploadPFPLoading)
  const profilePhotoFallbackLetter = first_name ? first_name?.[0] : username?.[0] || email?.[0]

  const [crop, setCrop] = useState({
    unit: '%',
    x: 0,
    y: 0,
    width: 160,
    height: 90,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    document.documentElement.style.overflow = showModal ? 'hidden' : 'auto'
  }, [showModal])

  const onClose = () => {
    setImageSrc('')
    setShowModal(false)
    document.documentElement.style.overflow = 'auto'
  }

  function isFileImage(file) {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
    const fileType = file[0]?.type
    validImageTypes.includes(fileType)

    return validImageTypes.includes(fileType)
  }

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        if (!e.target.files) return

        const { files } = e.target
        const file = files[0]
        const availableImageSize = parseInt((file.size / 1e6).toFixed(2), 10) >= 5

        if (file && isFileImage(files)) {
          if (availableImageSize) {
            errorsHandler(errorMessages.other.big_size_image)
            setImageSizeError(true)
          } else {
            setImageSizeError(false)
          }
          setImageSrc(reader.result as string)
          setShowModal(true)
        } else {
          errorsHandler(errorMessages.other.not_image)
        }
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const getCroppedImg = (image: HTMLImageElement, cropValue): void => {
    const url = getCropImageURl(image, cropValue)
    setEditedImage(url)
  }

  const makeClientCrop = (cropValue): void => {
    if (imageRef.current && cropValue.width && cropValue.height) {
      getCroppedImg(imageRef.current, cropValue)
    }
  }

  const onCropComplete = (cropValue): void => {
    makeClientCrop(cropValue)
    document.documentElement.style.overflow = 'auto'
  }

  const onCropChange = (cropValue): void => {
    setCrop(cropValue)
  }

  const uploadImage = async (): Promise<void> => {
    try {
      const formData = new FormData()
      formData.append('upload', editedImage)
      dispatch(uploadProfilePicture(formData))
      setImageSrc('')
    } catch (err) {
      console.error(err)
    } finally {
      setShowModal(false)
    }
  }

  const deleteProfileImage = async () => {
    const result = await asyncConfirm({
      title: t('confirmToRemoveAvatarTitle'),
      message: t('confirmToRemoveAvatarMessage'),
    })

    if (result) {
      dispatch(deleteProfilePicture())
    }
  }

  function onImageLoad() {
    makeClientCrop(crop)
    setCrop({
      unit: 'px',
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    })
  }

  return (
    <div className={cn(styles.container, styles[size])} onClick={onClick}>
      {src ? (
        <div
          className={cn(
            styles.user_image_wrapper,
            { [styles.user_image_wrapper__with_border]: !!withBorder },
            styles[size],
          )}
        >
          {withBorder ? (
            <Hexagon
              className={styles.hexagon}
              src={src}
              onLoad={() => dispatch(setUploadLoader(false))}
            />
          ) : (
            <img
              className={styles.image}
              src={src}
              onLoad={() => dispatch(setUploadLoader(false))}
              alt="Profile"
            />
          )}
        </div>
      ) : (
        <div className={cn(styles.default_image_wrapper, styles[size])}>
          <div
            className={cn(
              styles.user_image_wrapper,
              styles.user_image_wrapper__no_img,
              styles[size],
            )}
          >
            {profilePhotoFallbackLetter}
          </div>
        </div>
      )}

      {uploadLoading && (
        <div className={styles.loading}>
          <LoaderIcon />
        </div>
      )}

      {src && isEditable && (
        <div className={styles.wrapDelete}>
          <div className={styles.deleteContainer}>
            <div className={styles.delete} onClick={deleteProfileImage}>
              <IconTrashCan />
            </div>
          </div>
        </div>
      )}

      <Popup size={size} onSelectFile={onSelectFile} />

      {imageSrc && !imageSizeError && showModal && (
        <>
          <div className={styles.modalWrapper} />
          <div className={styles.crop}>
            <button type="button" className={styles.crop__close} onClick={onClose}>
              <Close />
            </button>
            <div className={styles.crop__content}>
              <div className={styles.crop__canvas}>
                <ReactCrop
                  className={styles.cropper}
                  crop={crop as Crop}
                  ruleOfThirds
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                  aspect={1}
                >
                  <img ref={imageRef} src={imageSrc} onLoad={onImageLoad} alt="" />
                </ReactCrop>
              </div>
            </div>
            <div className={styles.crop__footer}>
              <Button onClick={uploadImage}> {tCommon('save')}</Button>
              <Button variant="danger" onClick={onClose}>
                {tCommon('cancel')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(Avatar)
