import React, { PropsWithChildren, memo } from 'react'
import cn from 'classnames'
import isEqual from 'react-fast-compare'

import styles from './style.module.scss'

interface IProps {
  variant?: 'success' | 'error' | 'warning'
  className?: string
}

function Pill({ variant = 'success', children, className }: PropsWithChildren<IProps>) {
  return <div className={cn(styles.pill, styles[variant], className)}>{children}</div>
}

export default memo(Pill, isEqual)
