import { RefObject } from 'react'
import useEffectOnce from './useEffectOnce'

function useOnClickOutside(ref: RefObject<HTMLInputElement>, handler: (arg0: MouseEvent) => void) {
  useEffectOnce(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node | null)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export default useOnClickOutside
