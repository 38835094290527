export const FETCH_QUOTE = 'GET_QUOTE'
export const GET_IP = 'GET_IP'
export const CREATE_SWAP = 'CREATE_SWAP'
export const SUCCESS_SIMPLEX_SWAP = 'SUCCESS_SIMPLEX_SWAP'
export const FAIL_SIMPLEX_SWAP = 'FAIL_SIMPLEX_SWAP'
export const UNSET_SUCCESS_SIMPLEX = 'UNSET_SUCCESS_SIMPLEX'
export const UNSET_SIMPLEX_MODAL = 'UNSET_SIMPLEX_MODAL'
export const FETCH_SWAP_TRANSACTION = 'FETCH_SWAP_TRANSACTION'
export const UPDATE_SWAP_TRANSACTION = 'UPDATE_SWAP_TRANSACTION'
export const SUCCESS_SWAP_TRANSACTION = 'SUCCESS_SWAP_TRANSACTION'
export const SUCCESS_GET_IP = 'SUCCESS_GET_IP'
export const FETCH_FIAT_QUOTE = 'FETCH_FIAT_QUOTE'
export const PAYMENT_METHOD_REQUEST = 'PAYMENT_METHOD_REQUEST'
export const SUCCESS_FIAT_QUOTE = 'SUCCESS_FIAT_QUOTE'
export const SUCCESS_QUOTE = 'SUCCESS_QUOTE'
export const ERROR_QUOTE = 'ERROR_QUOTE'
export const FAIL_QUOTE = '/buySell/FAIL_QUOTE'
