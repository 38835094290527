import React from 'react'
import Table from '@components/UiKit/Table'

const columns = [
  {
    title: 'Transaction Type',
    dataIndex: 'type',
    key: 'transaction_type',
  },
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
  },
]

const data = [
  {
    type: 'Type',
    item: 'item1',
  },
  {
    type: '2',
    item: 'item2',
  },
]

function TestInputs() {
  return (
    <div style={{ padding: 20 }}>
      <Table displayedMobileCards columns={columns} data={data} />
    </div>
  )
}

export default TestInputs
