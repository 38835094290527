import React, { memo } from 'react'
import cn from 'classnames'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'

import Button from '@components/UiKit/ButtonNew/Button'
import SwapAction from '@components/Icons/UIKit/SwapAction'

import useCurrentWidth from '@hooks/useCurrentWidth'
import { TSize } from '@components/UiKit/types'

import { getTransactionList } from '../Transactions.settings'
import { ITransactionTypes, ITtransactionItem } from '../types'

import styles from './style.module.scss'

interface Props {
  transactionType: ITransactionTypes
  onChangeTab: (item: ITtransactionItem) => void
  reload: () => void
}

function TransactionsHeader({ transactionType, onChangeTab, reload }: Readonly<Props>) {
  const { isMobile } = useCurrentWidth()
  const { t } = useTranslation('nsUserProfile')
  const transactions = t('transactions', { returnObjects: true })
  const TRANSACTIONS_LIST = getTransactionList(transactions)

  return (
    <div className={styles.header}>
      <div className={styles.categoryContainer}>
        <ul className={styles.categoryList}>
          {TRANSACTIONS_LIST.map((item) => {
            if (!item.isActive) return null

            return (
              <li key={item.type} className={styles.categoryList__item}>
                <button
                  className={cn(styles.categoryList__btn, {
                    [styles['categoryList__btn--active']]: transactionType === item.type,
                  })}
                  type="button"
                  onClick={() => {
                    onChangeTab(item)
                  }}
                >
                  {item.text}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
      <Button
        variant="secondary"
        className={styles.reloadBtn}
        size={TSize.compact}
        onClick={reload}
      >
        <SwapAction /> {isMobile ? '' : t('refresh')}
      </Button>
    </div>
  )
}

export default memo(TransactionsHeader, isEqual)
