export const getSettings = (selectedRow) => ({
  focusOnSelect: true,
  infinite: true,
  slidesToShow: selectedRow?.length > 3 ? 3 : 1,
  slidesToScroll: 1,
  arrows: false,
  initialSlide: 0,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
      },
    },
  ],
})

export const status = {
  IDLE: 'IDLE',
  REVEALING: 'REVEALING',
  ANIMATION: 'ANIMATION',
  COMPLETED: 'COMPLETED',
}

export const getStatus = (revealed, mediaUrl, animationPlaying) => {
  if (!revealed) {
    return status.IDLE
  }

  if (revealed && !mediaUrl) {
    return status.REVEALING
  }

  if (revealed && mediaUrl && animationPlaying) {
    return status.ANIMATION
  }

  if (revealed && mediaUrl && !animationPlaying) {
    return status.COMPLETED
  }

  return status.IDLE
}

export const defaultWonkaLimit = 30
