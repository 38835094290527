export const GET_PRESALE = 'presale/GET_PRESALE'
export const GET_PRESALE_SUCCESS = 'presale/GET_PRESALE_SUCCESS'
export const GET_PRESALE_FAILURE = 'presale/GET_PRESALE_FAILURE'
export const GET_PUBLIC_ICX_PROGRESS = 'presale/GET_PUBLIC_ICX_PROGRESS'
export const SAVE_PUBLIC_ICX_PROGRESS = 'presale/SAVE_PUBLIC_ICX_PROGRESS'
export const FETCH_CHART_DATA = 'presale/FETCH_CHART_DATA'
export const FETCH_GOOGLE_DATA = 'presale/FETCH_GOOGLE_DATA'
export const SET_CHART_DATA = 'presale/SET_CHART_DATA'
export const SET_GOOGLE_DATA = 'presale/SET_GOOGLE_DATA'
export const SET_COMPONENT_HIDE_OR_NOT = 'presale/SET_COMPONENT_HIDE_OR_NOT'
export const GET_COMPONENT_HIDE_OR_NOT = 'presale/GET_COMPONENT_HIDE_OR_NOT'
