/* eslint-disable import/no-cycle */
import {
  FETCH_ORDER_HISTORY,
  SUCCESS_ORDER_HISTORY,
  FAIL_ORDER_HISTORY,
  UPDATE_ORDER_HISTORY,
} from '../constants'

import { insertOrUpdateHistory } from '../helpers'

const initState = {
  list: [],
  total: 0,
  loading: false,
}

// eslint-disable-next-line default-param-last
export const orderHistoryReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ORDER_HISTORY: {
      return { ...state, loading: true }
    }

    case SUCCESS_ORDER_HISTORY: {
      return {
        ...state,
        list: action.payload.list,
        total: action.payload.total,
        loading: false,
      }
    }

    case FAIL_ORDER_HISTORY: {
      return {
        ...state,
        loading: false,
      }
    }

    case UPDATE_ORDER_HISTORY: {
      return {
        ...state,
        list: insertOrUpdateHistory(state.list, action.payload),
      }
    }

    default:
      return state
  }
}
