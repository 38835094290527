/* eslint-disable import/no-cycle */
import { KLINE_DATA, KLINE_FETCH, KLINE_PUSH } from '../constants'
import { klineArrayToObject } from '../helpers'

export const initialKlineState = {
  last: undefined,
  marketId: undefined,
  period: undefined,
  loading: false,
  data: [],
}

// eslint-disable-next-line default-param-last
export const klineReducer = (state = initialKlineState, action) => {
  switch (action.type) {
    case KLINE_PUSH:
      // eslint-disable-next-line no-case-declarations
      const { kline, marketId, period } = action.payload

      return {
        ...state,
        marketId,
        period,
        last: klineArrayToObject(kline),
      }

    case KLINE_FETCH:
      return {
        ...state,
        loading: true,
      }

    case KLINE_DATA:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }

    default:
      return state
  }
}
