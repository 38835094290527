/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux'
// import { history } from '../history'

// import { authReducer } from './auth'
import { userReducer } from './user'
// import { registerUserReducer } from './register'
// import { sendConfirmEmailReducer } from './sendConfirmEmail'
import { marketsReducer } from './market'
// import { phoneReducer } from './phone'
import { settingsReducer } from './settings'
// import { apiTokenReducer } from './apiToken'
import { balanceReducer } from './balance'
// import { depositsReducer } from './deposits'
// import { withdrawsReducer } from './withdraws'
import { currenciesReducer } from './currencies'
import { addressReducer } from './address'
// import { ordersReducer } from './orders'
import { myRecentTradesReducer } from './myRecentTrades'
import { alertReducer } from './alert'
import { klineReducer } from './kline'
import { rangerReducer } from './ranger'
import { depthReducer } from './depth'
// import { recentTradesReducer } from './recentTrades'
import { orderHistoryReducer } from './orderHistory'
import { openOrderReducer } from './openOrders'
// import { tradeHistoryReducer } from './tradeHistory'
import { statementReducer } from './statements'
// import { documentsReducer } from './documents'
// import { videoReducer } from './video'
// import { referralReducer } from './referral'
// import { beneficiaryReducer } from './beneficiary'
// import { contactsReducer } from './contact'
// import { askOrderReducer } from './askOrder'
// import { bidOrderReducer } from './bidOrder'
// import { rewardsReducer } from './rewards'
import { paymentGatewayReducer } from './paymentGateway'
// import profileReducer from '../../../profile/reducer'
// import emojisReducer from '../../../emojis/reducer'

// import { tradingFeeReducer } from './tradingfee'
import { BuyTuxcReducer } from './buyTuxc'
import { CryptoSettingReducer } from './cryptoSetting'
// import { accountReducer } from './account'
// import { persist } from './persist'
// import { aliasReducer } from './alias'
import { presaleReducer } from './presale'
import { swapReducer } from './swapWallet.reducer'
// import { layoutReducer } from './layout'
import { BuySellReducer } from './buySell'

export const IcxReducers = {
  alert: alertReducer,
  // account: accountReducer,
  // auth: authReducer,
  user: userReducer,
  // register: registerUserReducer,
  // confirmationEmail: sendConfirmEmailReducer,
  markets: marketsReducer,
  // phone: phoneReducer,
  settings: settingsReducer,
  // apiTokens: apiTokenReducer,
  balance: balanceReducer,
  // deposits: depositsReducer,
  // withdraws: withdrawsReducer,
  currencies: currenciesReducer,
  address: addressReducer,
  // orders: ordersReducer,
  myRecentTrades: myRecentTradesReducer,
  kline: klineReducer,
  ranger: rangerReducer,
  depth: depthReducer,
  // recentTrades: recentTradesReducer,
  orderHistory: orderHistoryReducer,
  openOrders: openOrderReducer,
  // tradeHistory: tradeHistoryReducer,
  // documents: documentsReducer,
  // video: videoReducer,
  // referral: referralReducer,
  // beneficiary: beneficiaryReducer,
  // contact: contactsReducer,
  // selectedAsks: askOrderReducer,
  // selectedBids: bidOrderReducer,
  // rewards: rewardsReducer,
  gateway: paymentGatewayReducer,
  // profile: profileReducer,
  // tradingFee: tradingFeeReducer,
  statements: statementReducer,
  buyTuxc: BuyTuxcReducer,
  cryptoSetting: CryptoSettingReducer,
  // alias: aliasReducer,
  presale: presaleReducer,
  swap: swapReducer,
  // layout: layoutReducer,
  buySell: BuySellReducer,
  // emojis: emojisReducer,
  // persist,
}

export default combineReducers(IcxReducers)
