import types from './types'

export const setProfileData = (data) => ({
  type: types.ADD_PROFILE,
  payload: data,
})

export const setWalletRequest = (payload) => ({
  type: types.SET_WALLET_REQUEST,
  payload,
})

export const fetchProfile = () => ({ type: types.FETCH_PROFILE_REQUEST })

export const fetchProfileSuccess = (payload) => ({ type: types.FETCH_PROFILE_SUCCESS, payload })

export const fetchProfileFailure = () => ({ type: types.FETCH_PROFILE_FAILURE })

export const fetchProfileFee = () => ({ type: types.FETCH_PROFILE_FEE_REQUEST })

export const fetchProfileFeeSuccess = (payload) => ({
  type: types.FETCH_PROFILE_FEE_SUCCESS,
  payload,
})

export const fetchProfileFeeFailure = () => ({ type: types.FETCH_PROFILE_FEE_FAILURE })

export const modifyProfile = (payload, cb) => ({ type: types.UPDATE_PROFILE, payload, cb })

export const modifyProfileSuccess = () => ({ type: types.UPDATE_PROFILE_SUCCESS })

export const modifyProfileFailure = () => ({ type: types.UPDATE_PROFILE_FAILURE })

export const postCountry = (payload) => ({
  type: types.POST_COUNTRY,
  payload,
})

export const successUser = (payload) => ({ type: types.SUCCESS_USER, payload })

export const getUsaDisclaimer = () => ({ type: types.GET_USA_DISCLAIMER })

export const setCountry = (payload) => ({ type: types.SET_COUNTRY, payload })

export const uploadProfilePicture = (payload, cb) => ({
  type: types.FETCH_UPLOAD_PROFILE_PICTURE_REQUEST,
  payload,
  cb,
})

export const deleteProfilePicture = (cb) => ({
  type: types.FETCH_DELETE_PROFILE_PICTURE_REQUEST,
  cb,
})

export const logout = () => ({
  type: types.LOGOUT_REQUEST,
})

export const setProfileEmojiPicture = (payload, cb) => ({
  type: types.SET_PROFILE_EMOJI_PICTURE,
  payload,
  cb,
})

export const setProfileExternalNFTPicture = (payload) => ({
  type: types.SET_PROFILE_EXTERNAL_NFT__PICTURE,
  payload,
})

export const setProfileWonkaPicture = (payload, cb) => ({
  type: types.SET_PROFILE_WONKA_PICTURE,
  payload,
  cb,
})

export const fetchSendOTPRequest = (cb) => ({
  type: types.FETCH_SEND_OTP_REQUEST,
  cb,
})

export const fetchSendOTPSuccess = () => ({
  type: types.FETCH_SEND_OTP_SUCCESS,
})

export const fetchSendOTPFailure = () => ({
  type: types.FETCH_SEND_OTP_FAILURE,
})

export const fetchGetNotificationsRequest = () => ({
  type: types.FETCH_GET_NOTIFICATIONS_REQUEST,
})

export const fetchGetNotificationsSuccess = (payload) => ({
  type: types.FETCH_GET_NOTIFICATIONS_SUCCESS,
  payload,
})

export const fetchGetNotificationsFailure = () => ({
  type: types.FETCH_GET_NOTIFICATIONS_FAILURE,
})

export const fetchSetNotificationsRequest = (payload) => ({
  type: types.FETCH_SET_NOTIFICATIONS_REQUEST,
  payload,
})

export const fetchSetNotificationsSuccess = (payload) => ({
  type: types.FETCH_SET_NOTIFICATIONS_SUCCESS,
  payload,
})

export const fetchSetNotificationsFailure = () => ({
  type: types.FETCH_SET_NOTIFICATIONS_FAILURE,
})

export const fetchDeleteAccountRequest = (payload, cb) => ({
  type: types.FETCH_DELETE_ACCOUNT_REQUEST,
  payload,
  cb,
})

export const fetchDeleteAccountSuccess = () => ({
  type: types.FETCH_DELETE_ACCOUNT_SUCCESS,
})

export const fetchDeleteAccountFailure = () => ({
  type: types.FETCH_DELETE_ACCOUNT_FAILURE,
})

export const fetchUpdateProfileEmailRequest = (payload, cb) => ({
  type: types.FETCH_UPDATE_PROFILE_EMAIL_REQUEST,
  payload,
  cb,
})

export const fetchUpdateProfileEmailSuccess = () => ({
  type: types.FETCH_UPDATE_PROFILE_EMAIL_SUCCESS,
})

export const fetchUpdateProfileEmailFailure = () => ({
  type: types.FETCH_UPDATE_PROFILE_EMAIL_FAILURE,
})

export const fetchVerifyProfileEmailRequest = (payload, cb) => ({
  type: types.FETCH_VERIFY_PROFILE_EMAIL_REQUEST,
  payload,
  cb,
})

export const fetchVerifyProfileEmailSuccess = () => ({
  type: types.FETCH_VERIFY_PROFILE_EMAIL_SUCCESS,
})

export const fetchVerifyProfileEmailFailure = () => ({
  type: types.FETCH_VERIFY_PROFILE_EMAIL_FAILURE,
})

export const fillNextMissedField = () => ({
  type: types.FILL_NEXT_MISSED_FIELD,
})

export const fetchUpdateProfilePhoneNumberRequest = (payload, cb) => ({
  type: types.FETCH_UPDATE_PROFILE_PHONE_NUMBER_REQUEST,
  payload,
  cb,
})

export const fetchUpdateProfilePhoneNumberSuccess = () => ({
  type: types.FETCH_UPDATE_PROFILE_PHONE_NUMBER_SUCCESS,
})

export const fetchUpdateProfilePhoneNumberFailure = () => ({
  type: types.FETCH_UPDATE_PROFILE_PHONE_NUMBER_FAILURE,
})

export const fetchVerifyProfilePhoneNumberRequest = (payload, cb) => ({
  type: types.FETCH_VERIFY_PROFILE_PHONE_NUMBER_REQUEST,
  payload,
  cb,
})

export const fetchVerifyProfilePhoneNumberSuccess = () => ({
  type: types.FETCH_VERIFY_PROFILE_PHONE_NUMBER_SUCCESS,
})

export const fetchVerifyProfilePhoneNumberFailure = () => ({
  type: types.FETCH_VERIFY_PROFILE_PHONE_NUMBER_FAILURE,
})

export const changePasswordRequest = (payload, cb) => ({
  type: types.CHANGE_PASSWORD_REQUEST,
  payload,
  cb,
})

export const setUploadLoader = (payload) => ({
  type: types.SET_UPLOAD_LOADER_FLAG,
  payload,
})

// for future ts implementation: ({page: number, limit: number})
export const fetchExternalNFTList = (payload) => ({
  type: types.FETCH_EXTERNAL_NFT_LIST,
  payload,
})

// for future ts implementation:
// {
//   name: string,
//   symbol: string,
//   volume: number,
//   token_id: string,
//   contract_address: string,
//   contract_type: string,
//   address: string,
//   media: {
//       url: string
// }
// }[]
export const fetchExternalNFTListSuccess = (payload) => ({
  payload,
  type: types.FETCH_EXTERNAL_NFT_LIST_SUCCESS,
})

export const fetchExternalNFTListFailure = () => ({
  type: types.FETCH_EXTERNAL_NFT_LIST_FAILURE,
})

export const resetExternalNFTList = () => ({
  type: types.RESET_EXTERNAL_NFT_LIST,
})

export const setProfileLoading = (payload) => ({
  type: types.SET_PROFILE_LOADING,
  payload,
})
