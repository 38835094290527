import React from 'react'

import TestDropdown from '@containers/Test/TestDropdown'
import styles from './style.module.scss'
import TestInputs from './TestInputs'
import TestButtons from './TestButtons'
import TestIconButtons from './TestIconButtons'
import TestCustomIconButtons from './TestCustomIconButtons'
import TestActionButtons from './TestActionButtons'
import TestTypography from './TestTypography'
import TestTable from './TestTable'
import TestNavBar from './TestNavBar'
import TestTabs from './TestTabs'
import TestModal from './TestModal'
import TestCardHeader from './TestCardHeader'
import TestPagination from './TestPagination'
import TestProfileDropdown from './TestProfileDropdown'
import TestOTPInput from './TestOTPInput'
import TestSize from './TestSize'
import TestShareLinks from './TestShareLinks'

function TestScreen() {
  return (
    <div className={styles.container}>
      <TestSize />
      <hr />
      <TestProfileDropdown />
      <hr />
      <TestInputs />
      <hr />
      <TestOTPInput />
      <hr />
      <TestButtons />
      <hr />
      <TestIconButtons />
      <hr />
      <TestCustomIconButtons />
      <hr />
      <TestShareLinks />
      <hr />
      <TestActionButtons />
      <hr />
      <TestTypography />
      <hr />
      <TestTable />
      <hr />
      <TestNavBar />
      <hr />
      <TestTabs />
      <hr />
      <TestCardHeader />
      <hr />
      <TestModal />
      <hr />
      <TestDropdown />
      <hr />
      <TestPagination />
    </div>
  )
}

export default TestScreen
