import ReactGA from 'react-ga4'

import { isProductionEnvironment } from '@constants/environments'

const STORAGE_COOKIES_KEY = 'cookies_agree'

const formatValueString = (value: string) => {
  if (value === 'true') {
    return true
  }

  return false
}

const formatValueBoolean = (value: boolean) => {
  if (value) {
    return 'true'
  }

  return 'false'
}

const initGA = () => {
  if (isProductionEnvironment) {
    ReactGA.initialize(window.env.googleKey, { gaOptions: { titleCase: false } })
  }
}

const getCookiesPermissions = () => {
  const value = localStorage.getItem(STORAGE_COOKIES_KEY)

  return value ? formatValueString(value) : value
}

export const checkCookies = () => {
  const agree = getCookiesPermissions()

  if (typeof agree === 'boolean') {
    if (agree) {
      initGA()
    }

    return true
  }

  return !!agree
}

export const setCookiesPermissions = (value: boolean) => {
  localStorage.setItem(STORAGE_COOKIES_KEY, formatValueBoolean(value))
  checkCookies()
}
