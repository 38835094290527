import storage from 'redux-persist/lib/storage'

import { persistReducer } from 'redux-persist'
import { getMissedFields } from '@utils/helpers'
import types from '../types'
import encryptor from '../encryptor'

const initialState = {
  data: null,
  loading: false,
  modifyUserLoading: false,
  missedFields: [],
  notifications: {
    loading: false,
    data: [],
  },
  otpPopup: {
    loading: false,
  },
  deleteAccountPopup: {
    loading: false,
  },
  emojiCollected: null,
  wonkaCollected: null,
  uploadLoading: false,
  wallet: {
    loading: false,
  },
  externalNFTList: [],
  externalNFTLoader: false,
}

const profileReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.FETCH_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case types.FETCH_PROFILE_SUCCESS:
      const feeData = state?.data?.fee || {}

      return {
        ...state,
        loading: false,
        data: { ...payload, fee: feeData },
        missedFields: getMissedFields(payload),
      }

    case types.SUCCESS_USER:
      return {
        ...state,
        popup: payload.last_country === 'United States' && !payload.usa_disclaimer,
      }

    case types.FETCH_PROFILE_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
      }

    case types.SET_PROFILE:
      const dataFee = state?.data?.fee || {}

      return {
        ...state,
        data: { ...payload, fee: dataFee },
      }

    case types.UPDATE_PROFILE:
      return {
        ...state,
        modifyUserLoading: true,
      }

    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        modifyUserLoading: false,
      }

    case types.SET_COUNTRY: {
      return {
        ...state,
        countrySelected: { ...payload },
      }
    }

    case types.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        modifyUserLoading: false,
      }

    case types.FETCH_PROFILE_FEE_SUCCESS:
      const feeState = state?.data?.fee || {}

      return {
        ...state,
        data: {
          ...state.data,
          fee: {
            ...feeState,
            ...payload,
          },
        },
      }

    case types.FETCH_GET_NOTIFICATIONS_REQUEST:
    case types.FETCH_SET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: true,
        },
      }

    case types.FETCH_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          data: [...payload],
          loading: false,
        },
      }

    case types.FETCH_SET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          data: [
            ...state.notifications.data.map((n) =>
              n.name === payload.email_type ? { ...n, enabled: payload.enabled } : n,
            ),
          ],
          loading: false,
        },
      }

    case types.FETCH_GET_NOTIFICATIONS_FAILURE:
    case types.FETCH_SET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          loading: false,
        },
      }

    case types.FETCH_SEND_OTP_REQUEST:
      return {
        ...state,
        otpPopup: {
          ...initialState.otpPopup,
          loading: true,
        },
      }

    case types.FETCH_SEND_OTP_SUCCESS:
    case types.FETCH_SEND_OTP_FAILURE:
      return {
        ...state,
        otpPopup: {
          ...initialState.otpPopup,
          loading: false,
        },
      }

    case types.FETCH_DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteAccountPopup: {
          ...initialState.deleteAccountPopup,
          loading: true,
        },
      }

    case types.FETCH_DELETE_ACCOUNT_SUCCESS:
    case types.FETCH_DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccountPopup: {
          ...initialState.deleteAccountPopup,
          loading: false,
        },
      }

    case types.FILL_NEXT_MISSED_FIELD:
      return {
        ...state,
        missedFields: state.missedFields.slice(1),
      }

    case types.FETCH_UPLOAD_PROFILE_PICTURE_REQUEST:
    case types.SET_PROFILE_EMOJI_PICTURE:
    case types.SET_PROFILE_WONKA_PICTURE:
    case types.SET_PROFILE_EXTERNAL_NFT__PICTURE:
      return {
        ...state,
        uploadLoading: true,
      }

    case types.SET_UPLOAD_LOADER_FLAG:
      return {
        ...state,
        uploadLoading: payload,
      }

    case types.FETCH_EXTERNAL_NFT_LIST:
      return {
        ...state,
        externalNFTLoader: true,
      }

    case types.FETCH_EXTERNAL_NFT_LIST_SUCCESS:
      return {
        ...state,
        externalNFTList: payload,
        externalNFTLoader: false,
      }

    case types.FETCH_EXTERNAL_NFT_LIST_FAILURE:
      return {
        ...state,
        externalNFTLoader: false,
      }

    case types.RESET_EXTERNAL_NFT_LIST:
      return {
        ...state,
        externalNFTList: [],
      }

    case types.SET_PROFILE_LOADING:
      return {
        ...state,
        loading: payload,
      }

    default:
      return state
  }
}

const profileConfig = {
  key: 'peer_profile',
  storage,
  transforms: [encryptor],
  blacklist: ['externalNFTLoader'],
}

export default persistReducer(profileConfig, profileReducer)
