import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import BigSegmentsIcon from './icons/BigSegmentsIcon'
import SmallSegmentsIcon from './icons/SmallSegmentsIcon'
import styles from './segmented_control.module.scss'

export type TSize = 'big' | 'small'

export interface ISegmentedControlProps {
  size?: TSize
  className?: string
  isBigSegmentsByDefault?: boolean
  action: (value: boolean) => void
}

function SegmentedControl({
  size = 'big',
  action,
  className,
  isBigSegmentsByDefault = false,
}: Readonly<ISegmentedControlProps>) {
  const [isBigActive, setIsBigActive] = useState(isBigSegmentsByDefault)

  useEffect(() => {
    action(isBigActive)
  }, [isBigActive])

  const handleSwitchState = (flag) => {
    if (flag === isBigActive) setIsBigActive(!isBigActive)
  }

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.wrapper}>
        <div
          className={cn(styles.button_wrapper, {
            [styles.active]: !isBigActive,
            [styles.small]: size === 'small',
          })}
        >
          <button
            className={styles.selector_button}
            type="button"
            onClick={() => handleSwitchState(true)}
          >
            <SmallSegmentsIcon size={20} active={!isBigActive} />
          </button>
        </div>
        <div
          className={cn(styles.button_wrapper, {
            [styles.active]: isBigActive,
            [styles.small]: size === 'small',
          })}
        >
          <button
            className={styles.selector_button}
            type="button"
            onClick={() => handleSwitchState(false)}
          >
            <BigSegmentsIcon size={20} active={isBigActive} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SegmentedControl)
