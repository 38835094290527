/* eslint-disable max-len */
import React from 'react'
import cls from 'classnames'
import styles from './styles.module.scss'

function AtomAction() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={cls(styles.icon)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3864 12C20.6973 8.7553 21.8757 5.40186 20.237 3.76311C18.5982 2.12436 15.2448 3.3028 12.0001 5.61373C8.75542 3.3028 5.40198 2.12436 3.76323 3.76311C2.12448 5.40186 3.30292 8.7553 5.61386 12C3.30292 15.2447 2.12448 18.5981 3.76323 20.2369C4.29104 20.7647 4.99604 21 5.81729 21C7.54885 21 9.79979 19.9528 12.0048 18.3862C14.2004 19.9528 16.4504 21 18.1876 21C19.0089 21 19.7148 20.7637 20.2417 20.2369C21.8757 18.5981 20.6973 15.2447 18.3864 12ZM19.1767 4.82342C19.8929 5.53967 19.4092 7.86092 17.4404 10.7559C16.8176 9.98491 16.1545 9.24732 15.4539 8.54623C14.7524 7.84684 14.0149 7.18469 13.2442 6.56248C16.1392 4.59373 18.4604 4.10717 19.1767 4.82342ZM4.82354 4.82342C5.02979 4.61623 5.37011 4.50936 5.81917 4.50936C6.92729 4.50936 8.69636 5.15623 10.7551 6.56248C9.98489 7.18491 9.24764 7.84706 8.54636 8.54623C7.8467 9.24739 7.18453 9.98498 6.56261 10.7559C4.59386 7.86092 4.10729 5.53967 4.82354 4.82342ZM4.82354 19.1765C4.10729 18.4603 4.59386 16.139 6.56261 13.244C7.18544 14.0151 7.84855 14.7526 8.54917 15.4537C9.24987 16.1528 9.98649 16.815 10.756 17.4375C7.86104 19.4062 5.53979 19.8928 4.82354 19.1765ZM12.0001 13.125C11.7776 13.125 11.5601 13.059 11.3751 12.9354C11.1901 12.8118 11.0459 12.6361 10.9607 12.4305C10.8756 12.2249 10.8533 11.9987 10.8967 11.7805C10.9401 11.5623 11.0473 11.3618 11.2046 11.2045C11.3619 11.0472 11.5624 10.94 11.7806 10.8966C11.9989 10.8532 12.2251 10.8755 12.4306 10.9606C12.6362 11.0458 12.8119 11.19 12.9355 11.375C13.0591 11.56 13.1251 11.7775 13.1251 12C13.1251 12.2984 13.0066 12.5845 12.7956 12.7955C12.5846 13.0065 12.2985 13.125 12.0001 13.125ZM19.1767 19.1775C18.4604 19.8947 16.1392 19.41 13.2442 17.4412C14.0148 16.8179 14.7523 16.1548 15.4539 15.4547C16.1535 14.7532 16.8157 14.0153 17.4376 13.244C19.4064 16.139 19.8929 18.4603 19.1767 19.1765V19.1775Z"
        fill="white"
      />
    </svg>
  )
}

export default AtomAction
