import React from 'react'
import LoginButtons from '@components/LoginButtons'
import FullScreenPopup from '@components/PopUp/FullScreenPopup'
import { POPUP_TITLES } from '@constants/popupTitles'

const TITLE = POPUP_TITLES.auth.unauthorizedPopupTitle

export default function AuthPopup() {
  return (
    <FullScreenPopup title={TITLE}>
      <LoginButtons />
    </FullScreenPopup>
  )
}
