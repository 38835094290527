import { API_URLS } from '@core/api/apiUrls'
import { ITPlayaItem, IPlayaTypes } from './types'

export const getPageList = ({
  mojisTitle,
  wonkaTilte,
}: {
  mojisTitle: string
  wonkaTilte: string
}): ITPlayaItem[] => [
  {
    children: mojisTitle || 'mojis',
    key: IPlayaTypes.emoji,
  },
  {
    children: wonkaTilte || 'wonka',
    key: IPlayaTypes.wonka,
  },
]

export const IMAGE_URLS_BY_TYPE = {
  [IPlayaTypes.emoji]: API_URLS.token.collected,
  [IPlayaTypes.wonka]: API_URLS.wonka.listTokens,
}
