/* eslint-disable max-len */
import React from 'react'
import cls from 'classnames'
import styles from './styles.module.scss'

function AtomAction() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cls(styles.icon)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9274 11.25C11.5512 11.25 12.057 10.7463 12.057 10.125V9.37498C12.057 8.75366 11.5512 8.24998 10.9274 8.24998L8.90952 8.24998C9.69077 6.90494 11.151 6 12.8234 6C14.6486 6 16.2211 7.07788 16.934 8.62938C17.1807 9.16626 17.788 9.51716 18.3411 9.30451L19.1779 8.98271C19.7127 8.7771 20.0165 8.20061 19.8002 7.67192C18.6792 4.93158 15.9778 3 12.8234 3C10.1619 3 7.82287 4.37509 6.48353 6.45084L5.94836 4.46165C5.78689 3.8615 5.1675 3.50535 4.5649 3.66615L3.83751 3.86027C3.23491 4.02108 2.87731 4.63796 3.03877 5.23811L4.43041 10.4107C4.56777 10.9212 5.03651 11.2552 5.5444 11.2446C5.58111 11.2481 5.61834 11.25 5.656 11.25L10.9274 11.25ZM13.0726 12.1876C12.4488 12.1876 11.943 12.6913 11.943 13.3126V14.0626C11.943 14.6839 12.4488 15.1876 13.0726 15.1876H15.0905C14.3092 16.5326 12.849 17.4376 11.1766 17.4376C9.35138 17.4376 7.77892 16.3597 7.06596 14.8082C6.81925 14.2713 6.212 13.9204 5.65895 14.133L4.82207 14.4548C4.28732 14.6605 3.98353 15.2369 4.19981 15.7656C5.32082 18.506 8.02223 20.4376 11.1766 20.4376C13.8381 20.4376 16.1771 19.0625 17.5165 16.9867L18.0516 18.9759C18.2131 19.5761 18.8325 19.9322 19.4351 19.7714L20.1625 19.5773C20.7651 19.4165 21.1227 18.7996 20.9612 18.1995L19.5696 13.0269C19.4322 12.5163 18.9635 12.1823 18.4556 12.193C18.4189 12.1894 18.3817 12.1876 18.344 12.1876H13.0726Z"
        fill="white"
      />
    </svg>
  )
}

export default AtomAction
