import React, { memo } from 'react'
import { NavLink } from 'react-router-dom'
import routes from '@routing/path'

import styles from './leftSection.module.scss'
import { IconLogo, IconLogoMobile } from './IconLogo'

interface ILeftSectionProps {
  isMobile: boolean
}

function LeftSection({ isMobile }: Readonly<ILeftSectionProps>) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.item}>
        <NavLink to={routes.withAuth.home} className={styles.logo}>
          {isMobile ? <IconLogoMobile /> : <IconLogo />}
        </NavLink>
      </div>
    </div>
  )
}

export default memo(LeftSection)
