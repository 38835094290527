/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M128 214C114.72 214 102.24 211.48 90.56 206.44C78.88 201.4 68.72 194.56 60.08 185.92C51.44 177.28 44.6 167.12 39.56 155.44C34.52 143.76 32 131.28 32 118C32 104.72 34.52 92.24 39.56 80.56C44.6 68.88 51.44 58.72 60.08 50.08C68.72 41.44 78.88 34.6 90.56 29.56C102.24 24.52 114.72 22 128 22C141.28 22 153.76 24.52 165.44 29.56C177.12 34.6 187.28 41.44 195.92 50.08C204.56 58.72 211.4 68.88 216.44 80.56C221.48 92.24 224 104.72 224 118V131.92C224 141.36 220.76 149.4 214.28 156.04C207.8 162.68 199.84 166 190.4 166C184.8 166 179.52 164.8 174.56 162.4C169.6 160 165.44 156.56 162.08 152.08C157.44 156.72 152.2 160.2 146.36 162.52C140.52 164.84 134.4 166 128 166C114.72 166 103.4 161.32 94.04 151.96C84.68 142.6 80 131.28 80 118C80 104.72 84.68 93.4 94.04 84.04C103.4 74.68 114.72 70 128 70C141.28 70 152.6 74.68 161.96 84.04C171.32 93.4 176 104.72 176 118V131.92C176 136.08 177.36 139.6 180.08 142.48C182.8 145.36 186.24 146.8 190.4 146.8C194.56 146.8 198 145.36 200.72 142.48C203.44 139.6 204.8 136.08 204.8 131.92V118C204.8 96.56 197.36 78.4 182.48 63.52C167.6 48.64 149.44 41.2 128 41.2C106.56 41.2 88.4 48.64 73.52 63.52C58.64 78.4 51.2 96.56 51.2 118C51.2 139.44 58.64 157.6 73.52 172.48C88.4 187.36 106.56 194.8 128 194.8H176V214H128ZM128 146.8C136 146.8 142.8 144 148.4 138.4C154 132.8 156.8 126 156.8 118C156.8 110 154 103.2 148.4 97.6C142.8 92 136 89.2 128 89.2C120 89.2 113.2 92 107.6 97.6C102 103.2 99.2 110 99.2 118C99.2 126 102 132.8 107.6 138.4C113.2 144 120 146.8 128 146.8Z"
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M128 214C114.72 214 102.24 211.48 90.56 206.44C78.88 201.4 68.72 194.56 60.08 185.92C51.44 177.28 44.6 167.12 39.56 155.44C34.52 143.76 32 131.28 32 118C32 104.72 34.52 92.24 39.56 80.56C44.6 68.88 51.44 58.72 60.08 50.08C68.72 41.44 78.88 34.6 90.56 29.56C102.24 24.52 114.72 22 128 22C141.28 22 153.76 24.52 165.44 29.56C177.12 34.6 187.28 41.44 195.92 50.08C204.56 58.72 211.4 68.88 216.44 80.56C221.48 92.24 224 104.72 224 118V131.92C224 141.36 220.76 149.4 214.28 156.04C207.8 162.68 199.84 166 190.4 166C184.8 166 179.52 164.8 174.56 162.4C169.6 160 165.44 156.56 162.08 152.08C157.44 156.72 152.2 160.2 146.36 162.52C140.52 164.84 134.4 166 128 166C114.72 166 103.4 161.32 94.04 151.96C84.68 142.6 80 131.28 80 118C80 104.72 84.68 93.4 94.04 84.04C103.4 74.68 114.72 70 128 70C141.28 70 152.6 74.68 161.96 84.04C171.32 93.4 176 104.72 176 118V131.92C176 136.08 177.36 139.6 180.08 142.48C182.8 145.36 186.24 146.8 190.4 146.8C194.56 146.8 198 145.36 200.72 142.48C203.44 139.6 204.8 136.08 204.8 131.92V118C204.8 96.56 197.36 78.4 182.48 63.52C167.6 48.64 149.44 41.2 128 41.2C106.56 41.2 88.4 48.64 73.52 63.52C58.64 78.4 51.2 96.56 51.2 118C51.2 139.44 58.64 157.6 73.52 172.48C88.4 187.36 106.56 194.8 128 194.8H176V214H128ZM128 146.8C136 146.8 142.8 144 148.4 138.4C154 132.8 156.8 126 156.8 118C156.8 110 154 103.2 148.4 97.6C142.8 92 136 89.2 128 89.2C120 89.2 113.2 92 107.6 97.6C102 103.2 99.2 110 99.2 118C99.2 126 102 132.8 107.6 138.4C113.2 144 120 146.8 128 146.8Z"
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M128 214C114.72 214 102.24 211.48 90.56 206.44C78.88 201.4 68.72 194.56 60.08 185.92C51.44 177.28 44.6 167.12 39.56 155.44C34.52 143.76 32 131.28 32 118C32 104.72 34.52 92.24 39.56 80.56C44.6 68.88 51.44 58.72 60.08 50.08C68.72 41.44 78.88 34.6 90.56 29.56C102.24 24.52 114.72 22 128 22C141.28 22 153.76 24.52 165.44 29.56C177.12 34.6 187.28 41.44 195.92 50.08C204.56 58.72 211.4 68.88 216.44 80.56C221.48 92.24 224 104.72 224 118V131.92C224 141.36 220.76 149.4 214.28 156.04C207.8 162.68 199.84 166 190.4 166C184.8 166 179.52 164.8 174.56 162.4C169.6 160 165.44 156.56 162.08 152.08C157.44 156.72 152.2 160.2 146.36 162.52C140.52 164.84 134.4 166 128 166C114.72 166 103.4 161.32 94.04 151.96C84.68 142.6 80 131.28 80 118C80 104.72 84.68 93.4 94.04 84.04C103.4 74.68 114.72 70 128 70C141.28 70 152.6 74.68 161.96 84.04C171.32 93.4 176 104.72 176 118V131.92C176 136.08 177.36 139.6 180.08 142.48C182.8 145.36 186.24 146.8 190.4 146.8C194.56 146.8 198 145.36 200.72 142.48C203.44 139.6 204.8 136.08 204.8 131.92V118C204.8 96.56 197.36 78.4 182.48 63.52C167.6 48.64 149.44 41.2 128 41.2C106.56 41.2 88.4 48.64 73.52 63.52C58.64 78.4 51.2 96.56 51.2 118C51.2 139.44 58.64 157.6 73.52 172.48C88.4 187.36 106.56 194.8 128 194.8H176V214H128ZM128 146.8C136 146.8 142.8 144 148.4 138.4C154 132.8 156.8 126 156.8 118C156.8 110 154 103.2 148.4 97.6C142.8 92 136 89.2 128 89.2C120 89.2 113.2 92 107.6 97.6C102 103.2 99.2 110 99.2 118C99.2 126 102 132.8 107.6 138.4C113.2 144 120 146.8 128 146.8Z"
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M128 214C114.72 214 102.24 211.48 90.56 206.44C78.88 201.4 68.72 194.56 60.08 185.92C51.44 177.28 44.6 167.12 39.56 155.44C34.52 143.76 32 131.28 32 118C32 104.72 34.52 92.24 39.56 80.56C44.6 68.88 51.44 58.72 60.08 50.08C68.72 41.44 78.88 34.6 90.56 29.56C102.24 24.52 114.72 22 128 22C141.28 22 153.76 24.52 165.44 29.56C177.12 34.6 187.28 41.44 195.92 50.08C204.56 58.72 211.4 68.88 216.44 80.56C221.48 92.24 224 104.72 224 118V131.92C224 141.36 220.76 149.4 214.28 156.04C207.8 162.68 199.84 166 190.4 166C184.8 166 179.52 164.8 174.56 162.4C169.6 160 165.44 156.56 162.08 152.08C157.44 156.72 152.2 160.2 146.36 162.52C140.52 164.84 134.4 166 128 166C114.72 166 103.4 161.32 94.04 151.96C84.68 142.6 80 131.28 80 118C80 104.72 84.68 93.4 94.04 84.04C103.4 74.68 114.72 70 128 70C141.28 70 152.6 74.68 161.96 84.04C171.32 93.4 176 104.72 176 118V131.92C176 136.08 177.36 139.6 180.08 142.48C182.8 145.36 186.24 146.8 190.4 146.8C194.56 146.8 198 145.36 200.72 142.48C203.44 139.6 204.8 136.08 204.8 131.92V118C204.8 96.56 197.36 78.4 182.48 63.52C167.6 48.64 149.44 41.2 128 41.2C106.56 41.2 88.4 48.64 73.52 63.52C58.64 78.4 51.2 96.56 51.2 118C51.2 139.44 58.64 157.6 73.52 172.48C88.4 187.36 106.56 194.8 128 194.8H176V214H128ZM128 146.8C136 146.8 142.8 144 148.4 138.4C154 132.8 156.8 126 156.8 118C156.8 110 154 103.2 148.4 97.6C142.8 92 136 89.2 128 89.2C120 89.2 113.2 92 107.6 97.6C102 103.2 99.2 110 99.2 118C99.2 126 102 132.8 107.6 138.4C113.2 144 120 146.8 128 146.8Z"
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M128 214C114.72 214 102.24 211.48 90.56 206.44C78.88 201.4 68.72 194.56 60.08 185.92C51.44 177.28 44.6 167.12 39.56 155.44C34.52 143.76 32 131.28 32 118C32 104.72 34.52 92.24 39.56 80.56C44.6 68.88 51.44 58.72 60.08 50.08C68.72 41.44 78.88 34.6 90.56 29.56C102.24 24.52 114.72 22 128 22C141.28 22 153.76 24.52 165.44 29.56C177.12 34.6 187.28 41.44 195.92 50.08C204.56 58.72 211.4 68.88 216.44 80.56C221.48 92.24 224 104.72 224 118V131.92C224 141.36 220.76 149.4 214.28 156.04C207.8 162.68 199.84 166 190.4 166C184.8 166 179.52 164.8 174.56 162.4C169.6 160 165.44 156.56 162.08 152.08C157.44 156.72 152.2 160.2 146.36 162.52C140.52 164.84 134.4 166 128 166C114.72 166 103.4 161.32 94.04 151.96C84.68 142.6 80 131.28 80 118C80 104.72 84.68 93.4 94.04 84.04C103.4 74.68 114.72 70 128 70C141.28 70 152.6 74.68 161.96 84.04C171.32 93.4 176 104.72 176 118V131.92C176 136.08 177.36 139.6 180.08 142.48C182.8 145.36 186.24 146.8 190.4 146.8C194.56 146.8 198 145.36 200.72 142.48C203.44 139.6 204.8 136.08 204.8 131.92V118C204.8 96.56 197.36 78.4 182.48 63.52C167.6 48.64 149.44 41.2 128 41.2C106.56 41.2 88.4 48.64 73.52 63.52C58.64 78.4 51.2 96.56 51.2 118C51.2 139.44 58.64 157.6 73.52 172.48C88.4 187.36 106.56 194.8 128 194.8H176V214H128ZM128 146.8C136 146.8 142.8 144 148.4 138.4C154 132.8 156.8 126 156.8 118C156.8 110 154 103.2 148.4 97.6C142.8 92 136 89.2 128 89.2C120 89.2 113.2 92 107.6 97.6C102 103.2 99.2 110 99.2 118C99.2 126 102 132.8 107.6 138.4C113.2 144 120 146.8 128 146.8Z"
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M128 214C114.72 214 102.24 211.48 90.56 206.44C78.88 201.4 68.72 194.56 60.08 185.92C51.44 177.28 44.6 167.12 39.56 155.44C34.52 143.76 32 131.28 32 118C32 104.72 34.52 92.24 39.56 80.56C44.6 68.88 51.44 58.72 60.08 50.08C68.72 41.44 78.88 34.6 90.56 29.56C102.24 24.52 114.72 22 128 22C141.28 22 153.76 24.52 165.44 29.56C177.12 34.6 187.28 41.44 195.92 50.08C204.56 58.72 211.4 68.88 216.44 80.56C221.48 92.24 224 104.72 224 118V131.92C224 141.36 220.76 149.4 214.28 156.04C207.8 162.68 199.84 166 190.4 166C184.8 166 179.52 164.8 174.56 162.4C169.6 160 165.44 156.56 162.08 152.08C157.44 156.72 152.2 160.2 146.36 162.52C140.52 164.84 134.4 166 128 166C114.72 166 103.4 161.32 94.04 151.96C84.68 142.6 80 131.28 80 118C80 104.72 84.68 93.4 94.04 84.04C103.4 74.68 114.72 70 128 70C141.28 70 152.6 74.68 161.96 84.04C171.32 93.4 176 104.72 176 118V131.92C176 136.08 177.36 139.6 180.08 142.48C182.8 145.36 186.24 146.8 190.4 146.8C194.56 146.8 198 145.36 200.72 142.48C203.44 139.6 204.8 136.08 204.8 131.92V118C204.8 96.56 197.36 78.4 182.48 63.52C167.6 48.64 149.44 41.2 128 41.2C106.56 41.2 88.4 48.64 73.52 63.52C58.64 78.4 51.2 96.56 51.2 118C51.2 139.44 58.64 157.6 73.52 172.48C88.4 187.36 106.56 194.8 128 194.8H176V214H128ZM128 146.8C136 146.8 142.8 144 148.4 138.4C154 132.8 156.8 126 156.8 118C156.8 110 154 103.2 148.4 97.6C142.8 92 136 89.2 128 89.2C120 89.2 113.2 92 107.6 97.6C102 103.2 99.2 110 99.2 118C99.2 126 102 132.8 107.6 138.4C113.2 144 120 146.8 128 146.8Z"
    />,
  ],
])

const CustomIconShareEmail: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconShareEmail.displayName = 'CustomIconShareEmail'

export default CustomIconShareEmail
