import { convertPmc, truncateData, convertUsd } from '../../helpers'
import {
  FETCH_CRYPTO_SETTING,
  SUCCESS_CRYPTO_SETTING,
  FAIL_CRYPTO_SETTING,
  SUCCESS_FETCH_USD_PRICE,
  FAIL_FETCH_USD_PRICE,
  SET_AMOUNT,
  MODAL_STATUS,
} from '../constants'

const initState = {
  price: 0,
  added: null,
  error: null,
  usd_price: 0,
  currency_amount: 0,
  usd_amount: 0,
  coinType: 'crypto',
  modalStatus: false,
  modalKey: '',
}

// eslint-disable-next-line default-param-last
export const CryptoSettingReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CRYPTO_SETTING: {
      return { ...state }
    }
    case SUCCESS_CRYPTO_SETTING: {
      return { ...state, price: action.payload.price }
    }
    case FAIL_CRYPTO_SETTING: {
      return { ...state, error: action.payload }
    }
    case SUCCESS_FETCH_USD_PRICE: {
      return {
        ...state,
        usd_price:
          action.payload.price && action.payload.amount
            ? parseFloat(action.payload.price) * parseFloat(action.payload.amount)
            : 0,
      }
    }

    case MODAL_STATUS: {
      return {
        ...state,
        modalStatus: action.payload,
        modalKey: action.key,
      }
    }

    case SET_AMOUNT: {
      if (action.payload.type === 'crypto') {
        return {
          ...state,
          currency_amount: action.payload.value,
          coinType: 'crypto',
          usd_amount: truncateData(
            convertPmc(state.price, action.payload.getPrice, action.payload.value) * state.price,
            2,
          ),
        }
      }
      if (action.payload.type === 'fiat') {
        return {
          ...state,
          usd_amount: action.payload.value,
          coinType: 'fiat',
          currency_amount: convertUsd(action.payload.getPrice, action.payload.value),
        }
      }
    }
    // eslint-disable-next-line no-fallthrough
    case FAIL_FETCH_USD_PRICE: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}
