/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { toast } from 'react-toastify'
import { Web3Modal } from '@web3modal/ethers/dist/types/src/client'
import debounce from 'lodash.debounce'
import { isProductionEnvironment, isUatEnvironment } from '@constants/environments'
import { Frontend } from '@core/api'
import { TNullable } from 'types/utilityTypes'
import { useSelector } from 'react-redux'
import { configSelectors } from '@core/selectors'

export function Web3Provider({ children }: { children: React.ReactNode }) {
  const projectId = window.env.walletConnectProjectID
  const { t } = useTranslation('nsMessages')
  const connected = useRef<TNullable<boolean>>(null)
  const profile = useSelector(configSelectors.profile)

  const mainnet = {
    chainId: Frontend.config.chainid,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com',
  }

  const testnet = {
    chainId: Frontend.config.chainidTestnet,
    name: 'Sepolia Testnet',
    currency: 'ETH',
    explorerUrl: 'https://sepolia.etherscan.io',
    rpcUrl: 'https://ethereum-sepolia-rpc.publicnode.com',
  }

  const metadata = {
    name: '💎 Playa',
    description: 'Marketplace',
    url: window.location.origin, // origin must match your domain & subdomain
    icons: [],
  }

  const ethersConfig = defaultConfig({
    metadata,
  })

  const debouncedChangeHandler = useCallback(
    debounce((instance: Web3Modal) => {
      const currentIsConnected = instance.getIsConnected()
      const address = instance.getAddress()
      const isConnected = connected.current

      if (isConnected !== null && isConnected !== currentIsConnected) {
        if (currentIsConnected && address === profile?.wallet_address) {
          toast.success(t('walletConnected'))
        } else if (!currentIsConnected) {
          toast.success(t('walletDisconnected'))
        }
      }

      connected.current = currentIsConnected
    }, 100),
    [],
  )

  useEffect(() => {
    const instance = createWeb3Modal({
      ethersConfig,
      chains: [isProductionEnvironment || isUatEnvironment ? mainnet : testnet],
      projectId,
      enableAnalytics: false,
    })

    instance.subscribeState(() => {
      debouncedChangeHandler(instance)
    })
  }, [])

  return children
}
