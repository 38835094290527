/* eslint-disable max-len */
import React, { useEffect } from 'react'

interface IHexagonProps {
  className?: string
  src: string
  onLoad?: () => void
}

function Hexagon({ className, src, onLoad }: IHexagonProps) {
  useEffect(() => {
    const img = new Image()
    img.src = src
    if (onLoad) {
      img.onload = () => onLoad()
    }
  }, [src, onLoad])

  return (
    <svg
      className={className}
      width="185"
      height="200"
      viewBox="0 0 185 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M176.631 46.468C168.851 39.6749 159.557 33.9654 149.716 27.8808L145.679 25.3803C132.305 17.1286 121.076 11.0023 110.31 6.08459C105.979 4.12585 102.067 2.50051 98.3246 1.1669C97.6517 0.916845 94.9181 0.166694 92.479 -7.4421e-06C90.0398 0.166694 87.3062 0.916854 86.5071 1.20858C82.8904 2.50052 78.9793 4.12585 74.6897 6.08459C63.9236 11.0023 52.6949 17.1286 39.3214 25.3803L35.2841 27.8808C25.4433 33.9237 16.1491 39.6333 8.36894 46.468C5.46714 49.0102 3.99522 51.344 2.85973 55.1365C2.48124 56.3867 0.588764 64.0967 0.0841053 92.7277C0.0420504 95.4366 -4.46313e-06 97.8954 -4.37023e-06 100.021C-4.27732e-06 102.146 0.0420508 104.647 0.084106 107.314C0.630822 135.945 2.48124 143.655 2.85974 144.905C3.99522 148.698 5.46715 151.031 8.36895 153.574C16.1491 160.367 25.4433 166.076 35.2842 172.161L39.3214 174.62C52.6949 182.871 63.9236 188.998 74.6897 193.915C78.9793 195.874 82.9325 197.499 86.6754 198.833C87.3483 199.083 90.0818 199.833 92.521 200C94.9602 199.833 97.6938 199.083 98.4928 198.791C102.11 197.499 106.021 195.874 110.31 193.915C121.076 188.998 132.305 182.871 145.679 174.62L149.716 172.161C159.557 166.118 168.851 160.408 176.631 153.574C179.533 151.031 181.005 148.698 182.14 144.905C182.519 143.655 184.411 135.945 184.916 107.314C184.958 104.605 185 102.146 185 100.021C185 97.8954 184.958 95.3949 184.916 92.7276C184.369 64.0967 182.519 56.3867 182.14 55.1365C181.005 51.344 179.533 49.0102 176.631 46.468Z"
        fill="#222222"
      />
      <path
        d="M176.631 46.468C168.851 39.6749 159.557 33.9654 149.716 27.8808L145.679 25.3803C132.305 17.1286 121.076 11.0023 110.31 6.08459C105.979 4.12585 102.067 2.50051 98.3246 1.1669C97.6517 0.916845 94.9181 0.166694 92.479 -7.4421e-06C90.0398 0.166694 87.3062 0.916854 86.5071 1.20858C82.8904 2.50052 78.9793 4.12585 74.6897 6.08459C63.9236 11.0023 52.6949 17.1286 39.3214 25.3803L35.2841 27.8808C25.4433 33.9237 16.1491 39.6333 8.36894 46.468C5.46714 49.0102 3.99522 51.344 2.85973 55.1365C2.48124 56.3867 0.588764 64.0967 0.0841053 92.7277C0.0420504 95.4366 -4.46313e-06 97.8954 -4.37023e-06 100.021C-4.27732e-06 102.146 0.0420508 104.647 0.084106 107.314C0.630822 135.945 2.48124 143.655 2.85974 144.905C3.99522 148.698 5.46715 151.031 8.36895 153.574C16.1491 160.367 25.4433 166.076 35.2842 172.161L39.3214 174.62C52.6949 182.871 63.9236 188.998 74.6897 193.915C78.9793 195.874 82.9325 197.499 86.6754 198.833C87.3483 199.083 90.0818 199.833 92.521 200C94.9602 199.833 97.6938 199.083 98.4928 198.791C102.11 197.499 106.021 195.874 110.31 193.915C121.076 188.998 132.305 182.871 145.679 174.62L149.716 172.161C159.557 166.118 168.851 160.408 176.631 153.574C179.533 151.031 181.005 148.698 182.14 144.905C182.519 143.655 184.411 135.945 184.916 107.314C184.958 104.605 185 102.146 185 100.021C185 97.8954 184.958 95.3949 184.916 92.7276C184.369 64.0967 182.519 56.3867 182.14 55.1365C181.005 51.344 179.533 49.0102 176.631 46.468Z"
        fill="url(#pattern0_175_28)"
      />
      <defs>
        <pattern id="pattern0_175_28" patternContentUnits="objectBoundingBox" width="1" height="1">
          <image
            id="image0_175_28"
            href={src}
            width="1"
            height="1"
            preserveAspectRatio="xMidYMid slice"
          />
        </pattern>
      </defs>
    </svg>
  )
}

export default Hexagon
