export const OPEN_ORDERS = 'orders/OPEN_ORDERS'
export const SUCCESS_OPEN_ORDERS = 'orders/SUCCESS_OPEN_ORDERS'
export const FAIL_OPEN_ORDERS = 'orders/FAIL_OPEN_ORDERS'
export const UPDATE_OPEN_ORDERS = 'orders/UPDATE_OPEN_ORDERS'

export const CANCEL_ORDER = 'orders/CANCEL_ORDER'
export const SUCCESS_CANCEL_ORDER = 'orders/SUCCESS_CANCEL_ORDER'
export const FAIL_CANCEL_ORDER = 'orders/FAIL_CANCEL_ORDER'

export const CANCEL_ALL_ORDERS = 'orders/CANCEL_ALL_ORDERS'
export const SUCCESS_CANCEL_ALL_ORDERS = 'orders/SUCCESS_CANCEL_ALL_ORDERS'
export const FAIL_CANCEL_ALL_ORDERS = 'orders/FAIL_CANCEL_ALL_ORDERS'
