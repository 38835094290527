/* eslint-disable */
import { call, put, takeEvery, delay } from 'redux-saga/effects';
import * as actions from '../actions';
import * as types from '../constants';
import { API } from '../../api';
import { showAlert } from '../actions/alert';

const presaleConfig = {
  apiVersion: 'peatio'
};

export function* fetchPresale(action) {
  yield put(actions.getUserRequested(true));

  try {
    let data = yield call(API.get(presaleConfig), `/public/active_presale`);
    yield put(actions.getUserRequested(false));
    yield put(actions.successPresale(data));
  } catch (e) {
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }));
    yield put(actions.getUserRequested(false));
    yield put(actions.failedPresale());
  }
}

export function* fetchPublicIcxSaga() {
  try {
    const publicIcxProgress = yield call(
      API.get(presaleConfig),
      `public/icx-progress`
    );
    yield put(actions.savePublicIcx(publicIcxProgress));
  } catch (e) {}
}

export function* fetchPresaleSaga() {
  yield takeEvery(types.GET_PRESALE, fetchPresale);
  yield takeEvery(types.GET_PUBLIC_ICX_PROGRESS, fetchPublicIcxSaga);
}
