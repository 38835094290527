import {
  SAVE_NUMBER,
  SUCCESS_SAVE_NUMBER,
  FAIL_SAVE_NUMBER,
  VERIFY_NUMBER,
  SUCCESS_VERIFY_NUMBER,
  FAIL_VERIFY_NUMBER,
  RESEND_OTP,
  SUCCESS_RESEND_OTP,
  FAIL_RESEND_OTP,
} from '../constants'

export const saveNumber = (payload, callback) => ({ type: SAVE_NUMBER, payload, callback })

export const successSaveNumber = (payload) => ({ type: SUCCESS_SAVE_NUMBER, payload })

export const failSaveNumber = (payload) => ({ type: FAIL_SAVE_NUMBER, payload })

export const verifyNumber = (payload, navigate) => ({ type: VERIFY_NUMBER, payload, navigate })

export const successVerifyNumber = (payload) => ({ type: SUCCESS_VERIFY_NUMBER, payload })

export const failVerifyNumber = (payload) => ({ type: FAIL_VERIFY_NUMBER, payload })

export const resendOtp = (payload) => ({ type: RESEND_OTP, payload })

export const successResendOtp = (payload) => ({ type: SUCCESS_RESEND_OTP, payload })

export const failResendOtp = (payload) => ({ type: FAIL_RESEND_OTP, payload })
