/* eslint-disable camelcase */
import moment from 'moment'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const BigNumber = require('bignumber.js')

export const h24Change = (open, last, n = 6) => {
  const p1 = new BigNumber(open)
  const p2 = new BigNumber(last)
  return p2.minus(p1).toFixed(n)
}

export const ticker_color_class = (l, o) => {
  const last = new BigNumber(l)
  const open = new BigNumber(o)
  if (last.eq(open)) {
    return ''
  }
  if (last.gt(open)) {
    return 'pink_txt'
  }
  return 'green_txt'
}

export const toFixed = (num, tofixed = 6) => new BigNumber(num).toFixed(tofixed)

export const total = (price, vol, tofixed = 6) => {
  const x = new BigNumber(price)
  const y = new BigNumber(vol)
  return x.multipliedBy(y).toFixed(tofixed)
}

export const preciseNumber = (str, upto = 6) => {
  const split_str = str.split('.')
  if (split_str[1] && split_str[1].length > upto) {
    split_str[1] = split_str[1].substring(0, upto)
    return split_str.join('.')
  }
  return str
}

export const tradeTime = (timestamp, unix = true) => {
  if (unix) {
    const m = moment.unix(timestamp)
    return `${m.format('HH:mm')}${m.format(':ss')}`
  }
  return new Date(timestamp).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  })
}

export const fulltime_order = (datetime) => {
  const dt = new Date(datetime)
  return `${dt.getMonth()}/${dt.getDate()} ${dt.getHours()}:${dt.getMinutes()}:${dt.getSeconds()}`
}

export const fulltime_order_account = (datetime) => {
  const dt = new Date(datetime)
  return `${dt.getFullYear()}-${
    dt.getMonth() + 1
  }-${dt.getDate()} ${dt.getHours()}:${dt.getMinutes()}`
}

export const uppercase = (str) => (str ? str.toUpperCase() : str)

export const calculateDate = (start_date) => {
  const date1 = new Date(start_date)
  const utc = new Date()
  const diffTime = Math.abs(utc - date1)
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))
  const lastDate = moment(date1).add(diffDays + 1, 'days')
  return { date: lastDate, time: lastDate.utc().format('hh:mm a') }
}
