import types from './types'

export const fetchCheckIfUsernameAvailableRequest = (payload, cb) => ({
  type: types.FETCH_CHECK_USERNAME_REQUSET,
  payload,
  cb,
})

export const fetchCheckIfUsernameAvailableSuccess = (payload) => ({
  type: types.FETCH_CHECK_USERNAME_SUCCESS,
  payload,
})

export const fetchCheckIfUsernameAvailableFailure = () => ({
  type: types.FETCH_CHECK_USERNAME_FAILURE,
})

export const fetchSendSessionOTPRequest = (payload, cb) => ({
  type: types.FETCH_SEND_SESSION_OTP_REQUEST,
  payload,
  cb,
})

export const fetchSendSessionOTPSuccess = () => ({
  type: types.FETCH_SEND_SESSION_OTP_SUCCESS,
})

export const fetchSendSessionOTPFailure = () => ({
  type: types.FETCH_SEND_SESSION_OTP_FAILURE,
})

export const fetchVerifySignup = (payload, navigate, startTimer) => ({
  type: types.FETCH_VERIFY_SIGNUP_REQUEST,
  payload,
  navigate,
  startTimer,
})

export const fetchVerifySignupSuccess = () => ({
  type: types.FETCH_VERIFY_SIGNUP_SUCCESS,
})

export const fetchVerifySignupFailure = () => ({
  type: types.FETCH_VERIFY_SIGNUP_FAILURE,
})

export const verifySigninEmail = (payload, navigate, setError) => ({
  type: types.VERIFY_SIGNIN_EMAIL_OTP_REQUEST,
  payload,
  navigate,
  setError,
})

export const verifySigninEmailSuccess = () => ({
  type: types.VERIFY_SIGNIN_EMAIL_OTP_SUCCESS,
})

export const verifySigninEmailFailure = () => ({
  type: types.VERIFY_SIGNIN_EMAIL_OTP_FAILURE,
})

export const verifySigninPhoneAndUpdateEmail = (payload, navigate, setError) => ({
  type: types.VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL,
  payload,
  navigate,
  setError,
})

export const verifySigninPhoneAndUpdateEmailSuccess = () => ({
  type: types.VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL_SUCCESS,
})

export const verifySigninPhoneAndUpdateEmailFailure = () => ({
  type: types.VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL_FAILURE,
})

export const verifySignupEmailRequest = (payload, navigate, setError) => ({
  type: types.VERIFY_SIGNUP_EMAIL_OTP_REQUEST,
  payload,
  navigate,
  setError,
})

export const verifySignupEmailSuccess = () => ({
  type: types.VERIFY_SIGNUP_EMAIL_OTP_SUCCESS,
})

export const verifySignupEmailFailure = () => ({
  type: types.VERIFY_SIGNUP_EMAIL_OTP_FAILURE,
})

export const verifyPhoneRequest = (payload, navigate, setError) => ({
  type: types.VERIFY_PHONE_OTP_REQUEST,
  payload,
  navigate,
  setError,
})

export const sendUserOTP = () => ({
  type: types.SEND_USER_OTP_REQUEST,
})

export const verifyUpdatePhoneRequest = (payload, navigate, setError) => ({
  type: types.VERIFY_UPDATE_PHONE_OTP_REQUEST,
  payload,
  navigate,
  setError,
})

export const verifyPhoneSuccess = () => ({
  type: types.VERIFY_PHONE_OTP_SUCCESS,
})

export const verifyPhoneFailure = () => ({
  type: types.VERIFY_PHONE_OTP_FAILURE,
})

export const setAuthFields = (payload) => ({
  type: types.SET_AUTH_FIELDS,
  payload,
})

export const setAuthLoading = (payload) => ({
  type: types.SET_AUTH_LOADING,
  payload,
})

export const setRouteBeforeAuth = (payload) => ({
  type: types.SET_ROUTE_BEFORE_AUTH,
  payload,
})

export const fetchCreateNewUserOTP = (payload, navigate, cb, startTimer) => ({
  type: types.FETCH_CREATE_NEW_USER_OTP_REQUEST,
  payload,
  navigate,
  cb,
  startTimer,
})

export const fetchUpdatePhone = (payload, cb) => ({
  type: types.FETCH_UPDATE_PHONE_REQUEST,
  payload,
  cb,
})

export const fetchCreateNewUserOTPSuccess = () => ({
  type: types.FETCH_CREATE_NEW_USER_OTP_SUCCESS,
})

export const fetchCreateNewUserOTPFailure = () => ({
  type: types.FETCH_CREATE_NEW_USER_OTP_FAILURE,
})

export const fetchVerifyEmailUpdateRequest = (payload, navigate, setError) => ({
  type: types.FETCH_VERIFY_EMAIL_UPDATE_REQUEST,
  payload,
  navigate,
  setError,
})

export const setCaptcha = (payload) => ({
  type: types.SET_CAPTCHA,
  payload,
})

export const setResetCaptchaCb = (payload) => ({
  type: types.SET_RESET_CAPTCHA_CB,
  payload,
})
