import React, { useState } from 'react'
import useCurrentWidth from '@hooks/useCurrentWidth'
import { fixedArgClassNames } from '@utils/utils'
import { TSize } from '../types'
import styles from './progress_bar.module.scss'

function ProgressBar({ size, progressPercent = 0 }: { size?: TSize; progressPercent?: number }) {
  const { size: autoSize } = useCurrentWidth()
  const currentSize = size || autoSize

  const partionedClassNames = fixedArgClassNames(styles[currentSize])
  const [fillProgress, setFillProgress] = useState(progressPercent)
  return (
    <div className={partionedClassNames(styles.container)}>
      <div className={styles.emptyBar} />
      <div className={styles.filledLayer} style={{ width: `${fillProgress}%` }} />
    </div>
  )
}

export default ProgressBar
