import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

const DEFAULT_REQUEST_INTERVAL_SEC = 60
const TICK_INTERVAL_MS = 1000
const PERSIST_KEY = 'OtpTimerProvider.timerEnd'

export interface IOtpTimerContext {
  secondsLeft: number
  isIntervalExists: boolean
  startTimer: () => void
}

const OtpTimerContext = createContext<IOtpTimerContext>({
  secondsLeft: 0,
  isIntervalExists: false,
  startTimer: () => {},
})

const clearAllExistingIntervals = () => {
  const intervalId = window.setInterval(() => {}, Number.MAX_SAFE_INTEGER)

  for (let i = 1; i < intervalId; i += 1) {
    clearInterval(i)
  }
}

export function OtpTimerProvider({ children }) {
  const [secondsLeft, setSecondsLeft] = useState(0)

  const startTimer = (secLeft?: number) => {
    const startTimerFromSec = secLeft ?? DEFAULT_REQUEST_INTERVAL_SEC
    setSecondsLeft(startTimerFromSec)

    const timerEndMs = Date.now() + startTimerFromSec * TICK_INTERVAL_MS
    localStorage.setItem(PERSIST_KEY, timerEndMs.toString())

    clearAllExistingIntervals()

    const interval = setInterval(() => {
      const sLeft = Math.ceil((timerEndMs - Date.now()) / TICK_INTERVAL_MS)
      if (sLeft > 0) {
        setSecondsLeft(sLeft)
      } else {
        clearInterval(interval)
        setSecondsLeft(0)
        localStorage.removeItem(PERSIST_KEY)
      }
    }, TICK_INTERVAL_MS)
  }

  // Note: to continue countdown after page refresh
  useEffect(() => {
    const persistedTimerEndMs = Number(localStorage.getItem(PERSIST_KEY))

    if (persistedTimerEndMs > 0) {
      const secLeft = Math.ceil((persistedTimerEndMs - Date.now()) / TICK_INTERVAL_MS)
      startTimer(secLeft)
    }
  }, [])

  const value = useMemo(
    () => ({
      secondsLeft,
      isIntervalExists: secondsLeft > 0,
      startTimer,
    }),
    [secondsLeft],
  )

  return <OtpTimerContext.Provider value={value}>{children}</OtpTimerContext.Provider>
}

export const useOtpTimer = () => useContext<IOtpTimerContext>(OtpTimerContext)

OtpTimerProvider.propTypes = {
  children: PropTypes.node,
}
