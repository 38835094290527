import React from 'react'
import Pagination from '@components/UiKit/Pagination'

function TestPagination() {
  return (
    <div>
      <Pagination pageRangeDisplayed={2} itemsPerPage={5} total={200} />
    </div>
  )
}

export default TestPagination
