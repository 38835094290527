import {
  GET_MARKET,
  SUCCESS_GET_MARKET,
  FAIL_GET_MARKET,
  MARKET_GET_TICKER,
  MARKET_GET_TICKER_SUCCESS,
  MARKET_GET_TICKER_FAILURE,
  MARKET_SET_CURRENT_MARKET,
  MARKETS_SET_CURRENT_MARKET_IFUNSET,
  SEARCH_MARKET,
  SET_UNSET_FAVOURITE,
  CHECK_MARKET_LIMIT,
  SUCCESS_CHECK_MARKET_LIMIT,
  FAIL_CHECK_MARKET_LIMIT,
} from '../constants'

export const getMarkets = (payload) => ({ type: GET_MARKET, payload })

export const successGetMarkets = (payload) => ({ type: SUCCESS_GET_MARKET, payload })

export const failGetMarkets = (payload) => ({ type: FAIL_GET_MARKET, payload })

export const marketGetTickers = () => ({ type: MARKET_GET_TICKER })

export const marketSuccessTicker = (payload) => ({ type: MARKET_GET_TICKER_SUCCESS, payload })

export const marketFailedTicker = () => ({ type: MARKET_GET_TICKER_FAILURE })

export const setCurrentMarket = (payload) => ({ type: MARKET_SET_CURRENT_MARKET, payload })

export const setCurrentMarketIfUnset = (payload) => ({
  type: MARKETS_SET_CURRENT_MARKET_IFUNSET,
  payload,
})

export const searchMarket = (payload) => ({ type: SEARCH_MARKET, payload })

export const setUnsetFavourite = (payload) => ({ type: SET_UNSET_FAVOURITE, payload })

export const checkMarketLimit = (payload) => ({ type: CHECK_MARKET_LIMIT, payload })

export const successCheckMarketLimit = (payload) => ({ type: SUCCESS_CHECK_MARKET_LIMIT, payload })

export const failCheckMarketLimit = (payload) => ({ type: FAIL_CHECK_MARKET_LIMIT, payload })
