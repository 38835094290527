/* eslint-disable */
import {
  FETCH_USER,
  SUCCESS_USER,
  FAIL_USER,
  RESET_USER,
  VERIFY_USER,
  SAVE_ICX_PROGRESS,
  GET_CIRCULATING_SUPPLY,
  SAVE_CIRCULATING_SUPPLY,
  GET_USA_DISCLAIMER,
  SUCCESS_USA_DISCLAIMER,
  FAIL_USA_DISCLAIMER,
  SET_COUNTRY,
  SET_KYC_AID,
  SET_USER_FEE,
  SEND_PASSWORD_OTP,
  SUCCESS_SEND_PASSWORD_OTP,
  FAIL_SEND_PASSWORD_OTP,
  SET_NEW_PASSWORD,
  SUCCESS_SET_NEW_PASSWORD,
  FAIL_SET_NEW_PASSWORD
} from '../constants';

const initState = {
  data: {
    created_at: '',
    email: '',
    id: 0,
    level: 0,
    otp: false,
    role: '',
    state: '',
    uid: '',
    updated_at: ''
  },
  loading: false,
  error: false,
  icx_progress: {},
  circulating_supply: null,
  popup: false,
  countrySelected: '',
  kycAid: {},
  loader: false,
  minFees: '',
  load: false
};

export const userReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_USER: {
      return { ...state, loading: false };
    }
    case SUCCESS_USER: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        popup:
          action.payload.last_country === 'United States' &&
          !action.payload.usa_disclaimer
      };
    }
    case SET_USER_FEE: {
      return {
        ...state,
        loading: false,
        minFees: action.payload
      };
    }
    case SET_COUNTRY: {
      return {
        ...state,
        countrySelected: action.payload
      };
    }
    case FAIL_USER: {
      return { ...state, loading: false, error: true };
    }
    case RESET_USER: {
      return { ...state, data: initState.data };
    }
    case VERIFY_USER: {
      state.data.otp = action.payload.otp;
      return { ...state };
    }
    case SET_KYC_AID: {
      return { ...state, kycAid: action.payload };
    }
    case SAVE_ICX_PROGRESS: {
      return {
        ...state,
        icx_progress: action.payload
      };
    }
    case SAVE_CIRCULATING_SUPPLY: {
      return {
        ...state,
        circulating_supply: action.payload.total_circulation
      };
    }
    case SUCCESS_USA_DISCLAIMER: {
      return {
        ...state,
        loading: false,
        popup:
          action.payload.last_country === 'United States' &&
          !action.payload.usa_disclaimer
      };
    }
    case FAIL_USA_DISCLAIMER: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }
    case SEND_PASSWORD_OTP: {
      return {
        ...state,
        loading: true
      };
    }
    case SUCCESS_SEND_PASSWORD_OTP: {
      return {
        ...state,
        loading: false
      };
    }
    case FAIL_SEND_PASSWORD_OTP: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }
    case SUCCESS_SET_NEW_PASSWORD: {
      return {
        ...state,
        loading: false
      };
    }
    case FAIL_SET_NEW_PASSWORD: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }
    case 'GET_USER_REQUESTED':
      return {
        ...state,
        loader: action.payload
      };

    case 'GET_USER_REQUEST':
      return {
        ...state,
        load: action.payload
      };

    default:
      return state;
  }
};
