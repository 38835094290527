import T from 'prop-types'
import cn from 'classnames'
import LoaderIcon from '@components/Icons/Loader'
import loaderGradient from '@components/Icons/Loader/loaderGradient.svg'

import Typography from '@components/UiKit/Typography'
import styles from './style.module.scss'

const { Label } = Typography

function LoaderComponent({
  height,
  color,
  center,
  absoluteCenter,
  gradient,
  labelText,
  size = 40,
  className,
  iconClassName,
}) {
  return (
    <div
      style={{ height }}
      className={cn(
        styles.wrap_loader,
        {
          [styles['wrap_loader--center']]: center,
          [styles['wrap_loader--absolute-center']]: absoluteCenter,
        },
        className,
      )}
    >
      {!gradient ? (
        <LoaderIcon color={color} size={size} />
      ) : (
        <div className={cn(styles.gradientIcon, iconClassName)}>
          <img src={loaderGradient} width={40} alt="" />

          <Label className={styles.label} size={5}>
            {labelText}
          </Label>
        </div>
      )}
    </div>
  )
}

LoaderComponent.propTypes = {
  color: T.string,
  height: T.string,
  center: T.bool,
  absoluteCenter: T.bool,
  gradient: T.bool,
  labelText: T.string,
  size: T.number,
  className: T.string,
  iconClassName: T.string,
}

export default function Loader({
  isMountVisibleContent,
  isMountContent,
  center,
  absoluteCenter,
  color,
  loading,
  children,
  height,
  heightContainer,
  gradient,
  labelText,
  size = 40,
  className,
  iconClassName,
}) {
  const LoadingIndicator = (
    <LoaderComponent
      height={height}
      center={center}
      absoluteCenter={absoluteCenter}
      color={color}
      gradient={gradient}
      labelText={labelText}
      size={size}
      className={className}
      iconClassName={iconClassName}
    />
  )

  if (isMountContent) {
    return (
      <>
        {loading && LoadingIndicator}
        <div style={{ display: loading ? 'none' : 'block', height: heightContainer }}>
          {children}
        </div>
      </>
    )
  }

  if (isMountVisibleContent) {
    return (
      <div
        style={{
          minHeight: height,
        }}
        className={cn(styles.wrapper, { [styles['wrapper--active']]: loading })}
      >
        {loading && (
          <LoaderComponent
            absoluteCenter
            color={color}
            className={className}
            iconClassName={iconClassName}
          />
        )}
        {children}
      </div>
    )
  }

  if (loading) {
    return LoadingIndicator
  }

  return children
}

Loader.propTypes = {
  color: T.string,
  height: T.string,
  heightContainer: T.string,
  loading: T.bool.isRequired,
  center: T.bool,
  absoluteCenter: T.bool,
  children: T.node,
  isMountContent: T.bool,
  isMountVisibleContent: T.bool,
  gradient: T.bool,
  labelText: T.string,
  className: T.string,
  iconClassName: T.string,
}
