/* eslint-disable max-len */
export default function SmallSegmentsIcon({ size, active }) {
  const fill = active ? 'url(#paint0_linear_4134_32391)' : '#646464'

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          d="M0 1.66667C0 0.746192 0.746192 0 1.66667 0H3.33333C4.25381 0 5 0.746192 5 1.66667V3.33333C5 4.25381 4.25381 5 3.33333 5H1.66667C0.746192 5 0 4.25381 0 3.33333V1.66667Z"
          fill={fill}
        />
        <path
          d="M0 9.16667C0 8.24619 0.746192 7.5 1.66667 7.5H3.33333C4.25381 7.5 5 8.24619 5 9.16667V10.8333C5 11.7538 4.25381 12.5 3.33333 12.5H1.66667C0.746192 12.5 0 11.7538 0 10.8333V9.16667Z"
          fill={fill}
        />
        <path
          d="M0 16.6667C0 15.7462 0.746192 15 1.66667 15H3.33333C4.25381 15 5 15.7462 5 16.6667V18.3333C5 19.2538 4.25381 20 3.33333 20H1.66667C0.746192 20 0 19.2538 0 18.3333V16.6667Z"
          fill={fill}
        />
        <path
          d="M7.5 1.66667C7.5 0.746192 8.24619 0 9.16667 0H10.8333C11.7538 0 12.5 0.746192 12.5 1.66667V3.33333C12.5 4.25381 11.7538 5 10.8333 5H9.16667C8.24619 5 7.5 4.25381 7.5 3.33333V1.66667Z"
          fill={fill}
        />
        <path
          d="M7.5 9.16667C7.5 8.24619 8.24619 7.5 9.16667 7.5H10.8333C11.7538 7.5 12.5 8.24619 12.5 9.16667V10.8333C12.5 11.7538 11.7538 12.5 10.8333 12.5H9.16667C8.24619 12.5 7.5 11.7538 7.5 10.8333V9.16667Z"
          fill={fill}
        />
        <path
          d="M7.5 16.6667C7.5 15.7462 8.24619 15 9.16667 15H10.8333C11.7538 15 12.5 15.7462 12.5 16.6667V18.3333C12.5 19.2538 11.7538 20 10.8333 20H9.16667C8.24619 20 7.5 19.2538 7.5 18.3333V16.6667Z"
          fill={fill}
        />
        <path
          d="M15 1.66667C15 0.746192 15.7462 0 16.6667 0H18.3333C19.2538 0 20 0.746192 20 1.66667V3.33333C20 4.25381 19.2538 5 18.3333 5H16.6667C15.7462 5 15 4.25381 15 3.33333V1.66667Z"
          fill={fill}
        />
        <path
          d="M15 9.16667C15 8.24619 15.7462 7.5 16.6667 7.5H18.3333C19.2538 7.5 20 8.24619 20 9.16667V10.8333C20 11.7538 19.2538 12.5 18.3333 12.5H16.6667C15.7462 12.5 15 11.7538 15 10.8333V9.16667Z"
          fill={fill}
        />
        <path
          d="M15 16.6667C15 15.7462 15.7462 15 16.6667 15H18.3333C19.2538 15 20 15.7462 20 16.6667V18.3333C20 19.2538 19.2538 20 18.3333 20H16.6667C15.7462 20 15 19.2538 15 18.3333V16.6667Z"
          fill={fill}
        />
        <defs>
          <linearGradient
            id="paint0_linear_4134_32391"
            x1="17.9306"
            y1="-0.022021"
            x2="0.17886"
            y2="2.57183"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFBAF3" />
            <stop offset="0.494792" stopColor="#FFF1D4" />
            <stop offset="1" stopColor="#8BF3FC" />
          </linearGradient>
        </defs>
      </g>
    </svg>
  )
}
