import { memo } from 'react'
import T from 'prop-types'
import PaginationReact from 'react-paginate'
import isEqual from 'react-fast-compare'
import cn from 'classnames'

import styles from './style.module.scss'

function Pagination({
  className,
  total = 1,
  itemsPerPage = 1,
  onPageChange,
  nextLabel = '>',
  previousLabel = '<',
  pageRangeDisplayed = 5,
  forcePage,
  pageCount,
  renderOnZeroPageCount = null,
  renderOnOnePageCount = false,
}) {
  const pageCounted = pageCount || Math.ceil(total / itemsPerPage)

  if (!renderOnOnePageCount && pageCounted < 2) {
    return null
  }

  return (
    <PaginationReact
      className={cn(styles.pagination, className)}
      pageCount={pageCounted}
      totalItemsCount={total}
      forcePage={forcePage}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={onPageChange}
      nextLabel={nextLabel}
      previousLabel={previousLabel}
      renderOnZeroPageCount={renderOnZeroPageCount}
    />
  )
}

Pagination.propTypes = {
  className: T.string,
  total: T.number,
  itemsPerPage: T.number,
  onPageChange: T.func,
  nextLabel: T.string,
  previousLabel: T.string,
  pageRangeDisplayed: T.number,
  pageCount: T.number,
  forcePage: T.number,
  // eslint-disable-next-line react/forbid-prop-types
  renderOnZeroPageCount: T.any,
  renderOnOnePageCount: T.bool,
}
export default memo(Pagination, isEqual)
