/* eslint-disable consistent-return */
import { useEffect, useLayoutEffect, useRef } from 'react'

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

const useHookOnce = (hook, callback, dependencies) => {
  const shouldSkip = useRef(!IS_PRODUCTION)
  hook(() => {
    if (shouldSkip.current) {
      shouldSkip.current = false
      return
    }
    return callback()
  }, dependencies)
}

export const useLayoutEffectOnce = (callback, dependencies) =>
  useHookOnce(useLayoutEffect, callback, dependencies)

const useEffectOnce = (callback, dependencies) => useHookOnce(useEffect, callback, dependencies)

export default useEffectOnce
