import { GET_ADDRESS, GET_ADDRESS_SUCCESS, GET_ADDRESS_FAILURE } from '../constants'

const initState = {
  list: [],
  loading: true,
  error: false,
}

// eslint-disable-next-line default-param-last
export const addressReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ADDRESS: {
      return { ...state, loading: true }
    }
    case GET_ADDRESS_SUCCESS: {
      return { ...state, loading: false, list: action.payload }
    }
    case GET_ADDRESS_FAILURE: {
      return { ...state, loading: false, error: true }
    }

    default:
      return state
  }
}
