import * as Sentry from '@sentry/react'
import get from 'lodash.get'
import { errorMessages } from '@constants/messages'

export const sentryErrorHandler = (err: any) => {
  const code = err?.response?.status || err?.status || err?.code
  const message = err?.message

  if ((code >= 400 && code != 401) || (!code && message)) {
    Sentry.captureException(err)
  }
}

export const getErrorMessage = (path: string): string => {
  if (!path) return ''
  return get(errorMessages, path, '')
}
