/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M84.0579 174.248C84.0598 174.25 84.0617 174.252 84.0636 174.254L91.6364 182.135L91.6513 182.15C101.709 192.617 118.015 192.617 128.072 182.15L219.726 86.7707C225.425 80.8398 225.425 71.2239 219.726 65.293L205.461 50.4482C199.762 44.5173 190.521 44.5173 184.822 50.4482L109.862 128.456L71.889 88.9396C66.1898 83.0087 56.9496 83.0087 51.2504 88.9396L35.7715 105.048C30.7428 110.281 30.7428 118.766 35.7715 123.999L84.0579 174.248Z"
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M84.0579 174.248C84.0598 174.25 84.0617 174.252 84.0636 174.254L91.6364 182.135L91.6513 182.15C101.709 192.617 118.015 192.617 128.072 182.15L219.726 86.7707C225.425 80.8398 225.425 71.2239 219.726 65.293L205.461 50.4482C199.762 44.5173 190.521 44.5173 184.822 50.4482L109.862 128.456L71.889 88.9396C66.1898 83.0087 56.9496 83.0087 51.2504 88.9396L35.7715 105.048C30.7428 110.281 30.7428 118.766 35.7715 123.999L84.0579 174.248Z"
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M84.0579 174.248C84.0598 174.25 84.0617 174.252 84.0636 174.254L91.6364 182.135L91.6513 182.15C101.709 192.617 118.015 192.617 128.072 182.15L219.726 86.7707C225.425 80.8398 225.425 71.2239 219.726 65.293L205.461 50.4482C199.762 44.5173 190.521 44.5173 184.822 50.4482L109.862 128.456L71.889 88.9396C66.1898 83.0087 56.9496 83.0087 51.2504 88.9396L35.7715 105.048C30.7428 110.281 30.7428 118.766 35.7715 123.999L84.0579 174.248Z"
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M84.0579 174.248C84.0598 174.25 84.0617 174.252 84.0636 174.254L91.6364 182.135L91.6513 182.15C101.709 192.617 118.015 192.617 128.072 182.15L219.726 86.7707C225.425 80.8398 225.425 71.2239 219.726 65.293L205.461 50.4482C199.762 44.5173 190.521 44.5173 184.822 50.4482L109.862 128.456L71.889 88.9396C66.1898 83.0087 56.9496 83.0087 51.2504 88.9396L35.7715 105.048C30.7428 110.281 30.7428 118.766 35.7715 123.999L84.0579 174.248Z"
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M84.0579 174.248C84.0598 174.25 84.0617 174.252 84.0636 174.254L91.6364 182.135L91.6513 182.15C101.709 192.617 118.015 192.617 128.072 182.15L219.726 86.7707C225.425 80.8398 225.425 71.2239 219.726 65.293L205.461 50.4482C199.762 44.5173 190.521 44.5173 184.822 50.4482L109.862 128.456L71.889 88.9396C66.1898 83.0087 56.9496 83.0087 51.2504 88.9396L35.7715 105.048C30.7428 110.281 30.7428 118.766 35.7715 123.999L84.0579 174.248Z"
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M84.0579 174.248C84.0598 174.25 84.0617 174.252 84.0636 174.254L91.6364 182.135L91.6513 182.15C101.709 192.617 118.015 192.617 128.072 182.15L219.726 86.7707C225.425 80.8398 225.425 71.2239 219.726 65.293L205.461 50.4482C199.762 44.5173 190.521 44.5173 184.822 50.4482L109.862 128.456L71.889 88.9396C66.1898 83.0087 56.9496 83.0087 51.2504 88.9396L35.7715 105.048C30.7428 110.281 30.7428 118.766 35.7715 123.999L84.0579 174.248Z"
    />,
  ],
])

const CustomIconShareCopied: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconShareCopied.displayName = 'CustomIconShareCopied'

export default CustomIconShareCopied
