import React from 'react'
/* eslint-disable max-len */
export function ArrowDownIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
      <path
        d="M20 5.09086L12.4978 15.7733C11.9544 16.5606 11.1481 17.2763 9.88607 17.2763C8.64154 17.2763 7.87029 16.6143 7.30938 15.7733L0 5.09086H4.97809L9.95618 12.5346L15.1972 5.09086H20Z"
        fill="white"
      />
      <path
        d="M20 5.09086L12.4978 15.7733C11.9544 16.5606 11.1481 17.2763 9.88607 17.2763C8.64154 17.2763 7.87029 16.6143 7.30938 15.7733L0 5.09086H4.97809L9.95618 12.5346L15.1972 5.09086H20Z"
        fill="white"
      />
      <path d="M6.28437 0.724609H14.0474V3.36618H6.28437V0.724609Z" fill="white" />
      <path d="M6.28437 0.724609H14.0474V3.36618H6.28437V0.724609Z" fill="white" />
    </svg>
  )
}
