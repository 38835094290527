import {
  OPEN_ORDERS,
  SUCCESS_OPEN_ORDERS,
  FAIL_OPEN_ORDERS,
  UPDATE_OPEN_ORDERS,
  CANCEL_ORDER,
  SUCCESS_CANCEL_ORDER,
  FAIL_CANCEL_ORDER,
  CANCEL_ALL_ORDERS,
  SUCCESS_CANCEL_ALL_ORDERS,
  FAIL_CANCEL_ALL_ORDERS,
} from '../constants'

export const fetchOpenOrders = (payload) => ({ type: OPEN_ORDERS, payload })

export const successOpenOrders = (payload) => ({ type: SUCCESS_OPEN_ORDERS, payload })

export const failOpenOrders = (payload) => ({ type: FAIL_OPEN_ORDERS, payload })

export const updateOpenOrders = (payload) => ({ type: UPDATE_OPEN_ORDERS, payload })

export const cancelOrder = (payload) => ({ type: CANCEL_ORDER, payload })

export const successCancelOrder = (payload) => ({ type: SUCCESS_CANCEL_ORDER, payload })

export const failCancelOrder = (payload) => ({ type: FAIL_CANCEL_ORDER, payload })

export const cancelAllOrders = (payload) => ({ type: CANCEL_ALL_ORDERS, payload })

export const successAllCancelOrders = (payload) => ({ type: SUCCESS_CANCEL_ALL_ORDERS, payload })

export const failAllCancelOrders = (payload) => ({ type: FAIL_CANCEL_ALL_ORDERS, payload })
