export const API_URLS = {
  token: {
    cart: {
      list: '/token/cart',
      create: '/token/purchases/create/order',
      update: '/token/purchases/update',
      delete: '/token/cart/delete',
      deleteItem: '/token/cart/item/delete',
      add: '/token/cart/add',
    },

    balances: '/token/balances',
    purchase: '/token/purchases',
    collected: '/token/collected-tokens',
    details: '/token/details',
    search: '/token/search',
    code: '/token/code',
  },
  wonka: {
    listPurchases: '/wonka/purchases',
    listTokens: '/wonka/tokens',
    listTokensCount: '/wonka/tokens/count',
    create: '/wonka/purchases/new',
    update: '/wonka/purchases/update',
    reveal: 'wonka/reveal',
    revealAll: 'wonka/reveal/all',
  },
  account: {
    banxa: {
      createOrder: '/account/banxa/create-order',
      createWonkaOrder: '/account/banxa/wonka-purchase',
      createNftOrder: '/account/banxa/create-nft-order',
      nftOrderStatus: '/account/banxa/nft/order-status',
      fiatCurrencies: '/account/banxa/fiat_currencies',
      getPrice: '/account/banxa/get-price',
      paymentMethods: '/account/banxa/payment-methods',
      transactions: '/account/banxa/transactions',
    },
    transactions: {
      main: '/account/transactions',
      dashboard: '/account/transactions/dashboard',
      adjustments: '/account/adjustments',
      quotes: '/account/quotes',
      deposits: '/account/deposits',
      banxa: '/account/banxa/transactions',
      bonus: '/account/bonus/all',
      withdraws: '/account/withdraws',
    },
    stripe: {
      createEmojiOrder: '/account/stripe/create-emoji-order',
      wonkaPurchase: '/account/stripe/wonka-purchase',
      transactions: '/account/stripe/transactions',
    },
    depositAddress: '/account/deposit_address',
    deposits: 'account/deposits',
    balances: '/account/balances',
    withdraws: '/account/withdraws',
    withdrawLimits: '/account/withdraw_limits',
    members: {
      me: '/account/members/me',
    },
    updateQuote: '/account/update_quote',
    createQuote: '/account/create_quote',
    swapAgreement: '/account/swap_agreement',
    quotes: '/account/quotes',
    paymentMethod: '/account/payment_method',
    paymentGateways: '/account/payment_gateways',
  },
  resource: {
    phone: '/resource/phones',
    email: {
      notifications: '/resource/email/notifications',
    },
    users: {
      me: '/resource/users/me',
      media: '/resource/users/media',
      removeMedia: '/resource/users/remove_media',
      activity: '/resource/users/activity/session',
      mediaEmoji: '/resource/profile/token-media',
      mediaWonka: '/resource/profile/wonka-media',
      mediaExternalNft: '/resource/users/media/nft',
      wallet: '/resource/user/wallet',
      updateEmail: '/resource/user/email',
      verifyEmail: '/resource/user/email/verify',
      updatePhoneNumber: '/resource/user/phone_number',
      verifyPhoneNumber: '/resource/user/phone_number/verify',
      resendOtp: 'resource/user/send-otp',
      setPassword: '/resource/users/set-password',
      update: '/resource/users/update',
      password: '/resource/users/password',
      nftDetails: '/resource/users/nft-details',
    },
    otp: {
      generateQrcode: 'resource/otp/generate_qrcode',
      enable: 'resource/otp/enable',
      disable: 'resource/otp/disable',
    },
  },
  identity: {
    sessions: {
      new: '/identity/sessions/new',
      verify: {
        otp: '/identity/sessions/verify/otp',
      },
      index: '/identity/sessions',
    },
    users: {
      new: '/identity/users/new',
      newUser: '/identity/users',
      username: '/identity/users/username',
      passwordGenerateCode: '/identity/users/password/generate_code',
      passwordConfirm: '/identity/users/password/confirm_code',
      verifySignup: '/identity/users/verify_signup',
    },
  },

  logout: '/identity/sessions',
}
