import ReactGA from 'react-ga4'

import helpers from '@utils/helpers'
import { isDevOrDevEnvironment, isProductionEnvironment } from '@constants'
import { ANALYTICS_CATEGORY } from './analyticsTracker.constants'
import PAYMENT_EVENTS from './categories/payment'
import EMOJI_EVENTS from './categories/emoji'
import WONKA_EVENTS from './categories/wonka'
import CHECKOUT_EVENTS from './categories/checkout'
import SEARCH_EVENTS from './categories/search'
import CART_EVENTS from './categories/cart'
import PURCHASE_EVENTS from './categories/purchase'
import AUTH_EVENTS from './categories/auth'
import STRIPE_EVENTS from './categories/stripe'
import WEB3_WALLET_EVENTS from './categories/web3Wallet'

const prepareLabel = ({ label, initialLabel, labelReplaceByKeys, labelEnding }) => {
  let newLabel = label

  if (!newLabel) {
    if (labelReplaceByKeys) {
      newLabel = Object.entries(labelReplaceByKeys).forEach((entry) => {
        initialLabel.replace(entry[0], entry[1])
      })
    } else {
      newLabel = initialLabel
    }
  }

  if (labelEnding) {
    newLabel = `${newLabel} ${labelEnding}`
  }

  return newLabel
}

const prepareActions = (category, events) => {
  const actions = {}
  Object.entries(events).forEach(([item, valueItem]) => {
    actions[item] = ({
      label,
      labelReplaceByKeys,
      labelEnding,
      value,
      nonInteraction,
      paymentType,
      currency,
      searchingItem,
      address,
      ownedTokensCount,
    } = {}) => {
      const newLabel = prepareLabel({
        label,
        initialLabel: valueItem.label,
        labelReplaceByKeys,
        labelEnding,
      })

      const obj = {
        category,
        action: valueItem.event,
        nonInteraction: nonInteraction || valueItem.nonInteraction,
        value: value || valueItem.value,
        label: newLabel,
        payment_type: paymentType,
        currency,
        search_term: searchingItem,
        address,
        nft_tokens_count: ownedTokensCount,
      }

      return helpers.removeEmptyValues(obj)
    }
  })

  return actions
}

export const PaymentEvents = prepareActions(ANALYTICS_CATEGORY.payment, PAYMENT_EVENTS)
export const EmojiEvents = prepareActions(ANALYTICS_CATEGORY.emoji, EMOJI_EVENTS)
export const WonkaEvents = prepareActions(ANALYTICS_CATEGORY.wonka, WONKA_EVENTS)
export const CheckoutEvents = prepareActions(ANALYTICS_CATEGORY.checkout, CHECKOUT_EVENTS)
export const SearchEvents = prepareActions(ANALYTICS_CATEGORY.search, SEARCH_EVENTS)
export const CartEvents = prepareActions(ANALYTICS_CATEGORY.cart, CART_EVENTS)
export const PurchaseEvents = prepareActions(ANALYTICS_CATEGORY.purchase, PURCHASE_EVENTS)
export const AuthEvents = prepareActions(ANALYTICS_CATEGORY.auth, AUTH_EVENTS)
export const StripeEvents = prepareActions(ANALYTICS_CATEGORY.stripe, STRIPE_EVENTS)
export const Web3WalletEvents = prepareActions(ANALYTICS_CATEGORY.web3Wallet, WEB3_WALLET_EVENTS)

export const eventTrack = ({ category, action, label, value, nonInteraction = false, ...rest }) => {
  const options = helpers.removeEmptyValues({
    category,
    action,
    label,
    value,
    nonInteraction,
    ...rest,
  })
  if (isDevOrDevEnvironment) {
    // eslint-disable-next-line no-console
    console.table({
      TRACK: 'EVENT',
      _____: '________________________',
      ...options,
    })
  }

  if (isProductionEnvironment && category && action) {
    ReactGA.event(options)
  }

  return null
}
