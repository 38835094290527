export default {
  authBegin: {
    event: 'auth_begin',
    label: 'Auth button click',
  },
  authCompleted: {
    event: 'auth_complete',
    label: 'Auth success',
    nonInteraction: true,
  },
  signupBegin: {
    event: 'sign_up_begin',
    label: `Filled email doesn't exist, signup started`,
  },
  signupComplete: {
    event: 'sign_up_complete',
    label: 'Success signup',
    nonInteraction: true,
  },
}
