import { call, put, takeEvery } from 'redux-saga/effects'
import routes from '@routing/path'
import * as actions from '../actions'
import * as types from '../constants'
import { API } from '../../api'
import { showAlert } from '../actions/alert'
import { fetchUser } from './user'

const signUpConfig = {
  apiVersion: 'barong',
}

export function* savePhone(action) {
  yield put(actions.getUserRequested(true))
  try {
    const api = yield call(API.post(signUpConfig), '/resource/phones', action.payload)
    yield put(actions.successSaveNumber({ success: true, statusCode: 201 }))
    if (action.callback) {
      action.callback(api)
    }
    yield put(showAlert({ message: ['OTP sent successfully'], type: 'success' }))
  } catch (e) {
    if (action.callback) {
      action.callback(e)
    }
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    yield put(actions.failSaveNumber({ statusCode: e.code, error: true }))
  }
  yield put(actions.getUserRequested(false))
}

export function* verifyPhone({ payload, navigate }) {
  yield put(actions.getUserRequested(true))

  try {
    yield call(API.post(signUpConfig), '/resource/phones/verify', payload)
    yield put(actions.successVerifyNumber({ success: true }))
    yield call(fetchUser)
    navigate(routes.withAuth.profile.kyc.profile)
    yield put(showAlert({ message: ['Phone number verified.'], type: 'success' }))
    yield put(actions.getUserRequested(false))
  } catch (e) {
    yield put(actions.failVerifyNumber({ error: true }))
    yield put(actions.getUserRequested(false))

    yield put(
      showAlert({
        message: e.message,
        code: e.code,
        type: 'error',
      }),
    )
  }
}

export function* resendOtp(action) {
  yield put(actions.getUserRequested(true))

  try {
    yield call(API.post(signUpConfig), '/resource/phones/send_code', action.payload)
    yield put(actions.getUserRequested(false))
  } catch (e) {
    yield put(actions.failResendOtp({ error: true }))
    yield put(actions.getUserRequested(false))
  }
}

export function* savePhoneSaga() {
  yield takeEvery(types.SAVE_NUMBER, savePhone)
}

export function* verifyPhoneSaga() {
  yield takeEvery(types.VERIFY_NUMBER, verifyPhone)
}

export function* resendOtpSaga() {
  yield takeEvery(types.RESEND_OTP, resendOtp)
}
