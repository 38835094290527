import { useEffect, useState } from 'react'

import { Breakpoints } from '@constants/settings'

const getElemOnRow = (isLarge?: boolean) => {
  const width = window.innerWidth
  let row = 2

  if (width >= Breakpoints.large) {
    row = 6
  } else if (width >= Breakpoints.xl) {
    row = 4
  } else if (width >= Breakpoints.md) {
    row = 3
  }

  if (isLarge) {
    row -= 1
  }

  return row
}

export const getItemOrder = (position, isLarge) => {
  const row = getElemOnRow(isLarge)

  return Math.ceil(position / row) * row
}

export const useLimitItem = (isLarge) => {
  const row = 5
  const [itemOnRow, setItemOnRow] = useState(getElemOnRow(isLarge))

  useEffect(() => {
    const func = () => setItemOnRow(getElemOnRow(isLarge))
    func()
    window.addEventListener('resize', func)

    return () => {
      window.removeEventListener('resize', func)
    }
  }, [isLarge])

  return itemOnRow * row
}
