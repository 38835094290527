import {
  CONNECT_WALLET,
  SUCCESS_CONNECT_WALLET,
  DISCONNECT_WALLET,
  INTIATE_SWAP_TRANSFER,
  SUCCESS_SWAP_TRANSFER,
  ERROR_SWAP_TRANSFER,
  CANCEL_SWAP_TRANSFER,
} from '../constants'

const initState = {
  address: null,
  balance: null,
  connect: false,
  txId: null,
  type: '',
  loading: false,
}

// eslint-disable-next-line default-param-last
export const swapReducer = (state = initState, action) => {
  switch (action.type) {
    case CONNECT_WALLET: {
      return {
        ...state,
        loading: true,
      }
    }

    case SUCCESS_CONNECT_WALLET: {
      return {
        ...state,
        loading: false,
        connect: !!action.payload,
        balance: action.payload ? action.payload.balance : '',
        address: action.payload ? action.payload.address : '',
        type: action.payload ? action.payload.type : '',
      }
    }

    case DISCONNECT_WALLET: {
      return {
        ...state,
        loading: false,
        connect: false,
        balance: null,
        address: null,
      }
    }

    case INTIATE_SWAP_TRANSFER: {
      return {
        ...state,
        loading: true,
      }
    }

    case SUCCESS_SWAP_TRANSFER: {
      return {
        ...state,
        loading: false,
        txId: action.payload,
      }
    }

    case ERROR_SWAP_TRANSFER: {
      return {
        ...state,
        loading: false,
        txId: null,
        connect: false,
        balance: null,
        address: null,
      }
    }

    case CANCEL_SWAP_TRANSFER: {
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}
