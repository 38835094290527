import React, { HTMLAttributes } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { TNullable } from 'types/utilityTypes'
import useCurrentWidth from '@hooks/useCurrentWidth'
import { fixedArgClassNames } from '@utils/utils'

import styles from './styles.module.scss'
import { ArrowDownIcon } from './icon'

const defaultImg = 'http://swap-assests.pstuff.net/tokens/219/medium_nfe_poop_03.png'

export interface IMojiCardProps extends HTMLAttributes<HTMLDivElement> {
  innerRef?: React.MutableRefObject<undefined> | any
  src?: string
  name?: string
  price?: TNullable<number>
  currency?: string
  contractType?: string
  classNameDetails?: string
  classNamePicture?: string
  additionContent?: React.ReactNode
  quantity?: number
  isActive?: boolean
  isOwned?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  details?: boolean
  appForbidden?: boolean
  contractAddress?: string
}

function MojiCard({
  src = defaultImg,
  name = '',
  price = null,
  currency = '$',
  details = true,
  contractType,
  additionContent,
  className,
  classNameDetails,
  classNamePicture,
  isActive,
  onClick,
  quantity,
  isOwned,
  id,
  style,
  innerRef,
}: Readonly<IMojiCardProps>) {
  const { size: autoSize } = useCurrentWidth()
  const partionedClassNames = fixedArgClassNames(styles[autoSize])
  const { t } = useTranslation('nsMoji')
  const card = t('card', { returnObjects: true })

  return (
    <div
      ref={innerRef}
      style={style}
      onClick={onClick}
      className={cn(styles.card, className, {
        [styles.active]: isActive,
        [styles.card__pointer]: onClick,
      })}
    >
      <div className={partionedClassNames(styles.inner)}>
        <div className={cn(styles.picture, classNamePicture)}>
          <div className={styles.picture__inner}>
            <div className={partionedClassNames(styles.picture__inner_wrap)}>
              <img src={src} alt={name} />
              <img src={src} alt={name} />
            </div>
          </div>
        </div>

        {details && (
          <div className={cn(styles.details, classNameDetails)}>
            <div className={styles.details__header}>
              <div className={styles.details__header_info}>
                <div className={styles.details__arrow}>
                  <div className={partionedClassNames(styles.details__arrow_icon)}>
                    <ArrowDownIcon />
                  </div>
                </div>
                {isOwned && (
                  <div className={partionedClassNames(styles.details__owned)}>
                    <span>{card.owned}</span>
                  </div>
                )}
              </div>

              {id && <div className={styles.id}>ID: {id}</div>}
            </div>

            <div className={styles.details__content}>
              <p className={partionedClassNames(styles.details__name)}>{name}</p>
              <div className={styles.details__price}>
                {(quantity && (
                  <div className={partionedClassNames(styles.details__price_value)}>
                    QTY:{quantity}
                  </div>
                )) ||
                  ''}
                {(price && (
                  <div className={partionedClassNames(styles.details__price_value)}>
                    {currency}
                    {price}
                  </div>
                )) ||
                  ''}
                {(contractType && (
                  <div className={partionedClassNames(styles.details__price_type)}>
                    {contractType}
                  </div>
                )) ||
                  ''}
              </div>
            </div>
          </div>
        )}

        {additionContent}
      </div>
    </div>
  )
}

export default React.memo(MojiCard)
