import React from 'react'
import cn from 'classnames'
import useCurrentWidth from '@hooks/useCurrentWidth'
import { TSize } from '@components/UiKit/types'
import cameraIcon from '@assets/images/camera/cameraIcon.svg'

import styles from './camera_button.module.scss'

interface ICameraProps {
  size?: TSize | undefined
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

function CameraButton({ size, onClick }: ICameraProps) {
  const { isMobile, isTablet, isLaptop } = useCurrentWidth()
  const getSize = (specificSize: TSize | undefined) => {
    if (isMobile || TSize.small === specificSize) return TSize.small
    if (isTablet || isLaptop || TSize.compact === specificSize) return TSize.compact
    return TSize.spacious
  }

  return (
    <div className={cn(styles.container, styles[getSize(size)])} onClick={onClick}>
      <div className={cn(styles.camera_button_wrapper, styles[getSize(size)])}>
        <img
          className={cn(styles.camera_button, styles[getSize(size)])}
          src={cameraIcon}
          draggable="false"
          alt=""
        />
      </div>
    </div>
  )
}

export default React.memo(CameraButton)
