import { toast } from 'react-toastify'

export const copyLink = (e, id) => {
  const copyText = document.querySelector(`#${id}`).innerHTML
  if (copyText) {
    copyText.select()
    document.execCommand('copy')
    window.getSelection().removeAllRanges()
    e.target.focus()
    toast.success('Copied!')
  }
}
