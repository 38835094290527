import { call, delay, put, select, take, takeLatest, takeLeading } from 'redux-saga/effects'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import { API, BarongConfig } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'
import { errorsHandler, getErrorText } from '@core/helper'
import routes from '@routing/path'
import { AuthPages } from '@containers/Auth/types'
import { fetchProfile, fetchProfileSuccess } from '@core/profile/actions'
import {
  navigateAfterPhoneOtp,
  navigateAfterSigninEmailOtp,
  navigateAfterSignupEmailOtp,
} from '@core/auth/sagas.navigate'
import { AuthEvents, eventTrack } from '@utils/analyticsTracker'
import { successMessages } from '@constants/messages'
import types from '../types'
import {
  fetchCheckIfUsernameAvailableFailure,
  fetchCheckIfUsernameAvailableSuccess,
  fetchCreateNewUserOTPFailure,
  fetchSendSessionOTPFailure,
  fetchSendSessionOTPRequest,
  fetchSendSessionOTPSuccess,
  fetchVerifySignupFailure,
  fetchVerifySignupSuccess,
  setAuthFields,
  setAuthLoading,
  verifyPhoneFailure,
  verifyPhoneSuccess,
  verifySigninEmailFailure,
  verifySigninEmailSuccess,
  verifySigninPhoneAndUpdateEmailFailure,
  verifySigninPhoneAndUpdateEmailSuccess,
  verifySignupEmailFailure,
  verifySignupEmailSuccess,
} from './actions'

const MS_BEFORE_REDIRECT = 1500

function* resetAndGetNewCaptcha() {
  const resetCaptcha = yield select((state) => state.auth.resetCaptcha)
  resetCaptcha()

  const { payload: newCaptcha } = yield take(types.SET_CAPTCHA)

  return newCaptcha
}

function* checkIfUsernameAvailable({ cb, payload }) {
  try {
    yield call(
      API.get(BarongConfig),
      `${API_URLS.identity.users.username}?username=${payload.userName}`,
    )
    yield put(fetchCheckIfUsernameAvailableSuccess())
    cb?.onSuccess()
  } catch (err) {
    yield put(fetchCheckIfUsernameAvailableFailure())
    cb?.onError(err)
  }
}

function* sendSessionOTPSaga({ payload, cb }) {
  try {
    yield call(API.post(BarongConfig), API_URLS.identity.sessions.new, payload)
    yield put(fetchSendSessionOTPSuccess())
    cb?.onSuccess?.call()
  } catch (err) {
    yield put(fetchSendSessionOTPFailure())
    errorsHandler(err)
    cb?.onError?.call(null, err)
  } finally {
    cb?.onFinally?.call()
  }
}

function* newUserSendOTPs({ payload, navigate, cb, startTimer }) {
  try {
    yield put(setAuthLoading(true))

    yield call(API.post(BarongConfig), API_URLS.identity.users.newUser, payload)
    yield delay(MS_BEFORE_REDIRECT)

    eventTrack(AuthEvents.signupBegin())
    startTimer()
    navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: { page: AuthPages.phoneOtp },
      }),
    )
  } catch (err) {
    yield put(fetchCreateNewUserOTPFailure())
    if (err?.message[0]?.includes('already_registered') && cb) {
      cb(err.message[0])
      errorsHandler(err)
    } else if (err?.message[0]?.includes('invalid_num')) {
      errorsHandler('Please Enter Valid Phone number')
    } else if (err?.message[0]?.includes('email.not_associated')) {
      const cb = {
        onSuccess: () => {
          navigate(
            queryString.stringifyUrl({
              url: routes.withoutAuth.auth,
              query: { page: AuthPages.signinPhoneOtp },
            }),
          )
        },
        onError: (err) => console.log(err),
      }

      yield put(
        fetchSendSessionOTPRequest(
          {
            ...payload,
            identity: payload.phone_number,
          },
          cb,
        ),
      )
    } else {
      errorsHandler(err)
    }
  } finally {
    yield put(setAuthFields({ phone: payload.phone_number }))
    yield put(setAuthLoading(false))
  }
}

function* verifySignupSaga({ payload, navigate, startTimer }) {
  try {
    yield put(setAuthLoading(true))
    yield call(API.post(BarongConfig), API_URLS.identity.sessions.new, payload)
    yield put(fetchVerifySignupSuccess())

    startTimer()

    navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: { page: AuthPages.signinEmailOtp },
      }),
    )
  } catch (err) {
    yield put(fetchVerifySignupFailure())

    if (err.message?.[0] === 'identity.session.invalid_params') {
      toast.info(getErrorText('identity.user.doesnt_exist'))
      yield delay(MS_BEFORE_REDIRECT)

      navigate(
        queryString.stringifyUrl({
          url: routes.withoutAuth.auth,
          query: { page: AuthPages.signupPhone },
        }),
      )
    }
  } finally {
    yield put(setAuthLoading(false))
    yield put(setAuthFields({ email: payload.identity }))
  }
}

function* verifySigninEmailSaga({ payload, navigate, setError }) {
  try {
    const userData = yield call(
      API.post(BarongConfig),
      API_URLS.identity.sessions.verify.otp,
      payload,
    )

    yield put(verifySigninEmailSuccess())

    // NOTE: fetchProfileSuccess need to successful signin
    yield put(fetchProfileSuccess(userData))
    // NOTE: fetchProfile need to grab all the user data
    yield put(fetchProfile())

    yield call(navigateAfterSigninEmailOtp, navigate, userData, payload)
  } catch (err) {
    yield put(verifySigninEmailFailure())
    setError(getErrorText(err.message?.[0]))
  }
}

function* verifySigninPhoneAndUpdateEmailSaga({ payload, navigate, setError }) {
  try {
    yield call(API.post(BarongConfig), API_URLS.identity.sessions.verify.otp, {
      identity: payload.identity,
      verification_code: payload.verification_code,
      platform: payload.platform,
      captchaValue: payload.captchaValue,
    })

    yield put(verifySigninPhoneAndUpdateEmailSuccess())
    yield put(fetchProfile())

    const newCaptchaValue = yield call(resetAndGetNewCaptcha)

    yield call(API.post(BarongConfig), API_URLS.resource.users.updateEmail, {
      email: payload.email,
      platform: payload.platform,
      captchaValue: newCaptchaValue,
    })

    navigate(
      queryString.stringifyUrl({
        url: routes.withoutAuth.auth,
        query: {
          page: AuthPages.updateEmailOtp,
        },
      }),
    )
  } catch (err) {
    yield put(verifySigninPhoneAndUpdateEmailFailure())
    setError(getErrorText(err.message?.[0]))
  }
}

function* verifySignupEmailSaga({ payload, navigate, setError }) {
  try {
    const userData = yield call(
      API.post(BarongConfig),
      API_URLS.identity.sessions.verify.otp,
      payload,
    )

    yield put(verifySignupEmailSuccess())
    yield put(fetchProfile())
    yield call(navigateAfterSignupEmailOtp, navigate, userData)
  } catch (err) {
    yield put(verifySignupEmailFailure())
    setError(getErrorText(err.message?.[0]))
  }
}

function* verifyPhoneSaga({ payload, navigate, setError }) {
  try {
    const userData = yield call(
      API.post(BarongConfig),
      API_URLS.identity.sessions.verify.otp,
      payload,
    )

    yield put(verifyPhoneSuccess())
    yield put(fetchProfileSuccess(userData))

    yield call(navigateAfterPhoneOtp, navigate, userData)
  } catch (err) {
    yield put(verifyPhoneFailure())
    setError(getErrorText(err.message?.[0]))
  }
}

function* updatePhoneOtpSaga({ payload, cb }) {
  try {
    yield put(setAuthLoading(true))
    yield call(API.post(BarongConfig), API_URLS.resource.users.updatePhoneNumber, payload)

    cb?.onSuccess()
  } catch (err) {
    errorsHandler(err)
    cb?.onError(getErrorText(err.message?.[0]))
  } finally {
    yield put(setAuthFields({ phone: payload.phone_number }))
    yield put(setAuthLoading(false))
  }
}

function* verifyUpdatePhoneSaga({ payload, navigate, setError }) {
  try {
    const userData = yield call(
      API.post(BarongConfig),
      API_URLS.resource.users.verifyPhoneNumber,
      payload,
    )

    yield put(fetchProfileSuccess(userData))

    yield call(navigateAfterPhoneOtp, navigate, userData)
  } catch (err) {
    yield put(verifyPhoneFailure())
    setError(getErrorText(err.message?.[0]))
  }
}

function* sendUserOTPSaga() {
  try {
    yield put(setAuthLoading(true))
    yield call(API.post(BarongConfig), API_URLS.resource.users.resendOtp)
  } catch (err) {
    // TODO: add proper handling errors
  } finally {
    yield put(setAuthLoading(false))
  }
}

function* verifyEmailUpdateSaga({ payload, navigate, setError }) {
  try {
    const userData = yield call(
      API.post(BarongConfig),
      API_URLS.resource.users.verifyEmail,
      payload,
    )
    yield put(fetchProfile())
    toast.success(successMessages.other.email_updated)

    yield call(navigateAfterSigninEmailOtp, navigate, userData, payload)
  } catch (err) {
    errorsHandler(err)
    setError(getErrorText(err.message?.[0]))
  }
}

export function* rootSagaAuth() {
  yield takeLatest(types.FETCH_CHECK_USERNAME_REQUSET, checkIfUsernameAvailable)
  yield takeLeading(types.FETCH_SEND_SESSION_OTP_REQUEST, sendSessionOTPSaga)
  yield takeLatest(types.FETCH_VERIFY_SIGNUP_REQUEST, verifySignupSaga)
  yield takeLatest(types.VERIFY_SIGNIN_EMAIL_OTP_REQUEST, verifySigninEmailSaga)
  yield takeLatest(types.VERIFY_SIGNUP_EMAIL_OTP_REQUEST, verifySignupEmailSaga)
  yield takeLatest(types.VERIFY_PHONE_OTP_REQUEST, verifyPhoneSaga)
  yield takeLatest(types.FETCH_CREATE_NEW_USER_OTP_REQUEST, newUserSendOTPs)
  yield takeLatest(types.FETCH_UPDATE_PHONE_REQUEST, updatePhoneOtpSaga)
  yield takeLatest(types.VERIFY_UPDATE_PHONE_OTP_REQUEST, verifyUpdatePhoneSaga)
  yield takeLatest(types.SEND_USER_OTP_REQUEST, sendUserOTPSaga)
  yield takeLatest(types.FETCH_VERIFY_EMAIL_UPDATE_REQUEST, verifyEmailUpdateSaga)
  yield takeLatest(types.VERIFY_SIGNIN_PHONE_AND_UPDATE_EMAIL, verifySigninPhoneAndUpdateEmailSaga)
}
