export default {
  addressConnected: {
    event: 'address_connected',
    label: 'Wallet address connected',
  },
  addressVerified: {
    event: 'address_verified',
    label: 'Wallet address verified and linked to profile',
    nonInteraction: true,
  },
  addressDisconnected: {
    event: 'address_disconnected',
    label: `Wallet address disconnected`,
  },
  ownedNFTsFetched: {
    event: 'owned_nft_tokens_fetched',
    label: 'Owned NFT tokens list fetched',
    nonInteraction: true,
  },
}
