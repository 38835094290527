/* eslint-disable max-len */
import React, { forwardRef, ReactElement } from 'react'
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react'

const weights = new Map<IconWeight, ReactElement>([
  [
    'thin',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M118.4 166H80C66.72 166 55.4 161.32 46.04 151.96C36.68 142.6 32 131.28 32 118C32 104.72 36.68 93.4 46.04 84.04C55.4 74.68 66.72 70 80 70H118.4V89.2H80C72 89.2 65.2 92 59.6 97.6C54 103.2 51.2 110 51.2 118C51.2 126 54 132.8 59.6 138.4C65.2 144 72 146.8 80 146.8H118.4V166ZM89.6 127.6V108.4H166.4V127.6H89.6ZM137.6 166V146.8H176C184 146.8 190.8 144 196.4 138.4C202 132.8 204.8 126 204.8 118C204.8 110 202 103.2 196.4 97.6C190.8 92 184 89.2 176 89.2H137.6V70H176C189.28 70 200.6 74.68 209.96 84.04C219.32 93.4 224 104.72 224 118C224 131.28 219.32 142.6 209.96 151.96C200.6 161.32 189.28 166 176 166H137.6Z"
    />,
  ],
  [
    'light',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M118.4 166H80C66.72 166 55.4 161.32 46.04 151.96C36.68 142.6 32 131.28 32 118C32 104.72 36.68 93.4 46.04 84.04C55.4 74.68 66.72 70 80 70H118.4V89.2H80C72 89.2 65.2 92 59.6 97.6C54 103.2 51.2 110 51.2 118C51.2 126 54 132.8 59.6 138.4C65.2 144 72 146.8 80 146.8H118.4V166ZM89.6 127.6V108.4H166.4V127.6H89.6ZM137.6 166V146.8H176C184 146.8 190.8 144 196.4 138.4C202 132.8 204.8 126 204.8 118C204.8 110 202 103.2 196.4 97.6C190.8 92 184 89.2 176 89.2H137.6V70H176C189.28 70 200.6 74.68 209.96 84.04C219.32 93.4 224 104.72 224 118C224 131.28 219.32 142.6 209.96 151.96C200.6 161.32 189.28 166 176 166H137.6Z"
    />,
  ],
  [
    'regular',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M118.4 166H80C66.72 166 55.4 161.32 46.04 151.96C36.68 142.6 32 131.28 32 118C32 104.72 36.68 93.4 46.04 84.04C55.4 74.68 66.72 70 80 70H118.4V89.2H80C72 89.2 65.2 92 59.6 97.6C54 103.2 51.2 110 51.2 118C51.2 126 54 132.8 59.6 138.4C65.2 144 72 146.8 80 146.8H118.4V166ZM89.6 127.6V108.4H166.4V127.6H89.6ZM137.6 166V146.8H176C184 146.8 190.8 144 196.4 138.4C202 132.8 204.8 126 204.8 118C204.8 110 202 103.2 196.4 97.6C190.8 92 184 89.2 176 89.2H137.6V70H176C189.28 70 200.6 74.68 209.96 84.04C219.32 93.4 224 104.72 224 118C224 131.28 219.32 142.6 209.96 151.96C200.6 161.32 189.28 166 176 166H137.6Z"
    />,
  ],
  [
    'bold',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M118.4 166H80C66.72 166 55.4 161.32 46.04 151.96C36.68 142.6 32 131.28 32 118C32 104.72 36.68 93.4 46.04 84.04C55.4 74.68 66.72 70 80 70H118.4V89.2H80C72 89.2 65.2 92 59.6 97.6C54 103.2 51.2 110 51.2 118C51.2 126 54 132.8 59.6 138.4C65.2 144 72 146.8 80 146.8H118.4V166ZM89.6 127.6V108.4H166.4V127.6H89.6ZM137.6 166V146.8H176C184 146.8 190.8 144 196.4 138.4C202 132.8 204.8 126 204.8 118C204.8 110 202 103.2 196.4 97.6C190.8 92 184 89.2 176 89.2H137.6V70H176C189.28 70 200.6 74.68 209.96 84.04C219.32 93.4 224 104.72 224 118C224 131.28 219.32 142.6 209.96 151.96C200.6 161.32 189.28 166 176 166H137.6Z"
    />,
  ],
  [
    'fill',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M118.4 166H80C66.72 166 55.4 161.32 46.04 151.96C36.68 142.6 32 131.28 32 118C32 104.72 36.68 93.4 46.04 84.04C55.4 74.68 66.72 70 80 70H118.4V89.2H80C72 89.2 65.2 92 59.6 97.6C54 103.2 51.2 110 51.2 118C51.2 126 54 132.8 59.6 138.4C65.2 144 72 146.8 80 146.8H118.4V166ZM89.6 127.6V108.4H166.4V127.6H89.6ZM137.6 166V146.8H176C184 146.8 190.8 144 196.4 138.4C202 132.8 204.8 126 204.8 118C204.8 110 202 103.2 196.4 97.6C190.8 92 184 89.2 176 89.2H137.6V70H176C189.28 70 200.6 74.68 209.96 84.04C219.32 93.4 224 104.72 224 118C224 131.28 219.32 142.6 209.96 151.96C200.6 161.32 189.28 166 176 166H137.6Z"
    />,
  ],
  [
    'duotone',
    <path
      style={{ stroke: 'none', fillRule: 'evenodd', fill: 'rgb(100%,100%,100%)', fillOpacity: 1 }}
      d="M118.4 166H80C66.72 166 55.4 161.32 46.04 151.96C36.68 142.6 32 131.28 32 118C32 104.72 36.68 93.4 46.04 84.04C55.4 74.68 66.72 70 80 70H118.4V89.2H80C72 89.2 65.2 92 59.6 97.6C54 103.2 51.2 110 51.2 118C51.2 126 54 132.8 59.6 138.4C65.2 144 72 146.8 80 146.8H118.4V166ZM89.6 127.6V108.4H166.4V127.6H89.6ZM137.6 166V146.8H176C184 146.8 190.8 144 196.4 138.4C202 132.8 204.8 126 204.8 118C204.8 110 202 103.2 196.4 97.6C190.8 92 184 89.2 176 89.2H137.6V70H176C189.28 70 200.6 74.68 209.96 84.04C219.32 93.4 224 104.72 224 118C224 131.28 219.32 142.6 209.96 151.96C200.6 161.32 189.28 166 176 166H137.6Z"
    />,
  ],
])

const CustomIconShareCopy: Icon = forwardRef((props, ref) => (
  <IconBase ref={ref} {...props} weights={weights} />
)) as Icon

CustomIconShareCopy.displayName = 'CustomIconShareCopy'

export default CustomIconShareCopy
