export default {
  openCheckout: {
    event: 'begin_checkout',
    label: 'Page Checkout has opened',
  },

  closeCheckout: {
    event: 'checkout_complete',
    label: 'Page Checkout has closed',
  },
}
