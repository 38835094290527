/* eslint-disable import/no-cycle */
import storage from 'redux-persist/lib/storage'
import persistReducer from 'redux-persist/es/persistReducer'
import { setLoading } from '@core/helper'
import encryptor from '../encryptor'
import types from './types'

const initialState = {
  loading: {
    list: false,
    revealAll: false,
    purchase: false,
  },
  error: '',
  total: 0,
  paginated: {},
  wonkaTokensPopup: {
    isOpen: false,
    tid: null,
  },
}

const wonkaReducer = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case types.FETCH_WONKA_TOKENS_SUCCESS:
      return {
        ...state,
        total: payload.total,
        paginated: {
          ...state.paginated,
          [payload.page]: payload.list.map((item) => ({
            ...item,
            explosionVideo: !item.revealed,
          })),
        },
        loading: setLoading(state.loading, 'list', false),
      }
    case types.FETCH_WONKA_TOKENS_REQUEST:
      return {
        ...state,
        loading: setLoading(state.loading, 'list', true),
      }
    case types.REVEAL_WONKA_REQUEST:
      const i = state.paginated[payload.page].findIndex((wonka) => wonka.code === payload.code)
      const revealingWonka = state.paginated[payload.page][i]
      revealingWonka.revealed = true
      return {
        ...state,
        paginated: {
          ...state.paginated,
          [payload.page]: [
            ...state.paginated[payload.page].slice(0, i),
            revealingWonka,
            ...state.paginated[payload.page].slice(i + 1),
          ],
        },
      }
    case types.REVEAL_WONKA_SUCCESS:
      let page
      if (payload.page) {
        page = payload.page
      } else {
        Object.entries(state.paginated).forEach(([pageNum, wonkas]) => {
          if (wonkas.some((wonka) => wonka.code === payload.code)) {
            page = pageNum
          }
        })

        if (page === -1) {
          return state
        }
      }

      const index = state.paginated[page].findIndex((wonka) => wonka.code === payload.code)

      if (index === -1) {
        return state
      }

      const isRevealing = state.paginated[page].every(
        (wonka) =>
          wonka.code !== payload.code &&
          wonka.revealed === true &&
          (wonka.status === 'completed' || wonka.status === 'in_progress'),
      )

      return {
        ...state,
        paginated: {
          ...state.paginated,
          [page]: [
            ...state.paginated[page].slice(0, index),
            {
              ...state.paginated[page][index],
              revealed: true,
              explosionVideo: true,
              ...payload,
            },
            ...state.paginated[page].slice(index + 1),
          ],
        },
        loading: setLoading(state.loading, 'revealAll', isRevealing),
      }

    case types.SET_WONKA_PURCHASE_LOADING:
      return {
        ...state,
        loading: setLoading(state.loading, 'purchase', payload),
      }

    case types.SET_WONKA_REVEAL_ALL_LOADING:
      return {
        ...state,
        loading: setLoading(state.loading, 'revealAll', payload),
      }

    case types.REVEAL_ALL_WONKA_REQUEST:
      return {
        ...state,
        loading: setLoading(state.loading, 'revealAll', true),
      }
    case types.REVEAL_ALL_WONKA_IMMEDIATELY_SUCCESS:
      return {
        ...state,
        paginated: {
          ...state.paginated,
          [payload.page]: payload.list.map((wonka, wonkaId) => ({
            ...wonka,
            explosionVideo: state.paginated[payload.page][wonkaId].revealed === false,
          })),
        },
        loading: setLoading(state.loading, 'reveal', false),
      }

    case types.SET_WONKA_REVEAL_EXPLOSION:
      const explosionIndex = state.paginated[payload.page].findIndex(
        ({ code }) => code === payload.code,
      )

      if (explosionIndex === -1) {
        return state
      }
      return {
        ...state,
        paginated: {
          ...state.paginated,
          [payload.page]: [
            ...state.paginated[payload.page].slice(0, explosionIndex),
            {
              ...state.paginated[payload.page][explosionIndex],
              explosionVideo: payload.explosionVideo,
            },
            ...state.paginated[payload.page].slice(explosionIndex + 1),
          ],
        },
        loading: setLoading(state.loading, 'reveal', false),
      }

    case types.SET_WONKA_TOKENS_POPUP:
      return {
        ...state,
        wonkaTokensPopup: {
          isOpen: payload.isOpen,
          tid: payload?.tid || null,
        },
      }

    default:
      return state
  }
}

const wonkaConfig = {
  key: 'peer_wonkas',
  storage,
  transforms: [encryptor],
}

export default persistReducer(wonkaConfig, wonkaReducer)
