/* eslint-disable object-shorthand */
import { Atom, CornersOut, ShoppingCart, UploadSimple } from '@phosphor-icons/react'

export enum ConvertedSizeToIconWidth {
  spacious = 32,
  compact = 32,
  small = 24,
}

export const PhosphorIcons = {
  UploadSimple: UploadSimple,
  CornersOut: CornersOut,
  ShoppingCart: ShoppingCart,
  Atom: Atom,
}

export type TIconsKeyNames =
  | keyof typeof PhosphorIcons
  | 'CustomIconClose'
  | 'CustomIconArrowLeft'
  | 'CustomIconArrowRight'
  | 'CustomIconResend'
  | 'CustomIconShareX'
  | 'CustomIconShareTG'
  | 'CustomIconShareEmail'
  | 'CustomIconShareCopy'
  | 'CustomIconShareCopied'
