import React from 'react'
import cn from 'classnames'
import AtomAction from '@components/Icons/UIKit/AtomAction'
import SwapAction from '@components/Icons/UIKit/SwapAction'
import Button from '../Button'
import { TActionVariant } from '../constants'

import styles from './actionButton.module.scss'

interface IActionButtonProps {
  children?: React.ReactNode
  variant?: TActionVariant
  disabled?: boolean
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const variants = {
  atom: AtomAction,
  swap: SwapAction,
}

export default function ActionButton({
  children,
  variant = 'atom',
  disabled = false,
  className,
  onClick,
}: IActionButtonProps) {
  const IconComponent = variants[variant] || SwapAction

  return (
    <Button
      variant="text"
      onClick={onClick}
      className={cn(styles.button, styles[variant], className, {
        [styles.disabled]: disabled,
      })}
    >
      <span className={styles.iconGroup}>
        <IconComponent /> {children}
      </span>
    </Button>
  )
}
