import React, { useSearchParams } from 'react-router-dom'
import MainTransactions from './main'
import DetailsTransactions from './details'

function Transactions() {
  const [search] = useSearchParams()
  const code = search.get('code')

  if (code) {
    return <DetailsTransactions />
  }

  return <MainTransactions />
}

export default Transactions
