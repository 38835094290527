import axios from 'axios'
import MockAdapter from 'axios-mock-adapter'

const mock = new MockAdapter(axios, { delayResponse: 500, onNoMatch: 'passthrough' })

// NOTE: as an example for development when API is not ready yet
// To turn on set enableMocks = true in constants/environments
mock
  .onPost(
    // eslint-disable-next-line max-len
    'https://swap.peerclub.com/api/v2/barong/identity/sessions/verify/otp',
  )
  .reply(401, { errors: ['resource.session.verification_invalid'] })
