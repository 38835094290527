export const FETCH_LOGOUT = 'auth/FETCH_LOGOUT'
export const FAIL_LOGOUT = 'auth/FAIL_LOGOUT'
export const SUCCESS_LOGOUT = 'auth/SUCCESS_LOGOUT'
export const FETCH_LOGIN = 'auth/FETCH_LOGIN'
export const SET_NEW_PASSWORD = 'auth/SET_NEW_PASSWORD'

export const FETCH_OTP_VERIFY = 'auth/FETCH_OTP_VERIFY'
export const FETCH_LOGIN_SUCCESS = 'auth/FETCH_LOGIN_SUCCESS'
export const FAIL_LOGIN = 'auth/FAIL_LOGIN'
export const RESET_FAIL_LOGIN = 'auth/RESET_FAIL_LOGIN'
export const OTP_RESEND = 'auth/OTP_RESEND'

export const FETCH_USER = 'user/FETCH_USER'
export const FETCH_USER_FEE = 'user/FETCH_USER_FEE'
export const SET_USER_FEE = 'user/SET_USER_FEE'
export const SUCCESS_USER = 'user/SUCCESS_USER'
export const FAIL_USER = 'user/FAIL_USER'
export const RESET_USER = 'user/RESET_USER'
export const VERIFY_USER = 'user/VERIFY_USER'
export const FETCH_DATA = 'user/FETCH_DATA'

export const FETCH_WALLET_DATA = 'wallet/FETCH_WALLET_DATA'
export const SUCCESS_WALLET_DATA = 'wallet/SUCCESS_WALLET_DATA'
export const FAIL_WALLET_DATA = 'wallet/FAIL_WALLET_DATA'
export const SET_ACTIVE_WALLET = 'wallet/SET_ACTIVE_WALLET'
export const FETCH_WALLET_ADDRESS = 'wallet/FETCH_WALLET_ADDRESS'
export const SUCCESS_WALLET_ADDRESS = 'wallet/SUCCESS_WALLET_ADDRESS'
export const FAIL_WALLET_ADDRESS = 'wallet/FAIL_WALLET_ADDRESS'

export const FETCH_HISTORY = 'history/FETCH_HISTORY'
export const SUCCESS_HISTORY = 'history/SUCCESS_HISTORY'
export const FAIL_HISTORY = 'history/FAIL_HISTORY'

export const FETCH_SUBMIT_WITHDRAW = 'withdraw/FETCH_SUBMIT_WITHDRAW'
export const SUCCESS_SUBMIT_WITHDRAW = 'withdraw/SUCCESS_SUBMIT_WITHDRAW'
export const FAIL_SUBMIT_WITHDRAW = 'withdraw/FAIL_SUBMIT_WITHDRAW'
export const HANDLE_CHANGE_WITHDRAW = 'withdraw/HANDLE_CHANGE_WITHDRAW'
export const CLEAR_WITHDRAW_FORM = 'withdraw/CLEAR_WITHDRAW_FORM'

export const REGISTER_USER = 'register/REGISTER_USER'
export const SUCCESS_REGISTER_USER = 'register/SUCCESS_REGISTER_USER'
export const FAIL_REGISTER_USER = 'register/FAIL_REGISTER_USER'
export const RESET_REGISTER_USER = 'register/RESET_REGISTER_USER'
export const FETCH_ICX_PROGRESS = 'user/ICX_PROGRESS'
export const SAVE_ICX_PROGRESS = 'user/SAVE_ICX_PROGRESS'
export const GET_CIRCULATING_SUPPLY = 'user/GET_CIRCULATING_SUPPLY'
export const SAVE_CIRCULATING_SUPPLY = 'user/SAVE_CIRCULATING_SUPPLY'
export const GET_USA_DISCLAIMER = 'user/GET_USA_DISCLAIMER'
export const POST_COUNTRY = 'user/POST_COUNTRY'
export const SET_COUNTRY = 'user/SET_COUNTRY'
export const SUCCESS_USA_DISCLAIMER = 'user/SUCCESS_USA_DISCLAIMER'
export const FAIL_USA_DISCLAIMER = 'user/FAIL_USA_DISCLAIMER'
export const VERIFY_USER_NAME = 'register/VERIFY_USER_NAME'
export const VALID_USER_NAME = 'register/VALID_USER_NAME'

export const SEND_CONFIRMATION_EMAIL = 'register/SEND_CONFIRMATION_EMAIL'
export const SUCCESS_SEND_CONFIRMATION_EMAIL = 'register/SUCCESS_SEND_CONFIRMATION_EMAIL'
export const FAIL_SEND_CONFIRMATION_EMAIL = 'register/FAIL_SEND_CONFIRMATION_EMAIL'
export const RESET_SEND_CONFIRMATION_EMAIL = 'register/RESET_SEND_CONFIRMATION_EMAIL'

export const EMAIL_CONFIRMATION = 'user/EMAIL_CONFIRMATION'
export const SUCCESS_EMAIL_CONFIRMATION = 'user/SUCCESS_EMAIL_CONFIRMATION'
export const FAIL_EMAIL_CONFIRMATION = 'user/FAIL_EMAIL_CONFIRMATION'
export const RESET_EMAIL_CONFIRMATION = 'user/RESET_EMAIL_CONFIRMATION'

export const GET_CURRENCIES = 'GET_CURRENCIES'
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS'
export const GET_CURRENCIES_FAILURE = 'GET_CURRENCIES_FAILURE'
export const SET_SELECTED_CURRENCY = 'SET_SELECTED_CURRENCY'
export const SET_SEARCHED_CURRENCY = 'SET_SEARCHED_CURRENCY'
export const UPDATED_CURRENCIES_SUCCESS = 'UPDATED_CURRENCIES_SUCCESS'

export const UPDATE_PROFILE = 'profile/UPDATE_PROFILE'
export const SET_KYC_AID = 'profile/SET_KYC_AID'
export const KYC_AID = 'profile/KYC_AID'
export const SAVE_PROFILE = 'profile/SAVE_PROFILE'
export const FAIL_PROFILE = 'profile/FAIL_PROFILE'
export const INITIATE_NET_VERIFY = 'profile/INITIATE_NET_VERIFY'
export const SAVE_NET_VERIFY = 'profile/SAVE_NET_VERIFY'
export const SAVE_TRANSACTION_REFERENCE = 'profile/SAVE_TRANSACTION_REFERENCE'
export const SUCCESS_TRANSACTION_REFERENCE = 'profile/SUCCESS_TRANSACTION_REFERENCE'

export const SAVE_NUMBER = 'phone/SAVE_NUMBER'
export const SUCCESS_SAVE_NUMBER = 'phone/SUCCESS_SAVE_NUMBER'
export const FAIL_SAVE_NUMBER = 'phone/FAIL_SAVE_NUMBER'

export const VERIFY_NUMBER = 'phone/VERIFY_NUMBER'
export const SUCCESS_VERIFY_NUMBER = 'phone/SUCCESS_VERIFY_NUMBER'
export const FAIL_VERIFY_NUMBER = 'phone/FAIL_VERIFY_NUMBER'

export const CONFIRM_EMAIL = 'email/CONFIRM_EMAIL'

export const FORGOT_PASSWORD = 'user/FORGOT_PASSWORD'
export const SUCCESS_FORGOT_PASSWORD = 'user/SUCCESS_FORGOT_PASSWORD'
export const FAIL_FORGOT_PASSWORD = 'user/FAIL_FORGOT_PASSWORD'

export const RESET_PASSWORD = 'user/RESET_PASSWORD'
export const SUCCESS_RESET_PASSWORD = 'user/FAIL_RESET_PASSWORD'
export const FAIL_RESET_PASSWORD = 'user/FAIL_RESET_PASSWORD'

export const RESEND_OTP = 'phone/RESEND_OTP'
export const SUCCESS_RESEND_OTP = 'phone/SUCCESS_RESEND_OTP'
export const FAIL_RESEND_OTP = 'phone/FAIL_RESEND_OTP'

export const CHANGE_PASSWORD = 'settings/CHANGE_PASSWORD'
export const SUCCESS_CHANGE_PASSWORD = 'settings/SUCCESS_CHANGE_PASSWORD'
export const FAIL_CHANGE_PASSWORD = 'settings/FAIL_CHANGE_PASSWORD'

export const GENERATE_CODE = 'setting/GENERATE_CODE'
export const SUCCESS_GENERATE_CODE = 'settings/SUCCESS_GENERATE_CODE'
export const FAIL_GENERATE_CODE = 'settings/FAIL_GENERATE_CODE'

export const VERIFY_QR_CODE = 'setting/VERIFY_QR_CODE'
export const SUCCESS_VERIFY_QR_CODE = 'settings/SUCCESS_VERIFY_QR_CODE'
export const FAIL_VERIFY_QR_CODE = 'settings/FAIL_VERIFY_QR_CODE'

export const DISABLE_GOOGLE_AUTH = 'setting/DISABLE_GOOGLE_AUTH'
export const SUCCESS_DISABLE_GOOGLE_AUTH = 'settings/SUCCESS_DISABLE_GOOGLE_AUTH'
export const FAIL_DISABLE_GOOGLE_AUTH = 'settings/FAIL_DISABLE_GOOGLE_AUTH'

export const API_TOKENS = 'setting/API_TOKENS'
export const SUCCESS_API_TOKENS = 'settings/SUCCESS_API_TOKENS'
export const FAIL_API_TOKENS = 'settings/FAIL_API_TOKENS'

export const ADD_TOKEN = 'setting/ADD_TOKEN'
export const SUCCESS_ADD_TOKEN = 'settings/SUCCESS_ADD_TOKEN'
export const FAIL_ADD_TOKEN = 'settings/FAIL_ADD_TOKEN'

export const CHANGE_STATE = 'setting/CHANGE_STATE'
export const SUCCESS_CHANGE_STATE = 'settings/SUCCESS_CHANGE_STATE'
export const FAIL_CHANGE_STATE = 'settings/FAIL_CHANGE_STATE'

export const DELETE_TOKEN = 'setting/DELETE_TOKEN'
export const SUCCESS_DELETE_TOKEN = 'settings/SUCCESS_DELETE_TOKEN'
export const FAIL_DELETE_TOKEN = 'settings/FAIL_DELETE_TOKEN'

export const FETCH_LAST_LOGIN = 'settings/FETCH_LAST_LOGIN'
export const SUCCESS_LAST_LOGIN = 'settings/SUCCESS_LAST_LOGIN'

export const SUCCESS_VI_ACCOUNT_CONFIRMATION = 'settings/SUCCESS_VI_ACCOUNT_CONFIRMATION'
export const FETCH_VI_ACCOUNT_CONFIRMATION = 'settings/FETCH_VI_ACCOUNT_CONFIRMATION'
export const FAIL_VI_ACCOUNT_CONFIRMATION = 'settings/FAIL_VI_ACCOUNT_CONFIRMATION'

export const UPLOAD_PROFILE_PICTURE = 'settings/UPLOAD_PROFILE_PICTURE'

export const OPEN_MODEL = 'OPEN_MODEL'
export const API_CREATED_SUCCESS_OPEN_MODEL = 'API_CREATED_SUCCESS_OPEN_MODEL'

export const FETCH_BALANCE = 'account/FETCH_BALANCE'
export const SUCCESS_FETCH_BALANCE = 'account/SUCCESS_FETCH_BALANCE'
export const FAIL_FETCH_BALANCE = 'account/FAIL_FETCH_BALANCE'
export const SEARCH_BALANCE = 'account/SEARCH_BALANCE'

export const FETCH_ADVERTISEMENTS = 'FETCH_ADVERTISEMENTS'
export const SUCCESS_FETCH_ADVERTISEMENTS = 'SUCCESS_FETCH_ADVERTISEMENTS'
export const FAIL_FETCH_ADVERTISEMENTS = 'FAIL_FETCH_ADVERTISEMENTS'

export const GET_ADDRESS = 'address/GET_ADDRESS'
export const GET_ADDRESS_SUCCESS = 'address/GET_ADDRESS_SUCCESS'
export const GET_ADDRESS_FAILURE = 'address/GET_ADDRESS_FAILURE'

export const SAVE_CONTACTS = 'contact/SAVE_CONTACTS'
export const SUCCESS_SAVE_CONTACTS = 'contact/SUCCESS_SAVE_CONTACTS'
export const FAIL_SAVE_CONTACTS = 'contact/FAIL_SAVE_CONTACTS'

export const ADD_VI_USER_ID = 'settings/ADD_VI_USER_ID'
export const SUCCESS_ADD_VI_USER_ID = 'settings/SUCCESS_ADD_VI_USER_ID'
export const FAIL_ADD_VI_USER_ID = 'settings/FAIL_ADD_VI_USER_ID'

export const VERIFY_INVESTOR_REQUEST = 'settings/VERIFY_INVESTOR_REQUEST'
export const SUCCESS_VERIFY_INVESTOR_REQUEST = 'settings/SUCCESS_VERIFY_INVESTOR_REQUEST'
export const FAIL_VERIFY_INVESTOR_REQUEST = 'settings/FAIL_VERIFY_INVESTOR_REQUEST'

export const UPDATE_VERIFICATION_STATUS = 'settings/UPDATE_VERIFICATION_STATUS'
export const SUCCESS_UPDATE_VERIFICATION_STATUS = 'settings/SUCCESS_UPDATE_VERIFICATION_STATUS'
export const FAIL_UPDATE_VERIFICATION_STATUS = 'settings/FAIL_UPDATE_VERIFICATION_STATUS'

export const SEND_PASSWORD_OTP = 'SEND_PASSWORD_OTP'
export const SUCCESS_SEND_PASSWORD_OTP = ' SUCCESS_SEND_PASSWORD_OTP'
export const FAIL_SEND_PASSWORD_OTP = 'FAIL_SEND_PASSWORD_OTP'

export const SUCCESS_SET_NEW_PASSWORD = 'SUCCESS_SET_NEW_PASSWORD'
export const FAIL_SET_NEW_PASSWORD = 'FAIL_SET_NEW_PASSWORD'
