import { takeLatest, all, put, call } from 'redux-saga/effects'
import queryString from 'query-string'

import { API } from '@core/api'
import { API_URLS } from '@core/api/apiUrls'
import { errorsHandler } from '@core/helper'

import types from './types'
import {
  fetchCurrenciesSuccess,
  fetchBalanceSuccess,
  fetchBalanceFailure,
  fetchCurrenciesFailure,
  fetchPriceDefaultCoinSuccess,
  fetchPriceDefaultCoinFailure,
  fetchFiatBanxaCurrenciesSuccess,
  fetchFiatBanxaCurrenciesFailure,
  fetchPaymentBanxaMethodsFailure,
  fetchPaymentBanxaMethodsSuccess,
  successPresale,
  failedPresale,
  updateCurrenciesPrice,
  fetchGetWithdrawLimitsSuccess,
  fetchGetWithdrawLimitsFailure,
} from './actions'

const peatioConfig = {
  apiVersion: 'peatio',
}

function* fetchWithdrawLimitsSaga() {
  try {
    const limits = yield call(API.get(peatioConfig), API_URLS.account.withdrawLimits)
    yield put(fetchGetWithdrawLimitsSuccess(limits))
  } catch (err) {
    yield put(fetchGetWithdrawLimitsFailure())
  }
}

function* fetchCurrenciesSaga() {
  try {
    const currencies = yield call(API.get(peatioConfig), API_URLS.public.currencies)

    yield put(fetchCurrenciesSuccess(currencies))
    const prices = currencies.reduce((acc, item) => ({ ...acc, [item.id]: Number(item.price) }), {})
    yield put(updateCurrenciesPrice(prices))
  } catch (err) {
    yield put(fetchCurrenciesFailure())
  }
}

function* fetchFiatBanxaCurrenciesSaga() {
  try {
    const currencies = yield call(API.get(peatioConfig), API_URLS.account.banxa.fiatCurrencies)

    yield put(fetchFiatBanxaCurrenciesSuccess(currencies))
  } catch (err) {
    yield put(fetchFiatBanxaCurrenciesFailure())
  }
}

function* fetchPaymentBanxaMethodsSaga({ payload }) {
  try {
    const url = `${API_URLS.account.banxa.paymentMethods}?${queryString.stringify(payload)}`
    const currencies = yield call(API.get(peatioConfig), url)

    yield put(fetchPaymentBanxaMethodsSuccess(currencies))
  } catch (err) {
    yield put(fetchPaymentBanxaMethodsFailure())
  }
}

function* fetchBalanceSaga({ payload }) {
  try {
    let url = API_URLS.account.balances
    if (payload) {
      url = `${url}/${payload}`
    }
    const balances = yield call(API.get(peatioConfig), url)
    let arr = []
    if (!Array.isArray(balances)) {
      arr.push(balances)
    } else {
      arr = balances
    }
    yield put(fetchBalanceSuccess(arr))
  } catch (err) {
    yield put(fetchBalanceFailure())
  }
}

function* fetchPriceDefaultCoin() {
  try {
    const res = yield call(API.get(peatioConfig), API_URLS.public.currentPrice)

    yield put(fetchPriceDefaultCoinSuccess(res))
  } catch (err) {
    yield put(fetchPriceDefaultCoinFailure())
  }
}

export function* fetchPresale() {
  try {
    const data = yield call(API.get(peatioConfig), `/public/active_presale`)
    yield put(successPresale(data))
  } catch (err) {
    errorsHandler(err)
    yield put(failedPresale())
  }
}

export function* postNewAgreement(action) {
  try {
    const url = 'account/swap_agreement'
    const adjustment = yield call(API.post(peatioConfig), url, action.payload)
    if (action.callback) {
      action.callback(adjustment)
    }
  } catch (err) {
    if (action.callback) {
      action.callback(err)
    }

    errorsHandler(err)
  }
}

export function* rootSagaCommon() {
  yield all([takeLatest(types.FETCH_GET_WITHDRAW_LIMITS, fetchWithdrawLimitsSaga)])
  yield all([takeLatest(types.FETCH_CURRENCIES_REQUEST, fetchCurrenciesSaga)])
  yield all([takeLatest(types.FETCH_FIAT_BANXA_CURRENCIES_REQUEST, fetchFiatBanxaCurrenciesSaga)])
  yield all([takeLatest(types.FETCH_PAYMENT_BANXA_METHODS_REQUEST, fetchPaymentBanxaMethodsSaga)])
  yield all([takeLatest(types.FETCH_BALANCE_REQUEST, fetchBalanceSaga)])
  yield all([takeLatest(types.FETCH_PRICE_DEFAULT_COIN_REQUEST, fetchPriceDefaultCoin)])
  yield all([takeLatest(types.NEW_AGREEMENT, postNewAgreement)])
  yield all([takeLatest(types.GET_PRESALE, fetchPresale)])
}
