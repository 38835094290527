import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { sentryErrorHandler } from '@utils/errors'

import { FormattedError, IApiConfig, IRequestConfig } from '@core/api/types'
import { applogicUrl, authUrl, tradeUrl, withCredentials, aliasUrl } from './config'

const getCsrfToken = () => localStorage.getItem('csrfToken') || undefined

const getAPI = () => ({
  barong: authUrl(),
  applogic: `${applogicUrl()}`,
  peatio: `${tradeUrl()}`,
  alias: `${aliasUrl()}`,
})

const getAxiosRequestConfig = (
  request: IRequestConfig,
  configData: IApiConfig,
): AxiosRequestConfig => {
  const { body, method, url } = request
  const { apiVersion } = configData
  const api = getAPI()
  const contentType = body instanceof FormData ? 'multipart/form-data' : 'application/json'

  const defaultHeaders = {
    'content-type': contentType,
    'X-CSRF-Token': getCsrfToken(),
  }
  const apiUrl = api[apiVersion]

  return {
    baseURL: apiUrl,
    data: body,
    headers: defaultHeaders,
    method,
    url,
    withCredentials: withCredentials(),
  }
}

export const defaultResponse = {
  code: 500,
  message: ['Server error'],
}

function hasErrors(
  error: AxiosError<{ errors: string[] } | { error: string }>,
): error is AxiosError<{ errors: string[] }> {
  return (error as AxiosError<{ errors: string[] }>).response?.data.errors !== undefined
}

function hasError(
  error: AxiosError<{ errors: string[] } | { error: string }>,
): error is AxiosError<{ error: string }> {
  return (error as AxiosError<{ error: string }>).response?.data.error !== undefined
}

export const formatError = (
  responseError: AxiosError<{ errors: string[] } | { error: string }>,
): FormattedError => {
  if (!responseError?.response) {
    return defaultResponse
  }

  const response = responseError?.response

  let message: string[] = ['']

  if (hasErrors(responseError)) {
    message = responseError.response?.data.errors
  } else if (hasError(responseError)) {
    message = [responseError.response?.data.error]
  }

  sentryErrorHandler(responseError)

  return {
    code: response.status,
    message,
  }
}

export const makeRequest = async <R = unknown>(
  request: IRequestConfig,
  configData: IApiConfig,
): Promise<AxiosResponse<R>['data']> => {
  const requestConfig = getAxiosRequestConfig(request, configData)

  return new Promise<AxiosResponse<R>['data']>((resolve, reject) => {
    const axiosRequest = axios<R>(requestConfig)
    axiosRequest
      .then((response) => {
        if (configData.withHeaders) {
          resolve(response as AxiosResponse<R>['data'])
        } else {
          resolve(response.data)
        }
      })
      .catch((error: AxiosError) => {
        reject(formatError(error as AxiosError<{ errors: string[] } | { error: string }, unknown>))
      })
  })
}
