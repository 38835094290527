import { useState, useEffect, useRef } from 'react'

export enum ScrollDirectionY {
  top = 'top',
  bottom = 'bottom',
  up = 'up',
  down = 'down',
}

export interface IScrollInfo {
  scrollY: number
  scrollX: number
  scrollDirectionY: ScrollDirectionY
}

const useScroll = (): IScrollInfo => {
  const [scrollInfo, setScrollInfo] = useState<IScrollInfo>({
    scrollY: 0,
    scrollX: 0,
    scrollDirectionY: ScrollDirectionY.top,
  })

  const prevScrollYRef = useRef(0)

  const listener = () => {
    const currentScrollY = window.scrollY
    const currentScrollX = window.scrollX
    const documentHeight = document.body.scrollHeight
    const windowHeight = window.innerHeight

    const newScrollInfo = {
      scrollY: currentScrollY,
      scrollX: currentScrollX,
      scrollDirectionY: ScrollDirectionY.top,
    }

    if (currentScrollY === 0) {
      newScrollInfo.scrollDirectionY = ScrollDirectionY.top
    } else if (currentScrollY + windowHeight >= documentHeight) {
      newScrollInfo.scrollDirectionY = ScrollDirectionY.bottom
    } else if (currentScrollY < prevScrollYRef.current) {
      newScrollInfo.scrollDirectionY = ScrollDirectionY.up
    } else {
      newScrollInfo.scrollDirectionY = ScrollDirectionY.down
    }

    prevScrollYRef.current = currentScrollY
    setScrollInfo(newScrollInfo)
  }

  useEffect(() => {
    window.addEventListener('scroll', listener)

    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])

  return scrollInfo
}

export default useScroll
