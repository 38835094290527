import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { getUserRequested } from '../actions'
import * as actions from '../actions/cryptoSetting'
import * as types from '../constants'
import { showAlert } from '../actions/alert'
import { API } from '../../api'

const peatioConfig = {
  apiVersion: 'peatio',
}

export function* fetchCurrentPrice() {
  yield put(getUserRequested(true))
  try {
    const url = '/public/current_price'
    const cryptoSetting = yield call(API.get(peatioConfig), url)
    yield put(actions.successTuxcCryptoSetting(cryptoSetting))
    yield put(getUserRequested(false))
  } catch (e) {
    yield put(showAlert({ message: e.message, code: e.code, type: 'error' }))
    yield put(getUserRequested(false))
    yield put(actions.failTuxcCryptoSetting(e))
  }
}

export function* fetchUsdPrice(action) {
  try {
    let response = yield call(API.post(peatioConfig), '/public/current_price', action.payload)
    response = {
      ...response,
      ...{ price: response, amount: action.payload.amount },
    }
    yield put(getUserRequested(false))
    yield put(actions.successUsdPrice(response))
  } catch (e) {
    yield put(getUserRequested(false))
    yield put(showAlert({ message: e.code, code: e.code, type: 'error' }))
  }
}

export function* fetchCryptoSetting() {
  yield takeEvery(types.FETCH_CRYPTO_SETTING, fetchCurrentPrice)
  yield takeLatest(types.FETCH_USD_PRICE, fetchUsdPrice)
}
