import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal, { IModalType } from '@components/UiKit/Modal'
import Typography from '@components/UiKit/Typography'

import styles from './modal.module.scss'

const { Heading, Paragraph } = Typography

function ConnectWallet({ open, onClose }: Readonly<IModalType>) {
  const { t } = useTranslation('nsCommon')

  return (
    <Modal open={open} onClose={onClose} className={styles.connectWalletWrapper}>
      <Heading size={3} className={styles.title}>
        {t('connectWallet')}
      </Heading>

      <div className={styles.list}>{t('walletConnect')}</div>

      <Paragraph font="Gilroy" size={2} className={styles.modal_paragraph}>
        {t('connectingWalletAgree')}
        <br />
        {t('termsAndPrivacy')}
      </Paragraph>
    </Modal>
  )
}

export default React.memo(ConnectWallet)
