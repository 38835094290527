import { localeDate, getTimezone } from '../../helpers'

export const convertTradeEventToTrade = (market, trade) => ({
  market,
  id: trade.tid,
  created_at: localeDate(trade.date, getTimezone(), ''),
  taker_type: trade.taker_type,
  price: String(trade.price),
  amount: String(trade.amount),
  funds: String(Number(trade.price) * Number(trade.amount)),
})

export const convertTradeEventList = (market, trades) =>
  trades.map((trade) => convertTradeEventToTrade(market, trade))
