import {
  GET_CURRENCIES,
  GET_CURRENCIES_SUCCESS,
  GET_CURRENCIES_FAILURE,
  SET_SELECTED_CURRENCY,
  SET_SEARCHED_CURRENCY,
} from '../constants'
import { UPDATED_CURRENCIES_SUCCESS } from '../constants/actions'

export const getCurrencies = (payload) => ({ type: GET_CURRENCIES, payload })

export const successCurrency = (payload) => ({ type: GET_CURRENCIES_SUCCESS, payload })

export const successUpdatedCurrency = (payload) => ({ type: UPDATED_CURRENCIES_SUCCESS, payload })

export const failedCurrency = () => ({ type: GET_CURRENCIES_FAILURE })

export const setSelectedCurrency = (payload) => ({ type: SET_SELECTED_CURRENCY, payload })

export const setSearchedCurrency = (payload) => ({ type: SET_SEARCHED_CURRENCY, payload })
