import React, { memo, ReactNode, useMemo } from 'react'
import ReactSelect, {
  FormatOptionLabelMeta,
  IndicatorsContainerProps,
  StylesConfig,
} from 'react-select'
import cn from 'classnames'
import isEqual from 'react-fast-compare'

import {
  StateManagerProps,
  // eslint-disable-next-line import/no-unresolved
} from 'react-select/dist/declarations/src/stateManager'

import { CustomIndicator } from '@components/UiKit/Form/Dropdown/components/CustomIndicator'
import { CustomOption } from '@components/UiKit/Form/Dropdown/components/CustomOption'
import { Option } from '@components/UiKit/Form/Dropdown/Dropdown.types'
import { ThorizontalMenuPlacement } from '@components/UiKit/Form/Dropdown/types'
import useCurrentWidth from '@hooks/useCurrentWidth'
import { TSize } from '@components/UiKit/types'
import styles from './style.module.scss'
import { getCustomStyles } from './Dropdown.utils'

export type IDropdownProps = StateManagerProps & {
  classNameWrapper?: string
  label?: string
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
  ) => ReactNode
  size?: TSize
  isDropdownDisabled?: boolean
  disabled?: boolean
  children?: ReactNode
  horizontalMenuPlacement?: ThorizontalMenuPlacement
  minimizeOnMobile?: boolean
}

function Dropdown({
  label,
  formatOptionLabel,
  size,
  getOptionLabel,
  isDropdownDisabled = false,
  minimizeOnMobile,
  horizontalMenuPlacement,
  classNameWrapper,
  ...rest
}: Readonly<IDropdownProps>) {
  const { size: currentSize } = useCurrentWidth()
  const displaySize = size || currentSize
  const customStyles: StylesConfig = useMemo(
    () => getCustomStyles(displaySize, horizontalMenuPlacement),
    [displaySize],
  )

  const partionedCustomOption =
    (minimizeOnMobile) => (item: Option, meta: FormatOptionLabelMeta<Option>) =>
      CustomOption(minimizeOnMobile, item, meta)
  const customOptionWithIsMinimized = partionedCustomOption(minimizeOnMobile)

  const partionedCustomIndicator =
    (minimizeOnMobile) => (containerProps: IndicatorsContainerProps<Option>) =>
      CustomIndicator(minimizeOnMobile, containerProps)
  const customIndicatorWithIsMinimized = partionedCustomIndicator(minimizeOnMobile)

  return (
    <div className={cn(styles.wrapper, classNameWrapper)}>
      {label && <div className={cn(styles.label)}>{label}</div>}
      <ReactSelect
        isDisabled={isDropdownDisabled}
        formatOptionLabel={
          !getOptionLabel ? formatOptionLabel || customOptionWithIsMinimized : undefined
        }
        getOptionLabel={getOptionLabel}
        isSearchable={false}
        styles={customStyles}
        maxMenuHeight={displaySize === 'spacious' ? 270 : 245}
        components={{
          IndicatorsContainer: customIndicatorWithIsMinimized,
        }}
        menuPlacement="auto"
        {...rest}
      />
    </div>
  )
}
export default memo(Dropdown, isEqual)
